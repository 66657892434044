import React, { FC } from 'react';

import { Button } from '@beeline/design-system-react';

import styles from '../styles.pcss';

interface LoaderBtnProps{
  disabled?: boolean;
  id?: string,
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  isWhite?: boolean;
}

export const LoaderBtn: FC<LoaderBtnProps> = ({ children, disabled = false, isWhite = false, id = '', ...props }) => {
  return (
    <Button
      {...props}
      disabled={disabled}
      id={id}
      size="medium"
      variant={isWhite ? 'outlined' : 'contained'}
      className={isWhite ? styles.whiteBtn : styles.nextBtn}
    >{children}</Button>
  );
};

