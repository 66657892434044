import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Templates from 'store/mobx/Templates';
import CreateReportStore from 'store/mobx/CreateFocusReport';
import { PhoneInfo } from 'components/layouts';
import commonStyles from 'styles/common.pcss';
import ErrorBase from '../ErrorBase';
import CreateCampaignButton from '../../CreateCampaignButton';
import ProcessInfoText from '../../../../../components/ProcessInfoText';
import DescriptionWrapper from '../../DescriptionWrapper';

const cx = classNames.bind(commonStyles);

function ErrorCalculateCost({ onClose }) {
  const { error } = CreateReportStore;
  const { getPopupsTemplate } = Templates;
  const { button, infoText, phone, icon } = getPopupsTemplate('ErrorCalculateCost');
  return (
    <ErrorBase title={error.title} level={2} iconType={icon}>
      <DescriptionWrapper level={5}>
        <ProcessInfoText>{error.description || ''}</ProcessInfoText>
      </DescriptionWrapper>

      <CreateCampaignButton onClose={onClose} iconSlug="arrowRightMinimal" className={cx('marb-l')}>
        {button}
      </CreateCampaignButton>

      <div className={cx('marb-xxs')}>{infoText}</div>

      <PhoneInfo>{phone}</PhoneInfo>
    </ErrorBase>
  );
}

ErrorCalculateCost.propTypes = {
  onClose: PropTypes.func,
};

export default observer(ErrorCalculateCost);
