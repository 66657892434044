import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import React, { FC, useEffect } from 'react';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { PopupStateless } from 'redesignSrc/UI/modals/Popup';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import UserInfo from 'store/mobx/UserInfo';
import styles from '../styles.pcss';

export interface AgreementModalProps {
  showModal?: boolean;
}

export const AgreementModal: FC<AgreementModalProps> = observer(() => {
  const onCancel = () => {
    messageTemplatesStore.agreementModal = false;
  };


  const agreementHandler = async () => {
    await messageTemplatesStore.verificationTemplate();
    messageTemplatesStore.agreementModal = false;
    await navigate('/message-templates');
  };

  return (
    <>
      <PopupStateless
        opened={messageTemplatesStore.agreementModal}
        className={styles.modal}
        onClose={onCancel}>
        <div className={styles.modalTitle}>Шаблон отправлен на согласование</div>
        <p style={{ marginBottom: '23px', textAlign: 'center', fontSize: '16px' }}>
          Срок согласования 1 рабочий день. О результате согласования вам придет уведомление на почту
        </p>
        <div>

          <div>
            <PrimaryButton
              className={styles.sendModalBtnCancel}
              onClick={agreementHandler}
            >
              Хорошо
            </PrimaryButton>
          </div>
        </div>
      </PopupStateless>
    </>
  );
});

