import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Router } from '@reach/router';
import { observer } from 'mobx-react';
import { PageLoader } from 'components/common/Loaders/components';
import InfoExternalOperator from 'components/modals/InfoExternalOperator';
import {
  useLocationScrollController,
} from 'containers/App/components/Location/hooks';
import PageLayout from 'redesignSrc/components/PageLayout';
import MyCampaignsPage from 'redesignSrc/pages/MyCampaigns';
import UserInfo from 'store/mobx/UserInfo';
import { SenderNamesPage } from 'redesignSrc/pages/SenderNames';
import CreateSenderName, { EditSenderName } from 'redesignSrc/pages/SenderNameCard/CreateSenderName';
import { MessageTemplatesPage } from 'redesignSrc/pages/MessageTemplates';
import { bonusesStore } from 'store/mobx/BonusesStore';
import WheelOfFortune from '../components/WheelOfFortune';
import {
  Notifications,
} from './components';

import {
  AUDIENCE_STATISTIC_REPORT_URL,
  AUDIENCE_STATISTIC_URL,
  FAQ_URL,
  FAQ_URL_SUBPAGE,
  MY_CAMPAIGNS_URL,
  MESSAGE_TEMPLATES_URL,
  MY_CAMPAIGNS_STATISTIC_URL,
  NEW_CAMPAIGN_URL,
  USER_INFO_URL,
  POLLS_PAGE_URL,
  MY_CAMPAIGNS_REPEAT_URL,
  MY_AUDIENCES,
  MY_FINANCES,
  NEW_AUDIENCES, NEW_AUDIENCES_TSMS, NEW_TEMPLATE, SENDER_NAMES_URL, NEW_SENDER, COUNTER_URL, SIMPLE_AUDIENCE, AUDIENCE_TYPE, VIEW_SIMPLE_AUDIENCE,
} from './constants';
import ErrorPage from './ErrorPage';

const AudienceStatisticPage = lazy(() => import('redesignSrc/pages/AudienceAnalysis'));
const AudienceReportPage = lazy(() => import('redesignSrc/pages/AudienceReport'));
const PollsStart = lazy(() => import('redesignSrc/pages/PollsStart'));
const UserInfoPage = lazy(() => import('redesignSrc/pages/UserInfoPage'));
const FaqPage = lazy(() => import('pages/Faq'));
const NewTemplate = lazy(() => import('redesignSrc/pages/NewTemplate'));
const CreateCampaign = lazy(() => import ('redesignSrc/pages/CreateCampaign'));
const MyCampaignsStatisticPage = lazy(() => import ('pages/MyCampaignsStatistic'));
const RepeatCampaignPage = lazy(() => import ('pages/RepeatCampaign'));
const MyAudiencesPage = lazy(() => import ('redesignSrc/pages/MyAudiences'));
const NewAudience = lazy(() => import ('redesignSrc/pages/NewAudience'));
const MyFinances = lazy(() => import ('pages/MyFinances'));
const Counter = lazy(() => import('pages/Counter'));
const CampaignSettingsType = lazy(() => import('redesignSrc/pages/CampaignSettingType'));
const SimpleAudience = lazy(() => import('redesignSrc/pages/NewAudience/SimpleAudience'));

const END_DATE_SANTA = new Date(2024, 4, 17);

function Routes() {
  useEffect(() => {
    (async () => {
      await UserInfo.getBalance();
      await bonusesStore.fetchBonuses();
    })();
  }, []);
  useLocationScrollController();
  // useLocationSenderGA();

  useEffect(() => {
    // https://servicedesk.veon.com/browse/TSMS-557
    const tick = setInterval(() => UserInfo.getUser(true), 300000);
    return () => clearInterval(tick);
  }, []);


  return (
    <>
      <Notifications/>
      <InfoExternalOperator/>

      {/* <MenuTooltip/> */}
      {/* <SnowFallComponent/> */}
      {new Date() >= END_DATE_SANTA ? null : <WheelOfFortune/>}

      <Suspense fallback={<PageLoader isLoading/>}>
        <Router component={PageLayout} basepath="/" primary={false}>

          <Redirect path="/" from="/" to={NEW_CAMPAIGN_URL} noThrow/>

          <Redirect path="/dashboard" from="/dashboard" to={MY_FINANCES} noThrow/>

          <MyCampaignsPage path={MY_CAMPAIGNS_URL} />

          <CampaignSettingsType path="/set-type/"/>

          <MessageTemplatesPage path={MESSAGE_TEMPLATES_URL}/>

          <SenderNamesPage path={SENDER_NAMES_URL}/>

          <NewTemplate isTemplateMessage path={`${NEW_TEMPLATE}*`}/>

          <CreateSenderName path={`${NEW_SENDER}`}/>

          <EditSenderName isTemplateMessage path={`${NEW_SENDER}:id`}/>

          <Counter path={`${COUNTER_URL}*`}/>

          <NewAudience isTSMS path={`${NEW_AUDIENCES_TSMS}*`}/>

          <NewAudience isMyAudience cantSave path="/view-audience/*"/>

          <NewAudience isMyAudience path={`${NEW_AUDIENCES}*`}/>

          <CampaignSettingsType path={`${AUDIENCE_TYPE}/:orderId`} />

          <SimpleAudience path={`${SIMPLE_AUDIENCE}/:orderId`} />

          <MyAudiencesPage path={`${MY_AUDIENCES}`}/>

          <CreateCampaign path={`${NEW_CAMPAIGN_URL}*`}/>

          <UserInfoPage path={USER_INFO_URL}/>

          <AudienceStatisticPage path={AUDIENCE_STATISTIC_URL}/>

          <AudienceReportPage path={`${AUDIENCE_STATISTIC_REPORT_URL}:orderId`}/>
          <MyCampaignsStatisticPage path={MY_CAMPAIGNS_STATISTIC_URL}/>
          <MyCampaignsStatisticPage path={`${MY_CAMPAIGNS_STATISTIC_URL}:chart`}/>
          <RepeatCampaignPage path={MY_CAMPAIGNS_REPEAT_URL}/>
          <PollsStart path={`${POLLS_PAGE_URL}*`}/>
          <UserInfoPage path={`${POLLS_PAGE_URL}confirm-inn/`}/>
          <UserInfoPage path={`${AUDIENCE_STATISTIC_URL}confirm-inn/`}/>
          <FaqPage path={FAQ_URL}/>
          <FaqPage path={FAQ_URL_SUBPAGE}/>
          <MyFinances path={MY_FINANCES}/>
          <PageLayout default>
            <ErrorPage/>
          </PageLayout>
        </Router>
      </Suspense>
    </>
  );
}

export default observer(Routes);
