import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { FormFieldLabel, HovesCardTitle } from '../../../../../../UI/typography';
import { TextArea } from '../../../../../../UI/form';
import messageTemplatesStore from '../../../../../../../store/mobx/MessageTemplates';
import { TemplateDescriptionProps } from './TemplateDescriptionProps';

import styles from './styles.pcss';

export const TemplateDescription: FC<TemplateDescriptionProps> = observer(() => {
  const onChangeHandler = (value: string) => {
    messageTemplatesStore.formTemplate.values.description = value;
  };

  const isDisabledField = () => {
    return messageTemplatesStore.formTemplate.values.status === 'Verification' || messageTemplatesStore.formTemplate.values.status === 'Accepted';
  };

  return (
    <div className={styles.inputHolder} style={{ marginTop: '65px' }}>
      <FormFieldLabel>Описание шаблона</FormFieldLabel>
      <TextArea
        isDisabled={isDisabledField()}
        placeholder="Укажите информацию, если необходимо"
        value={messageTemplatesStore.formTemplate.values.description}
        onChange={onChangeHandler}
        name="description"
      />
    </div>
  );
});
