import React from 'react';
import { observer } from 'mobx-react';
import { VARIABLES_TEMPLATE } from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/types';
import { LinkTemplate } from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/components/Link';

import StoreT from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/storeT';
import styles from './styles.pcss';

const ChipsTemplateContainer = () => {
  return (
    <div className={styles.wrapper}>
      {VARIABLES_TEMPLATE.map(({ name, info, value }) => {
        if (
          value === 'staticPromocode' &&
          (StoreT.variables.has('staticPromocode') || StoreT.variables.has('dynamicPromocode'))
        ) return null;
        return (
          <LinkTemplate
            key={value}
            typeTemplate={value}
            info={info}
          >
            {name}
          </LinkTemplate>
        );
      })}
    </div>
  );
};

export default observer(ChipsTemplateContainer);
