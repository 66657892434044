import { observable, action, runInAction, computed, reaction } from 'mobx';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import { FAQ_CATEGORIES } from 'requests/faq/constants';

class Faq {
  @observable categories = [];
  @observable currentCategoryId = null;
  @action getCategories = async () => {
    const categories = await axiosBaseRequestAdTech({ url: FAQ_CATEGORIES });
    runInAction(() => {
      this.categories = categories.filter(item => item.active).map((item) => {
        return {
          ...item,
          faqs: item.faqs.map((question) => {
            return {
              ...question,
              id: String(question.id),
            };
          }),
          url: String(item.url),
        };
      });
    });
  }

  @action set = (property, value) => {
    this[property] = value;
  }

  @computed get isLoaded() {
    return !!this.categories.length;
  }

  @computed get sideMenu() {
    return this.categories;
  }

  @computed get currentCategoryFaq() {
    return this.categories.filter(category => category.url === this.currentCategoryId)[0] || {};
  }

  @computed get getUrlIdsList() {
    return this.categories.map(category => category.url);
  }

  @computed get sideMenuList() {
    return [{
      title: '',
      slug: '',
      subItems: this.categories.map(item => ({
        title: item.category,
        slug: item.category,
        href: item.url,
        isActive: item.url === this.currentCategoryId,
      })),
    }];
  }
}

const faqStore = new Faq();

reaction(
  () => (faqStore.currentCategoryId),
  () => {
    // const dataLayer = window.dataLayer || [];
    // dataLayer.push({
    //   event: 'event_b2b_beePro',
    //   eventCategory: 'Interaction',
    //   eventAction: 'click',
    //   eventLabel: 'buttonMenu',
    //   eventContent: `Помощь|${faqStore.currentCategoryFaq.category}`,
    //   eventOther: null,
    //   blockName: 'leftNav',
    // });
  },
);

export default faqStore;
