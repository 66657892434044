import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import confirmDialog from 'components/modals/confirmDialog';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import DeleteModal from '../DeleteModal';
import { senderNameStore } from '../../../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import styles from './styles.pcss';
import { MenuClientBaseProps } from './MenuClientBaseProps';

const MenuClientBase: FC<MenuClientBaseProps> = (props) => {
  const { isMenuVisible, onClose, id, status } = props;
  // const isHideEditBtn = status === 'Verification' || status === 'Accepted';
  const isHideEditBtn = status === 'Verification';
  const isDeleteSenderName = status === 'Draft';

  const deleteSenderHandler = async () => {
    const isDelete = await confirmDialog(DeleteModal);
    if (isDelete) {
      await senderNameStore.deleteByIdSection(id);
    }
  };

  const showTemplateDocsHandler = () => {
    senderNameStore.showDocsSenderNameModal = true;
    senderNameStore.selectedSenderNameId = id;
  };

  const editSenderHandler = async () => {
    senderNameStore.isLoadingPage = true;
    await navigate(`/sender-name/${id}`);
  };

  useEffect(() => {
    const handlerClickOutside = () => {
      setTimeout(() => onClose(), 0);
    };
    document.getElementById('react-container')?.addEventListener('click', handlerClickOutside);
    return () => document.getElementById('react-container')?.removeEventListener('click', handlerClickOutside);
  }, []);

  return (
    <>
      <div className={`${styles.menuContainer} ${isMenuVisible ? styles.menuVisible : styles.menuHidden}`}>
        {isHideEditBtn ? null : <div onClick={editSenderHandler} className={styles.menuItem}>Редактировать</div>}
        {/* <div onClick={showTemplateDocsHandler} className={styles.menuItem}>Смотреть документы</div> */}
        {isDeleteSenderName && <div onClick={deleteSenderHandler} className={styles.menuItem}>Удалить</div>}
      </div>
    </>

  );
};

export default observer(MenuClientBase);
