import React, { FC, useEffect, CSSProperties } from 'react';
import { useDrag } from 'react-dnd';
import { observer } from 'mobx-react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import classNames from 'classnames';
import StoreDnd from './StoreDnd';
import { BoxProps, ItemTypes } from './types';
import styles from './styles.pcss';

function getStyles(
  left: number,
  top: number,
  isDragging: boolean,
): CSSProperties {
  const transform = `translate3d(${left}px, ${top}px, 0)`;
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
  };
}

export const Box:FC<BoxProps> = observer(({
  id,
  left,
  top,
  children,
}) => {
  const [{ isDragging }, drag, preview] = useDrag(

    () => ({
      type: ItemTypes.BOX,
      item: { id, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: StoreDnd.isDrag,
    }),
    [id, left, top, StoreDnd.isDrag],
  );
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (

    <div
      className={classNames(styles.box)}
      ref={drag}
      style={getStyles(left, top, isDragging)}
      data-testid="box"
      >
      {children}
    </div>
  );
});
