/**
 * ouputs the proper word declensions by given count
 * @param count - count to determine appropriate case
 * @param titles - list of words in three cases: [nominative, genitive, multiplicative]
 * @returns {*}
 */
import { isNullOrUndefined } from 'utils/fn';

const replaceChar = (strPrice) => strPrice.split('.')[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ');

export const phoneProxyRegExp = /(\D)+/g;
export const phonePrefix = '+7';

/**
 * splits price value into three-digit groups
 * */
export function formatPrice(price) {
  if (isNullOrUndefined(price) || price === 0) return '';
  const strPrice = typeof price === 'string' ? price : `${price}`;
  return replaceChar(strPrice) || '';
}

/**
 * Красиво выводит сумму, не обрезает числа после запятой: 56 000,90
 * @param price
 * @returns {string|string}
 */
export function formatAmount(price) {
  if (isNullOrUndefined(price)) return '';
  const strPrice = typeof price === 'string' ? price : `${price}`;
  return strPrice.split('.')[1] &&
      `${replaceChar(strPrice)},${strPrice.split('.')[1]}` || replaceChar(strPrice) || '';
}

export const formatPriceWithLabel = (value, label) => `${formatPrice(value)} ${label || '₽'}`;
export const formatPriceTotal = (value, label) => `${formatAmount(value)} ${label || '₽'}`;

export const formatPercent = (value) => `${value * 100}%`;

export const formatFloatWithComma = value => String(value).replace('.', ',');

const phonePattern = 'X|XXX|XXX|XX|XX';

export function formatPhone(phone) {
  if (!phone) return '';
  if (phone[0] === '+') phone = phone.slice(1);

  let result = '';
  let checkedDelimiters = 0;
  for (let i = 0; i < phonePattern.length; i += 1) {
    if (isNullOrUndefined(phone[i - checkedDelimiters])) return result;
    if (phonePattern[i] === 'X') {
      result += phone[i - checkedDelimiters];
    } else {
      checkedDelimiters += 1;
      result += ' ';
    }
  }
  return result;
}

export function formatTenNumbersPhone(phone) {
  const phoneArr = phone.toString().split('');
  phoneArr.splice(3, 0, ' ');
  phoneArr.splice(7, 0, ' ');
  phoneArr.splice(10, 0, ' ');

  return phoneArr.join('');
}

export function formatPhoneRu(value) {
  const ruNumber = `7${value}`;
  return `+${formatPhone(ruNumber)}`;
}

export const formatPhoneProxy = (phoneVal) => {
  if (phoneVal) {
    const withoutPrefix = phoneVal.startsWith('+7') ? phoneVal.slice(2) : phoneVal;
    return withoutPrefix.replace(phoneProxyRegExp, '');
  }
  return '';
};
export const normalizePhone = phoneVal => `${phonePrefix}${phoneVal}`;

export const formatPureFileName = (fileName) => {
  const lastSlash = fileName.lastIndexOf('/');
  const lastPoint = fileName.lastIndexOf('.');
  return decodeURI(fileName.slice(lastSlash ? lastSlash + 1 : 0, lastPoint));
};

export const formatPureFileExtension = (fileName, isUp) => {
  const lastPoint = fileName.lastIndexOf('.');
  return isUp ?
    fileName.slice(lastPoint + 1, fileName.length).toUpperCase() :
    fileName.slice(lastPoint + 1, fileName.length);
};

export const stringToNumber = (value) => {
  let res = 0;
  if (typeof value === 'string' && value === '') res;
  if (typeof value === 'number') return value;
  if (typeof value === 'string') {
    try {
      res = parseFloat(value.replace(' ', '').replace(',', '.'));
    } catch (e) {
      console.error(e);
      return res;
    }
  }
  return res;
};

export const fileNaming = (filesLength) => {
  let startWord = 'файл';
  if (filesLength < 10 && (`${filesLength}`.slice(-1) > 1 && `${filesLength}`.slice(-1) <= 4)) {
    startWord += 'а';
  } else if (
    (`${filesLength}`.slice(-1) > 4 && filesLength <= 20) ||
    (filesLength >= 20 && filesLength % 10 !== 1) ||
    (`${filesLength}`.length === 2 && (`${filesLength}`.slice(-1) >= 0) && `${filesLength}`.slice(-1) <= 4) &&
    filesLength < 21
  ) {
    startWord += 'ов';
  }
  return startWord;
};
