import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { StoresContext } from '../../../../../../../../../../../store/mobx';
import { UploadFilesSenderName } from './components/UploadFilesSenderName';

import styles from './styles.pcss';

export const SenderNameUploadValidatedFile = observer(({
  onFormatFailed,
  onFilesAdded,
  onClick,
  maxSize,
  error,
  fileExtensions,
  multiple,
  name,
  isLoading,
  fileUploadInfoTooltip,
  store,
  isError,
}: {
    isError?: any;
    store?: any;
    onFilesAdded?: (f: File, s?: string) => void,
    onFormatFailed?: (s: string) => void,
    onClick?: () => void,
    maxSize?: number,
    isLoading?: boolean,
    error?: string | null,
    fileUploadInfoTooltip?: string;
    fileExtensions?:string,
    multiple?: boolean,
    name: string,
  },
) => {
  const { NewCampaign } = useContext(StoresContext);
  const [errorTextFile, setErrorTextFile] = useState('');

  const maxSizeNumber = maxSize || 4000000;

  const handlerClickOutside = () => {
    setErrorTextFile('');
    document.body.removeEventListener('click', handlerClickOutside);
  };

  useEffect(() => {
    return () => document.body.removeEventListener('click', handlerClickOutside);
  }, []);

  const isValid = (file: File) => {
    setErrorTextFile('');

    const isSizeMax = (file.size > maxSizeNumber);

    if (isSizeMax) {
      document.body.addEventListener('click', handlerClickOutside);
      if (onFormatFailed) onFormatFailed('sizeError');
      else NewCampaign.isNotValidSizeFileOfMyAudience = true;
      return false;
    }

    return true;
  };

  const handleFileChanged = async (e: CustomEvent & { dataTransfer?: DataTransfer }) => {
    if (e.dataTransfer) {
      const isOneFile = Array.from(e.dataTransfer.files).length > 1;
      if (isOneFile && !multiple) {
        setErrorTextFile('Добавляйте по одному файлу');
        document.body.addEventListener('click', handlerClickOutside);
        return;
      }

      // @ts-ignore
      const files = e.dataTransfer?.files || e.target?.files;
      const filesArray =
        Array.from(files)
          .map(file => {
            return isValid(file) ? file : '';
          })
          .filter(item => item);

      if (filesArray.length) {
        // @ts-ignore
        await Promise.all(filesArray.map(file => onFilesAdded(file, 'dragFile')));
      }

      e.dataTransfer && e.dataTransfer.clearData();
    }

    const files = (e.target as HTMLInputElement)?.files;
    if (files) {
      const filesArray =
        Array.from(files)
          .map(file => {
            return isValid(file) ? file : '';
          })
          .filter(item => item);

      if (filesArray.length) {
        // @ts-ignore
        await Promise.all(filesArray.map(file => onFilesAdded(file, 'dragFile')));
      }
    }
  };


  return (
    <div>
      <UploadFilesSenderName
        isLoading={isLoading}
        fileExtensions={fileExtensions}
        onFileChanged={handleFileChanged}
        multiple={multiple}
        error={error}
        onClick={onClick}
        maxSize={maxSizeNumber}
        name={name}
        fileUploadInfoTooltip={fileUploadInfoTooltip}
      />
      {error || store.errorOnFileUploading || errorTextFile.length !== 0
        ? (
          <div className={styles.error}>
            {errorTextFile || error}
            {store.errorOnFileUploading === true ? 'Совокупный объем загружаемых файлов превышает 15 мб.' : null}
          </div>
        ) : null}
    </div>
  );
});
