import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import { ORDER } from '../../../constants';
import smsLocalStore from '../../../SmsPages/SmsPageSetup/SmsLocalStore';
import ErrorDescription from '../../../../components/ErrorDescription';
import styles from '../TemplateCustomization/styles.pcss';
import messageTemplatesStore from '../../../../../store/mobx/MessageTemplates';
import { SelectTemplateGroupProps } from './SelectTemplateGroupProps';
import { SelectItem } from './components';

const cx = classNames.bind(styles);

export const SelectTemplateGroup: FC<SelectTemplateGroupProps> = observer(({ valid }) => {
  const selectedItemHandler = (name: string, id: number = 0) => {
    messageTemplatesStore.messageTemplatesGroupName = name;
    messageTemplatesStore.formTemplate.values.groupId = id;
    messageTemplatesStore.formTemplate.values.groupName = name;
  };

  useEffect(() => {
    if (messageTemplatesStore.dataCustomer && !messageTemplatesStore.isFirstRender) {
      messageTemplatesStore.messageTemplatesGroupName = 'Без группы';
      messageTemplatesStore.formTemplate.values.groupId = null;
      messageTemplatesStore.formTemplate.values.groupName = null;
    }
  }, [messageTemplatesStore.dataCustomer, messageTemplatesStore.isFirstRender]);

  const isDisabledField = () => {
    return messageTemplatesStore.formTemplate.values.status === 'Verification' || messageTemplatesStore.formTemplate.values.status === 'Accepted';
  };

  return (
    <div className={cx(styles.nameHolder, { disabledSelect: isDisabledField() })}>
      <SelectItem
        onSelectedItem={selectedItemHandler}
        labelText="Группа шаблонов"
        error={valid.errors[ORDER.SENDER_NAME_FIELD]}
        onClose={() => smsLocalStore.setListNamesOpened(false)}
        createNewText="+ Создать новую группу"
        value={messageTemplatesStore.messageTemplatesGroupName}
        id={ORDER.SENDER_NAME_FIELD}
        isActionBtn={false}
        onDeleteItem={() => {
        }}
        onEditItem={() => {
        }}
      />
      <ErrorDescription errors={valid.errors} fieldName={ORDER.SENDER_NAME_FIELD}/>
    </div>
  );
});
