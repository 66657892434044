import { computed, observable } from 'mobx';

export const PROMO_CAMPAIGN_TYPE = 4;

type TypeItems = {
  id: number,
  name: string,
}

export type TypeItemPrise = {
  itemId: number,
  itemName: string,
  itemType: number,
  value: string
}

class Store {
  @observable maxAttempts = 0
  @observable promoCampaignId: number | null = null
  @observable attempts = 0
  @observable items: TypeItems[] = []

  @computed get getItemNormalise() {
    return this.items.map(el => {
      return {
        id: el.id,
        option: el.name,
      };
    });
  }
}

const store = new Store();

export default store;
