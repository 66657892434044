import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.pcss';
import IconGrey from './assets/infoIcon.svg';
import IconYellow from './assets/IconInfoYellow.svg';
import IconRed from './assets/IconRed.svg';

interface IProps {
  description?: string,
  className?:string,
  icon?: 'grey' | 'yellow' | 'red',
  children?: React.ReactNode | string, // компонент списока элементов
}


const InfoDescription = ({ description, children, icon, className }:IProps) => {
  const Icon = useMemo(() => {
    if (icon === 'grey') return IconGrey;
    if (icon === 'yellow') return IconYellow;
    if (icon === 'red') return IconRed;
    return IconGrey;
  }, [icon]);
  return (
    <div className={classNames(styles.infoHolder, className)}>
      <span className={styles.ico}><Icon /></span>
      <span className={styles.info}>
        {description}
        {children && children}
      </span>
    </div>
  );
};

export default InfoDescription;
