import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import IMask from 'imask';
import withError from 'redesignSrc/HOC/withError';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

// eslint-disable-next-line no-shadow
export enum IconStart {
  search = 'search'
}

// eslint-disable-next-line no-shadow
export enum IconEnd {
  calendar = 'calendar',
  search = 'search',
}

interface IProps {
  value?: string,
  className?: string,
  onChange?: (e: string) => void,
  onFocus?: () => void,
  onBlur?: () => void,
  startIcon?: IconStart,
  endIcon?: IconEnd,
  debounceDelay?: number,
  name?: string,
  placeholder?: string,
  isPrice?: boolean,
  labelText?: string,
  autoComplete?: string,
  disabled?: boolean,
  maxLength?: number,
  mask?: any,
  id?: string,
  classNameInput?: string,
  autoFocus?: boolean,
  type?: string,
}

const TextInput = React.forwardRef((props: IProps, ref) => {
  const {
    value,
    className = '',
    onChange,
    onFocus,
    onBlur,
    startIcon,
    endIcon,
    name,
    placeholder,
    isPrice,
    labelText,
    autoComplete,
    disabled,
    maxLength,
    mask,
    id,
    classNameInput,
    autoFocus,
    type,
  } = props;


  const [isFocus, setIsFocus] = useState(false);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value);
  };

  const onChangeHandlerMask = (text: string) => {
    onChange && onChange(text);
  };

  const onFocusHandler = () => {
    setIsFocus(true);
    onFocus && onFocus();
  };

  const onBlurHandler = () => {
    setIsFocus(false);
    onBlur && onBlur();
  };
  const input = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (mask && input && input.current) {
      const InputMask = IMask(
        input.current, mask);
      InputMask.on('accept', () => {
        onChangeHandlerMask(InputMask.value);
      });
    }
  }, []);

  return (
    <>
      {labelText && <label className={styles.labelText}>{labelText}</label>}
      <div className={cx(className, styles.holder)}>
        {
          startIcon &&
          <label htmlFor={name}
            className={classNames(styles.iconsStart, styles[startIcon], isFocus && styles.focusLabel)}/>
        }
        <input
          disabled={disabled}
          // @ts-ignore
          ref={ref || input}
          id={name || id}
          type={type || 'text'}
          placeholder={placeholder}
          autoFocus={autoFocus}
          name={name}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          className={classNames(styles.input, classNameInput, startIcon && styles.inputIconStart, endIcon && styles.inputIconEnd)}
          value={value}
          autoComplete={autoComplete}
          maxLength={maxLength}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
        {
          endIcon &&
          <label htmlFor={name} className={classNames(styles.iconsEnd, styles[endIcon], isFocus && styles.focusLabel)}/>
        }
      </div>
    </>
  );
});

export default withError(TextInput);
