/**
 * Выводит правильные склонения слов по заданному количеству
 * @param count - Число для склонения слова
 * @param titles - Список слов в трех падежах: [именительный, родительный, мультипликатив]
 */
export const wordFormByCount = (count:number, titles:string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]];
};

type TypeNameInitials = {
  lastName?:string | null,
  firstName?:string | null,
  middleName?:string | null,
  name?:string | null,
}

/**
 * Переводит Фамилию Имя Отчество nickname в Фамилия И.O(nickname)
 * @param el - Обьект с полными значениями
 */

export const nameWithInitials = (el:TypeNameInitials) => {
  const lastName = el.lastName || '';
  const firstName = el.firstName && el.firstName[0]?.toUpperCase() ? `${el.firstName[0]?.toUpperCase()}.` : '';
  const middleName = el.middleName && el.middleName[0]?.toUpperCase() ? `${el.middleName[0]?.toUpperCase()}.` : '';
  const name = el.name ? `(${el.name})` : '';
  return `${lastName} ${firstName}${middleName} ${name}`;
};

