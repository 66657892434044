import { Switch } from '@beeline/design-system-react';
import React from 'react';
import styles from '../styles.pcss';

const SwitchField = ({ value, onChange, ...rest } : { value: boolean, onChange: (val: boolean) => void }) => {
  return (
    <Switch
      label="Являюсь ИП"
      className={styles.checkBox}
      checked={value}
      onChange={() => onChange(!value)}
    />
  );
};

export default SwitchField;
