import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import { StoresContext } from 'store/mobx';
import DropdownLinks, { Item, LinkedList } from 'redesignSrc/components/DropdownLinks';
import useBlockingNavigateFromAudience from 'redesignSrc/HOC/hooks/useBlockingNavigateFromAudience';
import { formatPhone } from 'utils/formatting';
import UserInfo from 'store/mobx/UserInfo';
import Common from 'store/mobx/Common';
import { bonusesStore } from 'store/mobx/BonusesStore';
import { DesktopOnly, MobileOnly } from '../Responsive';
import { IconButton } from '../../UI/buttons';
import Hamburger from './assets/hamburder.svg';
import BeeLogo from './assets/logo.svg';
import WalletButton from './WalletButton';

import styles from './styles.pcss';

const dropdownItems = [
  {
    href: '/user-info/',
    label: 'Профиль',
  },
  // {
  //   href: '/faq',
  //   label: 'Помощь',
  // },
  {
    href: '/',
    label: 'Выход',
  },
];

const Header: React.FC = () => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const isTSMS = location.pathname.includes('tsms');
  const isPartners = UserInfo.data?.appType === 1;


  const handleWallet = () => {
    navigate('/my-finances');
  };

  const handleScore = () => {
    navigate('/my-finances?section=score');
  };

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    await axiosBaseRequestAdTech({ url: '/api/user/logout' });

    localStorage.removeItem('alreadyLoggedIn');

    window.location.search = '';
  };

  const handleLinkClick = async (item: Item, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    switch (item.label) {
      case 'Выход': {
        handleLogout(e);
        break;
      }
      // case 'Помощь':
      case 'Профиль': {
        // await globalHistory.navigate(item.href);
        await navigate(item.href);
        break;
      }
      default:
        break;
    }
  };

  return (
    <header className={classNames(styles.header, UserInfo.isAggregator && styles.aggregWidth)}>
      {!isTSMS && !isPartners &&
        <>
          <DesktopOnly>
            {!UserInfo.isAggregator &&
              <WalletButton
                handleWallet={handleWallet}
                balance={UserInfo.balance?.free_balance}
                handleScore={handleScore}
                // @ts-ignore
                score={bonusesStore?.bonuses?.free}
              />
            }
            <IconButton
              iconType="profile"
              className={styles.profileButton}
              onClick={() => {
                setIsProfileMenuOpen(!isProfileMenuOpen);
              }}
            >
              {// @ts-ignore
                (`${UserInfo.data?.phone}`?.split('')?.[0] === '+' ? `+${formatPhone(UserInfo.data?.phone)}` : formatPhone(UserInfo.data?.phone)) || 'Профиль'}

              {isProfileMenuOpen && (
                <DropdownLinks
                  dropDownClass={styles.dropDown}
                  isOpen={isProfileMenuOpen}
                  onClose={() => setIsProfileMenuOpen(false)}
                >
                  <LinkedList
                    items={dropdownItems}
                    onItemClick={handleLinkClick}
                    onClose={() => {
                      setIsProfileMenuOpen(false);
                    }}
                  />
                </DropdownLinks>
              )}
            </IconButton>
          </DesktopOnly>
          <MobileOnly className={styles.mobileHead}>
            <BeeLogo/>
            <button className={styles.menuButton} onClick={() => {
              Common.isMobileSidebarVisible = true;
            }}>
              <Hamburger/>
            </button>
          </MobileOnly>
        </>
      }

    </header>
  );
};

export default observer(Header);
