import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.pcss';

type Props = {
  current?:number,
  max?:number,
  className?:string,
}

const CharsCounter:React.FC<Props> = ({ current = 0, max = 99, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <span className={classNames(current > max && styles.moreThanMaxLength)}>({current}</span>&nbsp;/&nbsp;<span>{max})</span>
    </div>
  );
};

export default CharsCounter;
