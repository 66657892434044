import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import { toJS } from 'mobx';
import classNames from 'classnames/bind';
import { CardBottomPanelBtns } from 'redesignSrc/UI/elements/CardBottomPanelBtns/CardBottomPanelBtns';
import { SaveDraftBtn } from 'redesignSrc/UI/elements/CardBottomPanelBtns/components/SaveDraftBtn';
import { NextBtn } from 'redesignSrc/UI/elements/CardBottomPanelBtns/components/NextBtn';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { scrollSmoothToNodeById } from 'utils/scroll';
import styles from './styles.pcss';
import { AgreementModal } from './components/AgreementModal';

interface BtnsBlockProps {
  valid: any;
  validName: any;
}

const cx = classNames.bind(styles);

export const BtnsBlock: FC<BtnsBlockProps> = observer(({ valid, validName }) => {
  const onSaveToDraftHandler = async () => {
    messageTemplatesStore.isLoadingPage = true;
    await messageTemplatesStore.updateTemplate();
    messageTemplatesStore.isLoadingPage = false;
    await navigate('/message-templates/');
  };

  const onSendForApprovalHandler = async () => {
    const { firstErrorKey } = valid.getAllValid();
    const { firstErrorKey: error } = validName.getAllValid();
    if (firstErrorKey || error) {
      scrollSmoothToNodeById(firstErrorKey, 200);
    } else {
      await messageTemplatesStore.updateTemplate();
      await messageTemplatesStore.getTemplateById(messageTemplatesStore.id || 0);
      messageTemplatesStore.agreementModal = true;
    }
  };

  const isShowSendForApprovalBtn = () => {
    return messageTemplatesStore.formTemplate.values.status === 'Draft' || messageTemplatesStore.formTemplate.values.status === 'Rejected';
  };

  return (
    <CardBottomPanelBtns>
      {
        messageTemplatesStore.formTemplate.values.status === 'Draft' && (
          <SaveDraftBtn
            onClick={onSaveToDraftHandler}
          />
        )
      }
      {
        isShowSendForApprovalBtn() ? (
          <NextBtn
            onClick={onSendForApprovalHandler}
            text="Отправить на согласование"
          />
        ) : null
      }
      <AgreementModal/>
    </CardBottomPanelBtns>
  );
});
