import { OrderLink } from 'redesignSrc/types';

export interface MessageTemplatesGroup {
  id: number,
  name: string,
  description: string,
  templateCount: number
}

export interface MessageTemplatesComment {
  comment: string,
  type: number | null,
  createDate: string
}

export const defaultValuesTemplateGroupForm = {
  id: 0,
  name: '',
  description: '',
  templateCount: 0,
};

export const defaultValuesTemplateCommentForm = {
  comment: '',
  type: null,
  createDate: '',
};

export type TemplatesStatuses = 'Draft' | 'Accepted' | 'Rejected' | 'Verification';

export const defaultValuesTemplateForm = {
  name: '',
  description: '',
  additionalTemplate: '',
  template: '',
  groupId: null,
  groupName: null,
  isUnique: false,
  status: '',
  createDate: '',
  unseenComments: 0,
  links: {
    link: null,
    shortLink: null,
    isShort: false,
    dynamicPartLink: null,
  },
  shortTemplate: null,
  variables: null,
};

export type TypeTemplateValues = {
  id?: number;
  name: string;
  description: string;
  additionalTemplate: string;
  template: string;
  groupId: number | null;
  groupName: string | null;
  isUnique: boolean;
  status: TemplatesStatuses | string;
  createDate: string;
  customerId?: number;
  customerName?: string;
  shortTemplate: string | null;
  unseenComments: number,
  creator?: string,
  links: OrderLink,
  variables: null | object
}

export type TypeTemplates = {
  templates: TypeTemplateValues[] | [],
  pageCount: number
}

export type TypeTemplate = {
  values: TypeTemplateValues
}

export type TypeTemplateGroup = {
  values: MessageTemplatesGroup
}

export type TypeTemplateComments = MessageTemplatesComment
