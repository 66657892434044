import React, { FC } from 'react';
import { observer } from 'mobx-react';
import UploadValidatedFiles from 'redesignSrc/UI/elements/UploadValidatedFiles';

import { FilesProps } from './FilesProps';


export const Files: FC<FilesProps> = observer(({ onFilesAdded, error, validate, isLoading, fileUploadInfoTooltip, fileSize, store, isError }) => {
  return (
    <UploadValidatedFiles
      store={store}
      isLoading={isLoading}
      name="addNameFiles_UploadValidatedFile1"
      maxSize={fileSize?.size || 20000000}
      onFormatFailed={() => validate.setErrorsOfKey('files', `Размер файла больше ${fileSize?.shortSize || 20} Мб.`)}
      fileExtensions=".pdf, .jpg, .jpeg, .png, .rar, .zip"
      fileUploadInfoTooltip={fileUploadInfoTooltip}
      multiple
      onFilesAdded={
        async (e) => {
          validate && validate.setErrorsOfKey('files', '');
          await onFilesAdded(e);
      }}
      error={error}
      isError={isError}
    />
  );
});
