import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { SenderNameFilesProps } from './SenderNameFilesProps';
import { SenderNameUploadValidatedFile } from './components/SenderNameUploadValidatedFile';


export const SenderNameFiles: FC<SenderNameFilesProps> = observer((props) => {
  const { onFilesAdded, error, validate, isLoading, fileUploadInfoTooltip, fileSize, store, isError } = props;

  return (
    <SenderNameUploadValidatedFile
      store={store}
      isLoading={isLoading}
      name="addSenderNameFiles_UploadValidatedFile"
      maxSize={fileSize?.size || 20000000}
      onFormatFailed={() => validate.setErrorsOfKey('files', `Размер файла больше ${fileSize?.shortSize || 20} Мб.`)}
      fileExtensions=".pdf, .jpg, .jpeg, .png, .rar, .zip"
      fileUploadInfoTooltip={fileUploadInfoTooltip}
      multiple
      onFilesAdded={
        async (e) => {
          validate && validate.setErrorsOfKey('files', '');
          await onFilesAdded(e);
      }}
      error={error}
      isError={isError}
    />
  );
});
