import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
// @ts-ignore
import { DateRangePicker } from 'react-dates';
import IMask from 'imask';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import NavNext from './assets/navNext.svg';
import NavPrev from './assets/navPrev.svg';

import styles from './styles.pcss';

const START_NOT_VALID_MESSAGE = 'Выберите доступную дату старта из календаря';
const END_NOT_VALID_MESSAGE = 'Дата завершения не валидна';
const START_REQUIRED = 'Дата старта обязательное поле';
const END_REQUIRED = 'Дата завершения обязательное поле';
const PLACEHOLDER = 'дд.мм.гггг';
const DATE_FORMAT_FOR_CONVERSIONS = 'MM/DD/YYYY';

const undefinedToNull = (value?: moment.Moment) => {
  if (typeof value === 'undefined') return null;
  return value;
};

const createImgAndInit = (input: HTMLInputElement, width?: string | number) => {
  const img = document.createElement('img');
  img.src = 'https://static.beeline.ru/upload/images/marketing/calendar.svg';
  img.style.position = 'absolute';
  img.style.top = '14px';
  width ? img.style.right = '13px' : img.style.left = '101px';
  img.style.cursor = 'pointer';
  img.addEventListener('click', () => {
    input.focus();
  });
  input?.parentElement!.appendChild(img);
};

interface IProps {
  value: [string | undefined | null, string | undefined | null],
  name?: string,
  onInputFocusChange?: (e: string | null) => void,
  isResetDateStart?: boolean,
  isResetDateEnd?: boolean,
  setError?: (e: string | null) => void,
  minStart?: Date,
  maxEnd?: Date | Moment,
  countMinEnd?: (e: string | undefined) => void,
  onChange: (e: [moment.Moment | undefined, moment.Moment | undefined]) => void,
  countMaxStart?: (e: string) => void,
  maxStart?: Date,
  minEnd?: Date,
  isEndDisabled?: boolean,
  onStartDateClick?: () => void,
  onEndDateClick?: () => void,
  holidays?: { [key: string]: boolean },
  isWeekendDisabled?: boolean,
  isInternetChannel?: boolean,
  countMaxEnd?: (e?: string | null) => void,
  width?: string | number,
  numberInCollection?: number,
  isSecondDateDisabled?: boolean,
  numberOfMonths?: number,
  anchorDirection?: string,
  isInputsReadOnly?: boolean,
  startDatePlaceholder?: string,
  endDatePlaceholder?: string,
  label?: string,
  secondMarginLeft?: number
  className?: string,
  dateError?: string
  openDirection?: 'up' | 'down'
  handleCalendarClose?: () => void
  handleFocusChange?: () => void
}

class CalendarStore {
  @observable focusedInput: 'endDate' | 'startDate' | null = null;
  @action setFocusedInput = (value: 'endDate' | 'startDate' | null) => {
    this.focusedInput = value;
  }
}

const store = new CalendarStore();

/**
 * ПРЕЖДЕ ЧЕМ ИСПОЛЬЗОВАТЬ ЭТОТ КАЛЕНДАРИК ПРИГЛЯДИСЬ К src/redesignSrc/UI/form/NewDatePicker
 * МОЖЕТ ОТ ПОДОЙДЕТ ТЕБЕ ЛУЧШЕ!!! ЭТОТ ДОЛЖЕН БЫТЬ УТИЛИЗИРОВАН
 */

function DatePicker(props: IProps) {
  const { value, name, onInputFocusChange, secondMarginLeft, dateError } = props;
  // const [focusedInput, setFocusedInput] = useState<'endDate'|'startDate'|null>(null);
  const [inputs, setInputs] = useState<[HTMLInputElement | undefined, HTMLInputElement | undefined]>([undefined, undefined]);
  const [visibleMonthOnClick, setVisibleMonthOnClick] = useState<Moment>();

  useEffect(() => {
    onInputFocusChange && onInputFocusChange(store.focusedInput);
  }, [store.focusedInput]);

  const [startDate, setStartDate] = useState<moment.Moment | undefined>();
  const [endDate, setEndDate] = useState<Moment | undefined>();
  // const [isClosed, setIsClosed] = useState(true);

  const wrapperRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  // type CurRefObject = { current: { month: moment.Moment; onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void; } };
  // const monthController = useRef<CurRefObject>();

  useEffect(() => {
    if (props.isResetDateStart) setStartDate(undefined);
    if (props.isResetDateEnd) setEndDate(undefined);
  }, [props.isResetDateStart, props.isResetDateEnd]);

  useEffect(() => {
    setStartDate(value && value[0] ? moment(value[0]).startOf('day') : undefined);
    setEndDate(value && value[1] ? moment(value[1]).startOf('day') : undefined);
  }, [value?.[0], value?.[1]]);

  useEffect(() => {
    if (startDate && endDate) {
      props.setError && props.setError(null);
    }
  }, [startDate, endDate]);

  type DatesParams = {
    startDate: moment.Moment,
    endDate: Moment
  }

  const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }: DatesParams) => {
    if (newEndDate?.isBefore(newStartDate)) {
      setEndDate(undefined);
    }

    if (newStartDate && props.minStart && newStartDate.isBefore(props.minStart)) {
      newStartDate = moment(props.minStart);
    }
    if (newEndDate && props.maxEnd && newEndDate.isAfter(props.maxEnd)) {
      newEndDate = moment(props.maxEnd);
    }

    const isStartDateNotNull = store.focusedInput === 'startDate' && newStartDate !== null;
    const isEndDateNotNull = store.focusedInput === 'endDate' && newEndDate !== null;

    if (isStartDateNotNull || isEndDateNotNull) {
      props.setError && props.setError('');
    }

    if (newStartDate && props.countMinEnd) {
      // eslint-disable-next-line max-len
      if ((store.focusedInput === 'startDate' && (!newEndDate || moment(newEndDate).isSameOrBefore(newStartDate))) || props.isEndDisabled) {
        const minEnd = props.countMinEnd
          // @ts-ignore
          ? moment(props.countMinEnd(newStartDate.format(DATE_FORMAT_FOR_CONVERSIONS)))
          : undefined;

        props.onChange([newStartDate, minEnd]);
        setStartDate(newStartDate);
        setEndDate(minEnd);
        // return true;
      }
    }

    if (newEndDate && props.countMaxStart) {
      if (!newStartDate && store.focusedInput === 'endDate') {
        const maxStart = props.countMaxStart
          // @ts-ignore
          ? moment(props.countMaxStart(newEndDate.format(DATE_FORMAT_FOR_CONVERSIONS)))
          : undefined;

        props.onChange([maxStart, newEndDate]);
        setStartDate(maxStart);
        setEndDate(newEndDate);
        // return true;
      }
    }

    props.onChange([newStartDate, newEndDate]);

    handleFocusChange('endDate');
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const isOutsideRange = (day: moment.Moment) => {
    if (!props.minStart && !props.maxStart && !props.minEnd && !props.maxEnd) return false;
    day = day.startOf('day');

    const minStart = moment(props.minStart).startOf('day');
    const maxStart = moment(props.maxStart).startOf('day');
    const minEnd = moment(props.minEnd).startOf('day');
    const maxEnd = moment(props.maxEnd).startOf('day');

    if (store.focusedInput === 'startDate') {
      if (day.isBefore(minStart)) {
        return true;
      }
      if (day.isAfter(maxStart)) {
        return true;
      }
    }

    if (store.focusedInput === 'endDate') {
      if (day.isBefore(minEnd)) {
        return true;
      }
      if (day.isAfter(maxEnd)) {
        return true;
      }
    }
    return false;
  };

  const handleChangeVisibleMonth = () =>
    visibleMonthOnClick || moment(props.minEnd).startOf('day') || moment().format('MMMM');

  const handleFocusChange = (newFocusedInput: 'endDate' | 'startDate' | null) => {
    props.handleFocusChange && props.handleFocusChange();
    if (props.isEndDisabled && newFocusedInput !== null) {
      store.setFocusedInput('startDate');
      return;
    }

    store.setFocusedInput(newFocusedInput);

    if (newFocusedInput === 'startDate') {
      setVisibleMonthOnClick(startDate);
    } else if (newFocusedInput === 'endDate') {
      setVisibleMonthOnClick(endDate);
    }
    handleChangeVisibleMonth();
    // } else if (!isClosed) {
    //   if (newFocusedInput === 'startDate') {
    //     if (monthController?.current) {
    //       // @ts-ignore Этот код переключает ГОД, но срабатывает последнее из переключений
    //       monthController?.current.onYearSelect(
    //         // @ts-ignore
    //         monthController?.current.month,
    //         // @ts-ignore
    //         monthController?.current.month.year() - 1,
    //       );

    //       // @ts-ignore
    //       monthController?.current.onMonthSelect(
    //         // @ts-ignore
    //         monthController?.current.month,
    //         startDate?.startOf('day')?.format('MM'),
    //       );
    //     }
    //   } else if (newFocusedInput === 'endDate') {
    //     if (monthController?.current) {
    //       // @ts-ignore
    //       monthController?.current.onMonthSelect(
    //         // @ts-ignore
    //         monthController?.current.month,
    //         endDate?.startOf('day')?.format('MM'),
    //       );
    //     }
    //   }

    // setIsClosed(false);
  };

  const isRange = (day: moment.Moment) => {
    if (startDate !== undefined) {
      return !day.isBefore(startDate) && !day.isAfter(endDate);
    }
    return false;
  };

  const handleIsDayBlocked = (day: moment.Moment, isBlockingHolyday?: boolean) => {
    const currentDay = day.format('YYYY-MM-DD').substring(0, 10);
    const baseValidation = (props.holidays && props.holidays[currentDay]) || props.isWeekendDisabled && (day.day() === 0 || day.day() === 6);
    return (isBlockingHolyday && props.isInternetChannel) ? baseValidation && !isRange(day) : baseValidation;
  };

  const setNewValueIfDateIsValid = useCallback((newValue, errorMessage, isStart) => {
    const inputDateArray = newValue.split('.');
    const dateObject: moment.Moment = moment(`${inputDateArray[2]}-${inputDateArray[1]}-${inputDateArray[0]}`);

    if (dateObject.isValid()) {
      const isDayOutsideRange = isOutsideRange(dateObject);

      const isDayBlocked = handleIsDayBlocked(dateObject);

      const newStartDate = isStart ? dateObject : startDate;
      const newEndDate = isStart ? endDate : dateObject;

      if (isDayOutsideRange || isDayBlocked) {
        props.setError && props.setError(errorMessage);
        props.onChange([newStartDate, newEndDate]);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        return;
      }

      if (isStart) {
        if ((!newEndDate || moment(newEndDate).isSameOrBefore(newStartDate) || props.isEndDisabled) && props.countMinEnd) {
          const minEnd = (typeof props.countMinEnd === 'function' && newStartDate)
            // @ts-ignore
            ? moment(props.countMinEnd(newStartDate.format(DATE_FORMAT_FOR_CONVERSIONS)))
            : undefined;

          if (newStartDate && newStartDate.isBefore(moment(props.minStart).startOf('day'))) {
            props.setError && props.setError(START_NOT_VALID_MESSAGE);
            props.onChange([newStartDate, undefined]);
            setStartDate(newStartDate);
            setEndDate(undefined);
            return;
          }

          props.onChange([newStartDate, minEnd]);
          setStartDate(newStartDate);
          setEndDate(minEnd);
          props.setError && props.setError('');
          return;
        }
      } else if ((!newStartDate || moment(newStartDate).isSameOrAfter(newEndDate)) && props.countMaxStart) {
        const maxStart = (typeof props.countMaxStart === 'function' && newEndDate)
          // @ts-ignore
          ? moment(props.countMaxStart(newEndDate.format(DATE_FORMAT_FOR_CONVERSIONS)))
          : undefined;

        if (maxStart && maxStart.isAfter(moment(props.minStart))) {
          props.setError && props.setError(END_NOT_VALID_MESSAGE);
        }

        props.onChange([maxStart, newEndDate]);
        setStartDate(maxStart);
        setEndDate(newEndDate);
        props.setError && props.setError('');
        return;
      }

      const maxEnd = (typeof props.countMaxEnd === 'function')
        // @ts-ignore
        ? moment(props.countMaxEnd(newStartDate?.format(DATE_FORMAT_FOR_CONVERSIONS)))
        : null;

      if (maxEnd && newEndDate?.isAfter(maxEnd)) {
        props.setError && props.setError(END_NOT_VALID_MESSAGE);
      } else {
        props.setError && props.setError('');
      }

      props.onChange([newStartDate, newEndDate]);

      if (!props.minStart && !props.maxStart && !props.minEnd && !props.maxEnd) {
        if (isStart) setStartDate(newStartDate);
        else setEndDate(newEndDate);
      } else {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      }
    }
  }, [store.focusedInput]);

  const handleStartDateInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.value.length === 10) {
      setNewValueIfDateIsValid(target.value, START_NOT_VALID_MESSAGE, true);
    }
  };

  const handleEndDateInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.value.length === 10) {
      setNewValueIfDateIsValid(target.value, END_NOT_VALID_MESSAGE, false);
    }
  };

  const handleStartDateInputClick = () => {
    if (props.onStartDateClick) props.onStartDateClick();
  };

  const handleEndDateInputClick = () => {
    if (props.onEndDateClick) props.onEndDateClick();
  };


  const handleCalendarClose = () => {
    props.handleCalendarClose && props.handleCalendarClose();
    if (!endDate) {
      props.setError && props.setError(END_REQUIRED);
    }
    if (!startDate) {
      props.setError && props.setError(START_REQUIRED);
    }
    if (inputs[1]?.value && inputs[1].value.length !== 10) {
      props.setError && props.setError(END_NOT_VALID_MESSAGE);
    }
    if (inputs[0]?.value && inputs[0].value.length !== 10) {
      props.setError && props.setError(START_NOT_VALID_MESSAGE);
    }

    // setIsClosed(true);
  };

  useEffect(() => {
    const inputsCollection = wrapperRef && wrapperRef.current?.getElementsByClassName('DateInput_input') as HTMLCollectionOf<HTMLInputElement>;
    const input = inputsCollection?.[0];
    const input2 = inputsCollection?.[1];

    setInputs([input, input2]);
    try {
      if (props.width && input) {
        const element = input.parentNode as HTMLElement;
        element.style.width = `${props.width}px`;
      }
      if (input2) {
        const element = input2.parentNode as HTMLElement;
        if (props.width) {
          element!.style.width = `${props.width}px`;
        }
        if (props.secondMarginLeft) {
          element!.style.marginLeft = `${secondMarginLeft}px`;
        }
      }
    } catch (e) {
      console.error(e);
    }

    createImgAndInit(input, props.width);
    createImgAndInit(input2, props.width);

    if (props.isEndDisabled && input2) {
      input2.parentElement!.style.display = 'none';
    }

    IMask(input, {
      mask: Date,
    });

    IMask(input2, {
      mask: Date,
    });

    input.addEventListener('input', handleStartDateInputChange);
    input2.addEventListener('input', handleEndDateInputChange);

    input.addEventListener('click', handleStartDateInputClick);
    input2.addEventListener('click', handleEndDateInputClick);

    return () => {
      input.removeEventListener('input', handleStartDateInputChange);
      input2.removeEventListener('input', handleEndDateInputChange);
    };
  }, []);

  useEffect(() => {
    const inputsCollection = wrapperRef && wrapperRef.current?.getElementsByClassName('DateInput_input') as HTMLCollectionOf<HTMLInputElement> | undefined;
    const input = inputsCollection?.[0];
    const input2 = inputsCollection?.[1];

    if (dateError) {
      input?.classList.add(styles.dateFinansesError);
      input2?.classList.add(styles.dateFinansesError);
    } else {
      input?.classList.remove(styles.dateFinansesError);
      input2?.classList.remove(styles.dateFinansesError);
    }
  }, [dateError]);

  useEffect(() => {
    if (!props.isSecondDateDisabled) return;
    const inputsCollection = wrapperRef && wrapperRef.current?.getElementsByClassName('DateInput_input') as HTMLCollectionOf<HTMLInputElement>;
    const input2 = inputsCollection[1];
    if (input2 && !startDate) {
      input2.setAttribute('disabled', 'true');
    } else if (input2 && startDate) {
      input2.removeAttribute('disabled');
    }
  }, [startDate, props.isSecondDateDisabled]);

  useEffect(() => {
    handleChangeVisibleMonth();
  }, [visibleMonthOnClick]);

  return (
    <div id={name} className={classNames(props.className)}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      {props.label ? <label
        onClick={() => store.setFocusedInput('startDate')}
        className={styles.label}>{props.label}
      </label> : null}
      <input name={name} style={{ display: 'none' }}/>
      <div ref={wrapperRef}>
        <DateRangePicker
          horizontalMargin={100}
          // @ts-ignore
          startDate={undefinedToNull(startDate)}
          startDateId="start_date_id"
          // @ts-ignore
          endDate={undefinedToNull(endDate)}
          endDateId="end_date_id"
          // @ts-ignore
          onDatesChange={(values: DatesParams) => {
            handleDatesChange({ startDate: values.startDate, endDate: values.endDate });
          }}
          focusedInput={store.focusedInput}
          onFocusChange={handleFocusChange}
          displayFormat="DD.MM.YYYY"
          hideKeyboardShortcutsPanel
          numberOfMonths={props.numberOfMonths || 2}
          block={false}
          small={false}
          navPrev={<NavPrev/>}
          navNext={<NavNext/>}
          withFullScreenPortal={false}
          // @ts-ignore
          anchorDirection={props.anchorDirection || 'left'}
          orientation="horizontal"
          openDirection={props.openDirection || 'down'}
          startDatePlaceholderText={props.startDatePlaceholder || PLACEHOLDER}
          endDatePlaceholderText={props.endDatePlaceholder || PLACEHOLDER}
          minimumNights={0}
          // @ts-ignore
          isDayBlocked={(day: moment.Moment) => handleIsDayBlocked(day, true)}
          firstDayOfWeek={1}
          // @ts-ignore
          isOutsideRange={(day: moment.Moment) => {
            if (handleIsDayBlocked(day, true)) return false;
            return isOutsideRange(day);
          }}
          onClose={handleCalendarClose}
          readOnly={props.isInputsReadOnly}
          // @ts-ignore
          initialVisibleMonth={handleChangeVisibleMonth}
          // Этот код позволяет кастомно выставить месяц на клик по инпуту
          // renderMonthElement={(...args) => {
          //   // @ts-ignore
          //   monthController.current = {
          //     // @ts-ignore
          //     month: args[0].month,
          //     onMonthSelect: args[0].onMonthSelect,
          //     onYearSelect: args[0].onYearSelect,
          //   };
          //   return args[0].month.format('MMMM YYYY');
          // }}
          transitionDuration={0}
        />
      </div>
    </div>
  );
}

export default observer(DatePicker);
