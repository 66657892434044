import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import moment from 'moment';
import { TemplateCommentsProps } from '../../../../NewTemplate/components/TemplateComments/TemplateCommentsProps';
import { ContentContainer } from '../../../../../UI/layout';
import styles from '../../../../NewTemplate/components/TemplateComments/styles.pcss';
import { GroupTitle } from '../../../../../UI/typography';
import { TextArea } from '../../../../../UI/form';
import { senderNameStore } from '../../../../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';

const cx = classNames.bind(styles);

export const TemplateComments: FC<TemplateCommentsProps> = observer(() => {
  const onChangeHandler = (value: string) => {
    senderNameStore.senderNameComment = value;
  };

  return senderNameStore.comments.length > 0 ? (
    <ContentContainer className={styles.whiteBlock}>
      <GroupTitle className={styles.msgTitle}>Комментарии</GroupTitle>
      {
        senderNameStore.comments.map((comment) => {
          return (
            <div className={styles.commentRow}>
              <div
                className={cx(styles.commentItem, { commentItemReject: senderNameStore.senderStatus === 'Rejected' && comment.text === senderNameStore.actualRejectComment?.text })}
                id={senderNameStore.senderStatus === 'Rejected' && comment.text === senderNameStore.actualRejectComment?.text ? 'goTo' : ''}
              >
                { comment.text }
              </div>
              <div className={styles.commentRowDate}>{moment(comment.createdOn).format('DD.MM.YYYY, HH:mm')}, {comment.type !== 'Customer' ? 'Модерация' : 'Вы'}</div>
            </div>
          );
        })
      }
      {
        senderNameStore.senderStatus === 'Rejected' ? (
          <div className={styles.textArea}>
            <TextArea
              maxLength={4000}
              placeholder="Укажите любой комментарий, если нужно"
              value={senderNameStore.senderNameComment}
              onChange={onChangeHandler}
              name="comment"
            />
          </div>
        ) : null
      }
    </ContentContainer>
  ) : null;
});
