import React, { SyntheticEvent } from 'react';
import { Link, navigate, useLocation } from '@reach/router';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import UserInfo from 'store/mobx/UserInfo';


import Common from 'store/mobx/Common';

import { formatPriceTotal } from 'utils/formatting';
import {
  GridIcon,
  AudiencesIcon,
  ChartIcon,
  DiagramIcon,
  BriefcaseIcon,
  ProfileIcon,
  SenderNameIcon,
  SmsIcon,
} from 'redesignSrc/components/SidebarMenu/assets';
import styles from './styles.pcss';


type Props = {
  path: string;
}

const SidebarMenu: React.FC<Props> = () => {
  interface Item {
    href: string;
    label: string;
    iconType: string;
    type?: string
  }

  const promotionalMaterialsMenuItems: Item[] = [
    {
      label: 'Шаблоны сообщений',
      href: '/message-templates',
      iconType: 'template',
    },
    {
      label: 'Имена отправителей',
      href: '/sender-names',
      iconType: 'sender',
    },
  ];

  const menuItems: Item[] = [
    {
      label: 'Мои кампании',
      href: '/my-campaigns',
      iconType: 'grid',
    },
    {
      label: 'Аудитории',
      href: '/my-audiences',
      iconType: 'audiences',
    },
    // {
    //   label: 'Анализ аудитории',
    //   href: '/audience-statistic',
    //   iconType: 'chart',
    // },
    // {
    //   label: 'Мобильные опросы',
    //   href: '/polls',
    //   type: 'custom-poll',
    //   iconType: 'diagram',
    // },
  ];
  // if (window.innerWidth < 992) {
  //   menuItems.push(
  //     {
  //       label: 'Финансы',
  //       href: '/my-finances',
  //       iconType: 'briefcase',
  //     },
  //     {
  //       label: 'Профиль',
  //       href: '/user-info',
  //       iconType: 'profile',
  //     },
  //   );
  // }

  const getCurrentIcon = (iconType: string) => {
    switch (iconType) {
      case 'grid':
        return <GridIcon className={styles.menuIcon}/>;
      case 'template':
        return <SmsIcon className={styles.menuIconTemplate}/>;
      case 'audiences':
        return <AudiencesIcon className={styles.menuIcon}/>;
      case 'sender':
        return <SenderNameIcon className={styles.menuIcon}/>;
      case 'chart':
        return <ChartIcon className={styles.menuIcon}/>;
      case 'diagram':
        return <DiagramIcon className={styles.menuIcon}/>;
      case 'briefcase':
        return <BriefcaseIcon className={styles.menuIcon}/>;
      case 'profile':
        return <ProfileIcon className={styles.menuIcon}/>;
      default:
        return null;
    }
  };

  const { pathname } = useLocation();


  const onClickMenuItemHandler = (item: Item) => async (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    Common.isMobileSidebarVisible = false;
    await navigate(item.href);
  };

  return (
    <>
      <ul className={styles.menuList}>
        {menuItems.map((item) => {
          return (
            <li className={styles.menuItem} key={`${item.label}`}>
              <Link
                to={item.href}
                // условие после || добавлено чтобы вкладка опросы была выделенной при работе с кампаниями  опросы
                className={styles.menuLink}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  Common.isMobileSidebarVisible = false;
                  await navigate(item.href);
                }}
              >
                {getCurrentIcon(item.iconType)}
                {item.label}
                {item.label === 'Финансы' ?
                  <div
                    // @ts-ignore
                    className={classNames(styles.walletPrice, UserInfo.balance?.free_balance < 0 && styles.walletPriceNegative)}>
                    {formatPriceTotal(UserInfo.balance?.free_balance)}
                  </div> : null}
              </Link>
            </li>
          );
        })}
      </ul>
      <hr className={styles.menuListHr}/>
      <h4 className={styles.menuSubListTitle}>Рекламные материалы</h4>
      <ul className={styles.menuList}>
        {promotionalMaterialsMenuItems.map((item) => {
          return (
            <li className={styles.menuItem} key={`${item.label}`}>
              <Link
                to={item.href}
                className={styles.menuLink}
                onClick={onClickMenuItemHandler(item)}
              >
                {getCurrentIcon(item.iconType)}
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default observer(SidebarMenu);
