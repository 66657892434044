import React from 'react';
import { ToastContainer } from 'react-toastify';
import './ReactToastify.css';
import styles from './styles.pcss';

export default function ToastNotifications() {
  return (
    <ToastContainer
      position="top-right"
      className={styles.component}
      newestOnTop
      closeButton={<span />}
    />
  );
}
