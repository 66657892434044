import React from 'react';
import LogoB2B from 'components/common/Logo/LogoB2B';

import styles from './styles.pcss';

const ErrorPageHeader = () => (
  <div className={styles.container}>
    <a className={styles.component} href="/">
      <LogoB2B className={styles.logoImage} />
    </a>
  </div>
);

export default ErrorPageHeader;
