// #1
export const STEP_ERROR_FILE_LOADING = 'STEP_ERROR_FILE_LOADING';
// #2
export const STEP_ERROR_PREPARE_ORDER = 'STEP_ERROR_PREPARE_ORDER';
// #3
export const STEP_ERROR_CALCULATE_COST = 'STEP_ERROR_CALCULATE_COST';
// #4
export const STEP_ORDER_RESULT = 'STEP_ORDER_RESULT';

export const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS';

export const CHART_PIE = 'pie';
export const CHART_DOUBLE_BAR = 'doubleBar';
export const CHART_SIMPLE_BAR = 'simpleBar';
export const CHART_TREE_MAP = 'treeMap';
export const CHART_PICTORIAL_BAR = 'pictorialBar';
export const CHART_TRIPLE_PIE_BARS = 'triplePieBars';
