import { observable, action, runInAction, computed, toJS } from 'mobx';
import moment from 'moment';
import { navigate } from '@reach/router';
import { createAudienceProfiling } from 'requests/campaigns';
import {
  FIELD_CAMPAIGN_ID,
  FIELD_FILES,
  FIELD_FILE_NAME,
  FIELD_FILE_SIZE,
  FIELD_BUDGET,
  NOT_SIZE,
} from 'pages/AudienceStatistic/constants';
import { ORDER_FILES_URL } from 'requests/client/constants';
import {
  axiosAuthorizedRequest,
  composeAxiosDeleteRequest,
  composeAxiosMutationFileDataRequest,
  composeAxiosPostRequest,
} from 'requests/helpers';
import { getCampaignApiUrl, getCampaignStartApiUrl } from 'requests/campaigns/constants';
import { findByFocusOrders } from 'store/AudienceStatistic/utils';
import { FILE_TYPES } from 'pages/constants';
// import { pushToGA } from 'consts/ga-analytics/data-layer';
import { extractError } from 'utils/errors';
import EditCampaignStepper from 'pages/AudienceStatistic/containers/EditCampaignStepper';
import { formatPureFileExtension } from 'utils/formatting';
import confirmDialog from 'components/modals/confirmDialog';
import { ChangeEmail, LockedService } from '../../components/modals/Financial';
import { validateFile } from './utils/reportsAndCreateReport';
import Common from './Common';
import Audience from './Audience';

import {
  STEP_ERROR_FILE_LOADING,
  STEP_ERROR_PREPARE_ORDER,
  STEP_ERROR_CALCULATE_COST,
  STEP_ORDER_RESULT,
} from './constants';
import UserInfo from './UserInfo';
import NewCampaign from './NewCampaign';
import Templates from './Templates';

const successCreateReportTexts = {
  free: {
    title: 'Отчёт принят в работу!',
    description: 'Отчёт будет готов через пару минут\n Найти анализ вашей аудитории вы сможете в разделе «Мои отчёты»',
  },
  payed_post_pay: {
    title: 'Заявка на анализ аудитории отправлена!',
    description: 'Отчёт с платными параметрами появится в разделе «Мои отчёты» в течение 2-х рабочих дней.',
  },
  payed: {
    title: 'Заявка на анализ аудитории отправлена!',
    description: 'Отчёт с платными параметрами появится в разделе «Мои отчёты» в течение 2-х рабочих дней.',
  },
  payed_and_free_post_pay: {
    title: 'Заявка на анализ аудитории отправлена!',
    description: 'Отчёт по общим параметрам появится в разделе «Мои отчёты» через пару минут.\nОтчёт с платными параметрами появится в разделе «Мои отчёты» в течение 2-х рабочих дней.',
  },
  payed_and_free: {
    title: 'Заявка на анализ аудитории отправлена!',
    description: 'Отчёт по общим параметрам появится в разделе «Мои отчёты» через пару минут.\nОтчёт с платными параметрами в течение 2-х рабочих дней после оплаты.',
  },
};

const defaultValues = {
  [FIELD_CAMPAIGN_ID]: null,
  [FIELD_FILES]: null,
  [FIELD_FILE_NAME]: '',
  [FIELD_FILE_SIZE]: 0,
  fileBlob: null,
  [FIELD_BUDGET]: 0,
};

class CreateFocusReport {
  @observable isSend = false
  @observable isModalVisible = false;
  @observable isCriteriesLoading = false;
  @observable isDeleteLoading = false;

  @observable loading = false;
  @observable error = {};
  @observable step = '';
  @observable criteries = null;
  @observable selectedCriteries = [];
  @observable criteriesInputs = {};
  @observable fileContactsCount = 0;
  @observable successCreateModalTexts = {};
  @observable.ref uploadContainer = null;
  @observable myAudienceData = null;

  @observable values = defaultValues;
  @observable typeAddFile = null;

  @action set = (property, value) => {
    this[property] = value;
  }

  @computed get getCostSum() {
    return this.criteries?.filter(item => this.selectedCriteries.includes(item.id))
      .reduce((curSum, item) => curSum + item.addition_price, 0);
  }

  /**
   * Возвращает true если включены платные и бесплатные отчёты
   * @returns {boolean}
   */
  @computed get isFreeAndPayIncluded() {
    return this.isFreeIncluded && this.isPayIncluded;
  }

  /**
   * Если включены платные отчёты
   * @returns {boolean}
   */
  @computed get isPayIncluded() {
    const result = this.criteries?.filter(item => this.selectedCriteries.includes(item.id)) || [];
    return result.some(item => item.addition_price > 0);
  }

  /**
   * Если включены беспланые отчёты
   * @returns {boolean}
   */
  @computed get isFreeIncluded() {
    const result = this.criteries?.filter(item => this.selectedCriteries.includes(item.id)) || [];
    return result.some(item => item.addition_price === 0);
  }

  @action resetValues() {
    this.values = defaultValues;
  }

  @action loadReportCriteries = async () => {
    this.isCriteriesLoading = true;

    try {
      this.criteries = await axiosAuthorizedRequest({ url: '/api/v1/client/criteria/' });
    } catch (e) {
      console.error(e);
    } finally {
      this.isCriteriesLoading = false;
    }
  }

  // 1 шаг - загрузка файла
  @action processLoadFileStep = async (fileBlob, typeAdd) => {
    // const dataLayer = window.dataLayer || [];
    this.typeAddFile = typeAdd;
    if (fileBlob) {
      this.loading = true;
      this.error = {};
      this.values = defaultValues;

      try {
        const error = validateFile(fileBlob);

        if (error) {
          this.values = {
            [FIELD_FILES]: null,
            [FIELD_FILE_NAME]: fileBlob.name,
            [FIELD_FILE_SIZE]: fileBlob.size,
          };

          this.error = error;
          this.step = STEP_ERROR_FILE_LOADING;

          // dataLayer.push({
          //   event: 'event_b2b_beePro',
          //   eventCategory: 'Interaction',
          //   eventAction: 'view',
          //   eventLabel: 'popUp',
          //   eventContent: `${this.error.title}`,
          //   eventOther: this.values?.fileBlob?.name ? formatPureFileExtension(this.values?.fileBlob?.name) : 'myAudiences',
          //   blockName: this.typeAddFile,
          // });
          window.ym(87718585, 'reachGoal', 'err_audience_statistic', { err_audience_statistic: this.error.title });

          confirmDialog(EditCampaignStepper);
          return;
        }
        const { id: nextFileId } = await composeAxiosMutationFileDataRequest({ url: ORDER_FILES_URL, method: 'POST' })({
          file: fileBlob,
          type: FILE_TYPES.FOCUS,
        });
        runInAction(() => {
          this.values[FIELD_FILES] = nextFileId;
          this.values.fileBlob = fileBlob;
        });
      } catch (error) {
        runInAction(() => {
          this.error = extractError(error);
          this.values = {
            [FIELD_FILES]: null,
            [FIELD_FILE_NAME]: fileBlob.name,
            [FIELD_FILE_SIZE]: fileBlob.size,
          };
          this.step = STEP_ERROR_FILE_LOADING;

          // dataLayer.push({
          //   event: 'event_b2b_beePro',
          //   eventCategory: 'Interaction',
          //   eventAction: 'view',
          //   eventLabel: 'popUp',
          //   eventContent: `${this.error.title}`,
          //   eventOther: this.values?.fileBlob?.name ? formatPureFileExtension(this.values?.fileBlob?.name) : 'myAudiences',
          //   blockName: this.typeAddFile,
          // });
          window.ym(87718585, 'reachGoal', 'err_audience_statistic', { err_audience_statistic: this.error.title });

          confirmDialog(EditCampaignStepper);
        });
      } finally {
        runInAction(() => {
          this.loading = false;
        });
      }
    }
  };

  // 2 шаг
  @action processPrepareOrderStep = async () => {
    // const dataLayer = window.dataLayer || [];
    try {
      this.loading = true;
      await this.processCalculateCost();
    } catch (error) {
      runInAction(() => {
        this.step = STEP_ERROR_PREPARE_ORDER;
        this.error = extractError(error);

        // dataLayer.push({
        //   event: 'event_b2b_beePro',
        //   eventCategory: 'Interaction',
        //   eventAction: 'view',
        //   eventLabel: 'popUp',
        //   eventContent: `${this.error.title}`,
        //   eventOther: this.values?.fileBlob?.name ? formatPureFileExtension(this.values?.fileBlob?.name) : 'myAudiences',
        //   blockName: this.typeAddFile,
        // });
        window.ym(87718585, 'reachGoal', 'err_audience_statistic', { err_audience_statistic: this.error.title });

        confirmDialog(EditCampaignStepper);
        this.loading = false;
      });
    }
  };

  @computed get criteriesList() {
    if (UserInfo.data.late_payment) {
      const result = this.criteries?.filter(item => this.selectedCriteries.includes(item.id)) || [];
      return result.filter(item => item.addition_price === 0).map(item => item.id);
    }
    return this.criteries?.filter(item => this.selectedCriteries.includes(item.id)).map(item => item.id);
  }

  @computed get inputsList() {
    if (UserInfo.data.late_payment) {
      return {};
    }
    return this.criteriesInputs;
  }

  @action processCalculateCost = async () => {
    // const dataLayer = window.dataLayer || [];
    this.loading = true;
    const today = moment(new Date()).format('DD.MM.YYYY');
    const body = this.myAudienceData ? {
      campaignName: `Отчёт от ${today}`,
      audience: this.myAudienceData.id,
    } : {
      campaignName: `Отчёт от ${today}`,
      [FIELD_FILES]: [this.values[FIELD_FILES]],
    };

    try {
      const { orders, id } = await createAudienceProfiling(body);

      const { budget, data } = findByFocusOrders(orders);

      runInAction(() => {
        this.values[FIELD_BUDGET] = budget;
        this.values[FIELD_CAMPAIGN_ID] = id;
        this.values[FIELD_FILE_NAME] = this.values.fileBlob?.name || this.myAudienceData?.name;
        this.values[FIELD_FILE_SIZE] = this.myAudienceData ? NOT_SIZE : this.values.fileBlob.size;
        if (this.myAudienceData) {
          this.values[FIELD_FILES] = this.myAudienceData?.id;
        }
        this.fileContactsCount = data?.quantityMin;
      });
      this.myAudienceData = null;

      return true;
    } catch (error) {
      if (error?.response?.status === 400 && error?.response?.data?.detail && error?.response?.data?.detail === 'not_enough_data') {
        this.error = {
          title: 'В базе недостаточно данных',
          description: error.response.data.error,
          isDraftError: true,
        };

        // dataLayer.push({
        //   event: 'event_b2b_beePro',
        //   eventCategory: 'Interaction',
        //   eventAction: 'view',
        //   eventLabel: 'popUp',
        //   eventContent: `${this.error.title}`,
        //   eventOther: this.values?.fileBlob?.name ? formatPureFileExtension(this.values?.fileBlob?.name) : 'myAudiences',
        //   blockName: this.typeAddFile,
        // });
        window.ym(87718585, 'reachGoal', 'err_audience_statistic', { err_audience_statistic: this.error.title });

        this.step = STEP_ERROR_CALCULATE_COST;
        confirmDialog(EditCampaignStepper);
      } else {
        runInAction(() => {
          this.error = extractError(error);
          this.step = STEP_ERROR_CALCULATE_COST;
          // dataLayer.push({
          //   event: 'event_b2b_beePro',
          //   eventCategory: 'Interaction',
          //   eventAction: 'view',
          //   eventLabel: 'popUp',
          //   eventContent: `${this.error.title}`,
          //   eventOther: this.values?.fileBlob?.name ? formatPureFileExtension(this.values?.fileBlob?.name) : 'myAudiences',
          //   blockName: this.typeAddFile,
          // });
          window.ym(87718585, 'reachGoal', 'err_audience_statistic', { err_audience_statistic: this.error.title });
          confirmDialog(EditCampaignStepper);
        });
      }

      this.isModalVisible = true;
      return false;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
  // processOrderReport
  @action loadStartFocusCampaign = async ({ promocode } = {}) => {
    // const dataLayer = window.dataLayer || [];

    let reportData = {};
    const { FOCUS_DRAFT_COUNT } = Common.constants;
    if (this.getCostSum > 0) {
      if (!UserInfo.data?.company?.inn) {
        NewCampaign.currentCampaign.channel_uid = 'focus';
        await navigate('/audience-statistic/confirm-inn/');
        return;
      }
    }
    const campaignId = this.values[FIELD_CAMPAIGN_ID];

    this.loading = true;

    // не даём отправить кампанию в которой присутствуют только платные услуги, если есть задолженности
    if (UserInfo.data.late_payment && !this.isFreeAndPayIncluded && this.getCostSum > 0) {
      NewCampaign.startingCampaign = false;
      const response = await confirmDialog(LockedService, {
        description: 'DebtFocusReport',
      });
      if (response === 'changeEmail') {
        await confirmDialog(ChangeEmail);
      }
      return;
    }

    try {
      reportData = await composeAxiosPostRequest({
        url: getCampaignStartApiUrl(campaignId),
        data: {
          criteria: this.criteriesList,
          ...this.inputsList,
          promo_codes: promocode ? [{ promo_code: promocode }] : [],
        },
      });

      // Если есть платные и бесплатные отчёты и у пользователя нет задолженностей
      if (this.isFreeAndPayIncluded && !UserInfo.data.late_payment) {
        this.successCreateModalTexts = UserInfo.data.post_pay
          ? successCreateReportTexts.payed_and_free_post_pay
          : successCreateReportTexts.payed_and_free;
      }

      // Если у пользователя есть платные и бесплатные отчёты и есть задолженность
      if (this.isFreeAndPayIncluded && UserInfo.data.late_payment) {
        const response = await confirmDialog(LockedService, {
          description: 'DebtReportWarning',
        });
        if (response === 'changeEmail') {
          await confirmDialog(ChangeEmail);
        }
      }

      // Если у пользователя есть только платные отчёты и нет задолженности
      if (this.isPayIncluded && !this.isFreeIncluded && !UserInfo.data.late_payment) {
        this.successCreateModalTexts = UserInfo.data.post_pay
          ? successCreateReportTexts.payed_post_pay
          : successCreateReportTexts.payed;
      }

      // Если есть только бесплатные отчёты
      if (this.getCostSum === 0) {
        this.successCreateModalTexts = successCreateReportTexts.free;
      }

      if (reportData.free_focus_limit_exceeded) {
        runInAction(() => {
          this.error = {
            title: 'Вы достигли лимита \n по количеству отчётов в день',
            description: `В течение дня можно сформировать не более ${FOCUS_DRAFT_COUNT} отчётов.\n Завтра лимит восстановится и вы сможете получить новый отчёт`,
          };
          // dataLayer.push({
          //   event: 'event_b2b_beePro',
          //   eventCategory: 'Interaction',
          //   eventAction: 'view',
          //   eventLabel: 'popUp',
          //   eventContent: `${this.error.title}`,
          //   eventOther: this.values?.fileBlob?.name ? formatPureFileExtension(this.values?.fileBlob?.name) : 'myAudiences',
          //   blockName: this.typeAddFile,
          // });
          window.ym(87718585, 'reachGoal', 'err_audience_statistic', { err_audience_statistic: this.error.title });
          this.step = STEP_ERROR_PREPARE_ORDER;
          confirmDialog(EditCampaignStepper);
        });
      } else {
        this.step = STEP_ORDER_RESULT;
        // dataLayer.push({
        //   event: ' event_b2b_beePro',
        //   eventCategory: ' Interaction',
        //   eventAction: 'click',
        //   eventLabel: 'button',
        //   eventContent: 'Создать отчет',
        //   eventOther: this.values?.fileBlob?.name ? formatPureFileExtension(this.values?.fileBlob?.name) : 'myAudiences',
        //   blockName: this.typeAddFile,
        // });
        window.ym(87718585, 'reachGoal', 'audience_statistic');
      }

      this.values = {
        [FIELD_FILES]: null,
        [FIELD_FILE_NAME]: '',
        [FIELD_FILE_SIZE]: 0,
      };
    } catch (e) {
      // pushToGA({
      //   event: 'event_b2b_audienceAnalysis',
      //   action: 'order_confirmation_error',
      //   blockName: Templates.getAudienceStatisticTemplate('LoadBaseBanner').title,
      // });
      this.step = STEP_ERROR_PREPARE_ORDER;
      confirmDialog(EditCampaignStepper);
      throw e;
    } finally {
      await Audience.getCampaigns();
      this.loading = false;
      this.isSend = false;
      if (
        (!reportData.free_focus_limit_exceeded && !UserInfo.data.late_payment) ||
        (UserInfo.data.late_payment && this.isFreeIncluded && this.getCostSum === 0)
      ) {
        runInAction(() => {
          this.isModalVisible = true;
        });
      }
      this.selectedCriteries = [];
      this.criteriesInputs = {};
    }
  };

  @action deleteFile = async () => {
    this.loading = true;
    try {
      await composeAxiosDeleteRequest({ url: getCampaignApiUrl(this.values[FIELD_CAMPAIGN_ID]) })();

      this.values = {
        [FIELD_FILES]: null,
        [FIELD_FILE_NAME]: '',
        [FIELD_FILE_SIZE]: 0,
      };
    } finally {
      this.loading = false;
    }
  }

  @action restoreFromDraft = async (campaignId) => {
    this.loading = true;
    const { orders } = await axiosAuthorizedRequest({
      url: getCampaignApiUrl(campaignId),
    });
    runInAction(() => {
      this.loading = false;
      const { budget } = findByFocusOrders(orders);
      this.values[FIELD_CAMPAIGN_ID] = campaignId;
      this.values[FIELD_BUDGET] = budget;
      this.isModalVisible = true;
    });
  };
}


const CreateReportStore = new CreateFocusReport();
export default CreateReportStore;
