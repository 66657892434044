import React, { FC, Ref } from 'react';
import { Typography } from '@beeline/design-system-react';
import danglingPrepositions from 'utils/danglingPrepositions';

interface TypographyWithDanglingProps {
  variant: 'h1' |
  'h2' |
  'h3' |
  'h4' |
  'h5' |
  'h6' |
  'body1' |
  'body2' |
  'body3' |
  'subtitle1' |
  'subtitle2' |
  'subtitle3' |
  'caption' |
  'overline';
  ref?: Ref<HTMLElement>
  className?: string;
}

export const TypographyWithDangling: FC<TypographyWithDanglingProps> = ({ children, ...props }) => {
  return (
    <>
      { typeof children === 'string' ? (<Typography {...props}>{danglingPrepositions(children)}</Typography>) : <Typography {...props}>{children}</Typography>}
    </>
  );
};
