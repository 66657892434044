import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { observable, reaction, toJS } from 'mobx';
import { Link } from '@reach/router';
import danglingPrepositions from 'utils/danglingPrepositions';
import TooltipInfo from 'redesignSrc/UI/elements/TooltipInfo';
import NewCampaign from 'store/mobx/NewCampaign';
import { formatPrice, formatPriceWithLabel, formatPureFileExtension } from '../../../../utils/formatting';
import Icon from './warningIcon.svg';
import styles from './styles.pcss';

class Store {
  @observable isPopup = false
}
const store = new Store();

reaction(
  () => NewCampaign.calculate.insufficient_min_budget,
  () => {
    if (NewCampaign.calculate.insufficient_audience) {
      store.isPopup = true;
      setTimeout(() => {
        store.isPopup = false;
      }, 5000);
    }
  },
);

reaction(
  () => NewCampaign.calculate.insufficient_audience,
  () => {
    if (NewCampaign.calculate.insufficient_audience) {
      store.isPopup = true;
      setTimeout(() => {
        store.isPopup = false;
      }, 5000);
    }
  },
);

type Props = {
  className?: string
  isTotalBlock?:boolean
  TextMinBudget?: any,
  TextMinAudience?: any,
  TotalText?: any,
}

export const WarningAudiencePopup:React.FC<Props> = observer(({
  className,
  TextMinAudience,
  TextMinBudget,
}) => {
  const { insufficient_audience, insufficient_min_budget } = NewCampaign.calculate;

  useEffect(() => {
    // const dataLayer = window.dataLayer || [];
    // @ts-ignore
    // const channel = NewCampaign.currentCampaign.currentOrder?.channel_uid;
    if (insufficient_audience && !insufficient_min_budget) {
      // dataLayer.push({
      //   event: 'event_b2b_beePro',
      //   eventCategory: 'Interaction',
      //   eventAction: 'view',
      //   eventLabel: 'popUp',
      //   eventContent: 'Недостаточная аудитория',
      //   eventOther: 'audSmall',
      //   blockName: channel,
      // });
    }
    if (insufficient_audience && insufficient_min_budget) {
      // dataLayer.push({
      //   event: 'event_b2b_beePro',
      //   eventCategory: 'Interaction',
      //   eventAction: 'view',
      //   eventLabel: 'popUp',
      //   eventContent: 'Недостаточная аудитория',
      //   eventOther: 'audMin',
      //   blockName: channel,
      // });
    }
  }, [insufficient_audience, insufficient_min_budget]);

  // @ts-ignore
  return (
    <>
      {store.isPopup &&
      insufficient_audience &&
      !insufficient_min_budget &&
      <div className={classNames(styles.containerPopup, className)}>
        <Icon/>
        <span className={styles.text}>
          {// @ts-ignore
            TextMinAudience && <TextMinAudience />}
        </span>
      </div>}
      {store.isPopup &&
      insufficient_audience &&
      insufficient_min_budget &&
      <div className={classNames(styles.containerPopup, className)}>
        <Icon/>
        <span className={styles.text}>
          {// @ts-ignore
          TextMinBudget && <TextMinBudget />}
        </span>
      </div>}
    </>
  );
});

export const WarningZeroAudiencePopup:React.FC<Props> = observer(({ className }) => {
  return (
    <div className={classNames(styles.containerPopup, className)}>
      <Icon/>
      <span className={styles.text}>
        Не нашлось аудитории с такими параметрами.
        Попробуйте расширить географию или отказаться от некоторых интересов
      </span>
    </div>
  );
});

export const WarningMinConversionAudiencePopup:React.FC<Props> = observer(({ className }) => {
  return (
    <div className={classNames(styles.containerPopup1, className)}>
      <Icon/>
      <span className={styles.text}>
        Ваша аудитория слишком мала, чтобы собрать достаточное количество анкет. Измените параметры аудитории.
      </span>
    </div>
  );
});

export const WarningAudienceIcon:React.FC<Props> = observer(({
  className,
  isTotalBlock,
  // @ts-ignore
  TextMinAudience,
  // @ts-ignore
  TextMinBudget,
  TotalText,
}) => {
  const { insufficient_audience, insufficient_min_budget } = NewCampaign.calculate;
  return (
    <>
      {insufficient_audience &&
      !insufficient_min_budget &&
        !isTotalBlock &&
        <TooltipInfo
          icon="yellow"
          classNameIcon={classNames(styles.icon2, className)}
        >
          <span className={styles.text}>
            {// @ts-ignore
            TextMinAudience && <TextMinAudience />}
          </span>
        </TooltipInfo>
      }

      {insufficient_audience &&
      insufficient_min_budget &&
      <TooltipInfo
        icon="yellow"
        classNameIcon={classNames(styles.icon2, className)}
      >
        <span className={styles.text}>
          {// @ts-ignore
          isTotalBlock ? <>{TotalText && <TotalText />}</> : <>{
            // @ts-ignore
            TextMinBudget && <TextMinBudget />
          }</>}
        </span>
      </TooltipInfo>
      }
    </>

  );
});
