import { action, computed, observable, reaction, toJS } from 'mobx';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import { TemplatesStatuses } from 'store/mobx/MessageTemplates/types';
import NewOrder from 'store/mobx/NewOrder';
import ShortLinkStore from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/storeT';
import UserInfo from 'store/mobx/UserInfo';
import { OrderLink } from 'redesignSrc/types';
import { TypeMessageTemplate, TypeTemplates } from './types';

export type TemplateValues = {
  id?: number;
  name: string;
  description: string;
  additionalTemplate: string;
  template: string;
  groupId: number;
  groupName: string;
  isUnique: boolean;
  status: TemplatesStatuses | string;
  createDate: string;
  customerId?: number;
  shortTemplate:string | null;
  link: OrderLink,
  variables: object | null
}

class SaveTemplateStore {
  @observable templates: TypeTemplates | null = null
  @observable currentTemplate: null | TypeMessageTemplate = null
  @observable template: TemplateValues | null = null
  @observable appliedText: string | null = null
  @observable messageTemplatesAll: TypeMessageTemplate[] = []
  @observable isLoadingTemplate = false
  @observable isResetStore = false


  @action getTemplates = async () => {
    const url = UserInfo.isRoleSS ? `/api/selectlist/messageTemplates?customerId=${NewOrder.order?.customerId}` : '/api/selectlist/messageTemplates';
    try {
      this.templates = await axiosBaseRequestAdTech({
        url,
      });
      if (this.templates && Array.isArray(toJS(this.templates))) {
        this.templates.forEach((item) => {
          this.messageTemplatesAll = [...this.messageTemplatesAll, ...item.messageTemplates];
        });
      }
      if (NewOrder.smsCampaign.templateId) {
        this.template = await axiosBaseRequestAdTech({
          url: `/api/messageTemplates/${NewOrder.smsCampaign.templateId}`,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  @action getTemplate = async (id: number) => {
    this.isLoadingTemplate = true;
    try {
      this.template = await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${id}`,
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingTemplate = false;
    }
  }

  reset = () => {
    this.isResetStore = true;
    this.template = null;
    this.currentTemplate = null;
    this.templates = null;
    this.appliedText = null;
    this.messageTemplatesAll = [];
  }

  @computed get isAcceptedTemplate() {
    return (this.template?.status === 'Accepted' && NewOrder.smsCampaign.templateId);
  }
}

const saveTemplate = new SaveTemplateStore();

reaction(
  () => saveTemplate.template,
  () => {
    if (saveTemplate.template === null && !saveTemplate.isResetStore) {
      NewOrder.smsCampaign.message = null;
      NewOrder.smsCampaign.shortMessage = null;
      NewOrder.smsCampaign.links = {
        link: null,
        shortLink: null,
        isShort: false,
        dynamicPartLink: null,
      };
      ShortLinkStore.updateStore();
      NewOrder.smsCampaign.additionalInfo = null;
      saveTemplate.appliedText = '';
      NewOrder.smsCampaign.templateId = null;
    }
  },
);

export default saveTemplate;
