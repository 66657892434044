import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@beeline/design-system-react';
import GreyContainerPopup from 'redesignSrc/UI/elements/GreyContainerPopup';
import StoreDnd from 'containers/ContainerDnD/StoreDnd';
import { PriceInput } from 'redesignSrc/UI/form';
import { FormFieldLabel } from 'redesignSrc/UI/typography';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import StoreT from '../../storeT';
import styles from './styles.pcss';


const PhoneBlock:React.FC = () => {
  const [value, setValue] = useState(StoreT.currentValueVariable.phone?.value || '');
  const [oddLoaded, setOddLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    if (oddLoaded) {
      if (/^(\+7|8)[0-9]{10}$/.test(value)) {
        setIsValid(true);
        setError(null);
      } else {
        setIsValid(false);
      }
    }
    setOddLoaded(true);
  }, [value]);

  const handleSubmit = () => {
    if (StoreT.currentValueVariable.phone) {
      StoreT.setVariable(StoreT.currentValueVariable.phone.type, value);
      StoreT.currentValueVariable.phone = null;
    } else {
      StoreT.setVariable('phone', value);
    }
    StoreDnd.deleteBox('phone');
  };

  const onClose = () => {
    StoreT.currentValueVariable.phone = null;
    StoreDnd.deleteBox('phone');
  };

  const handleOnBlur = () => {
    if (!/^(\+7|8)[0-9]{10}$/.test(value)) {
      setError('Номер должен быть в формате +7 ХХХХХХХХХХ или 8 ХХХХХХХХХХ');
    }
  };
  return (
    <GreyContainerPopup
      title="Введите значение и мы добавим его в SMS"
      onClose={onClose}
    >
      <div className={styles.content}>
        <FormFieldLabel>Номер телефона</FormFieldLabel>
        <PriceInput
          isPlusPhone
          placeholder="+7 ХХХХХХХХХХ или 8 ХХХХХХХХХХ"
          format={false}
          value={value}
          onChange={(e) => {
            setValue(e);
          }}
          onBlur={handleOnBlur}
          error={error}
        />
        <div className={styles.buttonContainer}>
          <Button
            variant="overlay"
            size="medium"
            disabled={ !isValid }
            onClick={handleSubmit}
          >
            {StoreT.currentValueVariable.phone ? 'Изменить телефон' : 'Добавить в текст'}
          </Button>
          {/* <PrimaryButton
            isGray={!isValid}
            isDisabled={!isValid}
            onClick={handleSubmit}
          >
            {StoreT.currentValueVariable.phone ? 'Изменить телефон' : 'Добавить в текст'}
          </PrimaryButton> */}
        </div>

      </div>

    </GreyContainerPopup>
  );
};

export default observer(PhoneBlock);
