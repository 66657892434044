import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import styles from './styles.pcss';


const ContentContainer = ({ children, className = '', id }: {children: React.ReactNode | string, className?: string, id?:string}) => {
  return (
    <section id={id} className={classNames(styles.container, styles.content, className)}>{children}</section>
  );
};

const Section = ({ children, className = '' }: {children: React.ReactNode | string, className?: string}) => {
  return (
    <section className={classNames(styles.container, className)}>{children}</section>
  );
};

const Row = ({ children, className = '', marginTop, marginBottom, alignItems, justifyContent }: {
  children: React.ReactNode | string,
  className?: string
  marginTop?: number,
  marginBottom?: number,
  alignItems?: string,
  justifyContent?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'space-between'
}) => {
  const StyledRow = styled.div`
    ${marginTop && `margin-top:${marginTop}px;`}
    ${marginBottom && `margin-bottom:${marginBottom}px;`}
    ${alignItems && `align-items:${alignItems};`}
    ${justifyContent && `justify-content:${justifyContent};`}
  `;
  return (
    <StyledRow className={classNames(styles.row, className)}>{children}</StyledRow>
  );
};

const Column = ({ children, className = '' }: {children: React.ReactNode | string, className?: string}) => {
  return (
    <div className={classNames(styles.column, className)}>{children}</div>
  );
};

export { ContentContainer, Row, Column, Section };

