import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

export const CardBottomPanelBtns: FC = ({ children, ...props }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const currentWidth = 0;

  const divRef = useRef(null);

  // useEffect(() => {
  //   // строка ниже чисто для счетчиков нужна
  //   setScrollPosition(window.scrollX);
  //   // @ts-ignore
  //   currentWidth = divRef?.current?.offsetWidth;
  // }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollPosition(window.scrollX);
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <>
      {/* @ts-ignore */}
      <div ref={divRef} {...props} className={classNames(styles.btnsWrapper)}>
        <div className={styles.container}>
          {children}
        </div>
      </div>
    </>
  );
};


// export const CardBottomPanelBtns: FC = ({ children, ...props }) => {
//   const [scrollPosition, setScrollPosition] = useState(0);
//   const currentWidth = 0;

//   const divRef = useRef(null);

//   useEffect(() => {
//     // строка ниже чисто для счетчиков нужна
//     setScrollPosition(window.scrollX);
//     // @ts-ignore
//     currentWidth = divRef?.current?.offsetWidth;
//   }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       setScrollPosition(window.scrollX);
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <>
//       {/* @ts-ignore */}
//       <div ref={divRef} {...props} className={classNames(styles.btnsWrapper)} style={{ left: `${345 - scrollPosition}px` }}>
//         <div className={styles.container}>
//           {children}
//         </div>
//       </div>
//     </>
//   );
// };
