import React, { FC } from 'react';


import ImgOk from '../../../../../components/modals/AddName/assets/ok.svg';
import ImgNotOk from '../../../../../components/modals/AddName/assets/notOk.svg';
import styles from './styles.pcss';

export const InfoNameSender: FC = () => {
  return (
    <div className={styles.containerInfo} style={{ marginBottom: '20px' }}>
      <div className={styles.mainList}>
        <ImgOk/> <span>Какие документы подойдут:</span>
      </div>
      <ul className={styles.secondaryList} style={{ marginBottom: '10px' }}>
        <li>Свидетельство о регистрации бренда</li>
        <li>Документ о покупке доменного имени (свидетельство или справка где будут указаны сроки владения)</li>
        <li>Скан-копия ИНН, если имя отправителя совпадает с названием юр. лица</li>
        <li>Для ИП — фото вывески с адресом и документ об аренде помещения данным ИП</li>
        {/* <li>Франшиза — договор, в котором присутствует пункт о праве использования имени <span style={{ paddingLeft: '12px' }}>отправителя и/или бренда</span></li> */}
        <li>Франшиза – договор, в котором присутствует пункт о праве использования имени отправители и/или бренда + скан копия товарного знака</li>
        <li>В случае если Вы не является правообладателем доменного имени или любой другой интеллектуальной собственности, подойдёт лицензионный договор или договор отчуждения прав</li>
      </ul>
      <div className={styles.mainList}>
        <ImgNotOk/> <span>Какие документы точно НЕ подойдут:</span>
      </div>

      <ul className={styles.secondaryList}>
        <li>Выписка из ЕГРЮЛ</li>
        <li>Скриншот сайта, логотипа</li>
        <li>Копии документов без подписей и печатей</li>
        <li>Выписки из СПАРК, ФИПС, справочников</li>
      </ul>
    </div>
  );
};
