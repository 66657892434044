import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@beeline/design-system-react';
import GreyContainerPopup from 'redesignSrc/UI/elements/GreyContainerPopup';
import StoreDnd from 'containers/ContainerDnD/StoreDnd';
import { PriceInput } from 'redesignSrc/UI/form';
import { FormFieldLabel } from 'redesignSrc/UI/typography';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import StoreT from '../../storeT';
import styles from './styles.pcss';


const DiscountBlock:React.FC = () => {
  const [value, setValue] = useState(StoreT.currentValueVariable.discount?.value?.replace('%', '') || '');
  const [oddLoaded, setOddLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    if (oddLoaded) {
      if (value.length !== 0) {
        setIsValid(true);
        setError(null);
      } else {
        setIsValid(false);
      }
    }
    setOddLoaded(true);
  }, [value]);

  const handleSubmit = () => {
    if (StoreT.currentValueVariable.discount) {
      StoreT.setVariable(StoreT.currentValueVariable.discount.type, `${value}%`);
      StoreT.currentValueVariable.discount = null;
    } else {
      StoreT.setVariable('discount', `${value}%`);
    }
    StoreDnd.deleteBox('discount');
  };

  const onClose = () => {
    StoreT.currentValueVariable.discount = null;
    StoreDnd.deleteBox('discount');
  };

  const handleOnBlur = () => {
    if (value.length === 0) {
      setError('Обязательное поле');
    }
  };
  return (
    <GreyContainerPopup
      title="Введите значение и мы добавим его в SMS"
      onClose={onClose}
    >
      <div className={styles.content}>

        <FormFieldLabel>Скидка</FormFieldLabel>
        <PriceInput
          className={styles.input}
          placeholder="Введите число"
          format={false}
          maxNumber={100}
          value={value}
          onChange={(e) => {
                setValue(e);
          }}
          onBlur={handleOnBlur}
          error={error}
        />


        <div className={styles.buttonContainer}>
          <Button
            variant="overlay"
            size="medium"
            disabled={ !isValid }
            onClick={handleSubmit}
          >
            {StoreT.currentValueVariable.discount ? 'Изменить скидку' : 'Добавить в текст'}
          </Button>
          {/* <PrimaryButton
            isGray={!isValid}
            isDisabled={!isValid}
            onClick={handleSubmit}
          >
            {StoreT.currentValueVariable.discount ? 'Изменить скидку' : 'Добавить в текст'}
          </PrimaryButton> */}
        </div>

      </div>

    </GreyContainerPopup>
  );
};

export default observer(DiscountBlock);
