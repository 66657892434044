import { action, observable, computed, runInAction, reaction, keys } from 'mobx';
import { axiosAuthorizedRequest, axiosBaseRequestAdTech } from 'requests/helpers';
import NewCampaign from './NewCampaign';

const KEYSGEO = ['job_geo', 'home_geo', 'weekend_geo', 'any_location'];

const COMPANY_TYPE = {
  PUSH: 'Push',
  SMS: 'Sms',
};

class Constructor {
  @observable recommendations = null;
  @observable.ref industries = [];
  @observable.ref patternsSms = [];
  @observable activePatternsSmsId = null;
  @observable currentTextPattern = '';
  @observable selectedTextPattern = '';
  @observable clickedPatternSmsId = null;
  @observable lastTimeTemplateModified = null

  @action reset = () => {
    this.recommendations = null;
    this.industries = [];
    this.patternsSms = [];
    this.activePatternsSmsId = null;
    this.currentTextPattern = '';
    this.selectedTextPattern = '';
    this.clickedPatternSmsId = null;
    this.lastTimeTemplateModified = null;
  }

  @action getRecommendations = async (industrialId) => {
    try {
      const request = await axiosAuthorizedRequest({ url: `/api/v1/client/recommendations/?industry=${industrialId}` });
      runInAction(() => {
        this.recommendations = request[0];
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action getIndustries = async (type = COMPANY_TYPE.SMS) => {
    try {
      const request = await axiosBaseRequestAdTech({ url: `api/industry?campaignType=${type}` });

      runInAction(() => {
        this.industries = request;
      });
    } catch (e) {
      console.error(e);
    }
  }

  rename = (data) => {
    return data.map((tmp) => {
      return {
        ...tmp,
        name: tmp.name.replace('Шаблон', 'Пример'),
        sort: +tmp.name.split(' ')[1],
      };
    }).sort((a, b) => a.sort - b.sort);
  }

  @action getPatternsSMS = async (patternId) => {
    try {
      const request = await axiosBaseRequestAdTech({ url: `/api/industry/${patternId}/templates` });
      runInAction(() => {
        this.patternsSms = this.rename(request);
        this.clickedPatternSmsId = this.patternsSms[0]?.id;
      });
    } catch (e) {
      console.error(e);
    }
  }

  @computed get clickedPatternSmsText() {
    if (this.patternsSms?.length && this.clickedPatternSmsId) {
      return this.patternsSms.find(pattern => pattern.id === this.clickedPatternSmsId);
    }
    return '';
  }

  @computed get getNameindustries() {
    const curruntId = NewCampaign.currentCampaign.adtechIndustryID;
    return this.industries.find(({ id }) => id === curruntId)?.name;
  }

  @computed get getGeoOption() {
    if (this.recommendations?.taxons?.includes('geo_points')) {
      return 1;
    }
    return 0;
  }
}
const constructorStore = new Constructor();

reaction(
  () => constructorStore.recommendations,
  () => {
    const isDefaultGeoPoint = (
      !keys(NewCampaign.currentCampaign.selection.data)
        .some((keyTaxon) => KEYSGEO.includes(keyTaxon)) &&
      Boolean(constructorStore.recommendations?.geo_radius)
    );
    if (isDefaultGeoPoint) {
      NewCampaign.currentCampaign.selection.data.geoAction = 'points';
    }
  },
);

reaction(
  () => constructorStore.activePatternsSmsId,
  () => {
    constructorStore.currentTextPattern = constructorStore.patternsSms.find(({ id }) => constructorStore.activePatternsSmsId === id)?.text;
  },
);

export default constructorStore;
