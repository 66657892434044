import React, { useEffect, useRef, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import classNames from 'classnames/bind';
import OutsideClickTracker from 'redesignSrc/UI/elements/OutsideClickTracker/OutsideClickTracker';
import Arrow from 'redesignSrc/UI/form/SelectionInput/arrow.svg';
import NewOrder from 'store/mobx/NewOrder';
import { ERROR_KEY } from 'redesignSrc/HOC/hooks/useValidation';
import { FormFieldLabel } from 'redesignSrc/UI/typography';
import { TypeIndustries } from '../../../index';
import SuggestListWithSearchVoice, { TypeChildren } from '../SuggestListWithSearchVoice';
import styles from './styles.pcss';

export const findIndustriesId = (id:number| null, industries: TypeIndustries) => {
  if (!id) return null;
  for (let i = 0; i < industries.length; i++) {
    const el = industries[i].children;
    for (let j = 0; j < el.length; j++) {
      const item = el[j];
      if (item.id === id) {
        return {
          name: item.name,
          id: item.id,
        };
      }
    }
  }
  return null;
};

  type Props = {
  handlerChange:()=>void
  validation?: any,
  name?:string,
  industries:TypeIndustries,
}

const CoastPerActionBlock:React.FC<Props > = ({ handlerChange, validation, name, industries }) => {
  const [value, setValue] = useState<TypeChildren|null>(findIndustriesId(NewOrder.voiceTargetDetails.industryId, industries));
  const [active, setActive] = useState(false);
  const [error, setError] = useState<string|null>(null);

  const handleChange = (e:TypeChildren) => {
    setValue(e);
    setActive(false);
    NewOrder.voiceTargetDetails.industryId = e?.id || null;
    validation && validation?.getvalidObj(name);
    handlerChange();
  };

  useEffect(() => {
    setError(validation?.errors[name as ERROR_KEY]);
  }, [validation?.errors[name as ERROR_KEY]]);

  const handlerBlur = () => {
    validation && validation?.getvalidObj(name);
  };

  return (
    <>
      <FormFieldLabel>Отрасль компании</FormFieldLabel>
      <div
        id="industry"
        className={classNames(styles.containerMultiSelect, error && styles.errorBord)}
        onBlur={handlerBlur}
      >
        <div

          className={styles.header}
          onClick={() => setActive(!active)}
        >
          <div className={styles.selectTitle}>
            {value && value.name || <span>Не выбрано</span>}
          </div>
          <Arrow
            className={classNames(styles.arrow, active && styles.activeArrow)}
            // onClick={() => setActive(!active)}
          />
        </div>
        {active &&
          <OutsideClickTracker
            className={classNames(styles.openMultiSelect)}
            onClickOutside={(e: any) => {
              if (e.target.id !== 'industry' && !document.getElementById('industry')?.contains(e.target)) {
                setActive(false);
              }
            }}
          >
            <SuggestListWithSearchVoice
              onChangeText={() => {
                NewOrder.voiceTargetDetails.industryId = null;
                setValue(null);
              }
              }
              data={industries}
              onChange={handleChange}
              value={value?.name || ''}
            />

          </OutsideClickTracker>
        }
      </div>
      {error && !active && <div className={styles.error}>{error}</div>}
    </>
  );
};


export default observer(CoastPerActionBlock);
