import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import CreateFocusReport from 'store/mobx/CreateFocusReport';
import commonStyles from 'styles/common.pcss';
import { PrimaryButton } from 'redesignSrc/UI/buttons';

import ErrorBase from '../ErrorBase';
import DescriptionWrapper from '../../DescriptionWrapper';

const cx = classNames.bind(commonStyles);

function ErrorCreateOrder({ onClose }) {
  const {
    error,
  } = CreateFocusReport;

  return (
    <ErrorBase title={error.title} level={3}>
      <DescriptionWrapper level={5}>
        {error.description || 'Что-то пошло не так, можно попробовать создать отчёт ещё раз'}
      </DescriptionWrapper>

      <PrimaryButton
        className={cx('marb-l')}
        onClick={onClose}
      >
        OK
      </PrimaryButton>
    </ErrorBase>
  );
}

ErrorCreateOrder.propTypes = {
  onClose: PropTypes.func,
};

export default observer(ErrorCreateOrder);
