import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Wheel } from 'react-custom-roulette';
import { PopupStateless } from 'redesignSrc/UI/modals/Popup';
import { PureButton } from 'redesignSrc/UI/buttons';
import { wordFormByCount } from 'utils/strings';
import SantaButton from '../../assets/santaButton2.svg';
import { axiosBaseRequestAdTech } from '../../../../requests/helpers';
import PriseArrow from '../../assets/Polygon.svg';
import Store, { TypeItemPrise } from '../../store';
import { toastErrorNotification } from '../../../../modules/toast-notifications';
import styles from './styles.pcss';


type Props = {
  giveAnswer: (b?: TypeItemPrise) => void,
}

const ModalWheelOfFortune: React.FC<Props> = ({ giveAnswer }) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [priseNumber, setPriseNumber] = useState(0);
  const [itemPrise, setItemPrise] = useState<TypeItemPrise | null>(null);
  const [isPrise, setIsPrise] = useState(false);
  const priseForms = ['подарок', 'подарка', 'подарков'];
  const handlerSpin = async () => {
    try {
      const req = await axiosBaseRequestAdTech({
        url: `/api/promo/${Store.promoCampaignId}/fortunewheel/spin`,
        method: 'POST',
      });
      setItemPrise(req);
      const PriseId = req.itemId;
      const PriseNumber = Store.items.findIndex(el => el.id === PriseId);
      setPriseNumber(PriseNumber);
      Store.attempts = await axiosBaseRequestAdTech({ url: `/api/promo/${Store.promoCampaignId}/fortunewheel/attempts` });
    } catch (e) {
      toastErrorNotification('Что-то пошло не так');
      console.error(e);
    } finally {
      setMustSpin(true);
    }
  };

  useEffect(() => {
    if (!mustSpin && itemPrise) {
      setIsPrise(true);
      setTimeout(() => {
        giveAnswer(itemPrise);
      }, 1500);
    }
  }, [mustSpin]);


  return (
    <PopupStateless
      opened
      onClose={() => giveAnswer()}
      hideCloseButton={mustSpin}
    >
      <div className={styles.container}>
        <h4 className={styles.title}>Крутите колесо и получайте подарки!</h4>
        <span
          className={styles.description}>{`Можно забрать еще ${Store.attempts} ${wordFormByCount(Store.attempts, priseForms)}!`}</span>
      </div>

      <div className={styles.wheelContainer}>
        <Wheel
          mustStartSpinning={mustSpin}
          spinDuration={0.9}
          prizeNumber={priseNumber}
          data={Store.getItemNormalise}
          backgroundColors={['#FED305', '#383A51']}
          textColors={['#25282A', '#ffffff']}
          outerBorderWidth={0}
          innerBorderWidth={0}
          radiusLineColor="tranparent"
          radiusLineWidth={1}
          textDistance={65}
          fontSize={11}
          onStopSpinning={() => {
            setMustSpin(false);
          }}
        />
        <PriseArrow className={styles.priseArrow}/>
        <div className={styles.temp}/>

        <PureButton
          className={styles.button}
          onClick={handlerSpin}
          disabled={mustSpin}
        >
          <SantaButton/>
          <span className={styles.textButton}>
            {isPrise ? 'Ура' : mustSpin ? 'Жди!' : 'Жми!'}
          </span>
        </PureButton>
      </div>

    </PopupStateless>
  );
};

export default observer(ModalWheelOfFortune);
