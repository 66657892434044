import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import { ContentContainer } from '../../../../UI/layout';
import { GroupTitle } from '../../../../UI/typography';
import messageTemplatesStore from '../../../../../store/mobx/MessageTemplates';
import styles from './styles.pcss';
import uploadFilesLocalStore from './uploadFilesLocalStore';
import { UploadingFilesForModeration } from './components/UploadingFilesForModeration/UploadingFilesForModeration';

const cx = classNames.bind(styles);

export const DocumentsForModeration: FC = observer(() => {
  return (
    <ContentContainer className={styles.whiteBlock}>
      <GroupTitle className={styles.title}>Подтверждающие документы</GroupTitle>
      <div className={cx({ disabledFile: false })}>
        <UploadingFilesForModeration
          store={uploadFilesLocalStore}
          campaignId={0}
          isTemplateDownloadUrl
          fileUploadInfoTooltip="Формат: PDF, .JPG, .PNG, .JPEG, .RAR, .ZIP до 20 Мб."
          fileSize={{
            size: 20000000,
            shortSize: 20,
          }}
          text="Для некоторых отраслей, например, “Медицинские учреждения” потребуется загрузить лицензию или другие документы"
        />
      </div>
    </ContentContainer>
  );
});
