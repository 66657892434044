import React, { useState } from 'react';
import classNames from 'classnames/bind';
import commonStyles from 'styles/common.pcss';
import { Heading } from 'components/layouts';
import { PopupStateless } from 'components/common';
import { PrimaryButton } from 'redesignSrc/UI/buttons';

import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const imageSrc = 'https://static.beeline.ru/upload/images/marketing/sms-foreign-popup-01.svg';
const title = 'Отправляйте SMS-рассылки на других операторов';
const description = 'Теперь вы можете отправлять SMS-рассылки на других операторов связи в одной кампании';

const InfoExternalOperator = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <PopupStateless opened={isOpen} onClose={onClose} className={cx('wrapper')}>
      <div className={cx('content')}>
        <img src={imageSrc} alt="emoji" title={title} className={cx('image')} />

        <h2 className={cx('title')}>{title}</h2>

        <Heading level={4} className={cx('description')}>
          {description}
        </Heading>

        <PrimaryButton
          onClick={onClose}
          className={cx('button', 'closeButton')}
        >
          Отлично!
        </PrimaryButton>
      </div>
    </PopupStateless>
  );
};

export default InfoExternalOperator;
