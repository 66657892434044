import React from 'react';
import classNames from 'classnames/bind';

import { ProfileIcon } from './assets';

import styles from './styles.pcss';

type Props = {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  iconType: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const getCurrentIcon = (iconType: string) => {
  switch (iconType) {
    case 'profile': return <ProfileIcon />;
    default: return null;
  }
};

const IconButton: React.FC<Props> = ({ children, type = 'button', iconType, className, onClick }) => {
  return (
    <button
      type={type}
      className={classNames(styles.iconButton, className)}
      onClick={onClick}
    >
      {getCurrentIcon(iconType)}

      {children}
    </button>
  );
};

export default IconButton;
