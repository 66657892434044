import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import { PopupStateless } from 'redesignSrc/UI/modals/Popup';
import { PrimaryButton, PureButton } from 'redesignSrc/UI/buttons';
import UserInfo from 'store/mobx/UserInfo';
import LinkOpenInfo from 'redesignSrc/UI/elements/LinkOpenInfo';
import Arrow from '../../assets/Arrow.svg';
import Store, { TypeItemPrise } from '../../store';
import styles from './styles.pcss';

type Props = {
  giveAnswer: (b?: boolean) => void,
  itemPrise: TypeItemPrise
}

const ModalPrise: React.FC<Props> = ({ giveAnswer, itemPrise }) => {
  const [activePopup, setActivePopup] = useState(false);

  const descriptionText = useMemo(() => {
    if (itemPrise.itemType === 2) {
      return (
        <p className={styles.description}>Применить скидку можно при оплате рекламной кампании или создании отчета по
          анализу аудитории</p>
      );
    }
    if (itemPrise.itemType === 1) {
      if (itemPrise.itemName.toLowerCase().indexOf('скидка') === -1) {
        return (
          <p className={styles.description}>{
            `Отправили вам промокод на скидку по SMS на номер ${UserInfo.data?.phone}. Подробнее об анализе аудитории можете узнать `
          }
            <LinkOpenInfo onClick={() => {
              navigate('/audience-statistic');
              giveAnswer();
            }
            }>здесь</LinkOpenInfo>
          </p>
        );
      }
      return (
        <p className={styles.description}>{
          `Отправили вам промокод на скидку по SMS на номер ${UserInfo.data?.phone}. Применить скидку можно при оплате рекламной кампании или создании отчета по анализу аудитории`
        }</p>
      );
    }
    return 'Описание';
  }, [UserInfo.data?.phone, itemPrise]);


  useEffect(() => {
    if (activePopup) {
      setTimeout(() => {
        setActivePopup(false);
      }, 1000);
    }
  }, [activePopup]);

  const handlerCopy = async () => {
    try {
      await navigator.clipboard.writeText(itemPrise.value);
      setActivePopup(true);
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <PopupStateless opened onClose={() => giveAnswer()}>
      <div className={styles.container}>
        <h4 className={styles.title}>
          {`Ура! Вы выиграли ${Store.maxAttempts - Store.attempts} из ${Store.maxAttempts} призов.`}<br/>
          {itemPrise.itemName}
        </h4>
        {descriptionText}

        {itemPrise.itemType === 1 &&
          <div onClick={handlerCopy} className={styles.wrapInput}>
            <Arrow className={styles.arrow1}/>
            <input
              className={styles.input}
              disabled
              type="text"
              value={itemPrise.value}
            />
            <Arrow className={styles.arrow2}/>
            {activePopup && <div className={styles.copyPopup}>Скопировано!</div>}
          </div>
        }


        <PrimaryButton
          className={styles.button}
          isDisabled={Store.attempts === 0}
          onClick={() => giveAnswer(true)}
        >
          Крутить еще раз!
        </PrimaryButton>
      </div>
    </PopupStateless>
  );
};

export default observer(ModalPrise);
