import React, { FC } from 'react';

import { WhiteBtn } from 'redesignSrc/UI/beeline/btns/WhiteBtn/WhiteBtn';

import styles from '../styles.pcss';

interface SaveDraftBtnProps {
  disabled?: boolean;
  className?: string;
  text?: string;
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset'
}

export const SaveDraftBtn: FC<SaveDraftBtnProps> = ({ children, text = 'Сохранить черновик', disabled = false, ...props }) => {
  return (
    <WhiteBtn {...props} size="medium" variant="outlined" className={styles.draftBtn}>{text || children}</WhiteBtn>
  );
};
