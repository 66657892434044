import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type Props = {
  name?:string
  id?:number
  status?:string
}

export const NameInfo:React.FC<Props> = ({ status }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.descriptionContent}>
        <span className={cx(styles.status, { rejectedStatus: status === 'Не согласован' })}>{status}</span>
      </div>
    </div>
  );
};
