import React from 'react';
import classNames from 'classnames/bind';
import { GreyBlock } from 'redesignSrc/UI/typography';
import InfoDescription from '../../../components/InfoDescription';
import EditIcon from './editIcon.svg';
import styles from './styles.pcss';

type Props = {
  id?:string|number,
  title?:string,
  description?:string,
  onEdit?:(id?:string|number)=>void,
  className?:string,
  type?: string,
  rangeDescription?: [number, number],
  label?: string,
  isEditable?: boolean,
  isCorrect?:boolean,
}

const ConfirmationWidget:React.FC<Props> = ({
  id,
  title,
  description,
  onEdit,
  className,
  rangeDescription,
  label,
  isEditable = true,
  isCorrect,
}) => {
  if (!description && !rangeDescription?.length && !label) return null;
  let displayedInfo = '';

  if (!rangeDescription?.[0] && rangeDescription?.[1]) {
    displayedInfo = `до ${rangeDescription?.[1]}`;
  }
  if (rangeDescription?.[0] && !rangeDescription?.[1]) {
    displayedInfo = `от ${rangeDescription?.[0]}`;
  }

  if (rangeDescription?.[1] && rangeDescription?.[0]) {
    displayedInfo = `${rangeDescription?.[0]} - ${rangeDescription?.[1]}`;
  }

  return (
    <GreyBlock className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        {
          isEditable &&
          <EditIcon
            onClick={() => {
              onEdit && onEdit(id);
            }}
            className={styles.icon}/>
        }
      </div>

      {typeof description === 'string' &&
        <div className={styles.description}>{description}</div> || null
      }
      {
        // @ts-ignore
        typeof description === 'function' && <div className={styles.description}>{description()}</div> || null
      }
      {rangeDescription ? <div className={styles.description}>{displayedInfo}</div> : null}
      {label ? <div className={styles.description}>{label}</div> : null}
      {isCorrect ? <InfoDescription className={styles.info} description="Бюджет был скорректирован"/> : null}
    </GreyBlock>
  );
};

export default ConfirmationWidget;
