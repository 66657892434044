import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import LoaderFile from '../../loaders/LoaderFile';
import styles from './styles.pcss';

type Props = {
  onFileChanged: (e: CustomEvent & { dataTransfer?: DataTransfer }) => any,
  onClick?: () => any,
  maxSize?: number,
  error?: string | null,
  fileExtensions?:string,
  multiple?: boolean,
  name: string,
  isLoading?: boolean
}

const UploadFiles: React.FunctionComponent<Props> = ({
  onFileChanged,
  onClick,
  maxSize,
  error,
  fileExtensions,
  multiple,
  name,
  isLoading,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);

    onFileChanged(e);

    e.dataTransfer.clearData();

    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleFileChange = (e: any) => {
    onFileChanged(e);

    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  };


  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: any) => {
    setIsDragging(true);

    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div>
      <label
        htmlFor={name}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={classNames(styles.container, styles.label, isDragging && styles.active, error && styles.error)}
      >
        <LoaderFile isLoading={isLoading}>
          <input
            id={name}
            className={styles.input}
            type="file"
            onChange={handleFileChange}
            onClick={onClick}
            ref={inputRef}
            accept={fileExtensions && fileExtensions || '.txt,.csv,.xlsx'}
            multiple={multiple}
          />

          <>
            <span className={styles.insideText}>
              Перетащите файл сюда или выберите из папки
              <br />
              Формат:{' '}
              <span className={styles.coloredText}>
                {fileExtensions && fileExtensions.toUpperCase() || '.TXT, .CSV, .XLSX'}&nbsp;
                до {maxSize ? maxSize / 1000000 : 20} Мб.
              </span>
            </span>
          </>
        </LoaderFile>

      </label>
    </div>
  );
};

export default UploadFiles;
