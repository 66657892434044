import React, { useState } from 'react';
import classNames from 'classnames/bind';
import TextInput from '../TextInput';
import styles
  from '../../../pages/NewAudience/components/SettingAudience/components/TaxonWidgets/GeoWidget/components/InputCities/styles.pcss';


type Props = {
  onChange?: (e: string) => void;
  value?: string;
  id: string;
  className:string;
  isError?: boolean
};


const CoordinatesInput:React.FC<Props> = ({
  onChange,
  value,
  id,
  className,
  isError,
}) => {
  const handleChange = (e: string) => {
    const text = e.split(' ').join('');
    const coords = text.split(',');
    if (coords.length > 2) return;
    if (Number.isNaN(Number(coords[0]))) return;
    if (coords.length === 2) {
      if (Number.isNaN(Number(coords[1]))) return;
    }
    if (onChange) onChange(coords.toString());
  };
  return (
    <TextInput
      className={className}
      name={id}
      classNameInput={classNames(isError && styles.errorBorder)}
      value={value}
      onChange={handleChange}
      placeholder="Введите координату для поиска"
    />
  );
};

export default CoordinatesInput;
