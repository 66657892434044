import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import React, { FC, useEffect } from 'react';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import styles from '../styles.pcss';
import { PopupStateless } from '../../../../../UI/modals/Popup';
import { PrimaryButton } from '../../../../../UI/buttons';
import { senderNameStore } from '../../../../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';

export interface AgreementModalProps {
  showModal?: boolean;
}

export const AgreementModal: FC<AgreementModalProps> = observer(() => {
  const onCancel = async () => {
    senderNameStore.agreementModal = false;
    await navigate('/sender-names');
  };

  const agreementHandler = async () => {
    await senderNameStore.verificationSection();
    senderNameStore.agreementModal = false;
    await navigate('/sender-names');
  };

  return (
    <>
      <PopupStateless
        opened={senderNameStore.agreementModal}
        className={styles.modal}
        onClose={onCancel}>
        <div className={styles.modalTitle}>Имя отправителя отправлено на согласование</div>
        <p style={{ marginBottom: '23px', textAlign: 'center', fontSize: '16px' }}>
          Срок согласования 1 рабочий день. О результате согласования вам придет уведомление на почту
        </p>
        <div>

          <div>
            <PrimaryButton
              className={styles.sendModalBtnCancel}
              onClick={agreementHandler}
            >
              Хорошо
            </PrimaryButton>
          </div>
        </div>
      </PopupStateless>
    </>
  );
});

