import { observable, reaction, action, runInAction, computed } from 'mobx';
import moment from 'moment';
import { isNullOrUndefined } from 'utils/fn';

import { addDays } from 'utils/date';
import { TWELVE_HOURS } from '../NewCampaign/channels/constants';
import { axiosAuthorizedRequest } from '../../requests/helpers';
import { CHANNEL_STUB_SMS, CHANNEL_STUB_VOICE, CHANNEL_STUB_PUSH, CHANNEL_STUB_POLL_CUSTOM } from '../../constants';
import NewCampaign from './NewCampaign';

class LaunchDatesValidator {
  countMaxStartDate = () => {
    return this.maxStartDate;
  };

  countMinEndDate = (startDate) => {
    if (this.minDateEnd) {
      return this.minDateEnd;
    }
    if (startDate && !this.minDateEnd) {
      return startDate;
    }
    return this.countMinStartDate();
  };

  countMaxEndDate = () => {
    return this.maxDateEnd;
  }

  countMinStartDate = () => {
    const date = new Date();
    if (NewCampaign.orderDateStartOffset) {
      return addDays(date, NewCampaign.orderDateStartOffset);
    }
    return date.getHours() < TWELVE_HOURS ? date : addDays(date, 1);
  }

  @action getMaxDateEnd = async () => {
    if (
      NewCampaign.currentCampaign?.currentOrder?.date?.[0] &&
      (
        NewCampaign.currentCampaign.channel_uid === CHANNEL_STUB_PUSH ||
        NewCampaign.currentCampaign.channel_uid === CHANNEL_STUB_VOICE ||
        NewCampaign.currentCampaign.channel_uid === CHANNEL_STUB_SMS ||
        NewCampaign.currentCampaign.channel_uid === CHANNEL_STUB_POLL_CUSTOM
      )
    ) {
      const { max_date_end, min_date_end } = await axiosAuthorizedRequest({
        url: `/api/v1/client/campaigns/${NewCampaign.currentCampaign.id}/channel_max_date_end/`,
        method: 'POST',
        data: {
          date_start: NewCampaign.currentCampaign.currentOrder.date[0],
        },
      });
      runInAction(() => {
        this.maxDateEnd = moment(max_date_end);
        this.minDateEnd = moment(min_date_end);
        if ((
          min_date_end &&
            NewCampaign.currentCampaign.currentOrder.date[1] &&
            moment(NewCampaign.currentCampaign.currentOrder.date[1]).isBefore(moment(min_date_end))
        ) || !this.minDateEnd
        ) {
          NewCampaign.currentCampaign.currentOrder.date[1] = min_date_end;
        }
      });
    }
  };

  isInputsReadOnly = true;
  isEndDisabled = false;
  isSecondDateDisabled = true;
  @observable.ref minStartDate = moment(new Date()).add(NewCampaign.orderDateStartOffset, 'day');
  @observable.ref minDateEnd = null;
  @observable.ref maxStartDate = NewCampaign.maxDateStart;
  @observable maxDateEnd = moment(new Date()).add(NewCampaign.orderDateStartOffset, 'day');
  @observable focusedInput = null;
}

const launchDatesValidator = new LaunchDatesValidator();

reaction(
  () => NewCampaign.currentCampaign?.currentOrder?.date?.[0],
  async () => {
    if (NewCampaign.maxDateStart || !isNullOrUndefined(NewCampaign.orderDateStartOffset)) {
      await launchDatesValidator.getMaxDateEnd();
    }
  },
);

export default launchDatesValidator;
