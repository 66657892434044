import React, { useEffect, useRef } from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
  onClickOutside: (e: MouseEvent) => void;
  onClick?:()=>void
};

const OutsideClickTracker: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  onClickOutside,
  className,
  onClick = () => {},
  ...otherProps
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handlerClickOutside = (e: MouseEvent) => {
      if (
        document.getElementById('root')?.contains(e.target as Node) &&
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        onClickOutside(e);
      }
    };
    document.body.addEventListener('mousedown', handlerClickOutside);
    return () =>
      document.body.removeEventListener('mousedown', handlerClickOutside);
  }, []);
  return (
    <div onClick={onClick} ref={containerRef} className={className} {...otherProps}>
      {children}
    </div>
  );
};

export default OutsideClickTracker;
