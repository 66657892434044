import React from 'react';
import classNames from 'classnames/bind';
import Preloader from '../Preloader';
import styles from './styles.pcss';

type Props = {
  children?: React.ReactNode,
  isLoading?: boolean,
  isLoadingNotBlur?: boolean,
  className?: string,
  size?: number,
  isLoadingNotBlurWithLoader?:boolean
  classNameLoader?: string
}

const BlurLoader:React.FC<Props> = (
  {
    children,
    isLoading,
    isLoadingNotBlurWithLoader,
    isLoadingNotBlur,
    className,
    size = 48,
    classNameLoader,
  }) => (
    <div className={styles.wrapper}>
      <div className={classNames(styles.component, isLoading && styles.active, (isLoadingNotBlur || isLoadingNotBlurWithLoader) && styles.activeNotBlur, className)}>
        {children}
      </div>

      {(isLoading || isLoadingNotBlurWithLoader) && <Preloader size={`${size}`} className={classNames(styles.preloader, classNameLoader)} />}
    </div>
);

export default BlurLoader;
