import { observer } from 'mobx-react';
import React from 'react';
import styles from './styles.pcss';

type Errors = {
  errors: {[key: string]: string|undefined}|null,
  fieldName: string | string[]
  onError?:(e:boolean)=>void
}

const ErrorDescription = observer(({ errors, fieldName, onError = () => {} }:Errors) => {
  if (typeof fieldName === 'string') {
    if (!errors?.[fieldName]) {
      onError(false);
      return null;
    }
    onError(true);
    return <p className={styles.errorDescription}>{errors![fieldName]}</p>;
  }
  for (let i = 0; i < fieldName.length; i++) {
    if (errors?.[fieldName[i]]) {
      onError(true);
      return <p className={styles.errorDescription}>{errors![fieldName[i]]}</p>;
    }
  }
  onError(false);
  return null;
});

export default ErrorDescription;
