export const OFFER_CHECKED_FIELD = 'isChecked';

export const CHANNEL_DIGITAL_BILLBOARDS_ON_CITY_STREETS = 'DigitalBillboard';
export const CHANNEL_CONTEXT_ADS = 'contextads';
export const CHANNEL_STUB_INTERNET = 'internet';
export const CHANNEL_STUB_VOICE = 'voice-target';
export const CHANNEL_STUB_TARGET_INTERNET = 'target-internet';
export const CHANNEL_STUB_SMS = 'target-sms';
export const CHANNEL_STUB_PUSH = 'push';
export const CHANNEL_STUB_POLL_CUSTOM = 'custom-poll';
export const CHANNEL_STUB_FOCUS = 'focus';
export const CHANNEL_STUB_POLL = 'poll';


export const CAMPAIGN_START = 'campaignStart';

export const CAMPAIGN_STATUSES = {
  DRAFT: 5,
  COMPLETED: 7,
  ON_APPROVAL: 1,
};

export const ORDER_STATUSES = {
  COMPLETED: 5,
  CANCELED: 3,
  CREATED: 0,
  IN_PROGRESS: 7,
};

export const DISTANCE_TO_NEAREST_ERROR_FIELD = 40;

export const GEO_TYPES = {
  REGIONS: 'regions',
  POINTS: 'points',
};

export const TAXONS_TYPES = {
  GENDER: 'gender_ind',
  RANGE: 'rangeTaxon',
  SUBGROUPED: 'subgroupedTaxon',
  SELECTION: 'selectionTaxon',
  FILE_NAME: 'fileName',
  CATEGORY: 'category_taxon',
};

export const geoActionOptions = [
  { value: GEO_TYPES.REGIONS, label: 'Города и регионы' },
  { value: GEO_TYPES.POINTS, label: 'Указать точный адрес' },
];

export const TAXON_KEYS = {
  DEFAULT: 'default',
  JOB_GEO: 'job_geo',
  HOME_GEO: 'home_geo',
  WEEKEND_GEO: 'weekend_geo',
  ANY_LOCATION: 'any_location',
  GEO_TYPE: 'geo_type',
  GEO_POINTS: 'geo_points',
};

export const PROMOCODE_VALUES_TYPES = {
  PERCENT: 0,
  UNIT: 1,
  COUNT: 2,
};

export const PROMOCODE_TYPES = {
  PARTICULAR: 1,
  TOTAL: 0,
};

export const COUNT_SMS_DISCOUNT = 100000;

export const GEO_POINTS_TOOLTIP = 'Пример: 55.774303, 37.608580 (широта, долгота), формат файла: CSV/Excel, txt, не более 50 мб. ' +
  'Допустимые значения: широта от Х.ХХХХХХ до ХХ.ХХХХХХ, долгота от Х.ХХХХХХ до ХХХ.ХХХХХХ. ' +
  'Рекомендуем использовать координаты от сервиса Яндекс.Карты.';

export const ADDRESSES_TOOLTIP = 'выберите из папки Формат: .TXT, EXCEL, .CSV до 4Мб.';

export const SITES_OF_INTEREST_TOOLTIP = 'Формат файлов: CSV, txt, excel, вес файлов — не более 50 мб Используйте пример для загрузки' +
  ' Сайты можно загрузить в любом формате от 5 штук';

export const CALLS_TO_SUBSCRIBERS_TOOLTIP = 'Номера указываются без префикса для российских и иностранных номеров (т.е. без 8, +7, 7, +38 и тп.)' +
  ' и только цифры Формат файлов: CSV, txt, excel, вес файла - не более 4 мб Используйте пример для загрузки Можно загрузить от 5 номеров';

export const MY_CLIENTS_BASE_TOOLTIP = 'Загрузите файлы в формате CSV, txt, excel, вес не более 50 мб. Файл должен содержать не менее 100 номеров билайн.' +
  'Используйте пример для правильной загрузки файла.';

export const MY_CLIENTS_BASE_TOOLTIP_EXTENDED = 'Загрузите файлы в формате CSV, txt, excel, вес не более 50 мб. Файл должен содержать не менее 100 номеров билайн.' +
  'Используйте пример для правильной загрузки файла. SMS/Push-рассылка будет осуществляться только по тем пользователям из вашей базы, которые дали согласие билайн на получение рекламы и только в том случае, если пользователи не получали другие рекламные сообщения от билайн за предыдущие 7 дней';

export const AUDIENCE_ANALYSIS_TOOLTIP = 'Загрузите файлы в формате CSV, txt, excel, вес не более 50 мб. Файл должен содержать не менее 100 номеров билайн.' +
  ' Используйте пример для правильной загрузки файла';

export const DATA_PROCESSING_TOOLTIP = `Идет обработка данных. После этого, мы автоматически переведем вас на следующий шаг заполнения заявки

Пожалуйста, не обновляйте страницу до завершения обработки`;
export const DATA_PROCESSING_FAILURE_TOOLTIP = 'Не удалось обработать данные. Попробуйте еще раз';

export const FILTER_TOOLTIP = 'Фильтровать – уменьшение объема аудитории за счет пересечения с другими параметрами. Например, из всех женщин отфильтровать только тех, у кого есть дети';
export const EXPAND_TOOLTIP = 'Расширить – увеличение объема аудитории за счет добавления других параметров. Например, вам нужны и посетители сайтов, и получатели звонков';
export const EXCLUDE_TOOLTIP = 'Исключить – исключение аудитории из общего объема с помощью дополнительного параметра. Например, вы хотите исключить из аудитории вашу клиентскую базу';
