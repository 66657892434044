import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import danglingPrepositions from 'utils/danglingPrepositions';
import styles from './styles.pcss';
import IconGrey from './assets/IconInfoGrey.svg';
import IconYellow from './assets/IconInfoYellow.svg';
import SmallYellow from './assets/SmallYellow.svg';
import IconInfoWhite from './assets/IconInfoWhite.svg';
import IconInfoRed from './assets/iconInfoRed.svg';

class Store {
  @observable timerId: number | null = null
  @observable isPopup = false
}

const store = new Store();

type Props = {
  customIconBottomClass?: string;
  customIconClass?: string,
  CustomIcon?: any,
  positionPopup?: 'top' | 'bottom',
  children?: React.ReactNode | string,
  className?: string,
  icon?: 'grey' | 'yellow' | 'smallYellow' | 'white' | 'red',
  classNameIcon?: string,
  containerClassname?: string,
  delay?: number,
  isNotClose?: boolean
}

const TooltipInfo: React.FC<Props> = ({
  customIconBottomClass,
  customIconClass,
  CustomIcon,
  className,
  children,
  positionPopup = 'bottom',
  icon,
  classNameIcon,
  containerClassname,
  delay = 200,
  isNotClose,
}) => {
  const [localIsVision, setLocalIsVision] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const closePopup = () => {
    store.timerId = window.setTimeout(() => {
      setLocalIsVision(false);
    }, delay);
  };

  useEffect(() => {
    const currentPosition = ref?.current?.getBoundingClientRect().right;
    if (currentPosition && (window.innerWidth - currentPosition < 0)) {
      setIsRight(true);
    }
  }, [localIsVision]);

  const openPopup = () => {
    if (store.timerId && isNotClose) {
      clearTimeout(store.timerId);
    }
    setLocalIsVision(true);
  };
  const Icon = useMemo(() => {
    if (icon === 'grey') return IconGrey;
    if (icon === 'yellow') return IconYellow;
    if (icon === 'smallYellow') return SmallYellow;
    if (icon === 'white') return IconInfoWhite;
    if (icon === 'red') return IconInfoRed;
    return IconGrey;
  }, [icon]);


  const isTextChildren = (node: React.ReactNode): boolean => {
    return typeof node === 'string' || false;
  };

  const changeNode = (node: React.ReactNode) => {
    if (isTextChildren(node)) {
      return danglingPrepositions(node as string);
    }
    return node;
  };

  return (
    <div className={classNames(className, styles.container)}>
      {
        !CustomIcon ? (
          <Icon
            className={classNames(styles.icon, classNameIcon)}
            onMouseEnter={openPopup}
            onMouseLeave={closePopup}
          />
        ) : <CustomIcon
          className={classNames(styles.icon, classNameIcon, customIconClass)}
          onMouseEnter={openPopup}
          onMouseLeave={closePopup}
        />
      }

      {localIsVision &&
        <div
          // @ts-ignore
          ref={ref}
          className={classNames(styles.containerText,
            positionPopup === 'bottom' && customIconBottomClass || styles.bottom,
            positionPopup === 'top' && styles.top,
            isRight && styles.right,
            containerClassname,
          )}>
          <span
            className={styles.text}
            onMouseEnter={openPopup}
            onMouseLeave={closePopup}
          >
            {changeNode(children)}
          </span>
        </div>}
    </div>
  );
};

export default observer(TooltipInfo);
