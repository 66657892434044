import { action, observable } from 'mobx';
// @ts-ignore
import mimetype from 'mimetype';
import { toastErrorNotification } from '../../../../../modules/toast-notifications';
import { axiosBaseRequestAdTech, prepareMultipartFormData } from '../../../../../requests/helpers';
import messageTemplatesStore from '../../../../../store/mobx/MessageTemplates';

type FileItem = {
  id: number,
  name: string,
}

type FileListTmp = {
  customerFiles: FileItem[],
  moderatorFiles: FileItem[]
}

class UploadFilesLocalStore {
  @observable isChooseTemplateOpened = false;

  @observable isOperatorsSwitched:number|null = null;
  @observable errors: boolean = false;

  @observable isLoading = false
  @observable files: FileListTmp = {
    customerFiles: [],
    moderatorFiles: [],
  };
  @observable errorOnFileUploading: boolean | null | string = null;
  @observable filesProgress:{[key: string]: number}|null = null
  @action resetState = () => {
    this.files = {
      customerFiles: [],
      moderatorFiles: [],
    };
    this.errorOnFileUploading = null;
  }

  private isAllowMimeType = (file: File) => {
    const mapMimeType = ['image/jpeg', 'image/png', 'application/pdf', 'application/jpg', 'application/x-zip-compressed', 'application/x-rar-compressed', 'application/zip'];
    return mapMimeType.includes(mimetype.lookup(file.name));
  }

  @action uploadFile = async (file: File) => {
    if (!this.isAllowMimeType(file)) {
      toastErrorNotification('Неверный формат файла');
      return;
    }

    try {
      if (!file.size) {
        this.errorOnFileUploading = 'Нельзя загрузить пустой файл';
        return;
      }

      this.isLoading = true;

      await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${messageTemplatesStore.id}/file`,
        method: 'POST',
        data: prepareMultipartFormData({
          File: file,
        }),
      });
      this.errors = false;
      await this.getExistingFiles();
      this.errorOnFileUploading = null;
    } catch (e) {
      this.errorOnFileUploading = true;
    } finally {
      this.isLoading = false;
    }
  }

  @action getErrors() {
    return this.errorOnFileUploading;
  }

  @action getExistingFiles = async () => {
    if (messageTemplatesStore.id) {
      this.files = await axiosBaseRequestAdTech({
        url: `api/messageTemplates/${messageTemplatesStore.id}/files`,
      });
    }
  }

  @action handleFileRemove = async (id: number) => {
    try {
      await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${messageTemplatesStore.id}/file/${id}`,
        method: 'DELETE',
      });
      this.files.customerFiles = this.files?.customerFiles?.filter((file: any) => file.id !== id);
    } catch (e) {
      console.error(e);
    }
  };

  @action resetFilesList = () => {
    this.files = {
      customerFiles: [],
      moderatorFiles: [],
    };
  }
}

const uploadFilesLocalStore = new UploadFilesLocalStore();
export default uploadFilesLocalStore;
