import React, { useEffect, MouseEvent } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Link, navigate, Router, useLocation } from '@reach/router';
import { Button } from '@beeline/design-system-react';
import LogoRouterLink from 'redesignSrc/components/LogoRouterLink';
import PrimaryLink from 'redesignSrc/UI/buttons/PrimaryButtons/PrimaryLink';
import SidebarMenu from 'redesignSrc/components/SidebarMenu';
import FaqSidebar from 'pages/Faq/containers/FaqSidebar';
import Header from 'redesignSrc/components/Header';
import { FAQ_URL } from 'pages/constants';
import { PageLoader } from 'components/common/Loaders/components';
import Common from 'store/mobx/Common';
import UserInfo from 'store/mobx/UserInfo';
import { TSMSUserInfo } from 'store/mobx/TSMS/UserInfo';
import TroubleShootMessage from 'redesignSrc/components/TroubleShootMessage';
import { SenderNameSideSheet } from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName';
import { DesktopOnly, MobileOnly } from '../Responsive';
import { ym } from '../../../utils/ga-analytics/utils';


import ManagerInfo from '../ManagerInfo';
import { SmallText } from '../../UI/typography';
import Polls from '../Polls/ui';
import styles from './styles.pcss';

type Props = {
  children: React.ReactNode;
}

const year = new Date().getFullYear();

const PageLayout: React.FC<Props> = observer(({ children }) => {
  const location = useLocation();
  const isWidePageWithAudience = location.pathname.indexOf('/audience') !== -1 &&
    location.pathname.indexOf('/new-campaign') !== -1 &&
    location.pathname.indexOf('/channels') === -1;
  const isTSMS = location.pathname.includes('tsms');
  const isPartners = UserInfo.data?.appType === 1;

  const handlerOnClickGA = () => {
    const userId =
      (TSMSUserInfo.data?.customerInfo?.inn && `inn${TSMSUserInfo.data?.customerInfo?.inn}`) ||
      (TSMSUserInfo.data?.customerInfo?.contractNumber && `contract${TSMSUserInfo.data?.customerInfo?.contractNumber}`) ||
      'empty';
    ym(87718585, 'reachGoal', 'button_create_campaign', { create_campaign: { button_create_campaign: userId } });
  };

  useEffect(() => {
    if (Common.isMobileSidebarVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [Common.isMobileSidebarVisible]);

  const navClass = Common.isMobileSidebarVisible ? `${styles.visibleSidebar} ${styles.navHolder}` : styles.navHolder;

  const createCompanyHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handlerOnClickGA();
    await navigate('/new-campaign');
  };

  return (
    <SenderNameSideSheet>
      <Polls>
        <PageLoader isLoading={UserInfo.isLoadingPage}>
          <div className={`${styles.layoutContainer} ${Common.isFloatingPanelVisible ? styles.blured : ''}`}>
            <div className={navClass}>
              <nav className={styles.leftNavigation}>
                <LogoRouterLink/>
                {!isTSMS && !isPartners &&
                <>
                  <DesktopOnly>
                    <Button
                      className={styles.createCampaignLink}
                      onClick={createCompanyHandler}
                      variant="contained"
                      href="/new-campaign"
                      fullWidth
                      size="medium"
                  >
                      Создать кампанию
                    </Button>
                  </DesktopOnly>

                  <Router basepath="/" primary={false}>
                    <FaqSidebar path={`${FAQ_URL}*`}/>

                    <SidebarMenu path="/*"/>
                  </Router>
                  <MobileOnly>
                    <PrimaryLink
                      to="/new-campaign"
                      onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handlerOnClickGA();
                      Common.isMobileSidebarVisible = false;
                      await navigate('/new-campaign');
                      // await globalHistory.navigate('/new-campaign');
                      }}
                      className={styles.createCampaignLink}
                  >
                      Создать кампанию
                    </PrimaryLink>
                  </MobileOnly>
                </>
            }
                {!UserInfo.isAggregator &&
                <div className={styles.phoneLinkWrapper}>
                  <div className={styles.infoContainer}>
                    <div className={styles.divider} />
                    <div className={styles.phoneLinkContainer}>
                      <Link className={styles.phoneLink} to="/faq">Помощь</Link>
                      <span>Консультация со специалистом:</span>
                      <a className={`${styles.phoneLink} ${styles.beeline}`} href="tel:88006006262">8 800 600 62 62</a>
                      <SmallText>{`${year} ПАО «ВымпелКом»`} </SmallText>
                    </div>
                  </div>
                </div>
            }
                {UserInfo.isAggregator && <ManagerInfo/>}
              </nav>
            </div>
            <div
              className={classnames(styles.content, isWidePageWithAudience && styles.wide, UserInfo.isAggregator && styles.aggregContent)}>
              <Header/>
              <main className={styles.main}>
                <TroubleShootMessage/>
                {children}
              </main>
            </div>
          </div>
        </PageLoader>
      </Polls>
    </SenderNameSideSheet>
  );
});

export default PageLayout;
