import React, { ReactNode, useState } from 'react';

import classNames from 'classnames/bind';
import OutsideClickTracker from 'redesignSrc/UI/elements/OutsideClickTracker/OutsideClickTracker';
import { PureButton } from 'redesignSrc/UI/buttons';
import styles from './styles.pcss';

type Props = {
  children: ReactNode
  className?: string
  containerClassname?: string,
  content?: string | ReactNode
}

const TooltipWrapperClick: React.FC<Props> = ({
  children,
  className,
  containerClassname,
  content = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classNames(className, styles.container)}>
      <PureButton
        onClick={() => setIsOpen(true)}
      >
        {children}
      </PureButton>

      {isOpen &&
        <OutsideClickTracker
          onClickOutside={() => setIsOpen(false)}
          className={classNames(styles.containerText,
            containerClassname,
          )}
        >
          {content}
        </OutsideClickTracker>
      }
    </div>
  );
};

export default TooltipWrapperClick;
