import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import { toJS } from 'mobx';
import classNames from 'classnames/bind';
import { CardBottomPanelBtns } from 'redesignSrc/UI/elements/CardBottomPanelBtns/CardBottomPanelBtns';
import { SaveDraftBtn } from 'redesignSrc/UI/elements/CardBottomPanelBtns/components/SaveDraftBtn';
import { NextBtn } from 'redesignSrc/UI/elements/CardBottomPanelBtns/components/NextBtn';
import { WhiteBtn } from 'redesignSrc/UI/beeline/btns/WhiteBtn/WhiteBtn';
import { PrimaryButton, PureButton } from '../../../../UI/buttons';
import { scrollSmoothToNodeById } from '../../../../../utils/scroll';
import messageTemplatesStore from '../../../../../store/mobx/MessageTemplates';
import { senderNameStore } from '../../../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import styles from './styles.pcss';
import { BtnsBlockProps } from './BtnsBlockProps';
import { AgreementModal } from './components/AgreementModal';

const cx = classNames.bind(styles);

export const BtnsBlock: FC<BtnsBlockProps> = observer(({ valid, ban, customerId }) => {
  const onSaveToDraftHandler = async () => {
    const { firstErrorKey } = valid.getAllValid();
    if (firstErrorKey) {
      scrollSmoothToNodeById(firstErrorKey, 200);
    } else {
      if (!senderNameStore.ban) {
        await senderNameStore.createName(senderNameStore.nameSender, ban, customerId);
      }
      senderNameStore.isLoadingPage = true;
      await senderNameStore.updateSenderNameSection();
      senderNameStore.isLoadingPage = false;
      await navigate('/sender-names/');
    }
  };

  const onSendForApprovalHandler = async () => {
    const { firstErrorKey } = valid.getAllValid();
    if (firstErrorKey) {
      scrollSmoothToNodeById(firstErrorKey, 200);
    } else {
      if (!senderNameStore.ban) {
        await senderNameStore.createName(senderNameStore.nameSender, ban, customerId);
      }
      await senderNameStore.updateSenderNameSection();
      senderNameStore.agreementModal = true;
      senderNameStore.isUploadfileWhenStatusSenderAccepted = false;
    }
  };

  const isShowSendForApprovalBtn = () => {
    return senderNameStore.senderStatus === 'Draft' || senderNameStore.senderStatus === 'Rejected' || 'Accepted';
  };

  if (senderNameStore?.senderStatus === 'Verification') return null;

  return (
    <CardBottomPanelBtns>
      <>
        {
        senderNameStore.senderStatus === 'Draft' && (
          <SaveDraftBtn
            onClick={onSaveToDraftHandler}
          />
        )
      }
        {
        isShowSendForApprovalBtn() || !senderNameStore.senderStatus ? (
          <NextBtn
            backgroundWhite={!senderNameStore.isUploadfileWhenStatusSenderAccepted && senderNameStore.senderStatus !== 'Draft' && senderNameStore.senderStatus !== 'Rejected'}
            disabled={!senderNameStore.isUploadfileWhenStatusSenderAccepted && senderNameStore.senderStatus !== 'Draft' && senderNameStore.senderStatus !== 'Rejected'}
            onClick={onSendForApprovalHandler}
            text="Отправить на согласование"
          />
        ) : null
      }
        <AgreementModal />
      </>
    </CardBottomPanelBtns>
  );
});
