import React from 'react';
import { PopupStateless } from 'components/common';
import { PrimaryButton } from 'redesignSrc/UI/buttons';

import styles from './styles.pcss';

type Props = {
  giveAnswer:(b:boolean)=>void,
  title?: string,
  description?: string
}

const DeleteModal:React.FC<Props> = ({ giveAnswer, title, description }) => {
  return (
    <PopupStateless
      opened
      onClose={() => giveAnswer(false)}
      className={styles.popupModal}
    >
      <div className={styles.content}>
        <div className={styles.modalTitle}>{title || 'Удалить имя отправителя'}</div>

        <p style={{ marginBottom: '35px', textAlign: 'center' }}>
          {description || 'Вы действительно хотите удалить это имя отправителя?'}
        </p>

        <PrimaryButton
          isWhite
          onClick={() => giveAnswer(true)}
          className={styles.button}
        >
          Удалить
        </PrimaryButton>

        <PrimaryButton
          onClick={() => giveAnswer(false)}
          className={styles.saveButton}
        >
          Оставить
        </PrimaryButton>
      </div>
    </PopupStateless>
  );
};

export default DeleteModal;

