import React, { useEffect } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Link, navigate, Router } from '@reach/router';
import FaqSidebar from 'pages/Faq/containers/FaqSidebar';
import { FAQ_URL } from 'pages/constants';
import { PageLoader } from 'components/common/Loaders/components';
import Common from 'store/mobx/Common';
import UserInfo from 'store/mobx/UserInfo';
import { PureButton, PrimaryButton } from 'redesignSrc/UI/buttons';
import { SenderNameSideSheet } from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName';
import { SmallText } from '../../UI/typography';
import styles from './styles.pcss';
import LogoT from './assets/LogoTsms.svg';
import SidebarMenuTsms from './components/SidebarMenuTsms';

const year = new Date().getFullYear();

const PageLayoutTsms: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
  const isWidePageWithAudience = false;

  useEffect(() => {
    if (Common.isMobileSidebarVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [Common.isMobileSidebarVisible]);

  const logout = async () => {
    await navigate('/partners/account/logout');
    window.location.reload();
  };

  const backToTsms = (e: React.MouseEvent) => {
    if (process.env.BUILD_SOURCEBRANCHNAME !== 'master') {
      window.location.href = 'http://ms-opslkdev:98/sms/segments/';
    } else {
      window.location.href = 'http://tsms.beeline.ru/sms/segments';
    }
  };

  // const navClass = Common.isMobileSidebarVisible ? `${styles.visibleSidebar} ${styles.navHolder}` : styles.navHolder;

  return (
    <SenderNameSideSheet>
      <PageLoader isLoading={UserInfo.isLoadingPage}>
        <div className={`${styles.layoutContainer} ${Common.isFloatingPanelVisible ? styles.blured : ''}`}>
          <div /* className={navClass} */>
            <nav className={styles.leftNavigation}>

              <LogoT onClick={() => navigate('/')} className={styles.logoImg}/>

              <div className={styles.wrapButtons}>
                <PrimaryButton
                  isWhite
                  onClick={async (e) => {
                    backToTsms(e);
                  }}
                  className={styles.button}
                >
                  Вернуться в ТСМС
                </PrimaryButton>
                <PrimaryButton
                  onClick={async () => {
                    await navigate('/new-campaign');
                  }}
                  className={styles.button}
                >
                  Создать кампанию
                </PrimaryButton>
              </div>

              <Router basepath="/" primary={false}>
                <FaqSidebar path={`${FAQ_URL}*`}/>

                <SidebarMenuTsms path="/*"/>
              </Router>


              <div className={styles.phoneLinkWrapper}>
                <div className={styles.phoneLinkContainer}>
                  <Link className={styles.phoneLink} to="/faq">Помощь</Link>
                </div>
                <SmallText>{`${year} ПАО «ВымпелКом»`} </SmallText>
              </div>

            </nav>
          </div>


          <div className={classnames(styles.content, isWidePageWithAudience && styles.wide)}>
            <div className={styles.wrapperHead}>
              <Link className={styles.userInfo} to="/user-info">{`Профиль: ${UserInfo.username.fullName}`}</Link>
              <PureButton
                className={styles.userInfo}
                onClick={logout}
              >
                Выход
              </PureButton>
            </div>


            <main className={styles.main}>{children}</main>
          </div>
        </div>
      </PageLoader>
    </SenderNameSideSheet>
  );
});

export default PageLayoutTsms;
