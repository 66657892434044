
type statuses = {
  [key: string]: string
}

export const TEMPLATE_MESSAGE_STATUS_MAP: statuses = {
  Draft: 'Черновик',
  Accepted: 'Согласован',
  Rejected: 'Не согласован',
  Verification: 'На согласовании',
};

