import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import classNames from 'classnames/bind';
import { valueToText } from 'redesignSrc/pagesTsms/components/FilterPanel/utils';
import UploadingScale from '../../../../components/UploadingScale';
import { senderNameStore } from '../../../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import { axiosBaseRequestAdTech } from '../../../../../requests/helpers';
import NewOrder from '../../../../../store/mobx/NewOrder';
import { PopupStateless } from '../../../../UI/modals/Popup';
import { PrimaryButton } from '../../../../UI/buttons';
import { ORDER_FILES_FIELD } from '../../../constants';
import styles from './styles.pcss';
import DeleteFile from './assets/DeleteFile.svg';
import DownloadFileIcon from './assets/download-icon.svg';
import FileIcon from './assets/File.svg';
import { UploadSenderNameFilesProps } from './UploadSenderNameFilesProps';
import { SenderNameFiles } from './components/SenderNameFiles/SenderNameFiles';

const cx = classNames.bind(styles);

export const UploadSenderNameFilesEdit: FC<UploadSenderNameFilesProps> = observer((props) => {
  const { store, fileUploadInfoTooltip, fileSize, validation, isEditMode } = props;
  const MAX_LOADED_FILES = 10;
  const [showSendDocs, setShowSendDocs] = useState<boolean>(false);
  const [selectedFileId, setSelectedFileId] = useState<null | number>(null);

  useEffect(() => {
    if (!senderNameStore.senderId) return;
    (async () => {
      await senderNameStore.getAllFilesBySenderIdSection();
    })();
  }, []);


  const handleDeleteFile = (fileId: number) => () => {
    setShowSendDocs(true);
    setSelectedFileId(fileId);
  };

  const handleDownloadFile = (path: string, file: any) => async (e: any) => {
    e.preventDefault();

    const fileView = await axiosBaseRequestAdTech({
      url: path,
      responseType: 'blob',
    });
    const docLink = document.createElement('a');
    docLink.href = window.URL.createObjectURL(fileView);
    docLink.download = file.name || file.filename;
    docLink.click();
  };

  const popup = () => {
    const onCancel = () => {
      setShowSendDocs(false);
      setSelectedFileId(null);
    };

    const onOk = () => {
      selectedFileId && senderNameStore.handleFileRemoveSection(selectedFileId, isEditMode);
      setShowSendDocs(false);
      setSelectedFileId(null);
    };

    return (
      <PopupStateless
        opened
        className={styles.sendModal}
        onClose={onCancel}>
        <div className={styles.sendModalTitle}>Удаление файла</div>
        <div>Вы уверены, что хотите удалить файл?</div>
        <PrimaryButton
          className={styles.sendModalBtn}
          onClick={onOk}
        >
          Да
        </PrimaryButton>
        <PrimaryButton
          className={styles.sendModalBtnCancel}
          onClick={onCancel}
        >
          Отмена
        </PrimaryButton>
      </PopupStateless>
    );
  };
  const isDisabledFields = () => store?.files?.length >= MAX_LOADED_FILES || store?.clientFiles.length >= MAX_LOADED_FILES || ((store?.files?.length + store?.clientFiles.length) >= MAX_LOADED_FILES);
  return (
    <div>
      {showSendDocs && popup()}
      <div className={cx({ disabledSmsText: isDisabledFields() })}>
        <SenderNameFiles
          store={store}
          isLoading={store.isLoading}
          onFilesAdded={senderNameStore.uploadFileSection}
          error={validation && validation.errors[ORDER_FILES_FIELD]}
          validate={validation}
          fileUploadInfoTooltip={fileUploadInfoTooltip}
          fileSize={fileSize}
        />
      </div>
      {
        store.filesProgress && Object.entries(store.filesProgress).map((item: any) => {
          return <UploadingScale progress={item[1]} name={item[0]} key={item[0]}/>;
        })
      }

      {
       store?.files && store?.files?.length && store?.files?.map((file: any) => {
          const name = file?.name || file?.filename;
          const path = `/api/senderNames/${senderNameStore.senderId}/files/${file?.id}`;
          return (
            <div className={styles.uploadedFile} key={file.id}>
              <div className={styles.icon}>
                <FileIcon />
              </div>
              <div className={styles.column}>
                <div className={styles.docName} title={name}>{valueToText(name, 50)}</div>
              </div>
              <div className={styles.closeHolder}>
                <div className={styles.row}>
                  {!(senderNameStore.senderStatus === 'Verification') && <DownloadFileIcon className={styles.icon} onClick={handleDownloadFile(path, file)} />}
                  {
                    senderNameStore.isShowUploadFiles() && (
                      <button className={styles.button} onClick={handleDeleteFile(file.id)}>
                        <DeleteFile/>
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
          );
        }) || null
      }
    </div>
  );
});
