import React, { useEffect } from 'react';
import { Sidesheet } from '@beeline/design-system-react';
import { observer } from 'mobx-react';
import { toastErrorNotification } from 'modules/toast-notifications';
import sideSheetStore from 'store/mobx/SideSheetStore';
import { TSMSUserInfo } from 'store/mobx/TSMS/UserInfo';
import { questionsState } from '../store';
import QuestionsParser from './QuestionsParser';
import FirstScreen from './FirstScreen/index';
import LastScreen from './LastScreen';


const Polls = observer(({ children }: { children: React.ReactNode}) => {
  useEffect(() => {
    (async () => {
      try {
        if (
          (TSMSUserInfo.data?.customerInfo?.isBeePro && TSMSUserInfo.data?.customerInfo?.type === 0) ||
          !TSMSUserInfo.data?.customerInfo
        ) {
          await questionsState.getActiveSurvey();
          if (questionsState.surveys?.surveyId) {
            await questionsState.getSurveyData(questionsState.surveys?.surveyId);
          }
        }
      } catch (e) {
        toastErrorNotification('Ошибка при загрузке опроса');
      } finally {
        questionsState.loading = false;
      }
    })();
  }, []);

  if (!sideSheetStore.componentToShow) {
    return (
      <>
        {
          (questionsState.currentScreen === 0 || !questionsState.isOpened) &&
        !questionsState.loading &&
        <FirstScreen />
        }
        {children}
      </>
    );
  }

  if (!questionsState?.surveys || (sideSheetStore.componentToShow && sideSheetStore.componentToShow !== 'polls')) return <>{children}</>;

  const isLastScreen = questionsState.currentScreen > (questionsState?.surveys?.questions?.length || 0);

  return (
    <Sidesheet
      hasOverlay
      hasDivider
      mode="modal"
      elevation="above"
      onClose={() => {
        sideSheetStore.componentToShow = '';
      }}
      isOpen={sideSheetStore.componentToShow === 'polls'}
      content={isLastScreen ? <LastScreen /> : <QuestionsParser />}
      title={isLastScreen ?
        'Результаты' :
        `Вопрос ${questionsState.currentScreen} из ${questionsState?.surveys?.questions.length}`
    }
    >
      {children}
    </Sidesheet>
  );
});

export default Polls;
