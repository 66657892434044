import {
  action,
  observable,
  runInAction,
  computed,
  reaction,
  set,
  autorun, toJS,
  entries,
} from 'mobx';
import moment from 'moment';
import { navigate } from '@reach/router';
import {
  axiosAuthorizedRequest,
  composeAxiosPostRequest,
} from 'requests/helpers';
import { runInActionLogger } from 'utils/runInActionLogger.js';
import {
  filterValuable,
  filterValuableFormFields,
  isNullOrUndefined,
} from 'utils/fn';
import { debounce } from 'utils/debounce';
import { dtoToViewDraftData, dtoToViewAnyLocation } from 'store/NewCampaign/campaign/utils';
import {
  CAMPAIGN_STATUSES,
  CHANNEL_STUB_INTERNET,
  CHANNEL_STUB_PUSH,
  CHANNEL_STUB_SMS,
  CHANNEL_STUB_TARGET_INTERNET, CHANNEL_STUB_VOICE,
  ORDER_STATUSES,
  PROMOCODE_VALUES_TYPES,
  COUNT_SMS_DISCOUNT,
  TAXON_KEYS, CHANNEL_STUB_POLL_CUSTOM,
} from 'constants/index';
import { ADCREATINGFORM } from 'pages/NewCampaign/constants';
import CommonStore from 'store/mobx/Common';
// import messagesSmsStore from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/store';
import { filterNewCampaignChannels } from 'store/utils';
import { calculateOrderEventsRequest } from 'requests/orders';
import { setSelectionRequest } from 'requests/campaigns';
import { extractError } from 'utils/errors';
import { scrollSmoothTo } from 'utils/scroll';
import { PUSH_TARGET_ACTIONS } from 'redesignSrc/pages/PushPages/components/constants';
import { NEW_CAMPAIGN_URL } from 'pages/constants';
import messagesChainLocalStore from 'redesignSrc/pages/PushPages/PushPageSetup/components/PushMessages/store';
import { calcPriceByDiscount } from 'utils/business';
import { formatAmount } from 'utils/formatting';
import {
  CAMPAIGN_API_URL,
  getPushSelectionApiUrl,
} from 'requests/campaigns/constants';

import { ORDER } from 'redesignSrc/pages/constants';

import StartCampaignOrEditModal, { TEMPLATES } from 'redesignSrc/UI/modals/StartCampaignOrEditModal';
import {
  ORDER_MESSAGE_FIELD,
  ORDER_START_DATE_FIELD,
  ORDER_FINISH_DATE_FIELD,
} from '../NewCampaign/channels/constants';

import confirmDialog from '../../components/modals/confirmDialog';
import { ChangeEmail, LockedService } from '../../components/modals/Financial';

import WebsAndPhonesTaxons from './WebsAndPhonesTaxons';
import {
  collectCitiesArray,
} from './utils/taxons';
import Social, { initialAdStepValues } from './Social';
import UserInfo from './UserInfo';
import Taxons from './Taxons';

export const defaultCampaign = {
  id: null,
  name: '',
  orders: null,
  is_editable: true,
  channel_uid: '',
  industry: null,
  my_audiences: null,
  campaign_type_audience: null,
  selection: {
    selection_id: null,
    data: null,
  },
  status_id: null,
  audience: null,
  currentOrder: {
    minimalBudget: null,
    external_operator: false,
    timeSending: [9, 21],
    event_count: null,
    client_approves: false,
    budget: null,
    uid: null,
    id: 0,
    date: false,
    legalInfo: '',
    text: {
      text: '',
      originText: '',
      links: [],
    },
    data: {
      is_using_external_operator: false,
      nameSender: '',
    },
  },
  promocode: null,
};

const defaultCalculate = {
  event_cost: 0,
  event_count: 0,
  qty: 0,
  external_operator_qty: 0,
  total_price: [],
  total_price_external_operator: [],
  insufficient_audience: false,
  insufficient_min_budget: false,
  max_budget: 0,
  name_sender_cost: 0,
  final_qty: 0,
  final_external_operator_qty: 0,
  qty_max: 950,
  qty_min: 50,
  error: false,
  budget: null,
};

const reduceListToShow = (listToReduce) => listToReduce.reduce((accum, item, index) => {
  if (index === listToReduce.length - 1) return accum + item;
  return `${accum} ${item}, `;
}, '');

class NewCampaign {
  @observable totalBudget = null
  @observable isBadBudget = false // когда буджет 0 или менее станет true
  @observable showAudienceSideMenu = true; // Для скрытия в боковом меню Выбор аудитории используется в компоненте NameStepFormBody & NewCampaignSidebar & ClientBase
  @observable isNotificationActive;
  @observable lastAudienceRequest = 0; // только для запросов selection или order
  @observable isLoading = false;
  @observable isPolls = '';
  @observable smsTemplateLoading = false;
  @observable isBudgetFromServer = false;
  @observable startingCampaign = false;
  @observable tools = [];
  @observable isSelectionLoading = false;
  @observable isFirstOnlineGeoRequest = true;

  @observable isCampaignInitializing = true;
  @observable selectedChannelSlug = '';
  @observable connectionTypes = [];
  @observable callMethods = [];
  @observable activityFields = [];
  @observable calculating = false;
  @observable shouldPayForName = false;
  @observable calculate = { ...defaultCalculate };
  @observable bigDataError = false;
  @observable nameSenderInfo = null;
  @observable isProhibitedCampaignSubmission = false;

  @observable isShowMinWebsitesError = false;
  @observable isShowMinPhoneNumbersError = false;
  @observable isShowZeroAudienceTaxonModal = false;
  @observable isShowMinConversionAudienceModal = false;
  @observable isShowMinAudienceTaxonModalMyTarget = false;
  @observable isShowMaxAudienceOfMyAudience = false;
  @observable isNotValidFormatFileOfMyAudience = false;
  @observable isNotValidSizeFileOfMyAudience = false;

  @observable audienceError = '';
  @observable promocodeError = '';
  @observable geoFieldRequiredError = '';
  @observable ignorePromocodeId = null;
  @observable endDateLoaded = null;
  @observable endDateLoading = false;
  @observable endDateLoadingError = false;
  @observable orderDateStartOffset = 0;
  @observable maxDateStart = null;
  @observable onlineDateEndEstimation = null;
  @observable ordersHolidays = {
    sms: {},
    push: {},
    voice: {},
    internet: {},
    social: {},
  };

  @observable basicSmsCost = null

  @observable currentCampaign = { ...defaultCampaign };
  @observable isCampaignStarted = false;

  @action set = (value, data) => {
    this[value] = data;
  };

  @computed get isCampaignInDraft() {
    return this.currentCampaign.status_id === CAMPAIGN_STATUSES.DRAFT && !this.isCampaignStarted;
  }

  @computed get isOldPush() { // Статус заказа "Модерация не пройдена" и есть поле data.text
    return this.currentCampaign.currentOrder?.data?.text?.length > 0 &&
      this.currentCampaign?.currentOrder?.status_id === 7 &&
      this.currentCampaign.currentOrder.channel_uid === 'push';
  }

  @computed get getMyAudience() {
    return (this.currentCampaign?.my_audiences || [])
      .map(({ audience_name, audience, audience_count, type_my_audience }) => {
        return {
          name: audience_name,
          id: audience,
          count: audience_count,
          type_my_audience,
        };
      });
  }

  @computed get getPriceWithPromocode() {
    const budget = () => {
      if (this.currentCampaign.campaign_type_audience !== 1 && this.currentCampaign.channel_uid !== 'target-internet') {
        const { qty, event_cost } = this.calculate;
        return qty * event_cost;
      }
      if (this.currentCampaign.channel_uid === CHANNEL_STUB_TARGET_INTERNET) {
        return Social.getTotalBudget;
      }

      if (this.currentCampaign.channel_uid === CHANNEL_STUB_INTERNET) {
        return this.currentCampaign.currentOrder.tariffs.find(tariff => tariff.id === this.currentCampaign.currentOrder.chosenTariff).price;
      }
      if (this.currentCampaign.channel_uid === CHANNEL_STUB_POLL_CUSTOM) {
        return this.calculate.budget;
      }

      return this.calculate.max_budget ||
          CommonStore.getChannelInfoByUid(this.currentCampaign.channel_uid)
            .minimal_budget;
    };

    const currentChannelPromocode = this.currentCampaign?.promocode?.channels?.find(
      (item) => item.channel_type === this.currentCampaign.channel_uid,
    );

    if (currentChannelPromocode?.promo_code_value) {
      if (this.currentCampaign.currentOrder.external_operator) {
        const floorRound = Math.floor(this.calculate.external_operator_event_cost * this.calculate.final_external_operator_qty);

        const calcPrice = calcPriceByDiscount(
          (this.calculate.max_budget - floorRound),
          currentChannelPromocode.value_type_id,
          currentChannelPromocode.promo_code_value,
        );

        return calcPrice + floorRound;
      }

      return calcPriceByDiscount(
        budget(),
        currentChannelPromocode.value_type_id,
        currentChannelPromocode.promo_code_value,
      );
    }
    return budget();
  }

  @computed get isCanRecalculateAudience() {
    if (
      // если статус кампании "черновик" или "на согласовании"
      (this.currentCampaign?.status_id === 5 || this.currentCampaign?.status_id === 1) &&
      // если статус заказа "создан"
      this.currentCampaign?.currentOrder?.status_id === 0
    ) {
      return true;
    }
    return false;
  }

  @computed get getPrettyPromocode() {
    const currentChannelPromocode = this.currentCampaign?.promocode?.channels?.find(item => item.channel_type === this.currentCampaign.channel_uid);
    let budget = null;
    if (this.currentCampaign.campaign_type_audience !== 1) {
      const { qty, event_cost } = this.calculate;
      budget = qty * event_cost;
    } else {
      budget = this.currentCampaign.channel_uid === CHANNEL_STUB_TARGET_INTERNET && Social.getTotalBudget || this.currentCampaign?.currentOrder?.budget;
    }
    if (
      currentChannelPromocode?.promo_code_value &&
      typeof currentChannelPromocode?.value_type_id === 'number' &&
      !isNaN(currentChannelPromocode?.value_type_id) &&
      typeof +budget === 'number' &&
      !isNaN(budget)
    ) {
      if (currentChannelPromocode.value_type_id === PROMOCODE_VALUES_TYPES.PERCENT) {
        return `${formatAmount(budget * currentChannelPromocode?.promo_code_value / 100)} ₽`;
      } else if (
        currentChannelPromocode.value_type_id === PROMOCODE_VALUES_TYPES.COUNT ||
        currentChannelPromocode.value_type_id === PROMOCODE_VALUES_TYPES.UNIT
      ) {
        return `${formatAmount(currentChannelPromocode?.promo_code_value)} ₽`;
      }
    }
    return null;
  }

  checkForAudiencePushAndroid = async (targetAction) => {
    if (this.isCanRecalculateAudience === false) return;
    if (WebsAndPhonesTaxons.phonesIsActive || WebsAndPhonesTaxons.webSitesIsActive) return;
    this.isSelectionLoading = true;
    try {
      const request = await axiosAuthorizedRequest({
        method: 'POST',
        url: getPushSelectionApiUrl({ campaignId: this.currentCampaign.id }),
        data: {
          is_android: targetAction === PUSH_TARGET_ACTIONS.APP_DOWNLOAD,
        },
      });
      runInActionLogger(() => {
        this.currentCampaign.audience = request.audience;
      });
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this.isSelectionLoading = false;
      });
    }
  };

  @action checkHasRequiredGeo = async () => {
    if (this.currentCampaign.selection.data?.geoAction === 'regions' && !this.currentCampaign.selection.data.any_location?.length) {
      this.geoFieldRequiredError = 'Укажите город или регион';
      await navigate(`${NEW_CAMPAIGN_URL}${this.currentCampaign.id}/audience`);
      scrollSmoothTo(0);
      return false;
    }
    if (this.currentCampaign.selection.data?.geoAction === 'points' && !this.currentCampaign.selection.data.geo_points?.length) {
      this.geoFieldRequiredError = 'Укажите хотя бы один адрес';
      await navigate(`${NEW_CAMPAIGN_URL}${this.currentCampaign.id}/audience`);
      scrollSmoothTo(0);
      return false;
    }
    return true;
  };

  @computed get getCampaignTargetInternetOrder() {
    return (
      this.currentCampaign.orders?.find(
        (order) => order.channel_uid === 'target-internet',
      ) || null
    );
  }

  @computed get getCampaignPushOrder() {
    return (
      this.currentCampaign.orders?.find(
        (order) => order.channel_uid === 'push',
      ) || null
    );
  }

  @computed get getCampaignTargetSmSOrder() {
    return this.currentCampaign.is_editable;
  }

  @computed get getTargetInternetErrors() {
    const moderationComment = this.getCampaignTargetInternetOrder
      ?.moderation_comment;
    if (moderationComment) {
      return [moderationComment];
    }
    return (
      this.getCampaignTargetInternetOrder?.data
        ?.my_target_banner_moderation_reasons || []
    );
  }

  @computed get getIsCampaignInTargetInternetModeration() {
    return (
      this.getTargetInternetErrors.length > 0 &&
      this.getCampaignTargetInternetOrder?.status_id ===
      ORDER_STATUSES.IN_PROGRESS &&
      this.currentCampaign.status_id === CAMPAIGN_STATUSES.ON_APPROVAL
    );
  }

  getConnectionTypes = async () => {
    if (this.connectionTypes.length) return;
    const result = await axiosAuthorizedRequest({
      url: '/api/v1/client/voice_industries/connection_types/',
    });
    runInAction(() => {
      this.connectionTypes = result;
    });
  };

  getCallMethods = async () => {
    if (this.callMethods.length) return;
    const result = await axiosAuthorizedRequest({
      url: '/api/v1/client/voice_industries/call_methods/',
    });
    runInAction(() => {
      this.callMethods = result;
    });
  };

  getActivityFields = async () => {
    if (this.activityFields.length) return;
    const result = await axiosAuthorizedRequest({
      url: '/api/v1/client/voice_industries/',
    });
    runInAction(() => {
      this.activityFields = result;
    });
  };

  getActivityFieldById = id => {
    let result = { id: undefined };
    if (!id) return result;
    this.activityFields.forEach(item => {
      const itemFound = item.children.find(child => child.id === id);
      if (itemFound) result = itemFound;
    });
    return result;
  }

  @computed get getActivityFieldsByType() {
    const firstType = [];
    this.activityFields.forEach(item => {
      const result = item.children.filter(child => child.type_tab === 1);
      if (result.length) {
        firstType.push({
          ...item,
          children: result,
        });
      }
    });
    const secondType = [];
    this.activityFields.forEach(item => {
      const result = item.children.filter(child => child.type_tab === 2);
      if (result.length) {
        secondType.push({
          ...item,
          children: result,
        });
      }
    });

    return {
      1: firstType,
      2: secondType,
    };
  }

  @action resetCampaign = () => {
    this.currentCampaign = { ...defaultCampaign };
    this.calculate = { ...defaultCalculate };
    this.isCampaignStarted = false;
    WebsAndPhonesTaxons.resetAllCustomSegments();
    messagesChainLocalStore.resetData();

    Taxons.resetTaxonsData();
  };

  @action setCurrentCampaign = (newCampaignData = {}) => {
    const getCallDirectionValue = ({ incoming, outgoing }) => {
      if (incoming && outgoing) return '';
      if (incoming) return 'incoming';
      if (outgoing) return 'outgoing';
      return '';
    };

    const preparedTaxons = {};
    Object.keys(Taxons.taxonsGroupData).forEach((groupName) => {
      preparedTaxons[groupName] = Taxons.taxonsGroupData[groupName].taxons;
    });

    /* Группировка таксонов по типу для отображения в интерфейсе */

    const preparedSelectionData = () => {
      const {
        [TAXON_KEYS.ANY_LOCATION]: anyLocation,
        [TAXON_KEYS.JOB_GEO]: geoJob,
        [TAXON_KEYS.HOME_GEO]: geoHome,
        [TAXON_KEYS.WEEKEND_GEO]: geoWeekEnd,
        ...otherData
      } = newCampaignData.selection?.data || {};
      return otherData;
    };

    const preparedGeoPoints = newCampaignData?.selection?.data?.[TAXON_KEYS.GEO_POINTS]
      || newCampaignData.selection?.data?.[TAXON_KEYS.HOME_GEO]
      || newCampaignData.selection?.data?.[TAXON_KEYS.WEEKEND_GEO]
      || newCampaignData.selection?.data?.[TAXON_KEYS.JOB_GEO] || [];
    const preparedAnyLocation = dtoToViewAnyLocation(newCampaignData?.selection?.data?.any_location || []);

    // all channels data (don't use it to store formValues, it just data of source campaign model)
    // Строчка на 493стр добавленна т.к is_editable дублируется и используется
    // currentCampaign.is_editable, currentCampaign.orders.is_editable, currentCampaign.currentOrder.is_editable,
    // ! Нужно фиксить
    const currentOrders = filterNewCampaignChannels(newCampaignData.orders || []);

    runInAction(() => {
      this.currentCampaign = {
        ...this.currentCampaign,
        id: newCampaignData.id,
        name: newCampaignData.name,
        status_id: newCampaignData.status_id,
        orders: currentOrders,
        adtechIndustryID: newCampaignData.adtechIndustryID,
        currentOrder: { ...this.currentCampaign.currentOrder, is_editable: currentOrders[0]?.is_editable },
        promocode:
          (newCampaignData?.promo_codes?.length && {
            ...newCampaignData.promo_codes[0],
            isValid: true,
          }) ||
          null,
        audience: newCampaignData?.selection?.audience,
      };

      Taxons.geoPoints = preparedGeoPoints;
      if (preparedAnyLocation.length) Taxons.anyLocation = preparedAnyLocation;

      if (!Taxons.geoPoints?.length && Taxons.anyLocation?.length) {
        Taxons.geoType = 'regions';
      } else if (Taxons.geoPoints?.length && !Taxons.anyLocation?.length) {
        Taxons.geoType = 'points';
      } else if (preparedAnyLocation?.length === 1 && preparedAnyLocation?.[0] === 'allRussia' &&
        preparedSelectionData()?.is_online_geo === 'true') {
        Taxons.geoType = 'points';
      } else Taxons.geoType = 'regions';

      Object.keys(preparedSelectionData()).forEach((key) => {
        if (key === 'range_values') {
          Taxons.selectedData.set('range_values', null);
          Taxons.selectedData.set('range_values', ...newCampaignData.selection?.data?.range_values);
        } else if (key === 'allRussia') {
          Taxons.anyLocation = ['allRussia', ...toJS(Taxons.anyLocation)];
        } else {
          Taxons.selectedData?.set(key, null);
          Taxons.selectedData?.set(key, preparedSelectionData()?.[key]);
        }
      });
    });

    const phonesNumbersSegment = newCampaignData.custom_segments?.phone;
    const webSitesSegment = newCampaignData.custom_segments?.site;

    runInAction(() => {
      WebsAndPhonesTaxons.set('webSitesId', webSitesSegment?.id);
      WebsAndPhonesTaxons.set('webSitesDepth', webSitesSegment?.event_depth);
      WebsAndPhonesTaxons.set(
        'webSitesFile',
        webSitesSegment?.custom_segment_files && webSitesSegment?.custom_segment_files.reverse()[0],
      );
      WebsAndPhonesTaxons.set(
        'sitesNotCountElements',
        webSitesSegment?.black_list?.length ? `Не были учтены сайты: ${reduceListToShow(webSitesSegment?.black_list || [])}.` : null,
      );
      WebsAndPhonesTaxons.set('webSitesArray', webSitesSegment?.data?.sites);
      WebsAndPhonesTaxons.set('webSitesBlacklist', webSitesSegment?.black_list);
      WebsAndPhonesTaxons.set('webSitesIsActive', webSitesSegment?.is_active);

      WebsAndPhonesTaxons.set('phonesId', phonesNumbersSegment?.id);
      WebsAndPhonesTaxons.set('phonesDepth', phonesNumbersSegment?.event_depth);
      WebsAndPhonesTaxons.set('phonesCallDirection', getCallDirectionValue(phonesNumbersSegment?.data || {}));
      WebsAndPhonesTaxons.set(
        'phonesFile',
        phonesNumbersSegment?.custom_segment_files && phonesNumbersSegment?.custom_segment_files.reverse()[0],
      );

      const newBlacklistPhones = phonesNumbersSegment?.black_list || phonesNumbersSegment?.missed_value;
      WebsAndPhonesTaxons.set(
        'phonesNotCountElements',
        newBlacklistPhones?.length ? `Не были учтены номера: ${reduceListToShow(newBlacklistPhones || [])}.` : null,
      );
      WebsAndPhonesTaxons.set('phonesArray', phonesNumbersSegment?.data?.phones);
      WebsAndPhonesTaxons.set('phonesBlacklist', newBlacklistPhones);
      WebsAndPhonesTaxons.set('phonesIsActive', phonesNumbersSegment?.is_active);
    });

    currentOrders.forEach((order) => {
      // add here initialization for another channels (to move from Redux to separate stores, like target-internet)
      // use different MobX stores to store brief formValues and other dynamic data, like in target-internet
      if (order.channel_uid === CHANNEL_STUB_TARGET_INTERNET) {
        const preparedValues = filterValuableFormFields(dtoToViewDraftData(order, CHANNEL_STUB_TARGET_INTERNET));

        Social.set('adStep', { ...initialAdStepValues, ...preparedValues, [ORDER.NAME_CAMPAIGN]: this.currentCampaign.name });
      }
    });
  };

  @action updateSelection = async ({ isFirstCallForDraft } = {}) => {
    if (!this.currentCampaign.orders || !this.currentCampaign.orders.length) { return; }
    const currentOrder = this.currentCampaign.orders.find((item) => {
      return item.channel_uid === this.currentCampaign.channel_uid;
    });
    this.onlineDateEndEstimation = null;
    this.isSelectionLoading = true;

    const dataToSendInSelection = {
      campaignId: this.currentCampaign.id,
      type: 'segmentation',
      data: filterValuable({
        ...Taxons.getCorrectGeo,
        ...Object.fromEntries(toJS(entries(Taxons.selectedData))),
      }),
      ...(Taxons.geoPoints && Taxons.geoPoints.length && Taxons.geoType === 'points'
        ? { locations: collectCitiesArray(Taxons.geoPoints) }
        : {}
      ),
    };

    try {
      this.lastAudienceRequest = Date.now();
      const selectionRequestData = await setSelectionRequest(dataToSendInSelection);

      runInAction(() => {
        runInActionLogger(() => {
          this.currentCampaign.selection.selection_id =
            selectionRequestData.selection_id;
          this.currentCampaign.selection.audience = selectionRequestData.audience;
          this.currentCampaign.currentOrder.minimalBudget =
            selectionRequestData.budgets[this.currentCampaign.channel_uid];
        });

        if (!WebsAndPhonesTaxons.isWebSitesOnline && (WebsAndPhonesTaxons.webSitesIsActive || WebsAndPhonesTaxons.phonesIsActive)) {
          if (WebsAndPhonesTaxons.webSitesIsActive) {
            if (isFirstCallForDraft) {
              if (isNullOrUndefined(currentOrder.data.msisdns_count) && !WebsAndPhonesTaxons.phonesIsActive) {
                WebsAndPhonesTaxons.callIntervalRequests = 'checking';
              } else if (currentOrder.data.msisdns_count || currentOrder.data.msisdns_count === 0) {
                runInActionLogger(() => { this.currentCampaign.audience = currentOrder.data.msisdns_count; });
              }
            } else {
              WebsAndPhonesTaxons.callIntervalRequests = 'updating';
            }
          }

          if (WebsAndPhonesTaxons.phonesIsActive) {
            if (!isNullOrUndefined(currentOrder.data.msisdns_count)) {
              runInActionLogger(() => {
                this.currentCampaign.audience = currentOrder.data.msisdns_count;

                if (!isFirstCallForDraft) {
                  WebsAndPhonesTaxons.isPhonesChanged = true;
                  WebsAndPhonesTaxons.callIntervalRequests = 'updating';
                }
              });
            } else {
              runInActionLogger(() => {
                WebsAndPhonesTaxons.isPhonesChanged = true;
                WebsAndPhonesTaxons.callIntervalRequests = 'updating';
              });
            }
          }
        } else {
          runInActionLogger(() => {
            if (this.currentCampaign.my_audiences.length === 0 && this.currentCampaign.campaign_type_audience === 0) {
              this.currentCampaign.audience = 0;
            } else {
              this.currentCampaign.audience = selectionRequestData.audience;
            }
          });
        }
      });
    } catch (newError) {
      this.set('selectionError', extractError(newError).description);
    } finally {
      this.set('isSelectionLoading', false);
    }
  };

  loadCalculateTariff = async (tariff, campaignId) => {
    try {
      const calculateTariffData = await calculateOrderEventsRequest({
        channel_uid: CHANNEL_STUB_TARGET_INTERNET,
        selection_id: this.currentCampaign.selection.id,
        locations: collectCitiesArray(this.currentCampaign.selection?.data?.geo_points || []),
        campaign_id: campaignId,
        budget: tariff.budget,
        cpc: Social.activeCompanyIndustry.cpc,
        ctr: Social.activeCompanyIndustry.ctr,
      });

      Social.setTariff(tariff.id, { count: calculateTariffData.qty });
    } catch (error) {
      throw error;
    }
  };

  getCalculate = async () => {
    this.calculating = true;
    if (
      !this.currentCampaign.selection.id ||
      !this.currentCampaign.id ||
      !this.currentCampaign.channel_uid ||
      (!this.currentCampaign.currentOrder.budget &&
        !this.currentCampaign.currentOrder.minimalBudget &&
        !this.currentCampaign.currentOrder.channel_uid === 'custom-poll'
      )
    ) {
      this.calculating = false;
      return;
    }
    if (!this.getDataForCalculate) {
      this.calculating = false;
      return;
    }
    try {
      const calculateInfo = await calculateOrderEventsRequest(this.getDataForCalculate);

      runInAction(() => {
        set(this.calculate, calculateInfo);
      });

      if (this.currentCampaign.channel_uid === CHANNEL_STUB_SMS && this.currentCampaign.currentOrder.external_operator === false) {
        this.calculate.external_operator_qty = null;
        this.calculate.external_operator_event_cost = null;
      }

      if (
        !isNullOrUndefined(this.calculate.qty) &&
        this.currentCampaign?.currentOrder?.date_start &&
        this.currentCampaign?.currentOrder?.budget &&
        WebsAndPhonesTaxons.isWebSitesOnline && WebsAndPhonesTaxons.webSitesIsActive &&
        isNullOrUndefined(this.currentCampaign.currentOrder[ORDER_FINISH_DATE_FIELD])
      ) {
        debounce(this.getCampaignEndDate, 500)();
      }

      if (
        this.currentCampaign.promocode &&
        this.currentCampaign.status_id !== 5
      ) {
        try {
          const {
            channels,
            promo_code,
            promo_code_value_type_id,
            promo_code_value,
            promo_type_id,
          } = await axiosAuthorizedRequest({
            url: `/api/v1/client/campaigns/${this.currentCampaign.id}/get_promo_codes/`,
            method: 'POST',
            data: {
              promo_code: this.currentCampaign.promocode.name,
              context_param: UserInfo.getUserInfoCompany().inn,
              events: {
                [this.currentCampaign.channel_uid]: {
                  ...this.calculate,
                },
              },
            },
          });
          runInAction(() => {
            this.ignorePromocodeId = null;
            this.currentCampaign.promocode = {
              channels,
              name: promo_code,
              promo_code_value_type_id,
              promo_code_value,
              promo_type_id,
              isValid: true,
            };
          });
        } catch (e) {
          console.error(e);

          runInAction(() => {
            this.currentCampaign.promocode.isValid = false;
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.calculating = false;
      });
    }
  };

  startCampaign = async () => {
    // не даём отправить кампанию, если есть неоплаченные счета
    // if (UserInfo.data.late_payment) {
    //   this.startingCampaign = false;
    //   const response = await confirmDialog(LockedService, { description: 'DebtCampaign' });
    //   if (response === 'changeEmail') {
    //     await confirmDialog(ChangeEmail);
    //   }
    //   return Promise.reject();
    // }
    const promocodeData = this.currentCampaign?.promocode || {};
    await axiosAuthorizedRequest({
      url: `/api/v1/client/campaigns/${this.currentCampaign.id}/start/`,
      method: 'POST',
      data: {
        onlyValid: true,
        promo_codes:
          (promocodeData.isValid && [{ promo_code: promocodeData.code }]) || [],
        locations: collectCitiesArray(this.currentCampaign.selection?.data?.geo_points || []),
      },
    });
    await CommonStore.getAvalablePromocodes();
    return Promise.resolve();
  };

  calculateBudgetWithSenderName = price => {
    if (this.currentCampaign?.campaign_type_audience !== 1 && this.shouldPayForName) {
      return +price + +CommonStore.constants.MY_AUDIENCE_NAME_SENDER_COST;
    }
    if (
      CommonStore.constants.EXTERNAL_OPERATOR_NAME_SENDER_BUDGET &&
      this.currentCampaign.currentOrder.external_operator === true &&
      this.shouldPayForName
    ) {
      return +price + +CommonStore.constants.EXTERNAL_OPERATOR_NAME_SENDER_BUDGET;
    }
    return price;
  }

  getTools = async () => {
    if (this.tools.length) return;
    const response = await axiosAuthorizedRequest({
      url: '/api/v1/infotech/tools/',
    });
    runInAction(() => {
      this.tools = response.map((item) => {
        return {
          ...item,
          budget: CommonStore.constants.MIN_TOOLS_BUDGET,
          isActive: false,
          min: CommonStore.constants.MIN_TOOLS_BUDGET,
          max: CommonStore.constants.MAX_TOOLS_BUDGET,
        };
      });
    });
  };

  @computed get getToolsById() {
    const result = new Map();
    this.tools.forEach(tool => result.set(tool.id, tool));
    return result;
  }

  @computed get getDataForCalculate() {
    if (this.currentCampaign.channel_uid === CHANNEL_STUB_INTERNET) {
      if (
        !this.currentCampaign?.currentOrder?.tools?.some((item) => item.isActive) ||
        !this.currentCampaign?.currentOrder?.industry
      ) { return null; }
      return {
        channel_uid: this.currentCampaign.channel_uid,
        selection_id: this.currentCampaign.selection.id,
        locations: collectCitiesArray(this.currentCampaign.selection?.data?.geo_points || []),
        campaign_id: this.currentCampaign.id,
        industry: this.currentCampaign?.currentOrder?.industry,
        tools: this.currentCampaign?.currentOrder?.tools.filter(
          (item) => item.isActive,
        ),
        budget:
          +this.currentCampaign.currentOrder.budget ||
          CommonStore.getChannelInfoByUid(this.currentCampaign.channel_uid)
            .minimal_budget,
      };
    }

    if (this.currentCampaign.channel_uid === CHANNEL_STUB_SMS) {
      // const isShort = messagesSmsStore.value?.links?.isShort;
      // const emulatorMessageText = isShort ?
      //   messagesSmsStore.value?.text :
      //   messagesSmsStore.value?.originText;

      if (isNullOrUndefined(this.currentCampaign.currentOrder.external_operator)) {
        return null;
      }

      return {
        channel_uid: this.currentCampaign.channel_uid,
        selection_id: this.currentCampaign.selection.id,
        locations: collectCitiesArray(this.currentCampaign.selection?.data?.geo_points || []),
        use_online_geo: window.location.pathname === `/new-campaign/${this.currentCampaign.id}/audience` ? false : Boolean(this.currentCampaign.currentOrder.use_online_geo),
        campaign_id: this.currentCampaign.id,
        budget:
          +this.currentCampaign.currentOrder.budget ||
          (this.currentCampaign.currentOrder.external_operator && CommonStore.constants.EXTERNAL_OPERATOR_MIN_BUDGET) ||
          this.currentCampaign?.currentOrder?.minimalBudget,
        // message_length: emulatorMessageText ? emulatorMessageText.length : 0,
        external_operator: this.currentCampaign.currentOrder.external_operator,
      };
    }

    if (this.currentCampaign.channel_uid === CHANNEL_STUB_PUSH) {
      if (isNullOrUndefined(this.currentCampaign.currentOrder?.data?.pushText?.length)) {
        return null;
      }
      return {
        channel_uid: this.currentCampaign.channel_uid,
        selection_id: this.currentCampaign.selection.id,
        locations: collectCitiesArray(this.currentCampaign.selection?.data?.geo_points || []),
        campaign_id: this.currentCampaign.id,
        budget:
          +this.currentCampaign.currentOrder.budget ||
          CommonStore.getChannelInfoByUid(this.currentCampaign.channel_uid)
            .minimal_budget,
        target_action:
          this.currentCampaign.currentOrder?.targetAction ||
          PUSH_TARGET_ACTIONS.FOLLOW_LINK,
        push_count: this.currentCampaign.currentOrder?.data?.pushText?.length,
      };
    }

    if (this.currentCampaign.channel_uid === CHANNEL_STUB_VOICE) {
      return {
        channel_uid: this.currentCampaign.channel_uid,
        selection_id: this.currentCampaign.selection.id,
        locations: collectCitiesArray(this.currentCampaign.selection?.data?.geo_points || []),
        campaign_id: this.currentCampaign.id,
        connection_type: this.currentCampaign?.currentOrder?.connectionType || '',
        activity_field: this.currentCampaign?.currentOrder?.activityField?.id || '',
        budget:
            +this.currentCampaign.currentOrder.budget ||
            CommonStore.getChannelInfoByUid(this.currentCampaign.channel_uid)
              ?.minimal_budget ||
            0,
      };
    }

    if (this.currentCampaign.channel_uid === CHANNEL_STUB_POLL_CUSTOM) {
      return {
        channel_uid: this.currentCampaign.channel_uid,
        selection_id: this.currentCampaign.selection.id,
        locations: collectCitiesArray(this.currentCampaign.selection?.data?.geo_points || []),
        campaign_id: this.currentCampaign.id,
        event_count: +this.currentCampaign.currentOrder.event_count || 1,
      };
    }

    return {
      channel_uid: this.currentCampaign.channel_uid,
      selection_id: this.currentCampaign.selection.id,
      locations: collectCitiesArray(this.currentCampaign.selection?.data?.geo_points || []),
      campaign_id: this.currentCampaign.id,
      budget:
        +this.currentCampaign.currentOrder.budget ||
        CommonStore.getChannelInfoByUid(this.currentCampaign.channel_uid)
          ?.minimal_budget ||
        0,
    };
  }

  @action getOrdersCurrentCampaign = () => {
    return this.currentCampaign?.currentOrder;
  };

  @computed get getAnaliticsSelection() {
    const getParamsIds = () => {
      return Object.keys(Taxons.selectedTaxonsGroups || {})?.filter((keyItem => {
        // @ts-ignore
        return (Taxons.selectedTaxonsGroups[keyItem].elements?.length) || Taxons.selectedTaxonsGroups[keyItem]?.value ||
        // @ts-ignore
        (Taxons.selectedTaxonsGroups[keyItem]?.rangeValues?.length && toJS(Taxons.selectedTaxonsGroups[keyItem]?.rangeValues)?.every(v => !!v));
      }))?.filter(newItem => {
        if (newItem === 'any_location' && Taxons.geoType === 'points') return false;
        else if (newItem === 'geo_points' && Taxons.geoType === 'regions') return false;
        return true;
      });
    };

    return getParamsIds().join(';');
  }

  @action getOnlineGeoAudience = async (fieldsArray = []) => {
    if (this.isCanRecalculateAudience === false) return;
    this.isLoading = true;
    this.isSelectionLoading = true;
    try {
      const requestTimeStamp = Date.now();
      this.lastAudienceRequest = requestTimeStamp;
      const { audience } = await axiosAuthorizedRequest({
        method: 'POST',
        url: `/api/v1/client/campaigns/${this.currentCampaign.id}/orders_audience/target-sms/`,
        data: fieldsArray,
      });

      runInAction(() => {
        if (this.isFirstOnlineGeoRequest && this.currentCampaign.audience !== audience) {
          this.isFirstOnlineGeoRequest = false;
          this.showNotification();
        }
      });

      if (fieldsArray.length) {
        if (this.lastAudienceRequest === requestTimeStamp) {
          runInActionLogger(() => {
            this.currentCampaign.audience = audience;
          });
        }
      } else {
        await this.updateSelection();
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
      this.isSelectionLoading = false;
    }
  };

  @action resetOnlineGeoAudience = async () => {
    if (this.isCanRecalculateAudience === false) return;
    try {
      this.isSelectionLoading = true;
      const { audience } = await axiosAuthorizedRequest({
        method: 'POST',
        url: `/api/v1/client/campaigns/${this.currentCampaign.id}/orders_audience/target-sms/`,
        data: [],
      });
      runInActionLogger(() => { this.currentCampaign.audience = audience; });
    } catch (e) {
      console.error(e);
    } finally {
      runInActionLogger(() => {
        this.isSelectionLoading = false;
      });
    }
  };

  @action showNotification = () => {
    this.isNotificationActive = setTimeout(() => {
      this.isNotificationActive = 0;
    }, 4000);
  };

  @action hideNotification = () => {
    this.isNotificationActive = 0;
  };

  @action init = () => {
    this.isFirstOnlineGeoRequest = true;
  };

  @action getCampaignEndDate = async () => {
    try {
      this.endDateLoading = true;
      this.endDateLoadingError = false;
      const request = await composeAxiosPostRequest({
        url: `${CAMPAIGN_API_URL}${this.currentCampaign.id}/custom_segment/${WebsAndPhonesTaxons.webSitesId}/blacklist_verifying/`,
        data: {
          event_count_min: this.calculate.qty,
        },
      });
      runInActionLogger(() => {
        this.endDateLoading = false;
        this.onlineDateEndEstimation = request.estimation;
        this.currentCampaign.currentOrder[ORDER_FINISH_DATE_FIELD] = moment(this.currentCampaign.currentOrder[ORDER_START_DATE_FIELD]).add(request.estimation, 'days');
      });
    } catch (e) {
      console.error(e);
      this.endDateLoadingError = true;
    } finally {
      this.endDateLoading = false;
    }
  };

  @action getChannelOffsetDateStart = async (id, is_repeat) => {
    try {
      const requestData = await axiosAuthorizedRequest({
        url: `/api/v1/client/campaigns/${id || this.currentCampaign.id}/channel_offset_date_start/`,
        method: 'POST',
        data: {
          external_operator: this.currentCampaign.currentOrder.external_operator,
          is_repeat: is_repeat && true,
        },
      });

      runInAction(() => {
        this.orderDateStartOffset = requestData?.offset_date_start;
        this.maxDateStart = requestData?.max_date_start;
      });
    } catch (e) {
      console.error('Server Error: ', extractError(e));
    }
  }

  @action getOrdersHolidays = async (type = '') => {
    try {
      const responseArray = await axiosAuthorizedRequest({
        url: '/api/v1/settings/holidays/',
        params: {
          is_sms_holiday: (type === 'sms' || type === 'push') ? true : null,
        },
      });

      if (!this.ordersHolidays) {
        this.ordersHolidays = {
          sms: {},
          push: {},
          voice: {},
          internet: {},
          social: {},
        };
      }

      runInAction(() => {
        responseArray.forEach(item => {
          this.ordersHolidays[type][item.date] = true;
        });
      });
    } catch (e) {
      console.error('Server Error: ', extractError(e));
    }
  }

  @computed get isInternetBudgetValid() {
    const totalToolsBudget = (this.currentCampaign.currentOrder.tools || []).reduce((acc, tool) => {
      if (tool.isActive) {
        return acc + tool.budget;
      }
      return acc;
    }, 0);

    return totalToolsBudget >= this.currentCampaign.currentOrder.minimalBudget;
  }

  @action validationSpaceLegalInfo = () => {
    this.currentCampaign.currentOrder.legalInfo = this.currentCampaign.currentOrder.legalInfo.replace(/ +/g, ' ').trim().replace(/\n+/g, '\n').trim();
  }

  @action handleFileRemove = async (id) => {
    await axiosAuthorizedRequest({
      url: `/api/v1/client/order_files/${id}/`,
      method: 'DELETE',
      data: {
        order_id: this.currentCampaign.currentOrder.id,
      },
    });
    runInAction(() => {
      this.currentCampaign.currentOrder.files = this.currentCampaign.currentOrder.files.filter(item => item.id !== id);
    });
  };

  @action popupClosePushToGA = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'event_b2b_beePro',
      eventCategory: 'Interaction',
      eventAction: 'click',
      eventLabel: 'buttonPopUp',
      eventContent: 'Изменить настройки',
      eventOther: 'myCampaigns',
      blockName: newCampaign.currentCampaign.channel_uid,
    });
  }

  @computed get getHolidaysByChannelType() {
    switch (this.currentCampaign.channel_uid) {
      case CHANNEL_STUB_INTERNET:
        return this.ordersHolidays.internet;
      case CHANNEL_STUB_PUSH:
        return this.ordersHolidays.push;
      case CHANNEL_STUB_VOICE:
        return this.ordersHolidays.voice;
      case CHANNEL_STUB_TARGET_INTERNET:
        return this.ordersHolidays.social;
      case CHANNEL_STUB_SMS:
      default:
        return this.ordersHolidays.sms;
    }
  }

  checkForNameUsage = async () => {
    return axiosAuthorizedRequest({
      url: `/api/v1/client/name_senders/?other_operators=${(this.currentCampaign.currentOrder.external_operator)}`,
      method: 'GET',
    });
  }
}

const newCampaign = new NewCampaign();

reaction(
  () => newCampaign.nameSenderInfo?.cost,
  () => {
    if (newCampaign.nameSenderInfo?.cost && newCampaign.nameSenderInfo.cost > 0) {
      newCampaign.shouldPayForName = true;
    } else if (newCampaign.nameSenderInfo?.cost === 0) {
      newCampaign.shouldPayForName = false;
    }
  },
);

reaction(
  () => newCampaign.nameSenderInfo?.name,
  () => {
    if (newCampaign.nameSenderInfo?.name) {
      newCampaign.currentCampaign.currentOrder.nameSender = newCampaign.nameSenderInfo.name;
      newCampaign.currentCampaign.currentOrder.files = newCampaign.nameSenderInfo.files;
    } else {
      newCampaign.currentCampaign.currentOrder.nameSender = '';
    }
  },
);

reaction(
  () => {
    return Social.adStep[ADCREATINGFORM.INDUSTRY];
  },
  () => {
    Social.tariffs.forEach((tariff) => {
      newCampaign.loadCalculateTariff(tariff, newCampaign.currentCampaign.id);
    });
  },
);

reaction(
  () => newCampaign.currentCampaign?.currentOrder.date_start,
  async () => {
    // код для расчёта даты окончания кампании при включенных онлайн сегментах
    if (WebsAndPhonesTaxons.isWebSitesOnline && WebsAndPhonesTaxons.webSitesIsActive &&
      newCampaign.currentCampaign.currentOrder[ORDER_FINISH_DATE_FIELD]
    ) {
      if (!isNullOrUndefined(newCampaign.onlineDateEndEstimation)) {
        newCampaign.currentCampaign.currentOrder[ORDER_FINISH_DATE_FIELD] = moment(newCampaign.currentCampaign.currentOrder[ORDER_START_DATE_FIELD]).add(newCampaign.onlineDateEndEstimation, 'days');
        return;
      }

      newCampaign.currentCampaign.currentOrder[ORDER_FINISH_DATE_FIELD] = null;
    }
  },
);

reaction(
  () => newCampaign.currentCampaign?.currentOrder.budget,
  async () => {
    // код для расчёта даты окончания кампании при включенных онлайн сегментах
    if (WebsAndPhonesTaxons.isWebSitesOnline && WebsAndPhonesTaxons.webSitesIsActive &&
      newCampaign.currentCampaign.currentOrder[ORDER_FINISH_DATE_FIELD]
    ) {
      newCampaign.currentCampaign.currentOrder[ORDER_FINISH_DATE_FIELD] = null;
    }
  },
);

reaction(
  () => newCampaign.currentCampaign?.currentOrder?.status_id,
  () => {
    // если открываем кампанию для редактирования первый раз запрос на пересчёт даты происходить не должен
    if (newCampaign.currentCampaign?.currentOrder?.status_id !== 7) {
      runInActionLogger(() => { newCampaign.shouldRecalculateEndDate = false; });
    }
  },
);

autorun(async () => {
  if (
    // есть кастомные сегменты
    (WebsAndPhonesTaxons.webSitesIsActive || WebsAndPhonesTaxons.phonesIsActive) &&
    // и в онлайн гео есть точки
    newCampaign.currentCampaign?.currentOrder?.geo_points?.length &&
    newCampaign.currentCampaign?.currentOrder?.use_online_geo
  ) {
    // затираем точки и делаем онлайн гео неактивным ME-2649
    runInActionLogger(() => {
      newCampaign.currentCampaign.currentOrder.geo_points = [];
      newCampaign.currentCampaign.currentOrder.use_online_geo = false;
    });
    await newCampaign.resetOnlineGeoAudience();
  }
});

reaction(
  () => newCampaign.currentCampaign.currentOrder.use_online_geo,
  () => {
    // отвечает за сброс онлайн гео при переключении на странице брифа
    if ((WebsAndPhonesTaxons.webSitesIsActive || WebsAndPhonesTaxons.phonesIsActive) ||
      newCampaign.currentCampaign.currentOrder.external_operator) return;
    if (newCampaign.currentCampaign.currentOrder.use_online_geo === true) {
      newCampaign.getOnlineGeoAudience(newCampaign.currentCampaign.currentOrder.geo_points);
    } else if (newCampaign.currentCampaign.currentOrder.use_online_geo === false) {
      runInActionLogger(() => { newCampaign.currentCampaign.currentOrder.geo_points = []; });
      newCampaign.getOnlineGeoAudience([]);
    }
  },
);

reaction(
  () => newCampaign.currentCampaign.currentOrder?.geo_points?.length,
  () => {
    if (newCampaign.currentCampaign.currentOrder.use_online_geo === true) {
      newCampaign.getOnlineGeoAudience(newCampaign.currentCampaign.currentOrder.geo_points);
    }
  },
);

reaction(
  () => newCampaign.currentCampaign.currentOrder.is_editable,
  async () => {
    // если заказ не редактируемый, то надо редиректить на страницу моих кампаний
    if (newCampaign.currentCampaign.currentOrder.is_editable === false) {
      navigate('/my-campaigns/');
    }
  },
);

const checkForName = debounce(newCampaign.checkForNameUsage, 1000);

reaction(
  () => newCampaign.currentCampaign.currentOrder.external_operator,
  () => {
    if (newCampaign.currentCampaign.channel_uid !== CHANNEL_STUB_SMS) return;

    if (newCampaign.currentCampaign.currentOrder.external_operator === true &&
      newCampaign.currentCampaign?.currentOrder.budget &&
      newCampaign.currentCampaign?.currentOrder.budget < CommonStore.constants.EXTERNAL_OPERATOR_MIN_BUDGET
    ) {
      newCampaign.currentCampaign.currentOrder.budget = CommonStore.constants.EXTERNAL_OPERATOR_MIN_BUDGET;
    }

    // так как имена для отправителей теперь разные, то при смене рассылки по Билайну имя нужно обнулять
    newCampaign.currentCampaign.currentOrder.nameSender = undefined;
    newCampaign.currentCampaign.currentOrder.files = [];
  },
);

const debouncedGetChannelOffsetDateStart = debounce(newCampaign.getChannelOffsetDateStart, 500);

reaction(
  () => newCampaign.currentCampaign?.currentOrder?.external_operator,
  async () => {
    if (newCampaign.currentCampaign?.currentOrder?.external_operator === true ||
      newCampaign.currentCampaign?.currentOrder?.external_operator === false &&
      newCampaign.currentCampaign.channel_uid === CHANNEL_STUB_SMS) {
      await debouncedGetChannelOffsetDateStart();
    }
  },
);

reaction(
  () => newCampaign.currentCampaign.currentOrder?.targetAction,
  () => {
    if (newCampaign.currentCampaign.currentOrder?.targetAction === PUSH_TARGET_ACTIONS.CALL_ON_NUMBER) {
      newCampaign.currentCampaign.currentOrder[ORDER.URL_ADVERTISER_FIELD] = newCampaign.currentCampaign.currentOrder[ORDER.SHORT_NUMBER];
    }
  },
);

reaction(
  () => newCampaign.currentCampaign.currentOrder.external_operator === true,
  async () => {
    if (
      newCampaign.currentCampaign.currentOrder?.use_online_geo === true ||
      newCampaign.currentCampaign.currentOrder?.geo_points?.length
    ) {
      await newCampaign.resetOnlineGeoAudience();
      runInActionLogger(() => {
        newCampaign.currentCampaign.currentOrder.geo_points = [];
        newCampaign.currentCampaign.currentOrder.use_online_geo = false;
      });
    }
  },
);

reaction(
  () => ((WebsAndPhonesTaxons.isWebSitesOnline && WebsAndPhonesTaxons.webSitesIsActive)
  || (Taxons.selectedData.get('is_online_geo') === 'true' && Taxons.geoType === 'points')),
  () => {
    if (newCampaign.currentCampaign.channel_uid === CHANNEL_STUB_SMS && (WebsAndPhonesTaxons.webSitesIsActive && WebsAndPhonesTaxons.isWebSitesOnline)) {
      runInActionLogger(() => {
        newCampaign.currentCampaign.currentOrder.external_operator = false;
      });
    }
  },
);

reaction(
  () => newCampaign.currentCampaign.currentOrder.nameSender ||
    newCampaign.currentCampaign.currentOrder.date ||
    newCampaign.currentCampaign.currentOrder.start_date,
  () => {
    if (
      newCampaign.currentCampaign.currentOrder.nameSender?.length &&
      newCampaign.currentCampaign.channel_uid === CHANNEL_STUB_SMS &&
      (newCampaign.currentCampaign.currentOrder?.external_operator === true || newCampaign.currentCampaign.campaign_type_audience !== 1)
    ) {
      checkForName();
    }
  },
);

reaction(
  () => newCampaign.currentCampaign.currentOrder.date,
  () => {
    if (
      newCampaign.currentCampaign.currentOrder.nameSender?.length &&
      newCampaign.currentCampaign.channel_uid === CHANNEL_STUB_SMS &&
      newCampaign.currentCampaign.currentOrder?.external_operator === true
    ) {
      checkForName();
    }
  },
);

reaction(
  () => newCampaign.currentCampaign.currentOrder.connectionType,
  () => {
    if (
      newCampaign.currentCampaign.currentOrder?.activityField?.type_tab !== newCampaign.currentCampaign.currentOrder.connectionType) {
      newCampaign.currentCampaign.currentOrder.activityField = { id: undefined };
    }
  },
);

reaction(
  () => newCampaign.currentCampaign.currentOrder?.text,
  () => {
    if (newCampaign.isOldPush) {
      newCampaign.currentCampaign.currentOrder.text = newCampaign.currentCampaign.currentOrder?.text?.replace(/[ \n]+/g, ' ').trim();
    }
  },
);

reaction(
  () => newCampaign.currentCampaign.currentOrder?.text?.text,
  () => {
    // код для расчёта даты окончания кампании при включенных онлайн сегментах
    if (WebsAndPhonesTaxons.isWebSitesOnline && WebsAndPhonesTaxons.webSitesIsActive &&
      newCampaign.currentCampaign.currentOrder[ORDER_FINISH_DATE_FIELD]
    ) {
      newCampaign.currentCampaign.currentOrder[ORDER_FINISH_DATE_FIELD] = null;
    }
  },
);

reaction(
  () => newCampaign.calculate.qty,
  () => {
    newCampaign.basicSmsCost = newCampaign.calculate.qty > COUNT_SMS_DISCOUNT ?
      +CommonStore.constants.SMS_SEGMENT_ABOVE_COST :
      +CommonStore.constants.SMS_SEGMENT_LOWER_COST;
  },
);

reaction(
  () => newCampaign.currentCampaign.audience === 0,
  () => {
    if (newCampaign.currentCampaign.audience === 0 && window.location.pathname.indexOf('channels') !== -1) {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: 'event_b2b_beePro',
        eventCategory: 'Interaction',
        eventAction: 'view',
        eventLabel: 'popUp',
        eventContent: `Ваша аудитория равна нулю&aud:${this.getAnaliticsSelection}`,
        eventOther: 'myCampaigns',
        blockName: newCampaign.currentCampaign.channel_uid,
      });
    }
  },
);

const getCalculateDebounce = debounce(newCampaign.getCalculate, 500);


reaction(
  () => (
    newCampaign.currentCampaign?.currentOrder.budget
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign?.currentOrder.external_operator
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign.currentOrder.urlAdvertiser
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign?.currentOrder?.data?.timeSending
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign?.currentOrder?.text?.text
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign?.currentOrder?.data?.pushText
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign.currentOrder?.data?.notificationType
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign.currentOrder?.date
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign.currentOrder?.timeSending
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign.currentOrder?.connectionType
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign.currentOrder?.callMethod
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign.currentOrder?.activityField
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign?.currentOrder.event_count
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (
    newCampaign.currentCampaign.currentOrder?.nameSender
  ),
  () => {
    getCalculateDebounce();
  },
);

reaction(
  () => (newCampaign.currentCampaign.audience || (WebsAndPhonesTaxons.isWebSitesOnline && WebsAndPhonesTaxons.webSitesIsActive)),
  async () => {
    if (newCampaign.currentCampaign.audience === 0 && !(WebsAndPhonesTaxons.isWebSitesOnline && WebsAndPhonesTaxons.webSitesIsActive)) {
      newCampaign.isProhibitedCampaignSubmission = true;
      await confirmDialog(StartCampaignOrEditModal, {
        templateSource: TEMPLATES.PROHIBITED,
      });
      return;
    }
    newCampaign.isProhibitedCampaignSubmission = false;
  },
);

export default newCampaign;
