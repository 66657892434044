import {
  CHANNEL_STUB_INTERNET, CHANNEL_STUB_PUSH,
  CHANNEL_STUB_SMS,
  CHANNEL_STUB_TARGET_INTERNET,
  CHANNEL_STUB_VOICE,
} from '../../constants';
// для dataLayer
export const TypeAudience = {
  0: 'file',
  1: 'beeline',
  2: 'wifi',
};

export const TIME_SETTING_TOOLTIP_TEXT = `Старт рассылки начинается по времени часового пояса абонента.
Например, указано, что рассылка стартует в 10:00 и в выборке номера: Калининграда, Хабаровска и Благовещенска.
Рассылка начнется для каждого пула номеров в 10:00 согласно их часовому поясу.`;

export const RANGE_TIME_TOOLTIP = 'Если на вашем балансе достаточно средств для оплаты кампании,' +
  ' то можете указать ближайшую дату запуска по календарю. Если вам предстоит сначала пополнить баланс,' +
  ' то закладывайте для запуска 1-2 дня на зачисление средств';

export const WEBINAR = '/dashboard/#webinar';
export const NEW_CAMPAIGN_URL = '/new-campaign/';
export const MY_CAMPAIGNS_URL = '/my-campaigns/';
export const MY_CAMPAIGNS_STATISTIC_URL = '/my-campaigns/:campaignId/statistics';
export const POLLS_PAGE_URL = '/polls/';
export const USER_INFO_URL = '/user-info/';
export const AUDIENCE_STATISTIC_URL = '/audience-statistic/';
export const AUDIENCE_STATISTIC_REPORT_URL = '/audience-statistic/report/';
export const OUTER_LINK_QUICKREQUEST = 'https://moskva.beeline.ru/business/services/beeline-prodvizhenie/#quickrequest';
export const FAQ_URL_SUBPAGE = '/faq/:faqPage';
export const FAQ_URL = '/faq/';
export const TINDER_URL = '/beetogether/';
export const CHANNELS_URL = '/channels/';
export const TINDER_LANDING = '/business-vmeste';
export const MY_AUDIENCES = '/my-audiences/';
export const FILE_TYPES = {
  BRAND_FILE: 0,
  ORDER_REPORT: 1,
  MEDIA_PLAN: 2,
  FOCUS: 3,
  FORM: 4,
  PHONE: 5,
};

export const FILE_FORMATS = {
  CSV: 'csv',
  TXT: 'txt',
};

export const SET_CHANNEL_TYPES = 'NEW_CAMPAIGN::SET_CHANNEL_TYPES';


export const ORDER = {
  BUDGET_FIELD: 'planPrice',
  URL_ADVERTISER_FIELD: 'targetResource',
  START_DATE_FIELD: 'startDate',
  FINISH_DATE_FIELD: 'endDate',
  DATE: 'date',
  INDUSTRY_ID: 'industryId',
  SERVICES_FIELD: 'services',
  INDUSTRY_FIELD: 'industry',
  ADD_INFO_FIELD: 'addInfo',
  CHOSEN_TARIFF: 'chosenTariff',
  SENDER_NAME_FIELD: 'senderName',
  MESSAGE_FIELD: 'message',
  PUSH_TEXT: 'pages',
  LEGAL_FIELD: 'legalInfo',
  LINKS_FIELD: 'links',
  FILES: 'files',
  SENDING_FIELD: 'timeSending',
  TEST_NUMBERS_FIELD: 'testCtns',
  TARGET_ACTION: 'targetAction',
  TOOLS_FIELD: 'tools',
  MOBILE_VERSION: 'mobileVersion',
  CONNECTION_TYPE: 'connectionType',
  COMMENT_FIELD: 'comment',
  ID: 'id',
  NOTIFICATION: 'notificationType',
  SHORT_NUMBER: 'phone',
  WAY_TO_MAKE_CALL: 'callMethod',
  ACTIVITY_FIELD: 'activityField',
  EXTERNAL_OPERATOR: 'external_operator',
  NAME_CAMPAIGN: 'name',
  EVENT_COUNT: 'event_count',
  CLIENT_APPROVES: 'client_approves',
  MEDIA_TYPE_ID: 'mediaTypeId',
  DEVICE_TYPE: 'deviceType',
  TARGETING_TYPE: 'targetingType',
  URL: 'url',
  GEO_PROFILES: 'geoProfiles',
  DESC_AUDIENCE: 'descAudience',
  PROMOTIONAL_MATERIALS: 'promotionalMaterials',
  TEMPLATE: 'template',
  PROMO_CODES_ERROR: 'isErrorPromoCodes',
  RETAILERS: 'retailers',
  TARIFF_ID: 'tariffId',
  VOICE: 'voice',
  MELODY: 'melody',
  TEXT: 'text',
};


export const ORDER_BUDGET_FIELD = 'budget';
export const ORDER_URL_ADVERTISER_FIELD = 'urlAdvertiser';
export const ORDER_START_DATE_FIELD = 'date_start';
export const ORDER_FINISH_DATE_FIELD = 'date_end';
export const ORDER_DATE = 'date';
export const ORDER_ACTIVE_FIELD = 'isActive';
export const ORDER_SERVICES_FIELD = 'services';
export const ORDER_INDUSTRY_FIELD = 'industry';
export const ORDER_ADD_INFO_FIELD = 'addInfo';
export const ORDER_CHOSEN_TARIFF = 'chosenTariff';
export const ORDER_SENDER_NAME_FIELD = 'nameSender';
export const IS_USING_SWITCHER_EXTERNAL_OPERATOR = 'is_using_external_operator';
export const ORDER_MESSAGE_FIELD = 'text';
export const ORDER_LEGAL_FIELD = 'legalInfo';
export const ORDER_LINKS_FIELD = 'links';
export const SMS_MESSAGE_MAX_LENGTH = 402;
export const PUSH_MESSAGE_MAX_LENGTH = 99;
export const SMS_LEGAL_INFO_MAX_LENGTH = 268;
export const ORDER_SENDING_FIELD = 'timeSending';
export const ORDER_SENDING_FIELD_DEFAULT = [9, 21];
export const ORDER_TEST_NUMBERS_FIELD = 'testCtns';
export const ORDER_TARGET_ACTION = 'targetAction';
export const GEO_TYPE_ACTION = 'geoAction';
export const ORDER_FILES_FIELD = 'files';
export const USE_ONLINE_GEO = 'use_online_geo';
export const GEO_POINTS = 'geo_points';
export const ORDER_ID = 'id';
export const ORDER_TOOLS_FIELD = 'tools';
export const ORDER_MOBILE_VERSION = 'mobileVersion';
export const ORDER_CONNECTION_TYPE = 'connectionType';
export const ACTIVITY_FIELD = 'activityField';
export const WAY_TO_MAKE_CALL = 'callMethod';
export const ORDER_COMMENT_FIELD = 'comment';
export const EXTERNAL_OPERATOR = 'external_operator';

export const PUSH_TARGET_ACTIONS = {
  CALL_ON_NUMBER: 0,
  FOLLOW_LINK: 1,
  APP_DOWNLOAD: 2,
};

export const CHANNEL = {
  INTERNET: 'internet',
  VOICE: 'voice-target',
  TARGET_INTERNET: 'target-internet',
  SMS: 'target-sms',
  PUSH: 'push',
  POLL: 'poll',
  CUSTOM_POLL: 'custom-poll',
  AUDIO_ADS: 'audio-ads',
  DIGITAL_BILLBOARDS: 'digital-billboards',
};

export const NEW_CAMPAIGN_CHANNELS = [
  CHANNEL.INTERNET,
  CHANNEL.SMS,
  CHANNEL.VOICE,
  CHANNEL.PUSH,
  CHANNEL.TARGET_INTERNET,
  CHANNEL.CUSTOM_POLL,
];

export const PROMOCODE_TYPES = {
  PARTICULAR: 1,
  TOTAL: 0,
};
