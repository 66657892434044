import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Link } from '@reach/router';
import { observer } from 'mobx-react';
import { senderNameStore } from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import TooltipInfo from 'redesignSrc/UI/elements/TooltipInfo';
import { FormFieldLabel } from '../../typography';
import { CharsCounter, TextInput } from '../index';
import styles from './styles.pcss';
import CancelSaveIco from './assets/close-ico.svg';

type Props = {
  className?: string,
  onChange?: (e:string)=>void,
  onSave?: ()=> void,
  onCancelSave?: ()=> void,
  max: number,
  label?:string,
  tooltip ?:string,
  value?:string,
  name?:string,
  validation?:any,
  onBlur?:()=>void
  onFocus?:()=>void
  placeholder?:string
  keepErrorIndent?: any,
  maxErrorWidth?: number,
  withSaveBtn?: boolean,
  valueId?: number
}

const CounterTextInput:React.FC<Props> = ({
  className,
  onChange,
  onFocus,
  max,
  label,
  value,
  name,
  validation,
  onBlur,
  placeholder,
  keepErrorIndent,
  maxErrorWidth,
  withSaveBtn,
  valueId,
  onSave,
  onCancelSave,
  tooltip,
}) => {
  const [isError, setIsError] = useState<string|undefined>();
  const [isPaste, setIsPaste] = useState(false);
  const [position, setPosition] = React.useState(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);


  const ACTION: {[key: string]: string} = {
    SAVE: 'save',
    CANCEL: 'cancel',
  };

  const handleAreaChange = (text:string, e: ChangeEvent<HTMLTextAreaElement>) => {
    if (text.length > max && !isPaste) {
      setIsPaste(false);
      return;
    }
    if (text.length > max && isPaste) {
      setPosition(e.target.selectionStart);
      onChange && onChange(text.slice(0, max));
      setIsPaste(false);
      return;
    }
    onChange && onChange(text);

    // @ts-ignore
    if (validation?.errors[name]) {
      validation.getvalidObj(name);
    }
    setIsPaste(false);
  };

  const handleOnBlur = () => {
    validation?.getvalidObj(name);
    onBlur && onBlur();
  };

  useEffect(() => {
    if (textareaRef !== null && textareaRef.current) {
      textareaRef.current.selectionStart = position;
      textareaRef.current.selectionEnd = position;
    }
  }, [position]);

  useEffect(() => {
    // @ts-ignore
    setIsError(validation?.errors[name]);
    // @ts-ignore
  }, [validation?.errors[name]]);

  const saveHandler = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    if (target.dataset.save === ACTION.SAVE) {
      onSave && onSave();
    }
  };

  const cancelSaveHandler = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    if (target.dataset.cancel === ACTION.CANCEL) {
      onCancelSave && onCancelSave();
    }
  };

  return (
    <div id={name} className={classNames(className, styles.container)}>

      <div className={styles.labelInfo}>
        {label && <FormFieldLabel>{label}</FormFieldLabel>}
        {tooltip && <TooltipInfo className={styles.tooltip}>{tooltip}</TooltipInfo>}
      </div>
      <span className={styles.inputWithSave}>
        <TextInput
          onFocus={onFocus}
          maxErrorWidth={maxErrorWidth}
          // keepErrorIndent={keepErrorIndent}
          textareaRef={textareaRef}
          placeholder={placeholder}
          error={isError}
          value={value}
          // @ts-ignore
          onChange={handleAreaChange}
          onBlur={handleOnBlur}
          onPaste={() => setIsPaste(true)}
        />
        {
          withSaveBtn && (
            <>
              <span onClick={saveHandler} className={styles.inputWithSaveBtn} data-save="save">Сохранить</span>
              <CancelSaveIco onClick={cancelSaveHandler} className={styles.inputWithCancelBtn} data-cancel="cancel" />
            </>
          )
        }
      </span>


      <div className={styles.counter}>
        {!senderNameStore.duplicateId ? <span /> : (<span className={styles.error}>Такое имя уже существует. <a className={styles.link} rel="noreferrer" target="_blank" href={`/sender-name/${senderNameStore.duplicateId}`}>Смотреть имя</a></span>)
        }
        <CharsCounter current={value?.length} max={max}/>
      </div>


    </div>
  );
};

export default observer(CounterTextInput);
