import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.pcss';

type Props = {
  isArrow?:boolean
  onClick?:(e:any)=>void
  children?:React.ReactNode | string;
  className?: string
}
const LinkOpenInfo:React.FC<Props> = ({ onClick, children, isArrow, className }) => {
  return (
    <p className={classNames(styles.conditions, className, isArrow && styles.arrow)}>
      <span className={styles.link} onClick={onClick}>{children}</span>
    </p>
  );
};

export default LinkOpenInfo;
