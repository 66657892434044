import React from 'react';
import { observer } from 'mobx-react';
import Templates from 'store/mobx/Templates';
import ErrorBase from '../ErrorBase';
import styles from './styles.pcss';

const ErrorTooManyRequests = () => {
  const { getPopupsTemplate } = Templates;
  const { title, subtitle } = getPopupsTemplate('AudienceTooManyRequests');
  return (
    <ErrorBase level={2} title={title}>
      <div className={styles.description}>{subtitle}</div>
    </ErrorBase>
  );
};

export default observer(ErrorTooManyRequests);
