import React from 'react';
import classNames from 'classnames';
import { toJS } from 'mobx';
import { navigate } from '@reach/router';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import Templates from 'store/mobx/Templates';
import danglingPrepositions from 'utils/danglingPrepositions';
import NewCampaign from 'store/mobx/NewCampaign';
import { PopupStateless } from '../Popup/components/Popup';
import styles from './styles.pcss';

export const TEMPLATES = {
  FAIL: 'CampaignFailPopup',
  SUCCESS: 'CampaignSuccessPopup',
  UPDATED: 'CampaignUpdatedPopup',
  PROHIBITED: 'ProhibitedCampaignPopup',
};

type Props = {
  giveAnswer:(b:boolean)=>void,
  modalTemplate:any,
  templateSource:string,
  serverError:any,
}


const StartCampaignOrEditModal:React.FC<Props> = ({
  giveAnswer,
  templateSource = TEMPLATES.SUCCESS,
  serverError,
  modalTemplate,
}) => {
  return (
    <PopupStateless opened onClose={() => giveAnswer(true)}>
      <div className={styles.content}>
        <h2 className={styles.title}>
          {modalTemplate?.title || Templates.getNewCampaignTemplate(`${templateSource}`)?.title || 'Ваша заявка отправлена'}
          {/* {modalTemplate?.title || 'Ваша заявка отправлена' } */}
        </h2>

        <div className={classNames(styles.description)}>
          {/* {modalTemplate?.description */}
          {/*  || serverError?.fullText */}
          {/*   */}
          {/* } */}
          {serverError?.fullText
          || (modalTemplate && modalTemplate?.description && <>
            {danglingPrepositions(modalTemplate?.description)}
            {modalTemplate?.link && (<>
              {' '}<a className={styles.descriptionLink} onClick={() => giveAnswer(true)} href={modalTemplate?.link.href}>
                {modalTemplate?.link.label}
              </a>
            </>)}
          </>)
          || (Templates.getNewCampaignTemplate(`${templateSource}`)?.description
            && danglingPrepositions(Templates.getNewCampaignTemplate(`${templateSource}`)?.description))
          || <span>{danglingPrepositions('В ближайшее время с вами свяжется специалист' +
            ' для подтверждения заявки. Всю информацию о вашей рекламной кампании вы сможете найти в разделе')}&nbsp;
            <a onClick={() => giveAnswer(true)} href="#">Мои кампании</a>
          </span>
          }
        </div>

        <div className={styles.buttonsContainer}>
          <PrimaryButton
            onClick={() => {
              if (templateSource === TEMPLATES.PROHIBITED) {
                navigate(`/new-campaign/${NewCampaign.currentCampaign.id}/audience`);
              }
              giveAnswer(true);
            }}
            className={classNames(styles.button)}
          >
            {Templates.getNewCampaignTemplate(`${templateSource}`)?.buttonText || 'Понятно'}
          </PrimaryButton>
        </div>
      </div>
    </PopupStateless>
  );
};

export default StartCampaignOrEditModal;
