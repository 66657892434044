import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { addDays, addMonths, getMinEndVoice, getMinStartInternet, getMinStartSmsPush, getMinStartVoice } from 'utils/date';
import DateRangePicker from 'redesignSrc/UI/form/NewDatePicker/DateRangePicker';
import ErrorDescription from 'redesignSrc/components/ErrorDescription';
import { ORDER } from 'redesignSrc/pages/constants';
import NewOrder from 'store/mobx/NewOrder';
import NewAudiences from 'store/mobx/NewAudiences';
import { senderNameStore } from '../SenderName/SenderNameStore';
import SaveTemplateStore from '../SaveTemplate/SaveTemplateStore';

const getMaxStartDate = (start:string, isRealTime:boolean, isVoice?: boolean) => {
  if (isRealTime) {
    return addDays(new Date(start), 60);
  }
  return addMonths(new Date(start), 3);
};
type Props = {
  handleSmsFormChange: () => void,
  validation: any,
  isInternet?:boolean,
  isVoice?: boolean,
}

const DateSelection = ({ handleSmsFormChange, validation, isInternet, isVoice }: Props) => {
  const [isError, setIsError] = useState(false);
  const [minStart, setMinStart] = useState<Date | undefined>(addDays(new Date(), 1));

  useEffect(() => {
    if (isInternet) {
      setMinStart(getMinStartInternet());
    } else if (isVoice) {
      setMinStart(getMinStartVoice());
    } else {
      setMinStart(NewOrder.smsCampaign.isOtherOperators ?
        addDays(new Date(), 7) :
        getMinStartSmsPush({
          isVoice: NewOrder.order?.type === 'VoiceTarget',
          isNow: senderNameStore.isAcceptedSederName && SaveTemplateStore.isAcceptedTemplate,
        }));
    }
  }, [senderNameStore.isAcceptedSederName, SaveTemplateStore.isAcceptedTemplate]);

  const maxEnd = NewOrder.currentOrder.startDate ? getMaxStartDate(NewOrder.currentOrder.startDate, NewAudiences.isRealTime, isVoice) : new Date();

  const handleChange = (start: string | null, end?: string | null) => {
    const isChangesStart = NewOrder.currentOrder.startDate !== start;
    if (start && end && new Date(start) > new Date(end)) {
      NewOrder.currentOrder.startDate = start;
      NewOrder.currentOrder.endDate = null;
    } else {
      NewOrder.currentOrder.startDate = start;
      NewOrder.currentOrder.endDate = isChangesStart ? null : end || null;
    }
    if (start && isChangesStart) {
      validation?.getvalidObj(ORDER.START_DATE_FIELD);
    }
    if (end && !isChangesStart) {
      validation?.getvalidObj(ORDER.FINISH_DATE_FIELD);
    }
    handleSmsFormChange();
  };

  const startDate2 = NewOrder.currentOrder.startDate ? new Date(NewOrder.currentOrder.startDate) : minStart;

  return (
    <>
      <div id={ORDER.START_DATE_FIELD} />
      <div id={ORDER.FINISH_DATE_FIELD} />
      <DateRangePicker
        valueDateStart={NewOrder.currentOrder.startDate}
        valueDateEnd={NewOrder.currentOrder.endDate}
        onChange={handleChange}
        minDate1={minStart}
        maxDate2={maxEnd}
        minDate2={isVoice ? addDays(startDate2, 30) : minStart}
        isNotCross
        isErrorStart={isError}
        isErrorEnd={isError}
        isNotMaxDate1
      />
      <ErrorDescription
        errors={validation.errors}
        fieldName={[ORDER.START_DATE_FIELD, ORDER.FINISH_DATE_FIELD]}
        onError={(e) => setIsError(e)}
      />

    </>
  );
};

export default observer(DateSelection);
