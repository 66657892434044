import { action, observable } from 'mobx';
import { axiosBaseRequestAdTech } from 'requests/helpers';

export type Hold = {
  customerId: number,
  ban: string,
  type: number,
  typeName: string,
  value: number,
  sourceId: number,
  timestamp: string
}

export type BalanceHistory = {
  customerId: number,
  ban: string,
  type: number,
  typeName: string,
  value: number,
  sourceId: number,
  timestamp: string
}

export type BalanceResponse = {
  free: number,
  reserved: number,
  hold: Hold[],
  balanceHistory: {
    data: BalanceHistory[],
    totalCount: number,
    pageCount: number
  }
}

export type BonusesStore = {
  bonuses: BalanceResponse | null;
  dateFrom: string | null;
  dateTo: string | null;
  fetchBonuses: () => Promise<void>,
  type: number
  page: number
}

class Bonuses {
  @observable bonuses = null;
  @observable dateFrom = null;
  @observable dateTo = null;
  @observable type = 0;
  @observable page = 1;
  @action fetchBonuses = async () => {
    const params = new URLSearchParams();
    if (this.type === 1) {
      params.append('Type', '1');
      params.append('Type', '2');
    }

    if (this.type === 4) {
      params.append('Type', '3');
      params.append('Type', '4');
    }

    this.dateTo && params.append('DateTo', `${this.dateTo}T23:29:59` || '');
    this.dateFrom && params.append('DateFrom', `${this.dateFrom}T00:00:00` || '');
    params.append('PageSize', '10');
    params.append('Page', `${this.page}`);

    this.bonuses = await axiosBaseRequestAdTech({
      url: '/api/bonuses/balance',
      params,
    });
  }
}

export const bonusesStore = new Bonuses();
