import React from 'react';
import classNames from 'classnames/bind';
import InfoDescription from 'redesignSrc/components/InfoDescription';
import messagesChainLocalStore from 'redesignSrc/pages/PushPages/PushPageSetup/components/PushMessages/store';
import styles from './styles.pcss';

type Props = {
  isStopWords?: boolean,
  isCautionWords?: boolean
  errors?: { [key: string]: string | undefined } | null,
  className?: string
}

const WordsDescription: React.FC<Props> = ({
  isStopWords,
  isCautionWords,
  errors,
  className,
}) => {
  // if (messagesChainLocalStore.isTextLengthMoreThanMaxLingthValid) {
  //   return (
  //     <div className={classNames(className, styles.error)}>
  //       На экране помещается только 99 символов. Пожалуйста, сократите текст или добавьте экран
  //     </div>
  //   );
  // }
  if (errors?.pages === 'PhoneNotValid') {
    return (
      <div className={classNames(className, styles.error)}>
        Номер телефона необходимо указывать с ' +
        'кодом страны в формате «+7» или «8» и кодом города, без «()», «-», ' +
        'пробелов, и без знаков препинания в конце, иначе модерация будет не пройдена.' +
        ' Например, +74951231212 или 88007000628
      </div>
    );
  }

  if (!isStopWords && !isCautionWords) return null;

  return (
    <>
      {isStopWords ?
        <>
          {isCautionWords ?
            <InfoDescription className={className} icon="red">
              <span className={styles.error}>
                В тексте присутствуют запрещенные и спорные слова, согласно редакционной политики билайн.
                Рекомендуем заменить их, так как ваша заявка может быть заблокирована на этапе модерации&nbsp;
              </span>
            </InfoDescription> :
            <InfoDescription className={className} icon="red">
              <span className={styles.error}>
                Данное слово запрещено использовать в таргетированных SMS-сообщениях, согласно редакционной политике билайн.
                Пожалуйста, замените его, так как ваша заявка может быть заблокирована на этапе модерации&nbsp;
              </span>
            </InfoDescription>
          }
        </> :
        <InfoDescription className={className} icon="yellow">
          <span className={styles.warning}>
            Данное слово является спорным, согласно редакционной политике билайн,
            и может трактоваться как запрещенное в определенном контексте.
            Рекомендуем заменить его, так как ваша заявка может быть заблокирована на этапе модерации&nbsp;
          </span>
        </InfoDescription>
      }
    </>
  );
};

export default WordsDescription;
