import { runInAction, spy } from 'mobx';

const is_global_logger_enabled = false;

let count = 0;

export function runInActionLogger(callback) {
  if (is_global_logger_enabled && process.env.NODE_ENV !== 'production') {
    console.info(`%c ======== ${count} =========`, 'background: #282828; color: #ffdc7d');
    let shouldLogEvents = true;
    let shouldLogDefault = true;
    spy((event) => {
      if (shouldLogEvents) {
        if (event.type === 'update' || event.type === 'create' || event.type === 'add' || event.type === 'delete') {
          console.info(`%c [${event.type}]: ${event.name}.${event.key} ---> ${event.newValue}`, 'background: #ffdc7d; color: #282828');
          shouldLogDefault = false;
        }
      }
    });
    runInAction(callback);
    try {
      const callbackToString = `${callback}`;
      const body = callbackToString.slice(callbackToString.indexOf('{') + 1, callbackToString.lastIndexOf('}'));
      const actions = body.split(';');
      if (actions.length) {
        actions.forEach(singleAction => {
          // вот тут можно добавить условие и смотреть за конкретным свойством, например, currentCampaign.audience
          if (singleAction.includes('=')) {
            const mutation = singleAction.trim().split('=');
            if (shouldLogDefault) {
              console.info(`%c ${mutation[0]} ---> ${mutation[1]}`, 'background: #ffdc7d; color: #282828');
            }
          }
        });
        // arguments нужны для того, чтобы посмотреть из какого файла была вызвана функция (в [[FunctionLocation]])
        // eslint-disable-next-line prefer-rest-params
        console.info(arguments);
        console.info(`%c ======== end of ${count}=========`, 'background: #282828; color: #ffdc7d');
        console.info('\n\n\n');
        count += 1;
      }
      shouldLogEvents = false;
      shouldLogDefault = true;
    } catch (e) {
      console.error(e);
    }
  } else {
    runInAction(callback);
  }
}
