import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import useValidation from '../../../../../../../HOC/hooks/useValidation';
import { RULES_TYPE_TEMPLATE_GROUP } from '../../../../../../NewTemplate/consts';
import { scrollSmoothToNodeById } from '../../../../../../../../utils/scroll';
import messageTemplatesStore from '../../../../../../../../store/mobx/MessageTemplates';
import { FormFieldLabel } from '../../../../../../../UI/typography';
import { TextArea, TextInput } from '../../../../../../../UI/form';
import { PrimaryButton } from '../../../../../../../UI/buttons';
import styles from '../styles.pcss';
import { PopupStateless } from '../../../../../../../UI/modals/Popup';

export interface CreateTemplateGroupModelProps {
  showModal: boolean;
}
export const EditDeleteTemplateGroupModal: FC<CreateTemplateGroupModelProps> = observer(({ showModal }) => {
  const mainRules = RULES_TYPE_TEMPLATE_GROUP;
  const rules = () => mainRules;
  const currentGroup: any = (messageTemplatesStore.selectedTemplateGroupId && showModal)
    && messageTemplatesStore.messageTemplatesGroup.filter((group) => group.id === messageTemplatesStore.selectedTemplateGroupId)[0];

  const valid = useValidation({
    name: messageTemplatesStore.formTemplateGroup.values.name,
  }, rules());

  useEffect(() => {
    if (!messageTemplatesStore.selectedTemplateGroupId || !showModal) return;
    messageTemplatesStore.formTemplateGroup.values.name = currentGroup?.name;
    messageTemplatesStore.formTemplateGroup.values.description = currentGroup?.description;
  }, [showModal]);

  const onCancel = () => {
    messageTemplatesStore.showTemplateEditGroup = false;
  };

  const changeNameHandler = (name: string) => {
    messageTemplatesStore.formTemplateGroup.values.name = name;
  };

  const changeDescriptionHandler = (description: string) => {
    messageTemplatesStore.formTemplateGroup.values.description = description;
  };

  const saveGroupHandler = async () => {
    const { firstErrorKey } = valid.getAllValid();
    if (firstErrorKey) {
      scrollSmoothToNodeById(firstErrorKey, 200);
    } else {
      messageTemplatesStore.messageTemplatesGroupName = messageTemplatesStore.formTemplateGroup.values.name;
      messageTemplatesStore.formTemplate.values.groupName = messageTemplatesStore.formTemplateGroup.values.name;
      await messageTemplatesStore.updateTemplateGroup(messageTemplatesStore.selectedTemplateGroupId);
      await messageTemplatesStore.getAllMessageTemplatesGroup();
      messageTemplatesStore.showTemplateEditGroup = false;
    }
  };

  return (
    <>
      <PopupStateless
        opened={showModal}
        className={styles.modal}
        onClose={onCancel}>
        <div className={styles.modalTitle}>Редактирование группы шаблонов</div>
        <p style={{ marginBottom: '23px', textAlign: 'left' }}>
          Вы можете редактировать название и описание группы. При удалении шаблоны остаются без группы
        </p>
        <div>
          <div style={{ marginBottom: '40px', textAlign: 'left' }}>
            <FormFieldLabel>Название группы</FormFieldLabel>
            <TextInput
              maxLength={50}
              value={messageTemplatesStore.formTemplateGroup.values.name}
              placeholder="Введите название"
              validation={valid}
              onChange={changeNameHandler}
              name="name"
            />
          </div>
          <div style={{ marginBottom: '20px', textAlign: 'left' }}>
            <FormFieldLabel>Описание группы шаблонов</FormFieldLabel>
            <TextArea
              placeholder="Укажите информацию, если необходимо"
              value={messageTemplatesStore.formTemplateGroup.values.description}
              onChange={changeDescriptionHandler}
              name="description"
            />
          </div>
          <div>
            <PrimaryButton
              className={styles.sendModalBtnCancel}
              onClick={saveGroupHandler}
          >
              Сохранить изменения
            </PrimaryButton>
          </div>
        </div>
      </PopupStateless>
    </>
  );
});
