import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import confirmDialog from 'components/modals/confirmDialog';
import UserInfo from 'store/mobx/UserInfo';
import DeleteModal from '../DeleteModal';
import messageTemplatesStore from '../../../../../store/mobx/MessageTemplates';
import { DelModal } from '../ClientBase/components/modals/DelModal/DelModal';
import styles from './styles.pcss';
import { MenuClientBaseProps } from './MenuClientBaseProps';

const MenuClientBase: FC<MenuClientBaseProps> = (props) => {
  const { isMenuVisible, onClose, id, status, setShowDelModal } = props;
  const isPartners = UserInfo.data?.appType === 1;
  const isHideEditBtn = status === 'Verification' || status === 'Accepted';

  const deleteTemplateHandler = async () => {
    const isDelete = await confirmDialog(DeleteModal);
    if (isDelete) {
      await messageTemplatesStore.deleteTemplateById(id);
    }
  };

  const editTemplateHandler = async () => {
    messageTemplatesStore.isLoadingPage = true;
    await navigate(`/new-template/${id}`);
  };

  const delTemplateHandler = async () => {
    messageTemplatesStore.selectedTemplateId = id;
    messageTemplatesStore.showDelTemplate = true;
  };

  const showTemplateDocsHandler = () => {
    messageTemplatesStore.showTemplateDocs = true;
  };

  const showTemplateCompanyHandler = () => {
    messageTemplatesStore.showTemplateCompany = true;
  };

  useEffect(() => {
    const handlerClickOutside = () => {
      setTimeout(() => onClose(), 0);
    };
    document.getElementById('root')?.addEventListener('click', handlerClickOutside);
    return () => document.getElementById('root')?.removeEventListener('click', handlerClickOutside);
  }, []);

  return (
    <>
      <div className={`${styles.menuContainer} ${isMenuVisible ? styles.menuVisible : styles.menuHidden}`}>
        {isHideEditBtn ? null : <div onClick={editTemplateHandler} className={styles.menuItem}>Редактировать</div>}
        <div onClick={showTemplateDocsHandler} className={styles.menuItem}>Смотреть документы</div>
        <div onClick={showTemplateCompanyHandler} className={classNames(styles.menuItem, isPartners && styles.disabled)}>Смотреть кампании</div>
        {/* <div onClick={deleteTemplateHandler} className={styles.menuItem}>Удалить</div> */}
        <div onClick={delTemplateHandler} className={styles.menuItem}>Удалить</div>
      </div>
    </>

  );
};

export default observer(MenuClientBase);
