import React from 'react';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import commonStyles from 'styles/common.pcss';
import CreateFocusReport from 'store/mobx/CreateFocusReport';

import ErrorBase from '../ErrorBase';
import CreateCampaignButton from '../../CreateCampaignButton';
import ProcessInfoText from '../../../../../components/ProcessInfoText';
import DescriptionWrapper from '../../DescriptionWrapper';
import FileInfo from '../../FileInfo';

const cx = classNames.bind(commonStyles);

function ErrorLoadFile({ onClose }) {
  const { error } = CreateFocusReport;
  return (
    <ErrorBase title={error.title} level={3}>
      <FileInfo className={cx('marb-l')} />

      <DescriptionWrapper className={cx('marb-l')} level={5}>
        <ProcessInfoText>{error.description || ''}</ProcessInfoText>
      </DescriptionWrapper>

      <CreateCampaignButton iconSlug="arrowRightLong" className={cx('marb-l')} onClose={onClose}>
        Загрузить другой файл
      </CreateCampaignButton>
    </ErrorBase>
  );
}

ErrorLoadFile.propTypes = {
  onClose: PropTypes.func,
};

export default observer(ErrorLoadFile);
