/**
 * Функция принимает символы текста разбивает и возвращает части смс
 * @param value{number} - количество символов
 * @returns {number} - Части смс
 */
export const SMSCounter = (value: number) => {
  if (value === 0) return 0;
  if (value < 71) return 1;
  if (value < 135) return 2;
  if (value < 202) return 3;
  if (value < 269) return 4;
  if (value < 336) return 5;
  if (value >= 336) return 6;
  return 0;
};
