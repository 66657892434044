import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.pcss';

type Props = {
  title?: string,
  children: React.ReactNode,
  isOpen?: boolean,
  className?: string,
}

const cx = classNames.bind(styles);

const Opener:React.FC<Props> = ({
  title,
  children,
  isOpen = false,
  className,
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!title) setOpen(true);
  }, []);
  useEffect(() => {
    if (!isOpen) return;
    setOpen(true);
  }, []);
  return (
    <div className={cx(styles.opener, className)}>
      <div
        className={styles.openerTitle}
        onClick={() => setOpen(!open)}>
        <div className={styles.titleHolder}>{title}</div>
        {
          title &&
          <div className={styles.closeHolder}>
            <button className={open ? `${styles.opened} ${styles.downBtn}` : styles.downBtn} />
          </div> || null
        }
      </div>
      {
        open && children
      }
    </div>
  );
};

export default Opener;
