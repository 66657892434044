import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from '@reach/router';
import LogoB2B from './LogoB2B';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export default function Logo({ href, className }) {
  return (
    <Link className={cx('component', className)} to={href}>
      <LogoB2B className={cx('logoImage')} />
    </Link>
  );
}

Logo.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
};
