import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Authorization from 'store/mobx/Authorization';
import { ym } from '../../../utils/ga-analytics/utils';
import { axiosBaseRequestAdTech } from '../../../requests/helpers';
import { getTSMSUrl } from '../../../utils/getUrl';
import Sms from './Sms';
import Phone from './Phone';
import styles from './styles.pcss';


const Login = observer(() => {
  const { screen } = Authorization;
  useEffect(() => {
    ym(87718585, 'reachGoal', 'form_shown');
    console.info('ID:231445302-YM');
  }, []);
  console.info(`${getTSMSUrl()}/partners/Account/LoginFromTargetSms`);
  const onClick = async () => {
    const token = await fetch(`${getTSMSUrl()}/sms/api/auth/getToken`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then(res => res.text());
    window.location.href = `${window.location.origin}/partners/Account/LoginFromTargetSms?returnUrl=${window.location.origin}/my-campaigns&access_token=${token}`;
  };
  return (
    <div className={styles.container}>

      <div className={`${styles.form} ${styles[`${screen}Screen`]}`}>

        {
          screen === 'phone' &&
          <Phone/> ||
          screen === 'sms' &&
          <Sms/>
        }
      </div>
      <div className={styles.conditions}>
        При входе на ресурс вы принимаете <a rel="noreferrer"
          href="https://static.beeline.ru/upload/images/marketing/Условия_предоставления_услуги_билайн_ПРОдвижение.pdf"
          target="_blank">условия оказания услуги</a> билайн.ПРОдвижение
      </div>
      <div tabIndex={0} role="link" className={styles.partnerLink} onClick={onClick}>Вход для партнеров</div>
    </div>
  );
});

export default Login;
