import { computed, observable, action, reaction, runInAction, toJS } from 'mobx';
// @ts-ignore
import qs from 'query-string';
// @ts-ignore
import mimetype from 'mimetype';
import { axiosBaseRequestAdTech, prepareMultipartFormData } from 'requests/helpers';
import { toastErrorNotification } from 'modules/toast-notifications';
import NewOrder from '../NewOrder';
import { BannerFilesType } from '../NewOrder/type';
import { BillBoard } from './types';

export const defaultBillBoard: BillBoard = {
  nameCampaign: '',
  budget: 0,
  companyGoals: 0,
  geoAdvertisingType: '0',
  creativeBsnnerInfoType: 0,
  mediaType: null,
  url: '',
  geoProfiles: '',
  startDate: '',
  endDate: '',
  descAudience: '',
  platforms: [],
  promotionalMaterials: '',
  comment: '',
  industryId: 0,
};

type City = {
  id: number,
  name: string,
}

type Billboards = {
  id: number,
  city: string,
  address: string,
  cost: number,
  type: number
}

type CityOption = {
  value: number,
  label: string,
}

type BillBoardsOption = {
  value: number,
  label: string,
  city: string,
  address: string,
  cost: number,
  type: number
}

class DigitalBillboardsCityStreets {
  @observable isLoading = false;
  @observable isValidAdress = true;
  @observable isValidLoadedFiles = true;
  @observable isValidLoadedFile = {
    isError: false,
    errors: [],
    id: null,
  };
  @observable id = null;

  @observable cities: City[] = [];
  @observable billboards: Billboards[] = [];

  @observable citiesOption: CityOption[] = [];
  @observable billboardsOption: BillBoardsOption[] = [];

  @observable billBoard: BillBoard = defaultBillBoard;
  @observable isLoadingPage = false;
  @observable agreementModal = false;
  @observable isCreateSenderNameMode = false;
  @observable showCreateSenderNameModal = false;
  @observable editSenderNameId: number | null = null;
  @observable orderBannerText: string = '';
  @observable senderStatus: string = '';
  @observable firstComment: string = '';
  @observable isUploadfileWhenStatusSenderAccepted = false;

  @observable SENDERS_LIMIT: number = 10;

  @observable files:any[] = [];
  @observable clientFiles:any[] = [];
  @observable errorOnFileUploading: string | null = null;
  @observable filesProgress:{[key: string]: number}|null = null
  @action resetBillboards = () => {
    this.isValidAdress = true;
    this.isValidLoadedFiles = true;
  }
  @action resetState = () => {
    this.files = [];
    this.clientFiles = [];
    this.errorOnFileUploading = null;
    this.orderBannerText = '';
  }

  private isAllowMimeType = (file: File) => {
    const mapMimeType = ['image/jpeg', 'image/png', 'application/pdf', 'application/jpg', 'application/x-zip-compressed', 'application/x-rar-compressed', 'application/zip'];
    return mapMimeType.includes(mimetype.lookup(file.name));
  }

  @action isShowUploadFiles = () => {
    if (!NewOrder.smsCampaign.isOtherOperators) {
      return this.senderStatus !== 'Accepted' && this.senderStatus !== 'Verification';
    }
    return this.senderStatus !== 'Accepted' && this.senderStatus !== 'Verification';
  }

  private preparyCityOptionData = (cities: City[]) => {
    return cities.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });
  };

  private preparyBillboardsOptionData = (billboards: Billboards[]) => {
    return billboards.map(({ id, city, address, cost, type }) => {
      return {
        value: id,
        label: `${city} ${address}`,
        address,
        city,
        cost,
        type,
      };
    });
  };

  @action getCities = async () => {
    try {
      this.cities = await axiosBaseRequestAdTech({ url: 'api/orders/digitalBillboards/cities' });
      this.citiesOption = this.preparyCityOptionData(this.cities);
    } catch (e) {
      console.error(e);
    }
  };

  @action getBillboards = async (text: string) => {
    try {
      this.billboards = await axiosBaseRequestAdTech({ url: `api/orders/digitalBillboards?text=${text}&limit=5` });
      this.billboardsOption = this.preparyBillboardsOptionData(this.billboards);
    } catch (e) {
      console.error(e);
    }
  };

  @action deleteFile = async (id: number) => {
    try {
      await axiosBaseRequestAdTech({
        url: `api/orders/${NewOrder.order?.id}/file/${id}`,
        method: 'DELETE',
      });
    } catch (error) {
      console.error(error);
    }
  }

  @action uploadFile = async (file: any, resolution: string) => {
    try {
      const { id } = await axiosBaseRequestAdTech({
        url: `api/orders/${NewOrder.order?.id}/file?isCtnFile=false`,
        method: 'POST',
        data: prepareMultipartFormData({
          File: file.originFileObj,
        }),
      });
      const newFileObj: BannerFilesType = {
        resolution,
        fileId: id,
      };

      NewOrder.digitalBillboardDetails.bannerFiles = [...NewOrder.digitalBillboardDetails.bannerFiles, newFileObj];
      NewOrder.bannerFiles = [...NewOrder.digitalBillboardDetails.bannerFiles, newFileObj];
      NewOrder.digitalBillboardDetails.hasBanner = true;
      NewOrder.digitalBillboardDetails.bannerText = '';
      this.isValidLoadedFile.isError = false;
      this.isValidLoadedFile.errors = [];
    } catch (e) {
      this.isValidLoadedFile.isError = true;
      this.isValidLoadedFile.errors = e.response?.data || [];

      delete NewOrder.uploadFileForResolutions[resolution];
      NewOrder.resolutionsFileLoaded = [...NewOrder.resolutionsFileLoaded?.filter((item) => item !== resolution)];
    }
  };

  @action uploadFileListSection = async (orderId?: number) => {
    if (!orderId) return;
    try {
      for await (const key of NewOrder.resolutionsFileLoaded) {
        const hasFile = !NewOrder.uploadFileForResolutions[key].file ? null : true;
        let fileId;
        if (hasFile) {
          const { id } = await axiosBaseRequestAdTech({
            url: `api/orders/${orderId}/file?isCtnFile=false`,
            method: 'POST',
            data: prepareMultipartFormData({
              File: NewOrder.uploadFileForResolutions[key].file.originFileObj,
            }),
          });
          fileId = id;
        } else {
          fileId = NewOrder.uploadFileForResolutions[key].fileId;
        }
        const newFileObj: BannerFilesType = {
          resolution: key,
          fileId,
        };
        NewOrder.digitalBillboardDetails.bannerFiles = [...NewOrder.digitalBillboardDetails.bannerFiles, newFileObj];
        NewOrder.digitalBillboardDetails.hasBanner = true;
      }
      this.clientFiles = [];
    } catch (e) {
      console.error(e);
    }
  };

  @action createBillboardUploadFile = (file:File) => {
    if (!file.size) {
      this.errorOnFileUploading = 'Нельзя загрузить пустой файл';
    }
    if (!this.isAllowMimeType(file)) {
      toastErrorNotification('Неверный формат файла');
      return;
    }
    this.clientFiles.push(file);
    this.errorOnFileUploading = null;
    if (this.senderStatus === 'Accepted') {
      this.isUploadfileWhenStatusSenderAccepted = true;
    }
  };
}

const digitalBillboardsCityStreets = new DigitalBillboardsCityStreets();

export default digitalBillboardsCityStreets;
