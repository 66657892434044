import { action, computed, observable, runInAction } from 'mobx';
import { extractError } from 'utils/errors';
import {
  customSegmentLoadFileRequest,
} from 'requests/campaigns';
import { axiosAuthorizedRequest, composeAxiosDeleteRequest, composeAxiosUpdateRequest } from 'requests/helpers';
import { CAMPAIGN_API_URL, getCampaignApiUrl } from 'requests/campaigns/constants';

import NewCampaign from './NewCampaign';

const reduceListToShow = (listToReduce) => listToReduce.reduce((accum, item, index) => {
  if (index === listToReduce.length - 1) return accum + item;
  return `${accum} ${item}, `;
}, '');

class WebsAndPhonesTaxons {
  @observable webSitesId = '';
  @observable webSitesDepth = '';
  @observable webSitesFile = null;
  @observable webSitesArray = null;
  @observable webSitesBlacklist = null;
  @observable webSitesIsActive = null;
  @observable loadingWebSitesFiles = false;
  @observable isShowWebSitesFormatError = false;
  @observable warningModalTitle = '';
  @observable sitesNotCountElements = '';
  @observable sitesErrorModalTitle = '';

  @observable phonesId = '';
  @observable phonesDepth = '';
  @observable phonesCallDirection = '';
  @observable phonesFile = null;
  @observable phonesIsActive = null;
  @observable loadingPhonesFiles = false;
  @observable isShowPhoneFormatError = false;
  @observable phonesNotCountElements = '';
  @observable phonesErrorModalTitle = '';
  @observable phonesBlacklist = null;

  @observable blackList = null;

  @observable depthOptionsPhones = null;
  @observable depthOptionsWebSites = null;
  @observable delayOptionsPhones = null;
  @observable delayOptionsWebSites = null;
  @observable eventsDepths = null;

  @observable loadingEventsDepths = true;

  @observable isShowSuccessCountingModal = false;

  @observable callIntervalRequests = '';

  @observable isPhonesChanged = false;
  @observable isWebSitesCalculating = false;

  @observable intervalRequestAttempts = 0;

  @action set = (value, data) => {
    this[value] = data;
  };

  @action resetAllCustomSegments = () => {
    this.webSitesId = '';
    this.webSitesDepth = '';
    this.webSitesFile = null;
    this.webSitesIsActive = null;
    this.loadingWebSitesFiles = false;
    this.isShowWebSitesFormatError = false;

    this.phonesId = '';
    this.phonesDepth = '';
    this.phonesCallDirection = '';
    this.phonesFile = null;
    this.phonesIsActive = null;
    this.loadingPhonesFiles = false;
    this.isShowPhoneFormatError = false;
    this.warningModalTitle = '';

    this.errorModalTitle = '';
    this.errorModalDescription = '';

    this.isPhonesChanged = false;
    this.isWebSitesCalculating = false;
  }

  @action resetPhoneNumbersTaxon = () => {
    this.phonesErrorModalTitle = '';
    this.warningModalTitle = '';
    this.errorModalDescription = '';
    this.phonesFile = null;
    this.isPhonesChanged = false;

    if (!this.webSitesFile?.id && !this.phonesFile?.id) {
      NewCampaign.currentCampaign.audience = NewCampaign.currentCampaign?.selection?.audience;
    }
  }

  @action resetWebSitesTaxon = () => {
    this.sitesErrorModalTitle = '';
    this.errorModalDescription = '';
    this.webSitesFile = null;
    this.isWebSitesCalculating = false;

    this.callIntervalRequests = 'reset';

    if (!this.webSitesFile?.id && !this.phonesFile?.id) {
      NewCampaign.currentCampaign.audience = NewCampaign.currentCampaign?.selection?.audience;
    }
  }

  @computed get isWebSitesOnline() {
    return !!this.eventsDepths?.online?.find((item) => item.id === this?.webSitesDepth);
  }

  @action loadCustomSegmentEventsDepth = async () => {
    if (this.eventsDepths &&
      this.depthOptionsWebSites &&
      this.depthOptionsPhones &&
      this.delayOptionsWebSites &&
      this.delayOptionsPhones) {
      return;
    }
    this.loadingEventsDepths = true;

    try {
      const eventsDepths = await axiosAuthorizedRequest({ url: `${CAMPAIGN_API_URL}custom_segment_event_depth/` });

      runInAction(() => {
        this.eventsDepths = eventsDepths;

        this.depthOptionsWebSites = eventsDepths?.offline?.filter(item => item?.segment_type === 0)
          .map(element => ({
            value: element.id,
            label: element.name,
          }));

        this.depthOptionsPhones = eventsDepths?.offline?.filter(item => item?.segment_type === 1)
          .map(element => ({
            value: element.id,
            label: element.name,
          }));

        this.delayOptionsWebSites = eventsDepths?.online?.filter(item => item?.segment_type === 0)
          .map(element => ({
            value: element.id,
            label: element.name,
            tooltip: element?.description || '',
          }));

        this.delayOptionsPhones = eventsDepths?.online?.filter(item => item?.segment_type === 1)
          .map(element => ({
            value: element.id,
            label: element.name,
            tooltip: element?.description || '',
          }));
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingEventsDepths = false;
    }
  }

  @action webSitesHandleFilesAdded = async (file) => {
    this.set('loadingWebSitesFiles', true);
    this.set('webSitesBlacklist', []);

    try {
      const result = await customSegmentLoadFileRequest({
        data: file,
        campaignId: NewCampaign.currentCampaign.id,
        segmentId: this.webSitesId,
        event_depth: this.webSitesDepth,
      });

      this.blackList = result.blacklist;

      if (result.blacklist.length) {
        // this.set('sitesErrorModalTitle', 'Некоторые сайты из вашего списка\n не будут учтены');
        this.set('webSitesBlacklist', result.blacklist);
        this.set(
          'sitesNotCountElements',
          result.blacklist.length === 1 ? `Не был учтен сайт: ${result.blacklist?.[0]}` : `Не были учтены сайты: ${reduceListToShow(result.blacklist)}.`,
        );
      }

      if (result.missed_value?.length) {
        // this.set('sitesErrorModalTitle', 'Некоторые сайты из вашего списка\n не будут учтены');
        this.set('webSitesBlacklist', result.missed_value);
        this.set(
          'sitesNotCountElements',
          result.missed_value.length === 1 ? `Не был учтен сайт: ${result.missed_value?.[0]}` : `Не были учтены сайты: ${reduceListToShow(result.missed_value)}.`,
        );
      }

      this.webSitesFile = { ...result, size: file.size, file_name: file.name };
      this.set('webSitesArray', result?.items);
      this.webSitesIsActive = result.is_active;
    } catch (error) {
      const { fullText } = extractError(error);

      if (error.response?.data?.blacklist?.length) {
        this.set('sitesErrorModalTitle', 'Измените базу сайтов');
        this.set('webSitesBlacklist', error.response?.data.blacklist);
        this.set(
          'sitesNotCountElements',
          error.response?.data.blacklist.length === 1 ? `Не был учтен сайт: ${error.response?.data.blacklist?.[0]}` : `Не были учтены сайты: ${reduceListToShow(error.response?.data.blacklist)}.`,
        );
      } else if (error.response?.data?.missed_value?.length) {
        this.set('sitesErrorModalTitle', 'Измените базу сайтов');
        this.set('webSitesBlacklist', error.response?.data?.missed_value);
        this.set(
          'sitesNotCountElements',
          error.response?.data?.missed_value.length === 1 ? `Не был учтен сайт: ${error.response?.data?.missed_value?.[0]}` : `Не были учтены сайты: ${reduceListToShow(error.response?.data?.missed_value)}.`,
        );
      } else if (fullText) {
        this.set('sitesErrorModalTitle', fullText);
      } else this.set('sitesErrorModalTitle', 'При загрузке файла произошла ошибка. Попробуйте другой файл.');
    } finally {
      this.set('loadingWebSitesFiles', false);
    }

    return true;
  };

  @action phoneHandleFilesAdded = async (file) => {
    this.set('loadingPhonesFiles', true);
    this.set('phonesBlacklist', []);

    const maxSize = 2000000;

    if (file.size > maxSize) {
      this.set('phonesErrorModalTitle', 'Размер файла превышает 2Мб');

      this.set('loadingPhonesFiles', false);
      return;
    }

    try {
      const result = await customSegmentLoadFileRequest({
        data: file,
        campaignId: NewCampaign.currentCampaign.id,
        segmentId: this.phonesId,
        event_depth: this.phonesDepth,
      });

      if (result.items?.length && result.missed_value?.length) {
        // this.set('warningModalTitle', 'Некоторые номера из вашего списка\n не будут учтены');
        this.set('phonesBlacklist', result.missed_value);
        this.set(
          'phonesNotCountElements',
          result.missed_value.length === 1 ? `Не был учтен номер: ${result.missed_value?.[0]}` : `Не были учтены номера: ${reduceListToShow(result.missed_value)}.`,
        );
      } else if (!result.items?.length && result.missed_value?.length) {
        this.set('phonesErrorModalTitle', 'Некоторые номера из вашего списка\n не будут учтены');
        this.set('phonesBlacklist', result.missed_value);
        this.set(
          'phonesNotCountElements',
          result.missed_value.length === 1 ? `Не был учтен номер: ${result.missed_value?.[0]}` : `Не были учтены номера: ${reduceListToShow(result.missed_value)}.`,
        );
      } else {
        this.set('phonesErrorModalTitle', '');
      }

      this.phonesFile = { ...result, size: file.size, file_name: file.name };
      this.set('phonesArray', result?.items);
      this.phonesIsActive = result.is_active;
    } catch (error) {
      const { fullText } = extractError(error);

      if (error.response?.data?.missed_value?.length) {
        this.set('phonesErrorModalTitle', 'Измените базу номеров');
        this.set('phonesBlacklist', error.response?.data?.missed_value);
        this.set(
          'phonesNotCountElements',
          error.response?.data?.missed_value.length === 1 ? `Не был учтен номер: ${error.response?.data?.missed_value?.[0]}` : `Не были учтены номера: ${reduceListToShow(error.response?.data?.missed_value)}.`,
        );
      } else if (fullText) {
        this.set('phonesErrorModalTitle', fullText);
      } else this.set('phonesErrorModalTitle', 'При загрузке файла произошла ошибка. Попробуйте другой файл.');
    } finally {
      this.set('loadingPhonesFiles', false);
    }
  };

  @action removeTriggerFile = async (customSegmentId) => {
    try {
      const newData = await composeAxiosDeleteRequest({
        url: `${getCampaignApiUrl(NewCampaign.currentCampaign.id)}custom_segment/${customSegmentId}/`,
      })();

      return newData;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  @action updateCustomSegmentInfo = async ({ type } = {}) => {
    try {
      await axiosAuthorizedRequest({
        method: 'POST',
        url: `/api/v1/client/campaigns/${NewCampaign.currentCampaign.id}/update_custom_segment/`,
      });

      if (type === 'sites') this.isWebSitesCalculating = true;
      else if (type === 'phones') this.isPhonesChanged = true;
    } catch (e) {
      if (type === 'sites') this.isWebSitesCalculating = false;
      this.intervalRequestAttempts = 100;
      NewCampaign.set('audienceError', extractError(e).fullText);
    }
  };

  @action updateWebSitesSegmentInfo = async () => {
    this.set('loadingFormWebSites', true);

    try {
      composeAxiosUpdateRequest({
        url: `${getCampaignApiUrl(NewCampaign.currentCampaign.id)}custom_segment/${this.webSitesId}/`,
      })({
        event_depth: this.webSitesDepth,
        data: {},
      });
    } catch (e) {
      this.set('sitesErrorModalTitle', 'Произошла ошибка');
      this.set('errorModalDescription', extractError(e).fullText);
    } finally {
      this.set('loadingFormWebSites', false);
    }
  };

  @action updatePhonesSegmentInfo = async () => {
    const callDirection = this.phonesCallDirection
      ? { [this.phonesCallDirection]: true }
      : { outgoing: true, incoming: true };

    this.set('loadingFormPhones', true);

    try {
      composeAxiosUpdateRequest({
        url: `${getCampaignApiUrl(NewCampaign.currentCampaign.id)}custom_segment/${this.phonesId}/`,
      })({
        event_depth: this.phonesDepth,
        data: {
          ...callDirection,
        },
      });
    } catch (e) {
      this.set('phoneErrorModalTitle', extractError(e).fullText);
    } finally {
      this.set('loadingFormPhones', false);
    }
  };

  @computed get isCustomSegmentActive() {
    return this.phonesIsActive || this.webSitesIsActive;
  }

  @computed get priceBySegment() {
    if (this.isCustomSegmentActive && NewCampaign.currentCampaign.currentOrder?.connectionType !== 2) {
      return 'custom_segment_cost';
    }
    return 'cost';
  }
}

const websAndPhonesTaxons = new WebsAndPhonesTaxons();
export default websAndPhonesTaxons;
