import React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

type Props = {
  to: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children?: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
}

const PrimaryLink: React.FC<Props> = ({
  children,
  to,
  onClick,
  className,
  isDisabled,
}) => {
  return (
    <Link
      to={to}
      className={
        classNames(
          styles.primaryComponent,
          styles.primaryLink,
          isDisabled && styles.disabled,
          className,
      )}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default PrimaryLink;
