import { useDragLayer } from 'react-dnd';
import type { XYCoord } from 'react-dnd';
import React, { useMemo } from 'react';
import {
  LinkBlock,
  PromoBlockVar,
  PromoBlock,
  DateBlock,
  DiscountBlock,
  AmountBlock,
  PhoneBlock,
  BonusBlock,
} from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/components';
import { ItemTypes, TypeIdBox } from './types';
import styles from './styles.pcss';


const BLOCKS = {
  link: LinkBlock,
  promo: PromoBlock,
  phone: PhoneBlock,
  discount: DiscountBlock,
  date: DateBlock,
  amount: AmountBlock,
  staticPromocode: PromoBlockVar,
  bonus: BonusBlock,
};

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const CustomDragLayer = () => {
  const { itemType, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  const CurrentBlock = useMemo(() => {
    if (item?.id) {
      return BLOCKS[item.id as TypeIdBox];
    }
    return null;
  }, [item?.id]);

  function renderItem() {
    switch (itemType) {
      case ItemTypes.BOX:
        return CurrentBlock && <CurrentBlock/>;
      default:
        return null;
    }
  }

  return (
    <div style={getItemStyles(initialOffset, currentOffset)} className={styles.custom}>
      {renderItem()}
    </div>
  );
};

export default CustomDragLayer;
