import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Button } from '@beeline/design-system-react';
import GreyContainerPopup from 'redesignSrc/UI/elements/GreyContainerPopup';
import StoreDnd from 'containers/ContainerDnD/StoreDnd';
import { PriceInput, SelectionInput } from 'redesignSrc/UI/form';
import { FormFieldLabel } from 'redesignSrc/UI/typography';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import DropdownButtonsList from 'redesignSrc/components/DropdownButtonsList';
import StoreT from '../../storeT';
import { optionRub } from './options';
import styles from './styles.pcss';


const initValue = (text?: string) => {
  if (!text) return null;
  const regArrValue = text.match(/\d+/);
  if (regArrValue) {
    return regArrValue[0];
  }
};

const initLabel = (text?: string) => {
  if (!text) return null;
  const regArrLabel = text.match(/(р\.|руб\.)/);
  if (regArrLabel) {
    const label = regArrLabel[0];
    return optionRub.find(el => el.label === label);
  }
};


const AmountBlock: React.FC = () => {
  const [value, setValue] = useState(initValue(StoreT.currentValueVariable.amount?.value) || '');
  const [param, setParam] = useState(initLabel(StoreT.currentValueVariable.amount?.value) || { label: 'р.', value: '0' });
  const [oddLoaded, setOddLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (oddLoaded) {
      if (value.length !== 0) {
        setIsValid(true);
        setError(null);
      } else {
        setIsValid(false);
      }
    }
    setOddLoaded(true);
  }, [value, param]);

  const handleSubmit = () => {
    if (StoreT.currentValueVariable.amount) {
      StoreT.setVariable(StoreT.currentValueVariable.amount.type, `${value} ${param.label}`);
      StoreT.currentValueVariable.amount = null;
    } else {
      StoreT.setVariable('amount', `${value} ${param.label}`);
    }
    StoreDnd.deleteBox('amount');
  };

  const handleOnBlur = () => {
    if (value.length === 0) {
      setError('Обязательное поле');
    }
  };
  return (
    <GreyContainerPopup
      title="Введите значение и мы добавим его в SMS"
      onClose={() => {
        StoreDnd.deleteBox('amount');
      }}
    >
      <div className={styles.content}>
        <FormFieldLabel>Сумма</FormFieldLabel>
        <div className={styles.wrapperInput}>
          <PriceInput
            className={styles.input}
            placeholder="Введите сумму"
            format={false}
            value={value}
            onChange={(e) => {
              setValue(e);
            }}
            onBlur={handleOnBlur}
          />
          <SelectionInput
            classNameContainer={styles.inputSelect}
            value={param.label}
          >
            <DropdownButtonsList
              items={optionRub}
              onItemClick={(e) => setParam(e)}
              activeItemValue={param.value}
            />
          </SelectionInput>
        </div>
        {error && <div className={styles.error}>{error}</div>}

        <div className={styles.buttonContainer}>
          <Button
            variant="overlay"
            size="medium"
            disabled={ !isValid }
            onClick={handleSubmit}
          >
            {StoreT.currentValueVariable.amount ? 'Изменить сумму' : 'Добавить в текст'}
          </Button>
          {/* <PrimaryButton
            isGray={!isValid}
            isDisabled={!isValid}
            onClick={handleSubmit}
          >
            {StoreT.currentValueVariable.amount ? 'Изменить сумму' : 'Добавить в текст'}
          </PrimaryButton> */}
        </div>

      </div>

    </GreyContainerPopup>
  );
};

export default observer(AmountBlock);
