import React, { FC } from 'react';

import { Button } from '@beeline/design-system-react';

import { WhiteBtn } from 'redesignSrc/UI/beeline/btns/WhiteBtn/WhiteBtn';
import styles from '../styles.pcss';

interface NextBtnProps {
  className?: string;
  id?: string;
  text?: string;
  backgroundWhite?: boolean;
  disabled?: boolean,
  onClick?: () => void,
  type?: 'button' | 'submit' | 'reset'
}

export const NextBtn: FC<NextBtnProps> = ({ type, children, text = 'Продолжить', disabled = false, backgroundWhite = false, ...props }) => {
  if (backgroundWhite) {
    return <WhiteBtn {...props} disabled={disabled}>{children}</WhiteBtn>;
  }
  return <Button type={type} {...props} disabled={disabled} size="medium" variant="contained" className={styles.nextBtn}>{text || children}</Button>;
};
