import React from 'react';
import classNames from 'classnames/bind';
import { Link } from '@reach/router';
import Dropdown from 'redesignSrc/UI/elements/Dropdown';
import OutsideClickTracker from 'redesignSrc/UI/elements/OutsideClickTracker/OutsideClickTracker';
import CheckMark from '../../UI/elements/CheckMark';
import styles from './styles.pcss';

export interface Item {
  href: string;
  label: string;
  hasClickHandler?: boolean;
}

type Props = {
  className?: string;
  dropDownClass?: string,
  // eslint-disable-next-line no-unused-vars
  onClose: any;
  isOpen: boolean;
  children: React.ReactNode,
}

const DropdownLinks: React.FC<Props> = ({
  dropDownClass,
  className,
  children,
  isOpen,
  onClose,
}) => {
  return (
    <OutsideClickTracker onClickOutside={(e: any) => {
      // isOpen ? onClose : null;
      if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'svg' && e.target.tagName !== 'path') onClose && onClose();
    }}>
      <Dropdown
        className={dropDownClass}
      >
        <ul className={classNames(styles.elementsList, className)}>
          {children}
        </ul>
      </Dropdown>
    </OutsideClickTracker>
  );
};

type PropsLinkedList = {
  className?: string;
  items: Item[];
  // eslint-disable-next-line no-unused-vars
  onItemClick?: ((item: Item, e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void);
  onClose?: () => void;
}

const LinkedList = ({ items, onItemClick, className, onClose }:PropsLinkedList) => {
  return (
    <>
      {
        items.map(item => {
          return (
            <li key={item.label} className={className}>
              <Link
                to={item.href}
                className={styles.elementLink}
                onClick={(e) => {
                  onItemClick ? onItemClick(item, e) : e;
                  onClose && onClose();
                }
              }
            >
                {item!.label}
              </Link>
            </li>
          );
        })
      }
    </>
  );
};

export type PropsCheckBoxList = {
  items: Array<{label: string, value: number | string, isChecked: boolean}>|[],
  onItemClick: (param: number | string) => void,
  activeItemIndex?: number,
  className?: string,
}

const CheckBoxList = ({ items, onItemClick, activeItemIndex }:PropsCheckBoxList) => {
  return (
    <>
      {items.map((item, index) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            key={item.label}
            className={classNames(styles.listElement, activeItemIndex === index && styles.active)}
            onClick={(e) => {
              onItemClick(item.value);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CheckMark
              value={item.isChecked}
            >
              <span className={styles.element}>{item.label}</span>
            </CheckMark>
          </li>
        );
      })}
    </>
  );
};

export { LinkedList, CheckBoxList };
export default DropdownLinks;
