import { toast } from 'react-toastify';
import React from 'react';
import ImgError from './assets/notOk.svg';
import ImgAccess from './assets/ok.svg';
import Warning from './assets/warning.svg';
import styles from './styles.pcss';

const Abc = ({ children, isAccess }) => {
  return (
    <div className={styles.container}>
      {isAccess ? <ImgAccess/> : <ImgError/>}
      {children}
    </div>
  );
};

export const toastErrorNotification = (errorMessage) => {
  toast.error(<Abc>{errorMessage}</Abc>, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const toastAccessNotification = (errorMessage) => {
  toast.error(<Abc isAccess>{errorMessage}</Abc>, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const toastWarningNotification = (errorMessage) => {
  toast.error(<div className={styles.container}><Warning/>{errorMessage}</div>, {
    position: 'top-right',
    autoClose: 7000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
