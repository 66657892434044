import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FAB, Tooltip } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { navigate } from '@reach/router';
import ContractSelectionStore from '../ContractSelection1/ContractSelectionStore';
import styles from './styles.pcss';


type ContentPopoverType = { mail: string, name: string } | string | null

const ManagerInfo = () => {
  const [contentPopover, setContentPopover] = useState<ContentPopoverType>(null);
  // TODO Нужно упростить
  useEffect(() => {
    let currentContentPopover = null;
    let firstMail: any = null;
    if (ContractSelectionStore.bans.length && ContractSelectionStore.bans[0]?.email) {
      firstMail = ContractSelectionStore.bans[0].email;
      ContractSelectionStore.bans.forEach(el => {
        if (firstMail !== el.email) {
          currentContentPopover = 'Связаться с вашим менеджером';
        }
      });
      if (currentContentPopover === null && ContractSelectionStore.bans.length) {
        currentContentPopover = {
          mail: ContractSelectionStore.bans[0].email || '',
          name: ContractSelectionStore.bans[0].managerName || '',
        };
      }
    }
    setContentPopover(currentContentPopover);
  }, [ContractSelectionStore.bans.length]);

  if (!contentPopover) return null;

  const bodyRender = () => {
    if (typeof contentPopover === 'string') {
      return <span className={styles.font1}>{contentPopover}</span>;
    } else if (contentPopover) {
      return (
        <>
          <span className={styles.font1}>{contentPopover.name}</span><br/>
          <a className={styles.font1} href={`mailto:${contentPopover.mail}`}>{contentPopover.mail}</a><br/><br/>
          <span className={styles.font2}>Ваш персональный менеджер</span>
        </>
      );
    }
  };
  return (

    <div className={styles.wrapper}>
      <Tooltip
        title={bodyRender()}
        popoverRootClassName={styles.tooltip}
      >
        <FAB
          iconName={typeof contentPopover === 'string' ? Icons.HeadsetHelp : Icons.Phone}
          type="standard"
          className={styles.button}
          onClick={() => {
            if (typeof contentPopover === 'string') {
              navigate('/user-info/bans');
            } else {
              const link = document.createElement('a');
              link.href = `mailto:${contentPopover.mail}`;
              link.click();
            }
          }}
        />
      </Tooltip>
    </div>
  );
};

export default observer(ManagerInfo);
