import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import styles from './styles.pcss';

export interface OptionsItem {
  value: string | undefined;
  label: string | React.ReactNode;
  className?: string,
}

interface IProps {
  value?: string,
  className?: string,
  onChange: (n: string, v: string | undefined) => void,
  name: string,
  options: OptionsItem[],
}

const StyledContainer = styled.div`
  &:before {
    position: absolute;
    top: -1px;
    left: calc((100% /${props => {
    // @ts-ignore
    return props.elementsCount;
  }}) * ${props => {
  // @ts-ignore
  if (props.activeIndex === 0) return (`${props.activeIndex} - 1px`);
  // @ts-ignore
  if (props.elementsCount - 1 === props.activeIndex) return (`${props.activeIndex} + 1.5px`);
  // @ts-ignore
  return props.activeIndex;
}});
    content: '';
    display: block;
    background-color: #E4E4E4;
    width: calc(100% /${props => {
    // @ts-ignore
    return props.elementsCount;
  }});
    height: 105%;
    border-radius: 8px;
    transition: left 0.3s;
  }
`;

const TileRadioGroup = ({
  className = '',
  onChange,
  name,
  value,
  options,
}: IProps) => {
  const [curValue, setCurValue] = useState<string|undefined>(value);

  const handleChange = (nameVal: string, item: OptionsItem) => {
    setCurValue(item.value);

    onChange(nameVal, item.value);
  };

  useEffect(() => {
    setCurValue(value);
  }, [value]);

  return (
    <StyledContainer
      className={classNames(styles.container, className)}
      id={name}
      // @ts-ignore
      activeIndex={options.findIndex((item) => item.value === curValue)}
      elementsCount={options.length}
    >
      {options.map((item, index) => {
        const itemId = `${Math.random()}_${Math.random()}`;
        return (
          <label
            className={classNames(
              styles.label,
              item.className,
              index === 0 && styles.first,
              index === options?.length - 1 && styles.last,
            )}
            htmlFor={itemId}
            key={itemId}
          >
            <input
              type="radio"
              onChange={() => handleChange(name, item)}
              className={styles.radioInput}
              name={name}
              id={itemId}
              checked={item.value === curValue}
            />

            {item.label}
          </label>
        );
      })}
    </StyledContainer>
  );
};

export default TileRadioGroup;
