import { observable, action, runInAction, computed, reaction } from 'mobx';
import { debounce } from 'debounce';
import axios from 'axios';
import { adTechRequest, axiosBaseRequestAdTech } from '../../requests/helpers';

class Authorization {
  @observable isLoggedIn = true;
  @observable email = ''
  @observable timeLeft = 0;
  @observable screen = 'phone';
  @observable isErrorPhone = false
  @observable phone = '';
  @observable showEmail = false;
  @observable phoneOnCheck = false;
  @observable isPhoneExists = false;
  @observable isError429 = false;
  @observable textError429 = ''


  @action setErrorPhone = value => {
    this.isErrorPhone = value;
  }

  @action resetError = () => {
    this.setErrorPhone(false);
  }

  @action setScreen = (screen = 'phone') => {
    this.screen = screen;
  }

  @action setPhone = phone => {
    this.phone = phone;
  }

  registration = async ({ phone, code, email }) => {
    try {
      await axiosBaseRequestAdTech({
        url: '/api/user/registration',
        method: 'POST',
        data: {
          phone: `+${phone}`,
          email,
          code,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  phoneConfirmation = async ({ phone, code, email }) => {
    try {
      const response = await axiosBaseRequestAdTech({
        url: '/api/user/phone_confirmation',
        method: 'POST',
        data: {
          phone: `+${phone}`,
          email: email ?? undefined,
          code,
        },
      });
      if (response?.timeLeft) {
        runInAction(() => {
          this.timeLeft = response?.timeLeft;
        });
      }
      this.screen = 'sms';
      this.isError429 = false;
      this.textError429 = '';
      return response;
    } catch (e) {
      if (e?.response?.status === 429) {
        this.textError429 = e?.response.data;
        this.isError429 = true;
        return e;
      }
      // Когда телефона нет
      if (e?.response?.status === 401) {
        this.showEmail = true;
        return e;
      }

      if (e?.response?.status === 403) {
        console.error(e?.response.data);
        return e;
      }

      // Когда телефон есть
      if (e?.response?.status === 400 && e?.response?.data?.timeLeft && !code && e.response.data.isPhoneExists === true) {
        this.isPhoneExists = e.response.data.isPhoneExists;
        this.timeLeft = e.response.data.timeLeft;
        this.showEmail = false;
        this.screen = 'sms';
        return e;
      }
      throw e;
    }
  };

  @action setUnauthorized = () => {
    this.isLoggedIn = false;
  }


  @computed get checkIsPhoneCorrect() {
    const clearedPhone = this.phone.replace(/\D/g, '');
    if (clearedPhone[0] !== '7' || clearedPhone[1] !== '9' || clearedPhone.length !== 11) return false;
    return true;
  }
}

const authorizationStore = new Authorization();

export default authorizationStore;
