import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { toJS } from 'mobx';
import OutsideClickTracker from 'redesignSrc/UI/elements/OutsideClickTracker/OutsideClickTracker';
import { PureButton } from 'redesignSrc/UI/buttons';
import NewOrder from 'store/mobx/NewOrder';
import ShortenerText from 'redesignSrc/UI/elements/ShortenerText';
import TooltipInfo from 'redesignSrc/UI/elements/TooltipInfo';
import StoreDnd from 'containers/ContainerDnD/StoreDnd';
import SaveTemplateStore from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SaveTemplate/SaveTemplateStore';
import { dateFormatChips, findTypeIdFromDecoratedText } from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/utils/utils';
import StoreT from '../../storeT';
import { PropsLinkTemplate } from '../../types';

import IconDrag from './assets/DragIcon.svg';
import IconDelete from './assets/IconDelete.svg';
import IconEdit from './assets/EditIcon.svg';
import styles from './styles.pcss';

// Этот компонент пока закоменчен в routes, пока не определились нужен ли
export const MenuTooltip = observer(() => {
  const onEdit = () => {
    StoreT.isOpenPopupPromo = true;
    StoreT.isOpenMenuPromo = false;
  };

  const onDelete = () => {
    NewOrder.smsCampaign.isParameterized = false;
    StoreT.isOpenMenuPromo = false;
  };

  return (
    <>
      {StoreT.isOpenMenuPromo &&
        <OutsideClickTracker
          className={styles.wrapMenu}
          style={{ top: StoreT.posXYPromo.y, left: StoreT.posXYPromo.x }}
          onClickOutside={() => {
            StoreT.isOpenMenuPromo = false;
          }}
        >
          <div className={styles.wrapperButton}>
            <PureButton
              className={styles.editButton}
              onClick={onEdit}
            >
              Изменить
            </PureButton>
            <PureButton
              className={styles.deleteButton}
              onClick={onDelete}
            >
              Удалить
            </PureButton>
          </div>

        </OutsideClickTracker>
      }
    </>
  );
});

export const Promo = observer((props:any) => {
  const isTemplate = window.location.href.includes('new-template');
  const isEdit = !isTemplate && NewOrder.smsCampaign.templateId && SaveTemplateStore.template?.status === 'Accepted';
  const handleStartDrop = (event:any) => {
    StoreT.blockDrag = 'promo';
    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.setData(
      'text',
      `DRAFTJS_BLOCK_KEY:${props.blockKey}`,
    );
  };

  const onDrop = (e:React.DragEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onClick = (e:MouseEvent) => {
    // Подгоняем координаты для MenuTooltip относительно общей страницы
    // const maxLeft = 556;
    // const curClientX = e.clientX < maxLeft ? maxLeft : e.clientX;
    // const xCoord = curClientX - 85;
    // const yCoord = e.clientY + window.scrollY + 20;
    // StoreT.posXYPromo = { x: xCoord, y: yCoord };
    // StoreT.isOpenMenuPromo = true;

    if (isTemplate) {
      if (StoreT.variables.has('staticPromocode')) {
        StoreDnd.setBox('staticPromocode');
      }
    } else {
      StoreT.isOpenPopupPromo = true;
    }
  };

  const onDelete = (e:MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    StoreT.deleteVariable('dynamicPromocode');
  };

  return (
    <span
      onDragStart={handleStartDrop}
      onDrop={onDrop}
      draggable
      contentEditable="false"
      className={classNames(styles.link1, StoreT.errorPromo && styles.error)}
      onClick={onClick}
    >
      <div className={styles.content}>
        {!isEdit && <IconDrag className={styles.iconDrag}/>}
        <span className={styles.linkTemplate3}>Ваш промокод</span>
        {isTemplate && <IconDelete className={styles.iconEdit} onClick={(e) => onDelete(e)}/>}
        {isEdit && <IconEdit className={styles.iconEdit} onClick={onClick}/>}
      </div>
    </span>
  );
});

export const LinkTemplate:React.FC<PropsLinkTemplate> = observer(({
  typeTemplate,
  children,
  info,
  decoratedText,
}) => {
  const isEdit = !window.location.href.includes('new-template') && NewOrder.smsCampaign.templateId && SaveTemplateStore.template?.status === 'Accepted';
  let text = '';
  let typeCurrent:string | null = null;
  let isError = false;

  const initDataTemplate = (type:string) => {
    text = StoreT.variables.get(type) || '';
    typeCurrent = type || null;
    // Сверяем дату в переменной с датой старта кампании
    if (typeCurrent?.includes('date')) {
      const date = dateFormatChips(text);
      if (date && NewOrder.currentOrder?.startDate) {
        isError = new Date(NewOrder.currentOrder?.startDate) >= date;
      }
    }
  };

  const handleStartDrop = (event:any) => {
    StoreT.blockDrag = typeTemplate || typeCurrent;
    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.setData(
      'text',
      `DRAFTJS_BLOCK_KEY:${typeTemplate || typeCurrent}`,
    );
  };

  if (decoratedText) {
    const { id, type } = findTypeIdFromDecoratedText(decoratedText);
    if (type && id) {
      initDataTemplate(`${type}${id}`);
    } else if (type) {
      initDataTemplate(type);
    }
  }

  const onDelete = (e:MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (decoratedText) {
      const { id, type } = findTypeIdFromDecoratedText(decoratedText);
      if (id && type) {
        StoreT.deleteVariable(`${type}${id}`);
      } else if (type) {
        StoreT.deleteVariable(type);
      }
    }
  };


  const onDrop = (e:React.DragEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onClick = () => {
    if (decoratedText) {
      const { type } = findTypeIdFromDecoratedText(decoratedText);
      if (typeCurrent && type && !StoreDnd.includesId(type)) {
        StoreT.currentValueVariable[type] = { type: typeCurrent, value: text };
        StoreDnd.setBox(type);
      }
    }
    if (typeTemplate) {
      if (!StoreDnd.includesId(typeTemplate)) {
        StoreDnd.setBox(typeTemplate);
      }
    }
  };

  return (
    <span
      onDragStart={handleStartDrop}
      onDrop={onDrop}
      draggable
      contentEditable="false"
      className={classNames(styles.link1, isError && styles.error)}
      onClick={onClick}
    >
      <div className={styles.content}>
        {!isEdit && <IconDrag className={styles.iconDrag}/>}
        <span className={styles.linkTemplate3}>{text || children}</span>
        {info && <TooltipInfo>{info}</TooltipInfo>}
        {text && (isEdit ?
          <IconEdit className={styles.iconEdit} onClick={onClick}/> :
          <IconDelete className={styles.iconEdit} onClick={(e) => onDelete(e)}/>)}
      </div>
    </span>
  );
});

export const Link = observer((props:any) => {
  if ((StoreT.linkValue.dynamicPartLink && !window.location.href.includes('new-template')) || NewOrder.order?.smsCampaign?.links.dynamicPartLink) {
    // @ts-ignore
    NewOrder.order?.smsCampaign?.links.dynamicPartLink = StoreT.linkValue.dynamicPartLink || NewOrder.order?.smsCampaign?.links.dynamicPartLink || '';
  }
  const isDisable =
    !window.location.href.includes('new-template') &&
    NewOrder.smsCampaign.templateId &&
    SaveTemplateStore.template?.status === 'Accepted';
  const canEdit = SaveTemplateStore.template?.link.dynamicPartLink || NewOrder.order?.smsCampaign?.links.dynamicPartLink || StoreT.linkValue.dynamicPartLink;
  const textLink = StoreT.linkValue.isShort ? StoreT.linkValue.shortLink : StoreT.linkValue.link;
  const handleStartDrop = (event:any) => {
    StoreT.blockDrag = 'link';
    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.setData(
      'text',
      `DRAFTJS_BLOCK_KEY:${props.blockKey}`,
    );
  };

  const onDrop = (e:React.DragEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onClick = async (e:MouseEvent) => {
    NewOrder?.order?.id && NewOrder.getOrder(NewOrder?.order?.id || 0);
    StoreT.isOpenPopupLink = true;
    if (StoreT.linkValue.dynamicPartLink || NewOrder.order?.smsCampaign?.links.dynamicPartLink) {
      StoreT.timeDop = StoreT.linkValue?.dynamicPartLink || NewOrder.order?.smsCampaign?.links.dynamicPartLink || '';
      StoreT.isOpenDynamicLink = true;
    } else {
      StoreT.timeDop = '';
      StoreT.isOpenDynamicLink = false;
    }
  };

  return (
    <span
      onDrop={onDrop}
      onDragStart={handleStartDrop}
      draggable
      contentEditable="false"
      className={classNames(styles.link1, (isDisable && !canEdit) && styles.disable, canEdit && styles.editable)}
      onClick={onClick}
    >
      {!isDisable && <IconDrag className={styles.iconDrag}/>}
      {textLink && <ShortenerText
        maxSymbol={23}
        text={textLink}
        notTooltip
      />}
    </span>
  );
});

export const PhoneNotValid = (props:any) => {
  const { decoratedText, children } = props;
  return (
    <span className={styles.phone}>
      {children}
    </span>
  );
};

export const MoreThanMaxSizePushTextValid = (props:any) => {
  const { decoratedText, children } = props;
  return (
    <span className={styles.textLengtMoreThanMaxLength}>
      {children}
    </span>
  );
};

export const cautionWordValid = (props:any) => {
  const { decoratedText, children } = props;
  return (
    <span className={styles.caution}>
      {children}
    </span>
  );
};
