import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import IMask from 'imask';
import TextInput from '../TextInput/components/TextInput';

const InputMaskedField = (props) => {
  const { mask, onChange, isDate } = props;
  const input = useRef();
  let Imask;
  useEffect(() => {
    if (input.current && !Imask) {
      Imask = IMask(input.current, {
        mask: isDate ? Date : mask,
      });
      Imask.on('accept', () => {
        onChange(Imask.value);
      });
    }
  }, [Imask]);

  useEffect(() => {
    return () => {
      Imask && Imask.off('accept');
    };
  }, []);

  return (
    <>
      <TextInput type="text" {...props} ref={input} />
    </>
  );
};

InputMaskedField.propTypes = {
  onChange: PropTypes.func,
  mask: PropTypes.string,
  isDate: PropTypes.bool,
};

export default InputMaskedField;
