import { action, observable } from 'mobx';
// @ts-ignore
import mimetype from 'mimetype';
import { toastErrorNotification } from '../../../../modules/toast-notifications';
import { axiosBaseRequestAdTech, prepareMultipartFormData } from '../../../../requests/helpers';
import NewOrder from '../../../../store/mobx/NewOrder';

class SmsLocalStore {
  @observable isChooseTemplateOpened = false;
  setChooseTemplate = (value: boolean) => {
    this.isChooseTemplateOpened = value;
  };
  @observable isListNamesOpened = false;
  setListNamesOpened = (value: boolean) => {
    this.isListNamesOpened = value;
  };
  @observable isAddNameVisible = false;
  showAddName = (value: boolean) => {
    this.isAddNameVisible = value;
  };
  @observable isEditNameVisible = false;
  setEditNameVisible = (value: boolean) => {
    this.isEditNameVisible = value;
  }
  @observable isOperatorsSwitched:number|null = null;

  @observable isLoading = false
  @observable files: FileList[] = [];
  @observable errorOnFileUploading: string | null = null;
  @observable filesProgress:{[key: string]: number}|null = null
  @action resetState = () => {
    this.files = [];
    this.errorOnFileUploading = null;
  }

  private isAllowMimeType = (file: File) => {
    const mapMimeType = ['image/jpeg', 'image/png', 'application/pdf', 'application/jpg', 'application/x-zip-compressed', 'application/x-rar-compressed', 'application/zip'];
    return mapMimeType.includes(mimetype.lookup(file.name));
  }

  @action uploadFile = async (file: File) => {
    if (!this.isAllowMimeType(file)) {
      toastErrorNotification('Неверный формат файла');
      return;
    }

    try {
      if (!file.size) {
        this.errorOnFileUploading = 'Нельзя загрузить пустой файл';
        return;
      }

      this.isLoading = true;

      await axiosBaseRequestAdTech({
        url: `api/campaign/${NewOrder.smsCampaign.campaignId}/file?fileType=4`,
        method: 'POST',
        data: prepareMultipartFormData({
          File: file,
        }),
      });

      await this.getExistingFiles();
      this.errorOnFileUploading = null;
    } catch (e) {
      if (e?.response?.data?.file) {
        // @ts-ignore
        this.errorOnFileUploading = e?.response?.data?.file[0];
      } else {
        // @ts-ignore
        this.errorOnFileUploading = e;
      }
    } finally {
      this.isLoading = false;
    }
  }

  @action getExistingFiles = async () => {
    this.files = await axiosBaseRequestAdTech({
      url: `api/campaign/${NewOrder.smsCampaign.campaignId}/files?fileType=4`,
    });
  }

  @action handleFileRemove = async (id: number) => {
    try {
      await axiosBaseRequestAdTech({
        url: `api/campaign/${NewOrder.smsCampaign.campaignId}/file/${id}`,
        method: 'DELETE',
      });
      this.files = this.files?.filter((file: any) => file.id !== id);
    } catch (e) {
      console.error(e);
    }
  };
}

const smsLocalStore = new SmsLocalStore();
export default smsLocalStore;
