import { LegacyUInfo, UInfo } from '../TSMS/UserInfo';

export const legacyUserInfo = (userInfoObj: UInfo): LegacyUInfo => {
  return {
    appType: userInfoObj?.appType,
    id: userInfoObj?.id,
    email: userInfoObj?.email,
    username: userInfoObj?.name,
    first_name: userInfoObj?.firstName,
    last_name: userInfoObj?.lastName,
    middle_name: userInfoObj?.middleName,
    phone: userInfoObj?.name,
    parentId: userInfoObj?.parentId,
    personalInfo: userInfoObj.personalInfo,
    company: {
      companyId: userInfoObj?.customerInfo?.companyId,
      id: userInfoObj?.customerInfo?.id,
      name: userInfoObj?.customerInfo?.name,
      website: userInfoObj?.customerInfo?.website,
      social_vkontakte: userInfoObj?.customerInfo?.socialVkontakte,
      inn: userInfoObj?.customerInfo?.inn,
      ban: userInfoObj?.customerInfo?.ban,
      post_pay: userInfoObj?.customerInfo?.isPostPay,
      industry: userInfoObj?.customerInfo?.industryBeePro,
      isSelfEmployee: userInfoObj?.customerInfo?.isSelfEmployee,
      edoName: userInfoObj?.customerInfo?.edoName,
      edoId: userInfoObj?.customerInfo?.edoId,
      type: userInfoObj.customerInfo?.type,
      kpp: userInfoObj.customerInfo?.kpp,
      ogrn: userInfoObj.customerInfo?.ogrn,
    },
    supervisor: userInfoObj?.supervisor,
    late_payment: false, // это надо брать на бэке
    type: userInfoObj?.userType,
    offer_accepted_date: userInfoObj?.offerAcceptedDate,
    userType: userInfoObj?.userType,
    isSbpAllowed: userInfoObj?.isSbpAllowed,
  };
};
