import { ARRAY_ERROR } from 'final-form';
import moment from 'moment';
import { toJS } from 'mobx';
import { format } from 'date-fns';
import { linkify, dateFormatChips } from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/utils/utils';
import NewOrder from 'store/mobx/NewOrder';
import messagesChainLocalStore from 'redesignSrc/pages/PushPages/PushPageSetup/components/PushMessages/store';
import messageTemplatesStore from '../store/mobx/MessageTemplates';

import { passAsIs } from './fn';

export const urlRegVK = /vk(\.com|.me|\.ru)/i;
export const urlRegOK = /ok(\.me|\.ru)/i;
// eslint-disable-next-line no-useless-escape,max-len
export const urlRegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zа-яё0-9]+([\-\.]{1}[a-zа-яё0-9]+)*\.[a-za-ё]{2,}(:[0-9]{1,})?(\/.*)?$/i;
const regEmoji = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug;

export const urlGooglePlayRegExp = new RegExp('^(https?:\\/\\/)?play.google.com(\\/.*)?$');
const urlAppStoreRegExp = new RegExp('^(https?:\\/\\/)?www.apple.com(\\/.*)?$');
export const phoneRegExp = /((\+?7)|(8))-? ?\(?\d\d\d\)?-? ?\d\d\d-? ?\d\d-? ?\d\d,?\.?;?!?\??/ig;
export const validPhoneRegExp = /((\+?7)|(8))\d\d\d\d\d\d\d\d\d\d/ig;


export const notValidPhoneNumber = (text) => {
  const numbers = text.match(phoneRegExp);
  if (numbers) {
    const notValidNumber = numbers.filter(el => {
      return !el.match(validPhoneRegExp) ||
        el[el.length - 1].includes(',') ||
        el[el.length - 1].includes('.') ||
        el[el.length - 1].includes(';') ||
        el[el.length - 1].includes('!') ||
        el[el.length - 1].includes('?');
    });
    if (notValidNumber.length === 0) return null;
    return notValidNumber;
  }
  return null;
};

/**
 * Function used to inject validators into form controlled by redux-forms
 * @param getValidators {function} - a function returning dict of field to validators
 * @returns {function(values)} - takes values and returns large object with errors for every separate validator
 *
 * Iterate fields that have validators and each validator individually
 * gather field errors in a large object and return it
 */
export const composeFormValidator = getValidators =>
  (values) => {
    values = values || {};
    const validators = getValidators();

    return Object.keys(validators).reduce((errors, name) => {
      let fieldValidatorsList = validators[name] || [];

      if (typeof validators[name] === 'function') {
        fieldValidatorsList = [fieldValidatorsList];
      }

      fieldValidatorsList.some((fieldValidator) => {
        const fieldError = fieldValidator(values[name], values);

        if (fieldError) {
          errors[name] = fieldError;
          return true;
        }
        return false;
      });

      return errors;
    }, {});
  };

const checkIsStringError = (val, message) => {
  return (val && ( // value should not be empty
    typeof val !== 'string' || // also it should either be not string
    val.replace(/^\s*(.*?)\s*$/, '$1').length // or not just spaces
  ) ? undefined : message);
};

export const composeRequiredValidator = (message, valueGetter = passAsIs) => (value) => {
  const val = valueGetter(value);
  return checkIsStringError(val, message);
};

/**
 * Валидирует текст на наличие более одной ссылки в СМС поле
 */
export const composeLinkValidator = (message) => (value) => {
  const links = linkify.match(value)?.filter(({ raw }) => raw !== 'https://www');
  if (links && links.length > 1) {
    return message;
  }
  return undefined;
};

export const composePhoneNotValid = (message) => (value) => {
  const currentText = Array.isArray(toJS(value)) ? value.toString().replaceAll(',', ' ') : value;
  const notValidNumber = notValidPhoneNumber(currentText);
  if (notValidNumber) {
    return message;
  }
  return undefined;
};

/**
 * Валидирует текст на наличие более max Emoji в push
 */
export const composePushEmojiMax = (message, max) => (value) => {
  const currentText = Array.isArray(toJS(value)) ? value.toString().replaceAll(',', ' ') : value;
  if (currentText && typeof currentText === 'string') {
    if (currentText.match(regEmoji) && currentText.match(regEmoji).length > max) {
      return message;
    }
  }
  return undefined;
};

/**
 * Валидирует текст на наличие более max Emoji в смс
 */
export const composeEmojiMax = (message, max) => (value) => {
  if (value && typeof value === 'string') {
    if (value.match(regEmoji) && value.match(regEmoji).length > max) {
      return message;
    }
  }
  return undefined;
};


export const composeTextLengthMoreThanMaxSizeNotValid = (message) => () => {
  if (messagesChainLocalStore.isTextLengthMoreThanMaxLingthValid) {
    return message;
  }
  return undefined;
};

export const composeCitiesBillboards = (message) => (value) => {
  if (value.length < 1) {
    return message;
  }
  return undefined;
};

export const composeBillboards = (message) => (value) => {
  if (value.length >= 1 && value.length < 5) {
    return message;
  }
  return undefined;
};

export const composeBanners = (message) => () => {
  if (NewOrder.digitalBillboardDetails.bannerText.length < 1 && NewOrder.digitalBillboardDetails.bannerFiles.length < 1) {
    return message;
  }
  return undefined;
};

export const composeBanners2 = (message) => () => {
  if (NewOrder.resolutionsFileLoaded.length < 1 && NewOrder.bannerText < 1) {
    return message;
  }
  return undefined;
};

export const composeStopBadWordsNotValid = (message) => (value) => {
  if (NewOrder.intolerantWords.stopWords.length === 0) return undefined;
  const currentText = Array.isArray(toJS(value)) ? value.toString().replaceAll(',', ' ') : value;
  const sample = NewOrder.intolerantWords.stopWords.toString().replaceAll(',', '|');
  const regExp = new RegExp(sample, 'ig');
  const matchArr = currentText.match(regExp);
  if (matchArr !== null) return message;
  return undefined;
};

export const composeStopBadWordsNotValidForTemplate = (message) => (value) => {
  if (messageTemplatesStore.intolerantWords.stopWords.length === 0) return undefined;
  const currentText = Array.isArray(toJS(value)) ? value.toString().replaceAll(',', ' ') : value;
  const sample = messageTemplatesStore.intolerantWords.stopWords.toString().replaceAll(',', '|');
  const regExp = new RegExp(sample, 'ig');
  const matchArr = currentText.match(regExp);
  if (matchArr !== null) return message;
  return undefined;
};

export const composeSignAfterDotValidator = (message, maxSigns) => (value) => {
  if (typeof value === 'string' && value.split('.').length && value.split('.')[1] && value.split('.')[1].length - 1 >= maxSigns) return message;
  return undefined;
};

export const composeCountPollsRequiredValidator = (min, max) => (value) => {
  if (Number(value) < Number(min)) return `Допустимые значения от ${min} до ${max}`;
  if (Number(value) > Number(max)) {
    return [`Допустимые значения от ${min} до ${max}`, 'Чтобы увеличить количество анкет измените параметры аудитории'];
  }
  return undefined;
};

export const composeDateRequiredValidator = messages => (value) => {
  if (!value) {
    return messages[0];
  }
  const isError1 = checkIsStringError(value[0], messages[0]);
  const isError2 = checkIsStringError(value[1], messages[1]);

  return isError1 || isError2;
};

export const composeStartDateValidator = (message, minStart) => (value) => {
  const date = () => {
    const jsValue = toJS(value);
    if (Array.isArray(jsValue)) {
      if (moment.isMoment(jsValue[0])) return jsValue[0];
      return moment(jsValue[0]);
    }
    if (moment.isMoment(jsValue)) return jsValue;
    return moment(jsValue);
  };
  if (date().isBefore(moment(minStart).startOf('day'))) {
    return message;
  }
  return undefined;
};

export const composeRequiredIsBool = (message, isParam) => (value) => {
  return value && isParam ? message : undefined;
};

export const composeStartDateValidatorVariable = (variables, value) => () => {
  if (!value) return undefined;
  for (const [key, val] of variables.entries()) {
    if (key.includes('date')) {
      const date = dateFormatChips(val);
      if (new Date(value) >= date) {
        return `Дата в тексте сообщения не должна быть ранее ${format(new Date(NewOrder.currentOrder?.startDate || ''), 'dd.MM.yy')}`;
      }
    }
  }
  return undefined;
};

export const composePhoneValidator = message => value =>
  /** validator does not make field required */
  (!/^\+79[0-9]{9}$/.test(value) ? message : undefined);

export const composePhoneValidator2 = message => value =>
  /** validator does not make field required */
  (!/^9[0-9]{9}$/.test(value) ? value && message : undefined);

export const composeEmailValidator = message => value =>
  /** validator does not make field required */
  // eslint-disable-next-line no-useless-escape
  (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ? message : undefined);


export const composeSlugValidator = message => (value) => {
  /** validator does not make field required */
  if (!value || value.length === 0) return undefined;
  if (value.replace(/[^a-zA-Zа-яА-ЯёЁ]/g, '').length === 0) return message;
  return undefined;
};

export const composeNumberValidator = (message, { min, max = null }) => (value) => {
  /** validator does not make field required */
  const number = Number(value);
  if (isNaN(number)) return undefined;
  if (min !== null && number < min || max !== null && number > max) return message;
  return undefined;
};

export const composeOnlyNumberValidator = ({ typeMessage, sizeMessage }, { min = 0, max = null }) => (value) => {
  /** validator does not make field required */
  const number = +value;
  if (isNaN(number)) return typeMessage;
  if (number < min || max && number > max) return sizeMessage;
  return undefined;
};

export const composeActivityValidator = (message, connectionType) => (value) => {
  if (connectionType === 'Custom') {
    return undefined;
  }
  if (value) {
    return undefined;
  }
  return message;
};

export const composeRangeValidator = ({ sizeMessage, typeMessage }, { min = null, max = null }) => (value) => {
  /** validator does not make field required */
  if (!value) return undefined;
  if (!(/^[0-9]*$/gm.test(value))) return typeMessage;
  if ((value.length < min || value.length > max) || (value.length > min && value.length < max)) {
    return sizeMessage;
  }
  return undefined;
};

export const docsNumberValidator = ({ sizeMessage, typeMessage }, { min = null, max = null }) => (value) => {
  /** validator does not make field required */
  if (!value) return undefined;
  if (!(/^[0-9]*$/gm.test(value))) return typeMessage;
  if ((value.length < min || value.length > max)) {
    return sizeMessage;
  }
  return undefined;
};

export const composeRequiredPushText = (message) => (value) => {
  if (value === null) return message;
  return value?.length === 0 || value[0].trim().length === 0 ? message : undefined;
};

export const composeRequiredBudgetPolls = (message, saleValue) => (value) => {
  if (Number(value) === 0 || (saleValue !== null && saleValue <= 0)) return message;
  return undefined;
};

export const composeFirstLetterUppercase = (message) => (value) => {
  return value[0] !== value[0].toUpperCase() ? message : undefined;
};

export const composeChekIfContainPhoneNumber = message => value => {
  return /\d{10,}/.test(value) ? message : undefined;
};

export const composeExlamationPointLimit = message => value => {
  return value && value.split('!').length > 2 ? message : undefined;
};

export const composeCheckIfNotHTML = message => value => {
  return /(<([^>]+)>)/ig.test(value) ? message : undefined;
};

export const composeCheckIfNotEmailSymbol = message => value => {
  return value.includes('@') ? message : undefined;
};

export const composeLastLetterNotDot = (message) => (value) => {
  return value[value.length - 1] === '.' ? message : undefined;
};

export const composeOrderSenderNameRegexpValidator = message => value => {
  return !/^[a-zA-Z0-9._-]+$/.test(value) ? message : undefined;
};

export const composeOrderSenderNameRegexpValidator2 = message => value => {
  return /\s/.test(value) ? message : undefined;
};
export const composeOrderSenderNameRegexpValidator3 = message => value => {
  return /^[^\s]*[а-яА-Я]+[^\s]*$/i.test(value) ? message : undefined;
};

export const composeOrderSenderNameRegexpValidator4 = message => value => {
  return !/^[a-zA-Z0-9._-]+$/.test(value) ? message : undefined;
};

export const composeLengthValidator = (message, { min = null, max = null }, valueGetter = passAsIs) => (value) => {
  /** validator does not make field required */
  const val = valueGetter(value);
  if (!val && typeof val !== 'string') return undefined;
  if (min !== null && val.length < min || max !== null && val.length > max) return message;
  return undefined;
};

export const composeArrayRequiredValidator = message => (value) => {
  if (value && value.length) {
    return undefined;
  }
  return message;
};

export const composeOnlineGeoValidator = (message, isOnlineGeoActive) => (value) => {
  if (!isOnlineGeoActive) {
    return undefined;
  }
  if (value && value.length) {
    return undefined;
  }
  const errors = [];
  errors[ARRAY_ERROR] = message;
  return errors;
};

export const isValidUrl = value => urlRegExp.test(value);
export const isUrlStartsFromHttp = value => /^(http:\/\/|https:\/\/)/.test(value);
export const isValidGooglePlayUrl = value => urlGooglePlayRegExp.test(value);
export const isValidAppStoreUrl = value => urlAppStoreRegExp.test(value);

export const composeUrlValidator = message => (value) => {
  /** validator does not make field required */
  if (!value || value.length === 0) return undefined;
  if (!isValidUrl(value)) return message;
  return undefined;
};

export const composeHttpValidator = message => (value) => {
  /** validator does not make field required */
  if (!value || value.length === 0) return undefined;
  if (!isUrlStartsFromHttp(value)) return message;
  return undefined;
};

export const composeUrlWithoutGameServices = message => (value) => {
  if (isValidGooglePlayUrl(value) || isValidAppStoreUrl(value)) return message;
  return undefined;
};

export const composeUrlGooglePlayValidator = message => (value) => {
  if (!isValidUrl(value) || !isValidGooglePlayUrl(value)) return message;
  return undefined;
};

export const composeDenyEntriesFromArray = (message, websitesList) => value => {
  let result;
  websitesList.forEach(item => {
    const webSite = new RegExp(`^(?!.*${item}).*$`);
    if (!webSite.test(value)) {
      result = message;
    }
  });
  return result;
};

export const composeArrayFieldLengthValidator = (message, { minLength }) => (value) => {
  if (value && value.filter(val => val.isActive).length < minLength) {
    // from FF documentation for arrays
    const errors = [];
    errors[ARRAY_ERROR] = message;
    return errors;
  }
  return undefined;
};

export const composeNumberPhonePushValidator2 = message => (value) => {
  if (isNaN(Number(value))) return message;
  return undefined;
};

export const composeNumberPhonePushValidator1 = message => (value) => {
  if (isNaN(Number(value))) return message;
  if ((`${value}`[0] === '7' || `${value}`[0] === '8') && `${value}`.length === 11) {
    return undefined;
  }
  if (`${value}`.length === 4 || `${value}`.length === 6) {
    return undefined;
  }
  return message;
};

export const composeUserInitialsValidator = message => value => {
  if (value === '') return undefined;
  return /^([а-яА-ЯёЁ. ]'?-?)+( [а-яА-ЯёЁ.]\s?)*$/.test(value) ? undefined : message;
};

export const composePrivacyPolicyValidator = massage => value => {
  if (!value) return massage;
  return undefined;
};

export const composeValidatorsMinLength = ({ message, minLength }) => (value) => {
  try {
    const preparedValue = value.replace(/[^0-9]+/g, '');
    if (preparedValue.length < minLength) return message;
  } catch (e) {
    return message;
  }
  return undefined;
};

export const composeValidatorsBirthdayCheck = (date) => {
  if (date?.length !== 10) {
    return 'Поле обязательное для заполнения';
  }
  const momentDate = moment(date.split('.').reverse().join('-'));
  if (moment.isMoment(momentDate) === false) {
    return 'Укажите дату рождения';
  }

  if (
    moment(momentDate).isBefore(moment(new Date('12.31.1900'))) ||
    moment(momentDate).isSame(moment(new Date('12.31.1900'))) ||
    parseInt(date.split('.')[2], 10) <= 1899
  ) {
    return 'Дата должна быть не ранее 31.12.1900';
  }
  if (moment(Date.now()).diff(momentDate, 'years') < 18) {
    return `Дата должна быть не позднее ${moment(Date.now()).subtract(18, 'years').format('DD.MM.YYYY')}`;
  }
  return undefined;
};

export const composeValidatorDocIssueDate = (birthDate) => (date) => {
  const momentBirthDate = moment(new Date(birthDate));
  if (date?.length !== 10) {
    return 'Поле обязательное для заполнения';
  }
  // const momentDate = moment(`${date.split('.')[1]}.${date.split('.')[0]}.${date.split('.')[2]}`);
  const momentDate = moment(date);
  if (moment.isMoment(momentDate) === false) {
    return 'Дата указана неверно';
  }

  if (date.length === 10) {
    if (moment(new Date()).diff(momentDate) <= 0) {
      return `Дата не может быть равна или позднее ${moment(new Date()).format('DD.MM.YYYY')}`;
    }
  }

  if (date?.length === 10 && birthDate?.length === 10) {
    //  текущий возраст по дате рождения 20 и более лет, а паспорт выдан в возрасте менее 20 лет
    if (
      momentBirthDate.diff(moment(new Date()), 'years') <= -20 &&
      momentBirthDate.diff(moment(new Date()), 'years') >= -45 &&
      moment(new Date(birthDate)).add(20, 'year') > momentDate
    ) {
      return 'Ваш паспорт недействителен';
    }
    // текущий возраст по дате рождения 45 и более лет, а паспорт выдан в возрасте менее 45 лет
    if (
      momentBirthDate.diff(moment(new Date()), 'years') <= -45 &&
      moment(new Date(birthDate)).add(45, 'year') > momentDate
    ) {
      return 'Ваш паспорт недействителен';
    }
  }
  return undefined;
};

export const composeValidatorNotSameLetters = (message) => (value) => {
  const prepValue = `${value}`.replace(/[./-]/ug, '').toLowerCase();
  const names = prepValue.split(' ');
  const res = names.some(name => name.length > 1 && new Set(name.split('')).size === 1);
  return res ? message : undefined;
};

export const composeValidatorsDummyWords = (message) => (dummyWords) => (value) => {
  const prepValue = `${value}`.toLowerCase();
  const preparedDummyWords = dummyWords.map(item => item.toLowerCase());
  const names = prepValue.split(' ');
  const res = names.some(name => preparedDummyWords.includes(name));
  return res ? message : undefined;
};

export const composeValidatorsCantBeZero = (message) => (value) => {
  const prepValue = `${value}`;
  if (prepValue.length > 1) {
    return (prepValue.split('')[0] === '0' && prepValue.split('')[1] === '0') ? message : undefined;
  }
  return undefined;
};

export const composeValidatorCntBeLessDate = ({ date, message }) => (value) => {
  try {
    // const userDate = moment(new Date(`${value.split('.')[1]}.${value.split('.')[0]}.${value.split('.')[2]}`));
    const userDate = moment(new Date(value));
    const beforeDate = moment(new Date(date));
    if (!value && !moment.isMoment(userDate)) return undefined;
    if (!moment.isMoment(userDate) || !moment.isMoment(beforeDate)) return message;

    return beforeDate.isAfter(userDate) || beforeDate.isSame(userDate) ? message : undefined;
  } catch (e) {
    return message;
  }
};

export const composeForeignerInitialsValidator = message => value => {
  if (value === '') return undefined;
  return /^([а-яА-ЯёЁa-zA-Z ]'?-?)+( [а-яА-ЯёЁa-zA-Z]\s?)*$/.test(value) ? undefined : message;
};

export const issuedByFieldEnglishLetterValidator = message => value => {
  if (value === '') return undefined;
  return value.search(/[a-zA-Z]/g) !== -1 ? message : undefined;
};

export const issuedByFieldSpecialSymbolsValidator = message => value => {
  if (value === '') return undefined;
  return /^([а-яА-ЯёЁa-zA-Z\d-.№ ]'?-?)+( [а-яА-ЯёЁa-zA-Z\d-.№]\s?)*$/.test(value) ? undefined : message;
};
