import React from 'react';
import classNames from 'classnames/bind';
import { SmallTitle } from '../../typography';
import CrossIcon from './Cross.svg';

import styles from './styles.pcss';

type Props = {
  id?: string,
  title?: string,
  onClose?: () => void,
  titleClassName?: string
}

const cx = classNames.bind(styles);


const GreyContainerPopup:React.FC<Props> = ({
  children,
  id,
  title,
  onClose,
  titleClassName,
}) => {
  return (
    <div id={id} className={styles.greyBlock}>
      {title &&
        <div className={styles.header}>
          <SmallTitle className={cx(titleClassName, styles.title)}>{title}</SmallTitle>
          {onClose && <CrossIcon className={styles.cross} onClick={onClose}/>}
        </div>
      }
      {children}
    </div>
  );
};

export default GreyContainerPopup;
