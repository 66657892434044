import React, { SyntheticEvent, useContext, useState } from 'react';
import { Link, navigate, useLocation } from '@reach/router';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import { Label } from '@beeline/design-system-react';
import { StoresContext } from 'store/mobx';
import UserInfo from 'store/mobx/UserInfo';
import useBlockingNavigateFromAudience from 'redesignSrc/HOC/hooks/useBlockingNavigateFromAudience';

import Common from 'store/mobx/Common';
import audienceStore from 'store/mobx/AudienceAnalysis';
import { formatPriceTotal } from '../../../utils/formatting';
import TooltipInfo from '../../UI/elements/TooltipInfo';
import { HovesCardTitle } from '../../UI/typography';
import {
  GridIcon,
  AudiencesIcon,
  ChartIcon,
  DiagramIcon,
  BriefcaseIcon,
  ProfileIcon,
  TemplateIcon,
  SenderNameIcon,
  SmsIcon,
} from './assets';
import styles from './styles.pcss';
import GrayInfoIcon from './assets/gray-info-icon.svg';
import Settings from './assets/settings.svg';

type Props = {
  path: string;
}

const SidebarMenu: React.FC<Props> = () => {
  const { NewCampaign } = useContext(StoresContext);

  interface Item {
    href: string;
    label: string;
    iconType: string;
    type?: string
  }

  const [isShowPlug, setIsShowPlug] = useState(false);
  const [isShowPlugSender, setIsShowPlugSender] = useState(false);
  const [isShowPlugCounter, setIsShowPlugCounter] = useState(false);

  const COUNTER_URL = '/counter';

  const TEMPLATE_TOOLTIP = 'Шаблоны сообщений доступны только подтвержденным профилям. Чтобы воспользоваться функционалом, пройдите по ';

  const promotionalMaterialsMenuItems: Item[] = [
    {
      label: 'Шаблоны сообщений',
      href: '/message-templates',
      iconType: 'template',
    },
    {
      label: 'Имена отправителей',
      href: '/sender-names',
      iconType: 'sender',
    },
  ];

  const menuItemsAgregator: Item[] = [
    {
      label: 'Мои кампании',
      href: '/my-campaigns',
      iconType: 'grid',
    },
    {
      label: 'Мои аудитории',
      href: '/my-audiences',
      iconType: 'audiences',
    },
  ];

  const menuItems: Item[] = [
    {
      label: 'Мои кампании',
      href: '/my-campaigns',
      iconType: 'grid',
    },
    {
      label: 'Мои аудитории',
      href: '/my-audiences',
      iconType: 'audiences',
    },
    // {
    //   label: 'Мобильные опросы',
    //   href: '/polls',
    //   type: 'custom-poll',
    //   iconType: 'diagram',
    // },
  ];
  if (!UserInfo.isAggregator) {
    menuItems.push(
      {
        label: 'Анализ аудитории',
        href: '/audience-statistic',
        iconType: 'chart',
      },
    );
    menuItems.push({
      label: 'Счетчики',
      href: COUNTER_URL,
      iconType: 'settings',
    });
  }
  if (window.innerWidth < 992) {
    menuItems.push(
      {
        label: 'Финансы',
        href: '/my-finances',
        iconType: 'briefcase',
      },
      {
        label: 'Профиль',
        href: '/user-info',
        iconType: 'profile',
      },
    );
  }

  const getCurrentIcon = (iconType: string) => {
    switch (iconType) {
      case 'grid':
        return <GridIcon className={styles.menuIcon}/>;
      case 'template':
        return <SmsIcon className={styles.menuIconTemplate}/>;
      case 'sender':
        return <SenderNameIcon className={styles.menuIcon}/>;
      case 'audiences':
        return <AudiencesIcon className={styles.menuIcon}/>;
      case 'chart':
        return <ChartIcon className={styles.menuIcon}/>;
      case 'diagram':
        return <DiagramIcon className={styles.menuIcon}/>;
      case 'briefcase':
        return <BriefcaseIcon className={styles.menuIcon}/>;
      case 'profile':
        return <ProfileIcon className={styles.menuIcon}/>;
      case 'settings':
        return <Settings className={styles.menuIcon}/>;
      default:
        return null;
    }
  };

  const { pathname } = useLocation();

  const onClickGA = (href: string) => {
    switch (href) {
      case COUNTER_URL:
        window.ym(87718585, 'reachGoal', 'Button_counter');
        break;
      default:
        return null;
    }
  };

  const globalHistory = useBlockingNavigateFromAudience();

  const linkForTempalte = (item: Item) => {
    return UserInfo.data?.company?.ban && item.iconType === 'template' ?
      item.href :
      '#';
  };

  const renderPlugField = (): JSX.Element => {
    const goToUserProfileHandler = (e: SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      navigate('/user-info/');
    };
    return (
      <div className={styles.inputHolder}>
        <div className={styles.plugDescription}>
          <p>
            <GrayInfoIcon className={styles.plugDescriptionIcon}/>Шаблоны сообщений доступны только подтвержденным
            профилям.
            <br/>Чтобы воспользоваться функционалом,<br/> пройдите по <Link to="#" className={styles.linkTo}
              onClick={goToUserProfileHandler}>ссылке</Link>
          </p>
        </div>
      </div>
    );
  };

  const renderPlugFieldSender = (): JSX.Element => {
    const goToUserProfileHandler = (e: SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      navigate('/user-info/');
    };
    return (
      <div className={styles.inputHolder}>
        <div className={styles.plugDescription}>
          <p>
            <GrayInfoIcon className={styles.plugDescriptionIcon}/>Имена отправителей доступны только подтвержденным
            профилям.
            <br/>Чтобы воспользоваться функционалом,<br/> пройдите по <Link to="#" className={styles.linkTo}
              onClick={goToUserProfileHandler}>ссылке</Link>
          </p>
        </div>
      </div>
    );
  };

  const renderPlugFieldCounters = (): JSX.Element => {
    const goToUserProfileHandler = (e: SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      navigate('/user-info/');
    };
    return (
      <div className={styles.inputHolder}>
        <div className={styles.plugDescription}>
          <p>
            <GrayInfoIcon className={styles.plugDescriptionIcon}/>Счетчики доступны только подтвержденным профилям.
            <br/>Чтобы воспользоваться функционалом,<br/> пройдите по <Link to="#" className={styles.linkTo}
              onClick={goToUserProfileHandler}>ссылке</Link>
          </p>
        </div>
      </div>
    );
  };

  const onClickMenuItemHandler = (item: Item) => async (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    Common.isMobileSidebarVisible = false;
    linkForTempalte(item);
    if (!UserInfo.data?.company?.ban && item.iconType === 'template') {
      setIsShowPlug(true);
    } else if (!UserInfo.data?.company?.ban && item.iconType === 'sender') {
      setIsShowPlugSender(true);
    } else {
      setIsShowPlugSender(false);
      setIsShowPlug(false);
      await navigate(item.href);
    }
  };


  const renderLabelForAA = () => {
    return (
      <Label
        className={styles.labelAA}
        title="улучшаем"
        variant="outline"
        style={{ fontSize: '16px' }}
      />
    );
  };

  return (
    <>
      <ul className={styles.menuList}>
        {menuItems.map((item) => {
          return (
            <li className={styles.menuItem} key={`${item.label}`}>
              {/* {item.label === 'Анализ аудитории' ? renderLabelForAA() : null} */}
              <Link
                to={item.href}
                // условие после || добавлено чтобы вкладка опросы была выделенной при работе с кампаниями  опросы
                className={`${styles.menuLink} ${(pathname.indexOf(item.href) !== -1 || item?.type === NewCampaign.isPolls) && styles.active}`}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!UserInfo.data?.company?.ban && item.iconType === 'settings') {
                    setIsShowPlugCounter(true);
                  } else {
                    setIsShowPlugCounter(false);
                    Common.isMobileSidebarVisible = false;
                    await navigate(item.href);
                    // await globalHistory.navigate(item.href);
                    onClickGA(item.href);
                  }
                }}
              >
                {getCurrentIcon(item.iconType)}
                {item.label}
                {item.label === 'Финансы' ?
                  <div
                    // @ts-ignore
                    className={classNames(styles.walletPrice, UserInfo.balance?.free_balance < 0 && styles.walletPriceNegative)}>
                    {formatPriceTotal(UserInfo.balance?.free_balance)}
                  </div> : null}
              </Link>
              {isShowPlugCounter && item.iconType === 'settings' && renderPlugFieldCounters()}
            </li>
          );
        })}
      </ul>
      <hr className={styles.menuListHr}/>
      <h4 className={styles.menuSubListTitle}>Рекламные материалы</h4>
      <ul className={styles.menuList}>
        {promotionalMaterialsMenuItems.map((item) => {
          return (
            <li className={styles.menuItem} key={`${item.label}`}>
              <Link
                to={linkForTempalte(item)}
                className={`${styles.menuLink} ${(pathname.indexOf(item.href) !== -1 || item?.type === NewCampaign.isPolls) && styles.active}`}
                onClick={onClickMenuItemHandler(item)}
              >
                {getCurrentIcon(item.iconType)}
                {item.label}
              </Link>
              {isShowPlug && item.iconType === 'template' && renderPlugField()}
              {isShowPlugSender && item.iconType === 'sender' && renderPlugFieldSender()}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default observer(SidebarMenu);
