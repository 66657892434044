import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { formatPrice } from 'utils/formatting';
import { isNullOrUndefined } from 'utils/fn';

import styles from './styles.pcss';

interface IProps {
  value: number[],
  className?: string,
  onChange: (n: string, v: (number | undefined)[]) => void,
  onBlur: (n: string, v: (number | undefined)[]) => void,
  onSetError: (n: string, h:number) => void,
  name: string,
  status?: string,
  defaultValues: number[],
  errorIndex?: number,
}

const MinMaxInputsGroup = ({
  className = '',
  onChange,
  onBlur,
  onSetError,
  name,
  value,
  defaultValues,
  status,
  errorIndex,
}: IProps) => {
  const itemFromId = `from_${Math.random()}`;
  const itemToId = `to_${Math.random()}`;
  const [isFocus1, setIsFocus1] = useState(false);
  const [isFocus2, setIsFocus2] = useState(false);

  const getArrayToSend = (index: number, curValue: string): (number | undefined)[] => {
    const numberText = Number(curValue.replaceAll(' ', ''));

    if (isNaN(numberText)) return [];
    if (index === 1 && numberText > defaultValues[1]) {
      onSetError(`Допустимый возраст до ${defaultValues[1]} лет`, 1);
      return [];
    }

    const arrayToSend: (number | undefined)[] = value;
    arrayToSend[index] = numberText === 0 ? undefined : numberText;

    // eslint-disable-next-line consistent-return
    return arrayToSend;
  };

  const handleInputChange = (index: number, curValue: string) => {
    const arrayToSend = getArrayToSend(index, curValue);

    if (arrayToSend.length) onChange(name, arrayToSend);
  };

  const handleInputBlur = (index: number, curValue: string) => {
    const arrayToSend = getArrayToSend(index, curValue) || [];
    if (arrayToSend.length) onBlur(name, arrayToSend);

    if (value[0] > value[1]) {
      onSetError('Значение "От" не может быть больше значения "До"', 3);
    }

    // @ts-ignore
    if (index === 0 && arrayToSend[0] < defaultValues[0]) {
      onSetError(`Допустимый возраст от ${defaultValues[0]} лет`, 0);
    }
  };

  useEffect(() => {
    if (value[0] > value[1]) {
      onSetError('Значение "От" не может быть больше значения "До"', 3);
    }

    // @ts-ignore
    if (value[0] < defaultValues[0]) {
      onSetError('Значение "От" должно быть не меньше минимально возможного', 0);
    }
  }, []);

  return (
    <div className={classNames(styles.container, className)} id={name}>
      <label
        className={classNames(
          styles.label,
          status === 'fail' && (!value[0] || errorIndex === 0 || errorIndex === 3) && styles.fail,
          isFocus1 && styles.focusBorder)
        }
        htmlFor={itemFromId}
      >
        <span className={styles.labelText}>От</span>

        <input
          // ref={input1}
          onFocus={() => setIsFocus1(true)}
          type="text"
          onChange={(e) => handleInputChange(0, e.target.value)}
          onBlur={(e) => {
            setIsFocus1(false);
            handleInputBlur(0, e.target.value);
          }}
          value={formatPrice(value[0])}
          className={styles.numberInput}
          id={itemFromId}
          placeholder={!isNullOrUndefined(defaultValues[0]) ? `${defaultValues[0]}` : ''}
          maxLength={(!isNullOrUndefined(defaultValues[0]) && (
            `${formatPrice(defaultValues[1])}`.length === 1 ? 2 : `${formatPrice(defaultValues[1])}`.length
          )) || 13}
        />
      </label>

      <label
        className={classNames(
          styles.label,
          status === 'fail' && (!value[1] || errorIndex === 1 || errorIndex === 3) && styles.fail,
          isFocus2 && styles.focusBorder)
        }
        htmlFor={itemToId}
      >
        <span className={styles.labelText}>До</span>
        <input
          // ref={input2}
          onFocus={() => setIsFocus2(true)}
          type="text"
          onChange={(e) => handleInputChange(1, e.target.value)}
          onBlur={(e) => {
            setIsFocus2(false);
            handleInputBlur(1, e.target.value);
          }}

          value={formatPrice(value[1])}
          className={styles.numberInput}
          placeholder={defaultValues[1] ? `${defaultValues[1]}` : ''}
          id={itemToId}
          maxLength={defaultValues[1] ? `${formatPrice(defaultValues[1])}`.length : 13}
        />
      </label>
    </div>
  );
};

export default MinMaxInputsGroup;
