import React from 'react';
import classNames from 'classnames/bind';
import { PrimaryButton } from '../../redesignSrc/UI/buttons';
import ErrorPageHeader from './components/ErrorPageHeader';
import styles from './styles.pcss';
import Laptop from './assets/Laptop.svg';

const cx = classNames.bind(styles);

const ErrorPage = () => {
  return (
    <div className={cx('wrapper')}>
      <ErrorPageHeader />
      <div className={styles.errorBody}>
        <Laptop/>
        <p className={styles.title}>Кажется, этой страницы нет</p>
        <p className={styles.description}>Перейдите на главную, чтобы продолжить работу</p>
        <PrimaryButton
          type="submit"
          onClick={() => {
            window.location.href = '/';
          }}>
          На главную
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ErrorPage;
