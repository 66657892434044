import moment from 'moment';
import { format } from 'date-fns';
import { wordFormByCount } from 'utils/fn';

const weekDayNames = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
const monthsNames = [
  'января', 'февраля', 'марта', 'апреля',
  'мая', 'июня', 'июля', 'августа',
  'сентября', 'октября', 'ноября', 'декабря',
];

export const getWeekDayName = dayNumber => weekDayNames[dayNumber];
export const getMonthName = monthNumber => monthsNames[monthNumber];

export const getDateGivenTimezone = (date) => {
  return date && format(new Date(date), 'dd.MM.yyyy, HH:mm');
// / Убирать не стал но это выдавало неправильную дату, позже удалю как вернусь
  // const format1 = 'DD.MM.YYYY, HH:mm';
  // moment.locale('ru');
  // const dateUtc = moment.utc(date);
  // const localDate = dateUtc.local();
  // return localDate.format(format1);
};

export const getMinStartSmsPush = (value) => {
  const isVoice = value?.isVoice;
  const isNow = value?.isNow;
  const date = new Date();
  if (isNow) return date;
  if (date.getHours() >= 15 || date.getDay() === 0 || isVoice) {
    return addDays(new Date(), 2);
  }
  return addDays(new Date(), 1);
};

export const getMinStartInternet = () => {
  const date = new Date();
  switch (date.getDay()) {
    case 4:
    case 5:
      return addDays(new Date(), 4);
    case 6:
      return addDays(new Date(), 3);
    default:
      return addDays(new Date(), 2);
  }
};

export const getMinStartVoice = () => {
  const date = new Date();
  switch (date.getDay()) {
    case 4:
      return addDays(new Date(), 5);
    case 5:
      return addDays(new Date(), 6);
    case 6:
      return addDays(new Date(), 5);
    case 7:
      return addDays(new Date(), 4);
    default:
      return addDays(new Date(), 3);
  }
};

export const getMinEndVoice = (date) => {
  return addDays(date, 30);
};

export const addDays = (date, numDays) => {
  const newDate = new Date(date.valueOf());
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
};

export const addMonths = (date, numMonths) => {
  const newDate = date.setMonth(date.getMonth() + numMonths);
  return new Date(newDate);
};
/**
 *
 * @param {string[]} dates - Даты которые есть
 * @param {string[]} fromTo - Даты которые хотим вырезать из dates
 * @returns {string[] || []} - Вырезанные даты или пустой массив
 */

export const pickingDates = (dates, fromTo) => {
  const fromIndex = dates.indexOf(fromTo[0]);
  const toIndex = dates.indexOf(fromTo[1]);
  if (fromIndex === -1 && toIndex !== -1) return dates.slice(0, toIndex + 1);
  if (fromIndex !== -1 && toIndex === -1) return dates.slice(fromIndex, dates.length);
  return dates.slice(fromIndex, toIndex + 1);
};

export const formatDateBySplitter = (dateStr = '', splitter = '-') => {
  const [year = '', month = '', day = ''] = dateStr.split(splitter);
  if (dateStr.split(splitter).length === 3) return `${day}.${month}.${year}`;

  console.warn('formatDateBySplitter: неверно задан требуемый формат даты');
  return '';
};

export function formatDate([start, end]) {
  const fd1 = start && start.format('YYYY-MM-DD').substring(0, 10);
  const fd2 = end && end.format('YYYY-MM-DD').substring(0, 10);

  return [fd1, fd2];
}

export const formatDateArray = ([start, end]) => {
  if (!start && !end) return '';
  const startF = start?.split('-').reverse().join('.');
  const endF = end?.split('-').reverse().join('.');
  if (end === 'Invalid da') return `${startF}`;
  return `${startF} - ${endF}`;
};

const contactsCases = ['контакт', 'контакта', 'контактов'];
export const contactsFormByCount = count => wordFormByCount(count, contactsCases);

const customersCases = ['абонент', 'абонента', 'абонентов'];
export const customersFormByCount = count => {
  return wordFormByCount(Math.floor(count), customersCases);
};

export const getFormattedDate = (dateString) => moment(dateString).format('YYYY.MM.DD');

export const getIsWorkTime = (holidays, startTime = '09:00') => {
  const date = moment();
  const dayNum = date.day();
  const currentDay = date.format('YYYY-MM-DD').substring(0, 10);
  const currentHour = parseInt(date.format('HH'), 10);
  const currentMinutes = parseInt(date.format('mm'), 10);

  const [startHoursString, startMinutesString] = startTime.split(':');
  const [startHours, startMinutes] = [parseInt(startHoursString, 10), parseInt(startMinutesString, 10)];

  if (holidays[currentDay] || dayNum === 0 || dayNum === 6) {
    return false;
  }

  if (startHours === currentHour && currentMinutes < startMinutes) {
    return false;
  }

  if (dayNum === 5) {
    if (currentHour < startHours || ((currentHour === 16 && currentMinutes > 45) || currentHour > 16)) {
      return false;
    }
  }

  if (currentHour < startHours || currentHour >= 18) {
    return false;
  }

  return true;
};

export const formatOffer = (date) => {
  const index = date?.indexOf(',');
  return date?.slice(0, index) ? date?.slice(0, index) : null;
};

export const formatDatefns = (date) =>
  date && format(new Date(date), 'dd.MM.yyyy');
