import React, { ComponentType, FocusEvent, useEffect, useState } from 'react';
import { ERROR_KEY, VALID } from 'redesignSrc/HOC/hooks/useValidation';
import styles from './styles.pcss';

type PropsWrapper = {
  error?: string | boolean | null,
  className?: string,
  name?: string,
  onBlur?: (e: any) => void,
  onFocus?: (e: any) => void,
  onChange?: (e: any, t: any) => void,
  validation?: VALID,
  keepErrorIndent?: any,
  maxErrorWidth?: number
}

const withError = <P extends object>(Component: ComponentType<P>): React.FC<PropsWrapper & P> => ({
  error,
  validation,
  name,
  className,
  onBlur,
  onFocus,
  onChange,
  maxErrorWidth,
  keepErrorIndent,
  ...props
}: PropsWrapper) => {
  const [isError, showError] = useState<string | null | boolean | undefined>(null);

  useEffect(() => {
    if (!validation) {
      showError(error);
    }
  }, [error]);

  useEffect(() => {
    showError(validation?.errors[name as ERROR_KEY]);
  }, [validation?.errors[name as ERROR_KEY]]);

  const handlerBlur = (e: FocusEvent) => {
    // @ts-ignore
    validation && validation?.getvalidObj(name);
    onBlur && onBlur(e);
  };

  const onFocusHandler = (e: FocusEvent) => {
    onFocus && onFocus(e);
  };

  const onChangeHandler = (e: never, t: any) => {
    onChange && onChange(e, t);

    if (validation?.errors[name as ERROR_KEY] && name) {
      validation?.getvalidObj(name);
    }
  };

  return (
    <>
      <Component
        {...props as P}
        className={`${className} ${isError ? styles.errorField : ''}`}
        // @ts-ignore
        onBlur={handlerBlur}
        onFocus={onFocusHandler}
        onChange={onChangeHandler}
        name={name}
        isError={isError}
      />
      {
        isError &&
        <div className={keepErrorIndent ? `${styles.absolute} ${styles.error}` : `${styles.error}`}
          style={maxErrorWidth && { maxWidth: maxErrorWidth } || undefined}>
          {Array.isArray(isError) ?
            isError.map((str) => {
              return <p className={styles.strError}>{str}</p>;
            })
            : isError}
        </div>}
    </>

  );
};

export default withError;
