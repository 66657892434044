import React, { ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import OutsideClickTracker from 'redesignSrc/UI/elements/OutsideClickTracker/OutsideClickTracker';
import Common from 'store/mobx/Common';
import { PortalWrapper } from 'components/helpers';
import { TypographyWithDangling } from 'redesignSrc/UI/beeline/TypographyWithDangling/TypographyWithDangling';
import styles from '../../styles.pcss';


type Props = {
  onClose: () => void;
  children: ReactNode,
  title?: string,
  subtitle?: string,
  disableOutsideClick?: boolean,
  className?: string
  scrollContainerClassName?: string
}

const FloatingSidePanel = (props:Props) => {
  const { onClose, children, title, subtitle = '', disableOutsideClick, className, scrollContainerClassName } = props;
  useEffect(() => {
    Common.isFloatingPanelVisible = true;
    return () => {
      Common.isFloatingPanelVisible = false;
    };
  }, []);
  return (
    <PortalWrapper>
      <OutsideClickTracker onClickOutside={!disableOutsideClick && onClose || (() => {})}>
        <div className={classNames(styles.sideHolder, className)}>
          <button className={styles.close_float} onClick={onClose}/>
          {
            title && <TypographyWithDangling variant="h4" className={styles.titlePanel}>{title}</TypographyWithDangling>
          }

          {subtitle ? (<div className={styles.subtitlePanel}>{subtitle}</div>) : null}

          <div className={classNames(styles.scrollContainer, scrollContainerClassName)}>
            {children}
          </div>
        </div>
      </OutsideClickTracker>
    </PortalWrapper>
  );
};

export default observer(FloatingSidePanel);
