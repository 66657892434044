import React from 'react';
import { observer } from 'mobx-react';
import { IconButton, PopoverHelper } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import NewAudiences from 'store/mobx/NewAudiences';
import danglingPrepositions from 'utils/danglingPrepositions';
import NewOrder from 'store/mobx/NewOrder';
import { formatPrice } from 'utils/formatting';
import Info from './assets/info.svg';
import Loader from './assets/loader.svg';
import styles from './style.pcss';

const TextAudience = () => {
  if (
    (NewAudiences.isLookAlike && window.location.pathname.includes('/audience'))
  ) {
    return (
      <p className={styles.headerRealTime}>
        Абонентов билайн<br/>
        <span>
          до&nbsp;
        </span>
        <span>
          <p className={styles.price}>{formatPrice(NewAudiences.audience)}</p>
        </span>
      </p>
    );
  }
  if (NewAudiences.isLookAlike) {
    if (!NewAudiences.msisdnsCount) {
      return (
        <p className={styles.headerRealTime}>
          Абонентов билайн:<br/><span>{danglingPrepositions('Идет расчет аудитории')}</span>
          <div><Loader className={styles.loader}/></div>
        </p>
      );
    }
    return (
      <p className={styles.headerRealTime}>
        Абонентов билайн<br/>
        <span>
          до&nbsp;
        </span>
        <span>
          <p className={styles.price}>{formatPrice(NewAudiences.msisdnsCount)}</p>
        </span>
      </p>
    );
  }
  if (NewAudiences.filterAudienceData.some(item => item.condition.type === 'Pixels')) {
    return (
      <p className={styles.headerRealTime}>
        Аудитория<br/><span>{danglingPrepositions('будет рассчитана в течение 30 минут после нажатия на кнопку «Продолжить» ')}</span>
      </p>
    );
  }

  if (NewAudiences.isCustom) {
    if (NewAudiences.msisdnsCount && NewAudiences.status === 'Succeeded') {
      return (
        <p className={styles.headerRealTime}>
          Абонентов билайн<br/>
          <span>
            <p className={styles.price}>{formatPrice(NewAudiences.msisdnsCount)}</p>
          </span>
        </p>
      );
    }
    return (
      <>
        <p className={styles.headerRealTime}>
          Аудитория<br/><span>{danglingPrepositions('сбор аудитории займет от 3 рабочих дней')}</span>
        </p>
      </>
    );
  }

  if (NewAudiences.isSimple) {
    if (NewAudiences.msisdnsCount && NewAudiences.status === 'Succeeded') {
      return (
        <p className={styles.headerRealTime}>
          Абонентов билайн<br/>
          <span>
            <p className={styles.price}>{formatPrice(NewAudiences.msisdnsCount)}</p>
          </span>
        </p>
      );
    }
    if (!NewAudiences.msisdnsCount && NewAudiences.status === 'Processing') {
      return (
        <p className={styles.headerRealTime}>
          Абонентов билайн:<br/><span>{danglingPrepositions('Идет расчет аудитории')}</span>
          <div><Loader className={styles.loader}/></div>
        </p>
      );
    }
    return (
      <>
        <p className={styles.headerRealTime}>
          Абонентов билайн<br/>
          <span>
            до&nbsp;
          </span>
          <span>
            <p className={styles.price}>{formatPrice(NewAudiences.audience)}</p>
          </span>
        </p>
      </>
    );
  }

  if (NewAudiences.isRealTime) {
    return (
      <>
        <p className={styles.headerRealTime}>
          Аудитория<br/><span>{danglingPrepositions('будет собираться в реальном времени')}</span>
        </p>
      </>
    );
  }

  if (NewAudiences.timerId) {
    return (
      <p className={styles.headerRealTime}>
        Аудитория:<br/><span>{danglingPrepositions('Идет расчет аудитории')}</span>
        <div><Loader className={styles.loader}/></div>
      </p>
    );
  }

  if (!NewAudiences.isAudienceCountCheck && NewAudiences.status !== 'Succeeded') {
    return (
      <>
        {NewOrder.order?.type === 'VoiceTarget' ?
          <>
            <p className={styles.header}>
              Кол-во абонентов билайн доступных для обзвона
            </p>
            {NewAudiences.isLoadingAudience || NewAudiences.audience === null ?
              <div className={styles.wrapperLoaderZero}>
                <Loader className={styles.loaderZero}/>
                идет загрузка данных
              </div> :
              <>
                <p className={styles.price}>
                  <span className={styles.voiceText}>до</span>&nbsp;
                  {formatPrice(NewAudiences.audienceVT)}
                </p>
                <div className={styles.wrapperInfo}>
                  <Info/>
                  <span className={styles.voiceText}>
                    Это абоненты, которым можно совершить звонки из общего объема вашего сегмента
                    <br/>
                    {`в ${formatPrice(NewAudiences.audience)} абонентов`}
                  </span>
                </div>
              </>
            }
          </>
          :
          <>
            <p className={styles.header}>
              <span>Абонентов билайн</span>
              <PopoverHelper
                body="Указано количество абонентов билайн, которым может быть интересно ваше рекламное предложение"
              >
                <IconButton iconName={Icons.InfoCircled}/>
              </PopoverHelper>

            </p>
            {NewAudiences.isLoadingAudience || NewAudiences.audience === null ?
              <div className={styles.wrapperLoaderZero}>
                <Loader className={styles.loaderZero}/>
                идет загрузка данных
              </div> :
              <>
                <p className={styles.price}>{formatPrice(NewAudiences.audience)}</p>
                {NewAudiences.isMultiOperators && <div className={styles.wrapperInfo}>
                  <Info/>
                  <span className={styles.voiceText}>
                    Размер аудитории других операторов будет известен после запуска кампании
                  </span>
                </div>}
              </>
            }

          </>
        }

      </>
    );
  }

  // @ts-ignore
  if (NewAudiences.status !== 'Succeeded' && NewAudiences.isAudienceCountCheck) {
    if (NewAudiences.myAudienceCount !== null) {
      return (
        <>
          {NewOrder.order?.type === 'VoiceTarget' ?
            <>
              <p className={styles.headerRealTime}>
                Аудитория<br/>
                <span>
                  {danglingPrepositions('будет рассчитана в течение 30 минут после нажатия на кнопку "Продолжить"')}
                </span>
              </p>
            </> :
            <>
              <p className={styles.header}>
                Кол-во абонентов билайн
              </p>
              <p className={styles.price}>{formatPrice(NewAudiences.myAudienceCount)}</p>
            </>
          }
        </>
      );
    }
    return (
      <p className={styles.headerRealTime}>
        Аудитория<br/>
        <span>
          {danglingPrepositions('будет рассчитана в течение 30 минут после нажатия на кнопку "Продолжить"')}
        </span>
      </p>
    );
  }

  if (NewAudiences.status === 'Succeeded') {
    return (
      <>
        {NewOrder.order?.type === 'VoiceTarget' ?
          <>
            <p className={styles.header}>
              Кол-во абонентов билайн доступных для обзвона
            </p>
            <p className={styles.price}>
              <span className={styles.voiceText}>до</span>&nbsp;
              {formatPrice(NewAudiences.voiceTargetMsisdnsCount)}
            </p>
            <div className={styles.wrapperInfo}>
              <Info/>
              <span className={styles.voiceText}>
                Это абоненты, которым можно совершить звонки из общего объема вашего сегмента
                <br/>
                {`в ${formatPrice(NewAudiences.msisdnsCount)} абонентов`}
              </span>
            </div>
          </> :
          <>
            <p className={styles.header}>
              <span>Абонентов билайн</span>
              <PopoverHelper
                body="Указано количество абонентов билайн, которым может быть интересно ваше рекламное предложение"
              >
                <IconButton iconName={Icons.InfoCircled}/>
              </PopoverHelper>
            </p>
            <p className={styles.price}>{formatPrice(NewAudiences.msisdnsCount)}</p>
            {NewAudiences.isMultiOperators && <div className={styles.wrapperInfo}>
              <Info/>
              <span className={styles.voiceText}>
                Размер аудитории других операторов будет известен после запуска кампании
              </span>
            </div>}
          </>
        }
      </>
    );
  }

  return null;
};

export default observer(TextAudience);
