import { Sidesheet } from '@beeline/design-system-react';
import React from 'react';
import { observer } from 'mobx-react';
import sideSheetStore, { ComponentsNames } from 'store/mobx/SideSheetStore';
import BeelineSideSheetHeader, { HeaderProps } from '../BeelineSideSheetHeader';
import styles from './styles.pcss';


const SideSheet = (
  { onBack, title, onClose, showBack, content, children, componentToShow }:
    HeaderProps & { title: string | React.ReactNode, content: React.ReactNode, children: React.ReactNode, componentToShow: ComponentsNames }) => {
  return (
    <Sidesheet
      hasOverlay
      hasDivider
      mode="modal"
      className={styles.sideSheet}
      elevation="above"
      onClose={() => {
        sideSheetStore.componentToShow = '';
      }}
      isOpen={sideSheetStore.componentToShow === componentToShow}
      content={content}
      title={
        <BeelineSideSheetHeader
          onBack={onBack}
          showBack={showBack}
          title={title}
          onClose={onClose}
        />
      }
    >
      <div className={styles.overlay}>{children}</div>
    </Sidesheet>
  );
};

// const SideSheet = ({ content, componentToShow }: { content: React.ReactNode, componentToShow: ComponentsNames }) => {
//   return <Drawer visible={sideSheetStore.componentToShow === componentToShow}>{content}</Drawer>;
// }

export default observer(SideSheet);
