import { observable, action, runInAction, computed, reaction, keys, toJS } from 'mobx';
import moment from 'moment';
import { axiosAuthorizedRequest, axiosBaseRequestAdTech, composeAxiosDeleteRequest } from 'requests/helpers';
import { getCampaignApiUrl } from 'requests/campaigns/constants';
import { isNullOrUndefined } from 'utils/fn';
import Common from './Common';
import { CHANNEL_TYPE_CHARTS, INTERNET_STATISTICS, TARGET_STATISTIC } from './constants/constantsMyCampaignsStatistic';
import campaignStatistics from './TSMS/CampaignStatistics';
// данные в таком виде приходят для статистики интернет рекламы, можно юзать как моковые данные
// import statsMock from './stats.json';


class MyCampaignsStatistic {
  @observable isAudienceAndToolsLoading = false;
  @observable isCampaignLoading = false;
  @observable isChartLoading = true;
  @observable isKeyWordsLoading = true;
  @observable isSmsPushData = true;
  @observable isBannerClick = false;
  @observable isBannerData = true;
  @observable isKeyWordsData = true;
  @observable isStatisticsData = true;
  @observable totalDays = [];
  // Нет инфо - нет графиков, временная обработка этого кейса
  @observable isInfoError = false;

  @observable campaign = null;
  @observable order = null;
  @observable chartDataRequest = []
  @observable chartData = {
    defaultDates: [],
    filterDates: [],
    toolsId: [],
    showCharts: [],
    title: [],
    srcIcon: {},
  }
  @observable chartDataSms = {
    filterDatesHours: [],
    filterDatesDays: [],
    showCharts: [],
  }
  @observable keyWordsDataRequest = {};
  @observable BannerDataRequest = [];
  @observable currentKeyWordsData = {
    name: [],
    clicks: [],
  };
  @observable currentBannersList = [];

  @observable filterToolsKeyWords = [];
  @observable filterToolsBanner = [];
  @observable currentInternetAudience = '';
  @observable currentTools = [];
  @observable currentBannerModal = null;

  @observable isStats = [];

  @action getCurrentCampaign = async (campaignId) => {
    this.isCampaignLoading = true;

    try {
      const campaignData = await axiosBaseRequestAdTech({ url: `api/orders/${campaignId}` });

      runInAction(() => {
        this.campaign = campaignData;
        this.order = campaignData.orders[0];
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.isCampaignLoading = false;
    }
  }

  @action deleteCampaign = async (idToDelete) => {
    this.isCampaignLoading = true;
    try {
      await composeAxiosDeleteRequest({ url: getCampaignApiUrl(idToDelete || this.campaign?.id) })();
    } finally {
      this.isCampaignLoading = false;
    }
  }

  @action loadCurrentAudienceAndTools = async () => {
    this.isAudienceAndToolsLoading = true;

    try {
      const requestData = await axiosAuthorizedRequest({ url: `/api/v1/client/campaigns/${this.campaign.id}/dashboard_info/` });

      runInAction(() => {
        this.currentInternetAudience = requestData.audience.reduce((totalString, item, index) => {
          return totalString.concat(index === 0 ? item : `, ${item}`);
        }, '');
        this.currentTools = requestData.tools ? requestData.tools : [];
      });
    } catch (e) {
      this.currentTools = [];
      this.currentInternetAudience = '';
      console.error(e);
    } finally {
      this.isAudienceAndToolsLoading = false;
    }
  }

  @action getCurrentDataChart = async () => {
    this.isStatisticsData = true;
    const id = campaignStatistics.data.smsCampaign.campaignId || campaignStatistics.data.pushCampaign.campaignId;
    try {
      const temp = await axiosBaseRequestAdTech({ url: `/api/v1/client/campaign/${id}/statistic/` });
      const chartData = this.mapToLegacyData(temp);
      await Common.getCampaignsChannelTypes();
      runInAction(() => {
        this.chartDataRequest = chartData;
        if (this.chartDataRequest.length === 0) {
          this.isStatisticsData = false;
        }
        this.chartData.filterDates = Array.from(new Set(this.chartDataRequest.map(({ date }) => date)));
        this.chartData.toolsId = [0];
      });
    } catch (e) {
      this.isStatisticsData = false;
      console.error(e);
    }
  }

  @action getSmsPushDataChart = async (isInternet) => {
    try {
      const id = campaignStatistics?.data?.smsCampaign?.campaignId ||
        campaignStatistics?.data?.pushCampaign?.campaignId;


      const stats = isInternet ?
        await axiosBaseRequestAdTech({ url: `/api/orders/otm/${campaignStatistics?.data?.id}/dynamicStatistic` }) :
        await axiosBaseRequestAdTech({ url: `/api/campaign/${id}/dynamicStatistic` });
      const chartData = this.mapToLegacyData(stats);
      this.isStats = stats;
      await Common.getCampaignsChannelTypes();
      runInAction(() => {
        if (chartData?.length === 0) {
          this.isSmsPushData = false;
          return;
        }
        this.chartDataRequest = chartData;
        this.chartDataSms.filterDatesDays = this.chartDataRequest.map(({ date }) => date);
        this.totalDays = this.chartDataRequest.map(({ date }) => date);
      });
    } catch (e) {
      console.error(e);
    }
  }

  mapToLegacyData = (data) => {
    if (!data) return [];
    const res = [];
    data.forEach(day => {
      const temp = day.day.split('.');
      const formDate = `${temp[2]}-${temp[1]}-${temp[0]}`;
      day.hours.forEach(hour => {
        const correctHour = hour.time < 10 ? `0${hour.time}` : hour.time;
        res.push({ date: `${formDate}T${correctHour}:00:00`, data: hour });
      });
    });
    return res;
  }


  @action getCurrentKeyWords = async () => {
    this.isKeyWordsLoading = true;
    this.isKeyWordsData = true;
    this.filterToolsKeyWords = [];
    try {
      this.keyWordsDataRequest = await axiosAuthorizedRequest({ url: `/api/v1/client/campaigns/${this.campaign.id}/dashboard_keywords_data/` });
      runInAction(() => {
        keys(this.keyWordsDataRequest).forEach(item => {
          const currenttoolKeyWords = this.currentTools.find(({ id }) => id === Number(item) && this.keyWordsDataRequest[item].length !== 0);
          if (!isNullOrUndefined(currenttoolKeyWords)) {
            this.filterToolsKeyWords.push(currenttoolKeyWords);
          }
        });
        if (this.filterToolsKeyWords.length === 0) {
          this.isKeyWordsData = false;
        }
        this.setCurrentKeyWords(this.filterToolsKeyWords[0]?.id);
      });
    } catch (e) {
      this.isKeyWordsData = false;
      console.error(e);
    } finally {
      this.isKeyWordsLoading = false;
    }
  }

  @action getBannerData = async () => {
    this.isBannerData = true;
    this.filterToolsBanner = [];
    try {
      const BannerData = await axiosAuthorizedRequest({ url: `/api/v1/client/campaigns/${this.campaign.id}/dashboard_banners_data/` });

      runInAction(() => {
        this.BannerDataRequest = BannerData;
        this.BannerDataRequest.forEach(item => {
          const currentToolBanner = this.currentTools.find(({ id }) => id === Number(item.toolId) && item.bannersList.length !== 0);
          if (!isNullOrUndefined(currentToolBanner)) {
            this.filterToolsBanner.push(currentToolBanner);
          }
        });
        if (this.filterToolsBanner.length === 0) {
          this.isBannerData = false;
        }
        this.setCurrentBannersList(this.filterToolsBanner[0]?.id);
      });
    } catch (e) {
      this.isBannerData = false;
      console.error(e);
    }
  }


  @action setShowTools(value) {
    this.chartData.toolsId = value;
  }

  @action setFilterDate(value, isSmsPushChannel) {
    if (isSmsPushChannel) {
      this.chartDataSms.filterDatesDays = value;
    } else {
      this.chartData.filterDates = value;
      const channelType = this.order.channel_type;
      const channel = Common.getChannelsOptions.find(item => item.label === channelType);
      this.updateChartData(CHANNEL_TYPE_CHARTS[channel.value], channel.value);
    }
  }

  @action setCurrentKeyWords(id) {
    this.currentKeyWordsData.name = this.keyWordsDataRequest[id] ? this.keyWordsDataRequest[id].map(({ name }) => name.split('+').join('')) : [];
    this.currentKeyWordsData.clicks = this.keyWordsDataRequest[id] ? this.keyWordsDataRequest[id].map(({ clicks }) => clicks) : [];
  }

  @action setCurrentBannersList(id) {
    const findBannersList = this.BannerDataRequest.find(({ toolId }) => Number(toolId) === id);
    this.currentBannersList = findBannersList ? findBannersList.bannersList : [];
  }

  @computed get getEventsCount() {
    if (this.campaign.custom_segments?.site?.is_active &&
      this.campaign.custom_segments?.site?.is_online) {
      return this.order?.event_count_min;
    }

    if (this.campaign.custom_segments?.site?.is_active || this.campaign.custom_segments?.phone?.is_active) {
      if (isNullOrUndefined(this.order?.data?.msisdns_count)) {
        return 'Идёт подбор аудитории...';
      }

      return Math.min(this.order.data?.msisdns_count, this.order?.event_count_min);
    }

    return Math.min(this.campaign.selection?.audience, this.order?.event_count_min);
  }

  @computed get getNameCurrentInternetTools() {
    return this.currentTools.reduce((totalString, item, index) => {
      return totalString.concat(index === 0 ? item.name : `, ${item.name}`);
    }, '');
  }

  @computed get getDefaultDataChart() {
    return this.chartData.defaultDates.map(data => [data, 0]);
  }

  @computed get getSrcIcon() {
    const srcIcon = {};
    this.currentTools.forEach(element => {
      srcIcon[element.id] = element.icon;
    });
    return srcIcon;
  }

  @computed get getTeaserImg() {
    return this.order?.files?.find(item => item.type === 'брэнд файл' && !item.is_logo && item.is_square);
  }

  @computed get getLogoImg() {
    return this.order?.files?.find(item => item.type === 'брэнд файл' && item.is_logo && !item.is_square);
  }

  @computed get getMainImg() {
    return this.order?.files?.find(item => item.type === 'брэнд файл' && !item.is_logo && !item.is_square);
  }

  @computed get getHrefReport() {
    const fileReport = this.order?.files.filter(({ type_id }) => type_id === 1);
    fileReport.sort((a, b) => a.id - b.id);
    return fileReport[fileReport.length - 1]?.file_path;
  }

  @computed get getImpressionsChartSms() {
    return this.chartDataSms.filterDatesHours.map((item) => {
      return [item.date, item?.data?.impressions || 0];
    });
  }

  @computed get getSentChartSms() {
    return this.chartDataSms.filterDatesHours.map((item) => {
      return [item.date, item?.data?.sent || 0];
    });
  }

  @computed get getTotalSent() {
    return this.chartDataSms.filterDatesHours.reduce((acc, item) => acc + (isNullOrUndefined(item?.data?.sent) ? 0 : item?.data?.sent), 0);
  }

  @computed get getTotalImpressions() {
    return this.chartDataSms.filterDatesHours[this.chartDataSms.filterDatesHours.length - 1]?.data?.impressions;
  }

  @computed get getOpenedChartSms() {
    return this.chartDataSms.filterDatesHours.map((item) => {
      return [item.date, item?.data?.opened || 0];
    });
  }

  @computed get getClicksChartSms() {
    return this.chartDataSms.filterDatesHours.map((item) => {
      return [item.date, item?.data?.clicks || 0];
    });
  }

  @computed get getCompleteChartSms() {
    return this.chartDataSms.filterDatesHours.map((item) => {
      return [item.date, item?.data?.complete || 0];
    });
  }

  @computed get getTotalOpened() {
    return this.chartDataSms.filterDatesHours.reduce((acc, item) => acc + (isNullOrUndefined(item?.data?.opened) ? 0 : item?.data?.opened), 0);
  }

  @computed get getTotalClicks() {
    return this.chartDataSms.filterDatesHours[this.chartDataSms.filterDatesHours.length - 1]?.data?.clicks;
  }

  @computed get getClickedChartSms() {
    return this.chartDataSms.filterDatesHours.map((item) => {
      return [item.date, isNullOrUndefined(item?.data?.clicked) ? 0 : item.data.clicked.reduce((acc, elem) => acc + elem.clickCount, 0)];
    });
  }

  @computed get getTotalComplete() {
    return this.chartDataSms.filterDatesHours.reduce((acc, item) => acc + (isNullOrUndefined(item?.data?.complete) ? 0 : item?.data?.complete), 0);
  }

  @computed get getTotalClicked() {
    return this.chartDataSms.filterDatesHours.reduce((acc, item) => {
      if (isNullOrUndefined(item?.data?.clicked)) return acc + 0;
      return acc + item.data.clicked.reduce((acc1, elem) => {
        return acc1 + elem.clickCount;
      }, 0);
    }, 0);
  }

  @computed get getIsKeyWords() {
    const requiredItem = this.currentTools.find(({ id }) => id === 1 || id === 2 || id === 3 || id === 4);
    return requiredItem !== undefined;
  }

  @computed get getIsBanner() {
    const requiredItem = this.currentTools.find(({ id }) => id === 5 || id === 6 || id === 7 || id === 8);
    return requiredItem !== undefined;
  }

  calculateMaxValue = (value, isClick) => {
    return Math.max.apply(null, value.map(({ stats }) => {
      return stats.map((elem) => {
        return (isClick ? elem.clicks : elem.impressions);
      });
    }).flat());
  };

  @computed get getMaxValueImpressions() {
    return this.calculateMaxValue(this.currentBannersList);
  }

  @computed get getMaxValueClicks() {
    return this.calculateMaxValue(this.currentBannersList, true);
  }

  @computed get getMinDateValue() {
    return moment.min(this.currentBannersList.map(({ stats }) => {
      return stats.map(({ date }) => {
        return moment(date);
      });
    }).flat()).format('YYYY-MM-DD');
  }

  @computed get getMaxDateValue() {
    return moment.max(this.currentBannersList.map(({ stats }) => {
      return stats.map(({ date }) => {
        return moment(date);
      });
    }).flat()).format('YYYY-MM-DD');
  }

  updateChartData = (statisticCharts, channelId) => {
    this.chartData.showCharts = [];
    const filterDataTotal = (delimiter) => {
      return this.chartData.filterDates.map((currentDate) => {
        if (delimiter === INTERNET_STATISTICS[3] && channelId === 1) {
          const sumCost = this.chartDataRequest.reduce((total, obj) => (obj.date === currentDate) ? total + Number(obj[INTERNET_STATISTICS[2]]) : total, 0);
          const sumClicks = this.chartDataRequest.reduce((total, obj) => (obj.date === currentDate) ? total + Number(obj[INTERNET_STATISTICS[1]]) : total, 0);
          const sum = sumClicks === 0 ? 0 : Math.round(sumCost / sumClicks);
          return [currentDate, sum, 0];
        }
        if (delimiter === TARGET_STATISTIC[3] && channelId === 6) {
          const sumSpent = this.chartDataRequest.reduce((total, obj) => (obj.date === currentDate) ? total + Number(obj[TARGET_STATISTIC[2]]) : total, 0);
          const sumClicks = this.chartDataRequest.reduce((total, obj) => (obj.date === currentDate) ? total + Number(obj[TARGET_STATISTIC[1]]) : total, 0);
          const sum = sumClicks === 0 ? 0 : Math.round(sumSpent / sumClicks);
          return [currentDate, sum, 0];
        }
        const sum = Math.round(this.chartDataRequest.reduce((total, obj) => (obj.date === currentDate) ? total + Number(obj[delimiter]) : total, 0));
        return [currentDate, sum, 0];
      });
    };

    const filterDataChartId = (delimiter, id) => {
      const dataId = this.chartDataRequest.filter(({ tool_id }) => tool_id === id);
      const dataIdFilter = [];
      this.chartData.filterDates.forEach((date) => {
        dataId.forEach((item) => {
          if (date === item.date) dataIdFilter.push(item);
        });
      });
      return dataIdFilter.map(item => {
        return [item.date, Math.round(item[delimiter]), id];
      });
    };


    this.chartData.toolsId.forEach((toolId) => {
      if (Number(toolId) === 0) {
        this.chartData.showCharts.push(statisticCharts.CHARTS.map((chart) => {
          return filterDataTotal(chart);
        }));
      } else {
        this.chartData.showCharts.push(statisticCharts.CHARTS.map((chart) => {
          return filterDataChartId(chart, toolId);
        }));
      }
    });

    const calculateTotal = (index) => {
      const { showCharts, toolsId } = this.chartData;
      let totalSum = 0;
      if (toolsId.includes(0)) {
        // index = 3 Средняя цена за клик
        if (index === 3) {
          const totalSumCosts = showCharts[0][2].reduce((acc, el) => acc + el[1], 0);
          const totalSumClicks = showCharts[0][1].reduce((acc, el) => acc + el[1], 0);
          return Math.round(totalSumClicks !== 0 ? totalSumCosts / totalSumClicks : 0);
        }
        totalSum = showCharts[0][index].reduce((acc, el) => acc + el[1], 0);
        return Math.round(totalSum);
      }
      // index = 3 Средняя цена за клик (высчитываем отношение сумммы расходов index = 2 к сумме кликов index = 1)
      if (index === 3) {
        const totalSumCosts = showCharts.reduce((accTotal, el1) => accTotal + el1[2].reduce((acc, el2) => acc + el2[1], 0), 0);
        const totalSumClicks = showCharts.reduce((accTotal, el1) => accTotal + el1[1].reduce((acc, el2) => acc + el2[1], 0), 0);
        return Math.round(totalSumClicks !== 0 ? totalSumCosts / totalSumClicks : 0);
      }
      totalSum = showCharts.reduce((accTotal, el1) => accTotal + el1[index].reduce((acc, el2) => acc + el2[1], 0), 0);
      return Math.round(totalSum);
    };


    this.chartData.title = statisticCharts.CHARTS.map((chart, index) => {
      return {
        name: statisticCharts.TITLES[chart],
        amount: calculateTotal(index),
      };
    });
  }
}

const statistics = new MyCampaignsStatistic();

export default statistics;
// Сохраняем последний набор дат для дефолтного графика(для присудствия сетки графиков)
reaction(
  () => statistics.chartData.filterDates,
  () => {
    if (statistics.chartData.filterDates.length !== 0) {
      statistics.chartData.defaultDates = statistics.chartData.filterDates;
    }
  },
);

reaction(
  () => statistics.chartData.toolsId,
  () => {
    const channelType = statistics.order.channel_type;

    const channel = Common.getChannelsOptions.find(item => item.label === channelType);

    statistics.updateChartData(CHANNEL_TYPE_CHARTS[channel.value], channel.value);
  },
);

reaction(
  () => statistics.chartDataRequest,
  () => {
    const dateFormat = statistics.chartDataRequest.map(({ date }) => date.slice(0, 10));
    statistics.chartDataSms.filterDatesDays = Array.from(new Set(dateFormat));
  },
);

reaction(
  () => statistics.chartDataSms.filterDatesDays,
  () => {
    statistics.chartDataSms.filterDatesHours = [];
    const beforeDate = moment(statistics.chartDataSms.filterDatesDays[statistics.chartDataSms.filterDatesDays.length - 1])
      .add(1, 'days').format('YYYY-MM-DD');
    statistics.chartDataRequest.forEach((item) => {
      if (moment(item.date).isAfter(statistics.chartDataSms.filterDatesDays[0]) &&
        moment(item.date).isBefore(beforeDate) ||
        statistics.chartDataSms.filterDatesDays.length === 1) {
        statistics.chartDataSms.filterDatesHours.push(item);
      }
    });
    // Если данных нет в эти даты, подставляем моковые для правильного рендера графика
    if (statistics.chartDataSms.filterDatesHours.length === 0) {
      statistics.chartDataSms.filterDatesDays.forEach(oneDay => {
        new Array(24).fill(1).forEach((_, i) => {
          statistics.chartDataSms.filterDatesHours.push({
            date: moment(oneDay).add(i, 'hours').format('YYYY-MM-DD HH:mm:ss'),
          });
        });
      });
    }
  },
);

const chart1 = [
  {
    day: '2022-09-08T00:00:00',
    hours: [
      {
        time: 11,
        impressions: 2,
        clicks: 1,
        complete: 3,
      },
      {
        time: 12,
        impressions: 3,
        clicks: 2,
        complete: 4,
      },
      {
        time: 13,
        impressions: 4,
        clicks: 3,
        complete: 5,
      },
    ],
  },
  {
    day: '2022-09-09T00:00:00',
    hours: [
      {
        time: 9,
        impressions: 5,
        clicks: 4,
        complete: 6,
      },
      {
        time: 10,
        impressions: 5,
        clicks: 4,
        complete: 6,
      },
      {
        time: 11,
        impressions: 6,
        clicks: 5,
        complete: 7,
      },
    ],
  },
];
