import React from 'react';
import styles from './styles.module.pcss';

const CampaignProgressBar = ({
  completed,
  active,
  total,
}: { completed: number, active: number, total: number }) => {
  const currentActive = 100 * active / total;
  const currentCompleted = 100 * completed / total;
  return (
    <div className={styles.progressBar}>
      <div className={styles.active} style={{ width: `${currentActive}%` }} />
      <div className={styles.completed} style={{ width: `${currentCompleted}%` }} />
    </div>
  );
};

export default CampaignProgressBar;
