import { observable, action, computed, reaction, runInAction } from 'mobx';
import moment from 'moment';
import { adTechRequest, axiosAuthorizedRequest } from '../../requests/helpers';
import { debounce } from '../../utils/debounce';
import customConfirm from '../../components/modals/confirmDialog';
import { SendingStatus } from '../../components/modals/Financial';
import { toastAccessNotification } from '../../modules/toast-notifications';
import UserInfo from './UserInfo';

class MyFinances {
  @observable invoices = null;
  @observable invoicesLoading = true;
  @observable pageLoaded = false;
  @observable selectedInvoicesIds = [];
  @observable filterByStatus = null;
  @observable allInvoicesToggled = false;
  @observable sendingEmail = false;
  @observable search = '';
  @observable modalToShow = null // prepareToSend, changeEmail, successSending, errorEmailSending
  @observable expensesLoading = true;
  @observable operationHistoryLoading = true;
  @observable operationHistoryError = false;
  @observable shouldDisplayDates = null;
  @observable email = '';
  @observable errorOnSendingInvoices = false;
  @observable.ref operationHistory = {};
  @observable balance = null;
  @observable balanceLoading = true;
  @observable values = {
    dates: [],
  }
  @observable expensesList = [];

  @action resetToDefault = () => {
    this.selectedInvoicesIds = [];
    this.filterByStatus = null;
    this.modalToShow = null;
    this.pageLoaded = false;
    this.allInvoicesToggled = false;
    this.invoicesLoading = true;
    this.search = '';
    this.invoices = null;
    this.sendingEmail = false;
    this.expensesList = [];
  }

  @computed get showEmptyPlaceHolder() {
    return !this.expensesList.length || (this.getTotalExpenses === 0 && !this.expensesLoading);
  }

  setDates = () => {
    this.values.dates = [new Date(UserInfo.data.offer_accepted_date), new Date()];
  }

  @action getInvoices = async (page = 1) => {
    this.invoicesLoading = true;
    try {
      // this.invoices = await axiosAuthorizedRequest({
      //   url: `/api/v1/client/invoices/?page=${page}${this.filterByStatus !== null ? `&status=${this.filterByStatus}` : ''}${this.search !== '' ? `&search=${this.search}` : ''}`,
      // });
      runInAction(() => {
        this.chekIfEveryInvoiceSelected();
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.pageLoaded = true;
      this.invoicesLoading = false;
    }
  }

  getBalance = async () => {
    const { balance } = await axiosAuthorizedRequest({
      url: `/api/v1/client/company/${UserInfo.data?.company?.id}/balance/?date=${Date.now()}`,
    });
    this.balance = +balance;
    this.balanceLoading = false;
  }

  checkIfHasCosts = async () => {
    try {
      await axiosAuthorizedRequest({
        url: '/api/v1/client/invoices/costs/',
        method: 'POST',
      });
      this.shouldDisplayDates = true;
    } catch (e) {
      this.shouldDisplayDates = false;
    }
  }

  @action getExpenses = async () => {
    try {
      this.expensesLoading = true;
      // ME-2761 пункт 3
      if (this.shouldDisplayDates === null) {
        await this.checkIfHasCosts();
      }
      this.expensesList = await axiosAuthorizedRequest({
        url: '/api/v1/client/invoices/costs/',
        method: 'POST',
        data: {
          date_start: this.startDate,
          date_end: this.endDate,
        },
      });
    } catch (e) {
      this.expensesList = [];
    } finally {
      this.expensesLoading = false;
    }
  }

  @action getOperationHistory = async (page = 1) => {
    try {
      this.operationHistoryLoading = true;
      this.operationHistoryError = false;
      const history = await adTechRequest({
        url: `/api/Customers/billingHistory?page=${page}`,
      });
      runInAction(() => {
        this.operationHistory = history;
      });
    } catch (e) {
      runInAction(() => {
        this.operationHistoryLoading = false;
        this.operationHistoryError = true;
      });
    } finally {
      this.operationHistoryLoading = false;
    }
  }

  @computed get getTotalExpenses() {
    if (this.expensesList.length) {
      return this.expensesList.map(item => item.value).reduce((prev, curr) => {
        return prev + curr;
      });
    }
    return 0;
  }

  @computed get endDate() {
    return this.values.dates[1] ? moment(this.values.dates[1]).format('YYYY-MM-DD') : undefined;
  }

  @computed get startDate() {
    return this.values.dates[0] ? moment(this.values.dates[0]).format('YYYY-MM-DD') : undefined;
  }

  @computed get chartDates() {
    return {
      min_start: null,
      min_end: (startDate) => {
        if (startDate) {
          return moment(startDate);
        }
        if (this.values.dates[0]) {
          return moment(this.values.dates[0]);
        }
        return undefined;
      },
    };
  }

  @action chekIfEveryInvoiceSelected = () => {
    const isEverythingSelected = this.invoices?.results.every(item => {
      return this.selectedInvoicesIds.includes(item.id);
    });
    this.allInvoicesToggled = true;
    this.allInvoicesToggled = !!isEverythingSelected;
  }
  @action toggleInvoice = (id) => {
    if (this.selectedInvoicesIds.includes(id)) {
      this.selectedInvoicesIds.remove(id);
    } else {
      this.selectedInvoicesIds.push(id);
    }
    this.chekIfEveryInvoiceSelected();
  }

  @action sendInvoicesToEmail = async ({ email = undefined, invoices = undefined }) => {
    try {
      this.sendingEmail = true;
      await axiosAuthorizedRequest({
        url: '/api/v1/client/invoices/reprint/',
        method: 'POST',
        data: {
          email: email || this.email || UserInfo.data.email,
          invoices: invoices || this.selectedInvoicesIds.length ? this.selectedInvoicesIds : undefined,
          is_late_payment: UserInfo.data.late_payment ? UserInfo.data.late_payment : undefined,
        },
      });
      toastAccessNotification(`Счёт успешно сохранён и отправлен на почту ${email || this.email || UserInfo.data.email}`);
      customConfirm(SendingStatus);
    } catch (e) {
      console.error(e);
    } finally {
      this.sendingEmail = false;
    }
  }

  @action toggleAllInvoices = () => {
    if (!this.allInvoicesToggled) {
      this.invoices?.results.forEach(item => {
        if (!this.selectedInvoicesIds.includes(item.id)) this.selectedInvoicesIds.push(item.id);
      });
    } else {
      this.invoices?.results.forEach(item => {
        this.selectedInvoicesIds.remove(item.id);
      });
    }
    this.chekIfEveryInvoiceSelected();
  }

  @computed get isEmpty() {
    return this.pageLoaded && !this.invoicesLoading && this.totalInvoices === 0;
  }
  @computed get totalInvoices() {
    if (!this.invoices) return 0;
    return Object.values(this.invoices.statuses).reduce((a, b) => a + b);
  }
}

const myFinancesStore = new MyFinances();

reaction(
  () => myFinancesStore.search,
  debounce(() => myFinancesStore.getInvoices(1), 1000),
);

reaction(
  () => myFinancesStore.search,
  () => {
    myFinancesStore.invoicesLoading = true;
  },
);
export default myFinancesStore;
