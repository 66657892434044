import React, { FC } from 'react';

import styles from './styles.pcss';

type WrapperPageProps = {
  isAudienceNew?: boolean;
};

export const WrapperPage: FC<WrapperPageProps> = ({ children, isAudienceNew, ...props }) => {
  return (<div { ...props } className={!isAudienceNew ? styles.wrapperPage : styles.wrapperPageAudience}>{children}</div>);
};
