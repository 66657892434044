import { PUSH_TARGET_ACTIONS } from 'redesignSrc/pages/constants';

export const SHORT_LINK = 'https://beel.ink/*****';

export type TypeProps = {
  validation?:any,
  name?:string,
  onChange?:(e:string)=>void,
  onBlur?:()=>void,
}


export const option = [
  {
    label: 'Переход по ссылке на сайт или на магазин мобильных приложений',
    value: 'link',
  },
  {
    label: 'Набор номера для голосового вызова',
    value: 'phone',
  },
];

export const initAction = (typeAction:number | null) => {
  if (typeAction === null) return typeAction;
  if (typeAction === PUSH_TARGET_ACTIONS.CALL_ON_NUMBER) {
    return option[1];
  }
  return option[0];
};
