import React from 'react';
import StoreDnd from './StoreDnd';
import { PropsNotDrag } from './types';


const ContainerNotDrag:React.FC<PropsNotDrag> = ({ children }) => {
  const handleMouseDown = () => {
    StoreDnd.isDrag = false;
  };
  const handleMouseUp = () => {
    StoreDnd.isDrag = true;
  };

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {children}
    </div>
  );
};

export default ContainerNotDrag;
