import React, { ReactNode } from 'react';
import styles from './styles.pcss';

type Props = {
  children: ReactNode,
  className?: string | undefined,
}

const MobileOnly: React.FC<Props> = ({ children, className }) => {
  const classes = className ? `${styles.element} ${className}` : styles.element;
  return (
    <div className={classes}>
      {children}
    </div>
  );
};

const DesktopOnly: React.FC<Props> = ({ children, className }) => {
  const classes = className ? `${styles.elementDesktop} ${className}` : styles.elementDesktop;
  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export { MobileOnly, DesktopOnly };
