import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { LoaderBtn } from 'redesignSrc/UI/elements/CardBottomPanelBtns/components/LoaderBtn';
import Preloader from 'components/common/Preloader';

import { DATA_PROCESSING_FAILURE_TOOLTIP, DATA_PROCESSING_TOOLTIP } from '../../../../constants';
import { Tooltip } from './Tooltip';

import styles from './styles.pcss';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  className: string;
  isDisabled?: boolean;
  hasRightArrow?: boolean;
  isWhite?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isGray?: boolean;
  isFetchError?: boolean;
  isTooltip?: boolean;
  text: string;
  id?: string,
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const LoaderButton: React.FC<Props> = ({
  type = 'button',
  className,
  onClick,
  isDisabled,
  isWhite,
  hasRightArrow,
  isGray,
  isFetchError = false,
  isTooltip = false,
  text,
  id,
  startIcon,
}) => {
  const [cntClick, setCntClick] = useState<number>(0);
  const IS_PROCESS = 'В обработке';
  const MAX_CLICK = 2;

  const handleClick = () => {
    if (isFetchError) {
      setCntClick(0);
    }
    setCntClick(prev => prev < MAX_CLICK ? prev + 1 : prev);
  };

  const isProcessing = () => isDisabled && cntClick === MAX_CLICK ? IS_PROCESS : text;

  const isErrorFetch = () => !isFetchError
    ? DATA_PROCESSING_TOOLTIP
    : DATA_PROCESSING_FAILURE_TOOLTIP;

  const renderTooltip = (): JSX.Element | null => {
    if (isTooltip && cntClick === MAX_CLICK) {
      return <Tooltip text={isErrorFetch()} />;
    } else if (isFetchError) {
      return <Tooltip text={isErrorFetch()} />;
    }
    return null;
  };

  const isVisiblePreloaderAndProcessText = () => isDisabled && !isFetchError && cntClick === MAX_CLICK;

  const renderInsideBtn = () => {
    if (isVisiblePreloaderAndProcessText()) {
      return (
        <>
          <Preloader className={classNames(styles.loadBtnPreload)} size="24" />
          <span>{isProcessing()}</span>
        </>
      );
    }
    return <span>{isProcessing()}</span>;
  };

  const classNameList = () => classNames(
    styles.primaryComponent,
    styles.primaryButton,
    hasRightArrow ? styles.arrow : '',
    isGray ? styles.gray : '',
    isWhite ? styles.white : '',
    className,
  );

  return (
    <div
      className={classNames(styles.primaryButton, 'loadBtnWrapper')}
      onClick={handleClick}
    >
      {/* <button
        type={type}
        className={classNameList()}
        onClick={onClick}
        disabled={isDisabled}
        id={id}
      >
        {renderInsideBtn()}
      </button> */}
      <LoaderBtn
        isWhite={isWhite}
        startIcon={startIcon}
        onClick={onClick}
        disabled={isDisabled}
        id={id}
      >{renderInsideBtn()}</LoaderBtn>
      {renderTooltip()}
    </div>
  );
};

export default LoaderButton;
