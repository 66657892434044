import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from 'utils/prop-types';

export default function withCharReplacer(WrappedInput) {
  class CharReplacer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        value: props.value,
      };
    }
        handleChange = (nextValue, event) => {
          const { onChange } = this.props;

          let cleanedValue = nextValue;

          if (this.props.cleanSymbols) {
            cleanedValue = nextValue.replace(/((?![a-zA-Zа-яА-ЯЁё\s-]+).)/gi, '');
          }

          /** otherwise we change state locally and only fire onChange when needed */
          const persistEventData = { target: event.target }; // don't use a whole event.persist() for a bit of optimization
          this.setState({ value: cleanedValue }, () => { // eslint-disable-line react/no-unused-state
            onChange(cleanedValue, persistEventData);
          });
        };

        render() {
          const { value } = this.state;
          const { forwardedRef, autoTrim, trimInitialValue, onRawChange, ...otherProps } = this.props;
          return (
            <WrappedInput
              {...otherProps}
              ref={forwardedRef}
              onChange={this.handleChange}
              value={value}
                />
          );
        }
  }

  CharReplacer.propTypes = {
    /** onChange only fires when value actually changes (doesn't fire if only spaces are added at sides) */
    onChange: PropTypes.func,
    /** whilst onRawChange fires every time any change occurred */
    onRawChange: PropTypes.func,
    value: PropTypes.any,

    /** autoTrim keeps input value the way user entered it, but only fires onChange when value is trimmed */
    autoTrim: PropTypes.bool,
    trimInitialValue: PropTypes.bool,

    /** is not an actual prop */
    forwardedRef: CustomPropTypes.ref,
    cleanSymbols: PropTypes.bool,
  };

  /**
     * Override component name by prepending `Clean~`
     * to make it look nice, for example: `CleanTextInput`
     */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedInput.displayName || WrappedInput.name || 'Input';
    CharReplacer.displayName = `CharReplacer${WrappedComponentName}`;
  }

  return React.forwardRef((props, ref) => (
    <CharReplacer {...props} forwardedRef={ref} />
  ));
}
