import React from 'react';
import { TypographyWithDangling } from '../../../../UI/beeline/TypographyWithDangling/TypographyWithDangling';
import { DialogModal } from '../../../../UI/beeline/Dialogs/DialogModal';
import MoreInfoScreen from '../../../SmsPages/SmsPageSetup/components/SenderName/CreateSenderName/components/MoreInfoScreen';

const InfoDialog = ({ onClose }: { onClose: () => void, }) => {
  return (
    <DialogModal
      onSuccess={onClose}
      onCancel={onClose}
      open
      onClose={onClose}
      title="Как быстро согласовать имя и какие документы не подойдут?"
      cancelLabel="Закрыть"
      confirmLabel="Понятно"
      footer
    >
      <TypographyWithDangling variant="body2">
        <MoreInfoScreen />
      </TypographyWithDangling>
    </DialogModal>
  );
};

export default InfoDialog;
