import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { scrollSmoothToNodeById } from 'utils/scroll';
import { RULES_TYPE_TEMPLATE_GROUP } from 'redesignSrc/pages/NewTemplate/consts';
import useValidation from 'redesignSrc/HOC/hooks/useValidation';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { TextArea, TextInput } from 'redesignSrc/UI/form';
import { FormFieldLabel } from 'redesignSrc/UI/typography';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import styles from '../../styles.pcss';

export interface CreateTemplateGroupModelProps {
  onCloseModal: (val: boolean) => void,
  onCloseList: (val: boolean) => void,
}

export const CreateTemplateGroupModal: FC<CreateTemplateGroupModelProps> = observer(({ onCloseModal, onCloseList }) => {
  const mainRules = RULES_TYPE_TEMPLATE_GROUP;
  const rules = () => mainRules;

  const valid = useValidation({
    name: messageTemplatesStore.formTemplateGroup.values.name,
  }, rules());

  const changeNameHandler = (name: string) => {
    messageTemplatesStore.formTemplateGroup.values.name = name;
  };

  const changeDescriptionHandler = (description: string) => {
    messageTemplatesStore.formTemplateGroup.values.description = description;
  };

  const createGroupHandler = async () => {
    const { firstErrorKey } = valid.getAllValid();
    if (firstErrorKey) {
      scrollSmoothToNodeById(firstErrorKey, 200);
    } else {
      onCloseModal(false);
      onCloseList(false);
      messageTemplatesStore.messageTemplatesGroupName = messageTemplatesStore.formTemplateGroup.values.name;
      messageTemplatesStore.formTemplate.values.groupName = messageTemplatesStore.formTemplateGroup.values.name;
      await messageTemplatesStore.createTemplateGroup();
      await messageTemplatesStore.getAllMessageTemplatesGroup();
    }
  };
  return (
    <>
      <div style={{ marginBottom: '40px' }} className={styles.modalTitle}>Создание группы шаблонов</div>
      <div className="template-modal">
        <div style={{ marginBottom: '40px', textAlign: 'left' }}>
          <FormFieldLabel>Название группы</FormFieldLabel>
          <TextInput
            maxLength={50}
            value={messageTemplatesStore.formTemplateGroup.values.name}
            placeholder="Введите название"
            validation={valid}
            onChange={changeNameHandler}
            name="name"
          />
        </div>
        <div style={{ marginBottom: '20px', textAlign: 'left' }}>
          <FormFieldLabel>Описание группы шаблонов</FormFieldLabel>
          <TextArea
            placeholder="Укажите информацию, если необходимо"
            value={messageTemplatesStore.formTemplateGroup.values.description}
            onChange={changeDescriptionHandler}
            name="description"
          />
        </div>
        <div>
          <PrimaryButton
            className={styles.sendModalBtnCancel}
            onClick={createGroupHandler}
          >
            Создать
          </PrimaryButton>
        </div>
      </div>
    </>
  );
});
