import { action, computed, observable, runInAction, toJS } from 'mobx';
// @ts-ignore
import qs from 'query-string';
// @ts-ignore
import mimetype from 'mimetype';
import UserInfo from 'store/mobx/UserInfo';
import NewOrder from 'store/mobx/NewOrder';
import { ORDER_SENDER_NAME_FIELD } from 'store/NewCampaign/channels/constants';
import { toastErrorNotification } from '../../../../../../modules/toast-notifications';
import {
  axiosBaseRequestAdTech,
  BASE_URL_API_CLIENT,
  prepareMultipartFormData,
} from '../../../../../../requests/helpers';
import { CampaignSenderClient } from '../../../../../ApiClients';

const baseApi = new CampaignSenderClient({
  onResponse: response => Promise as any,
}, BASE_URL_API_CLIENT);

export interface SenderNamesComment {
  text: string,
  type: string,
  createdBy: string,
  createdOn: string
}

export type DocsByBusinessList = {
  id: number,
  documentTypeName: string,
}

export type FilesById = {
  documentTypeId: number,
  documentTypeName: string,
  customFileName: string,
  id?: string,
  file?: File
}

export type SenderNameStoreType = {
  documentsListViewId: null | number,
  form: {
    senderName: string,
    documentType: null | { id: string | number, value: string }
  },
  userFilesById: null | { [key: string]: FilesById[] },
  fetchDocsByBusinessType: (val: number) => Promise<void>,
  docsByBusinessType: DocsByBusinessList[],
  getFilesByDocId: FilesById[] | null,
  showMoreInfo: boolean,
  createName: (name: string) => Promise<string>,
  sendAllFiles: (id: string) => Promise<void>,
  sendComment: (id: string, comment: string) => void,
  getAllFiles: (id: string) => void,
  loading: boolean,
}

export const groupFilesById = (data: FilesById[]) => {
  const filesById: { [key: string]: FilesById[] } = {};
  data.forEach(item => {
    if (item.documentTypeId && !filesById[item.documentTypeId]) {
      filesById[item.documentTypeId] = [item];
    } else if (item.documentTypeId && filesById[item.documentTypeId]) {
      filesById[item.documentTypeId].push(item);
    }
  });
  return filesById;
};


export type TypeSenderNamesValues = {
  id: number,
  senderNameId: number,
  senderName: string,
  ban: string,
  customerId: number,
  customerInn: string,
  customerName: string,
  cost: number,
  createdBy: string,
  createdOn: string,
  status: string,
  moderatedBy: string,
  moderationRequestedOn: string,
  moderatedOn: string,
  moderatedTill: string,
  multiOperatorModeratedTill: string;
  multiOperatorModeratedOn: string;
  isMultiOperator: boolean,
  senderNameCorpSmsStatus: string,
}

export type Filter = {
  name: string,
  statuses: any[],
  createdFrom?: string | null,
  createdTo?: string | null,
  skip: number,
  ban: string[],
};

export type TypeSenderNames = {
  data: TypeSenderNamesValues[] | [],
  totalCount: number
}

export const SENDER_NAME_STATUSES: { [key: string]: string } = {
  Draft: 'Черновик',
  Accepted: 'Согласован',
  Rejected: 'Не согласован',
  Verification: 'На согласовании',
};

export type Status = {
  id: number,
  value: string,
  description: string

}

class SenderNameStore {
  @observable showEditSenderNameModal = false;
  @observable showDocsSenderNameModal = false;
  @observable selectedSenderNameId = 0;
  @observable isLoadingPage = false;
  @observable agreementModal = false;
  @observable isCreateSenderNameMode = false;
  @observable showCreateSenderNameModal = false;
  @observable editSenderNameId: number | null = null;
  @observable senderNameComment: string = '';
  @observable senderStatus: string = '';
  @observable firstComment: string = '';
  @observable isUploadfileWhenStatusSenderAccepted = false;

  @observable SENDERS_LIMIT: number = 10;
  @observable senderNames: TypeSenderNames = {
    data: [],
    totalCount: 1,
  };

  @observable comments: SenderNamesComment[] | [] = [];
  @observable comment: SenderNamesComment = {
    text: '',
    type: '',
    createdBy: '',
    createdOn: '',
  };

  @action resetPages = () => {
    this.filter.skip = 0;
    this.senderNames = {
      data: [],
      totalCount: 1,
    };
    this.nextPageUrl = `/api/messageTemplates${this.createSearchUrl()}`;
  }

  @action resetFilters = () => {
    this.nextPageUrl = `/api/messageTemplates${this.createSearchUrl()}`;
    this.form = {
      senderName: '',
      documentType: [],
      comment: '',
    };
    this.filter = {
      statuses: [],
      skip: 0,
      name: '',
      createdFrom: undefined,
      createdTo: undefined,
      ban: [],
    };
  };

  @action resetForm = () => {
    this.comments = [];
    this.nameSender = '';
    this.actualRejectComment = null;
    this.files = [];
    this.ban = null;
    this.clientFiles = [];
    this.senderStatus = '';
    this.senderNameComment = '';
    this.userFilesById = null;
    this.loading = false;
    this.senderStatus = '';
    this.form = {
      senderName: '',
      documentType: [],
      comment: '',
    };
  }
  @observable statuses: Status[] = [];
  @observable statusDescription: { [key: string]: string } = {};
  @observable filter: Filter = {
    statuses: [],
    skip: 0,
    name: '',
    createdFrom: undefined,
    createdTo: undefined,
    ban: [],
  };
  @observable totalSenderCount = 0
  @observable actualRejectComment: SenderNamesComment | null = null;

  @observable isLoading: boolean = false;
  @observable isMultiOperator: boolean = false;
  @observable nameSender: string = '';
  @observable moderatedTill: string = '';
  @observable senderId: number | null = null;
  @observable duplicateId: number | null = null;
  @observable previousName: string = '';
  @observable files: any[] = [];
  @observable clientFiles: any[] = [];
  @observable errorOnFileUploading: string | null = null;
  @observable filesProgress: { [key: string]: number } | null = null
  @observable ban: null | string = null;
  @action resetState = () => {
    this.previousName = '';
    this.ban = null;
    this.files = [];
    this.clientFiles = [];
    this[ORDER_SENDER_NAME_FIELD] = '';
    this.errorOnFileUploading = null;
    this.nameSender = '';
    this.senderId = null;
    this.duplicateId = null;
    this.senderNameComment = '';
    this.showEditSenderNameModal = false;
  }

  createSearchUrl = () => {
    return `?take=${this.SENDERS_LIMIT}&${qs.stringify(toJS(this.filter), { skipEmptyString: true })}`;
  }

  @observable nextPageUrl?: string | null = `/api/senderNames${this.createSearchUrl()}`;

  private buildStatuses(statuses: any[]) {
    const result: any[] = [];
    for (const key in statuses) {
      if ({}.hasOwnProperty.call(statuses, key)) {
        result.push({ value: key, description: statuses[key].type });
      }
    }
    return result;
  }

  @action getStatuses = async () => {
    if (this.statuses.length !== 0) return;
    const statuses = await axiosBaseRequestAdTech({
      url: '/api/resources/campaigns/senderNamesStatuses',
    });

    runInAction(() => {
      this.statuses = this.buildStatuses(statuses);
      if (!this.statusDescription) {
        this.statusDescription = {};
      }
      this.statuses.forEach((status: Status) => {
        this.statusDescription[status.value] = status.description;
      });
    });
  };

  isAllowMimeType = (file: File) => {
    const mapMimeType = ['image/jpeg', 'image/png', 'application/pdf', 'application/jpg', 'application/x-zip-compressed', 'application/x-rar-compressed', 'application/zip'];
    return mapMimeType.includes(mimetype.lookup(file.name));
  }

  @action isShowUploadFiles = () => {
    if (!NewOrder.smsCampaign.isOtherOperators) {
      return this.senderStatus !== 'Accepted' && this.senderStatus !== 'Verification' && !this.isMultiOperator;
    }
    return this.senderStatus !== 'Accepted' && this.senderStatus !== 'Verification';
  }

  @action updateSenderName = async () => {
    await axiosBaseRequestAdTech({
      url: `api/senderNames/${NewOrder.smsCampaign.senderId}`,
      method: 'POST',
      data: {
        senderName: this.nameSender,
      },
    });
  }

  @action updateSenderNameSection = async () => {
    if (this.clientFiles.length > 0) {
      await this.sendAllFiles(`${this.senderId}`);
      await this.getAllFilesBySenderIdSection();
    }
    this.senderId && await this.createComment(this.senderId);
    await axiosBaseRequestAdTech({
      url: `api/senderNames/${this.senderId}`,
      method: 'POST',
      data: {
        senderName: this.nameSender,
      },
    });
  }

  @action createSenderNameSection = async (ban?: string, customerId?: number) => {
    try {
      this.isLoadingPage = true;
      const { id, senderName } = await axiosBaseRequestAdTech({
        url: 'api/senderNames',
        params: {
          senderName: this.nameSender,
          ban,
          customerId,
        },
        method: 'POST',
      });
      this.senderId = id;
      this.nameSender = senderName;
      if (id) {
        await this.createComment(id);
        if (this.clientFiles.length > 0) {
          await this.uploadFileListSection();
          await this.getAllFilesBySenderIdSection();
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingPage = false;
    }
  }

  @action uploadFileListSection = async () => {
    try {
      for await (const file of this.clientFiles) {
        await axiosBaseRequestAdTech({
          url: `api/senderNames/${this.senderId}/files`,
          method: 'POST',
          data: prepareMultipartFormData({
            File: file,
          }),
        });
      }
      this.clientFiles = [];
    } catch (e) {
      console.error(e);
    }
  };

  @action getAllFilesBySenderIdSection = async () => {
    this.files = await axiosBaseRequestAdTech({
      url: `api/senderNames/${this.senderId}/files`,
    });
  }

  @action createSenderName = async () => {
    const customerIdQuery = UserInfo.isRoleSS ? `&customerId=${NewOrder.currentOrder.customerId}&ban=${NewOrder.currentOrder.ban}` : '';
    const { id, senderName } = await axiosBaseRequestAdTech({
      url: `api/senderNames?senderName=${this.nameSender}${customerIdQuery}`,
      method: 'POST',
      data: {
        ban: '',
      },
    });
    NewOrder.smsCampaign.senderId = id;
    NewOrder.smsCampaign.senderName = senderName;
    if (id) {
      await this.createComment(id);
      if (this.clientFiles.length > 0) {
        await this.uploadFileList(id);
        await this.getAllFilesBySenderId();
        await NewOrder.saveOrder();
        await NewOrder.calculate();
      }
    }
  }

  @action uploadFileList = async (senderId: number) => {
    try {
      for await (const file of this.clientFiles) {
        await axiosBaseRequestAdTech({
          url: `api/senderNames/${NewOrder.smsCampaign.senderId}/files`,
          method: 'POST',
          data: prepareMultipartFormData({
            File: file,
          }),
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  @action createSenderNameUploadFile = (file: File) => {
    if (!file.size) {
      this.errorOnFileUploading = 'Нельзя загрузить пустой файл';
    }
    if (!this.isAllowMimeType(file)) {
      toastErrorNotification('Неверный формат файла');
      return;
    }
    this.clientFiles.push(file);
    this.errorOnFileUploading = null;
    if (this.senderStatus === 'Accepted') {
      this.isUploadfileWhenStatusSenderAccepted = true;
    }
  };

  @action getAll = async () => {
    await baseApi.senderNamesGET(0, 777);
  }

  @action getAllSenderNames = async () => {
    this.isLoading = true;
    try {
      const data = await axiosBaseRequestAdTech({
        url: `/api/senderNames/${this.createSearchUrl()}`,
      });
      if (!UserInfo.isAggregator) {
        runInAction(() => {
          if (!data.data.length) this.nextPageUrl = null;
          if (this.filter.skip === 0) {
            this.senderNames.data = data.data;
          } else {
            this.senderNames.data = [...this.senderNames.data, ...data.data];
          }
          this.totalSenderCount = data.totalCount;
        });
      } else {
        this.senderNames.data = data.data;
        this.totalSenderCount = data.totalCount;
      }
    } catch (e) {
      this.nextPageUrl = null;
    } finally {
      this.isLoading = false;
    }
  }

  getMoreTemplates = async () => {
    this.filter.skip += 10;
    if (this.filter.skip <= this.totalSenderCount) {
      await this.getAllSenderNames();
    }
  }

  @action getAllComments = async (id: number) => {
    const data = await axiosBaseRequestAdTech({
      url: `api/senderNames/${id}/comments?skip=0&take=777`,
    });
    this.comments = data;
    this.actualRejectComment = this.comments
      .filter((comment) => comment.type === 'Moderator')
      .sort((a, b) => a.createdOn < b.createdOn ? 1 : -1)[0] || null;
    const customerComment = this.comments.find((comment) => comment.type === 'Customer');
    if (customerComment) {
      this.form.comment = customerComment.text;
    }
  }

  @action createComment = async (senderId: number) => {
    this.senderNameComment && await baseApi.comments(senderId, this.senderNameComment);
  }

  @action createCommentSection = async (senderId: number) => {
    this.senderNameComment && await baseApi.comments(senderId, this.senderNameComment);
  }

  @action uploadFileSection = async (file: File) => {
    if (!file.size) {
      this.errorOnFileUploading = 'Нельзя загрузить пустой файл';
    }
    try {
      if (!file.size) {
        this.errorOnFileUploading = 'Нельзя загрузить пустой файл';
        return;
      }

      this.isLoading = true;
      await axiosBaseRequestAdTech({
        url: `api/senderNames/${this.senderId}/files`,
        method: 'POST',
        data: prepareMultipartFormData({
          File: file,
        }),
      });

      await this.getAllFilesBySenderIdSection();

      this.errorOnFileUploading = null;
    } catch (e) {
      if (e?.response?.data?.file) {
        // @ts-ignore
        this.errorOnFileUploading = e?.response?.data?.file[0];
      } else {
        // @ts-ignore
        this.errorOnFileUploading = e;
      }
    } finally {
      this.isLoading = false;
    }
  }

  @action uploadFile = async (file: File) => {
    if (!file.size) {
      this.errorOnFileUploading = 'Нельзя загрузить пустой файл';
    }
    try {
      if (!file.size) {
        this.errorOnFileUploading = 'Нельзя загрузить пустой файл';
        return;
      }
      this.isLoading = true;
      await axiosBaseRequestAdTech({
        url: `api/senderNames/${this.editSenderNameId ? this.editSenderNameId : NewOrder.smsCampaign.senderId}/files`,
        method: 'POST',
        data: prepareMultipartFormData({
          File: file,
        }),
      });
      await this.getAllFilesBySenderId();
      this.errorOnFileUploading = null;
    } catch (e) {
      if (e?.response?.data?.file) {
        // @ts-ignore
        this.errorOnFileUploading = e?.response?.data?.file[0];
      } else {
        // @ts-ignore
        this.errorOnFileUploading = e;
      }
    } finally {
      this.isLoading = false;
    }
  }

  @action getSenderNameByIdSection = async (senderId: number) => {
    const { senderName, id, status, isMultiOperator, moderatedTill, ban } = await axiosBaseRequestAdTech({
      url: `api/senderNames/${senderId}`,
    });
    this.nameSender = senderName;
    this.moderatedTill = moderatedTill;
    this.senderId = id;
    this.senderStatus = status;
    this.isMultiOperator = isMultiOperator;
    this.ban = ban;
  }

  @action getSenderNameById = async (senderId: number | null = null) => {
    const { senderName, id, status, isMultiOperator, moderatedTill, ban } = await axiosBaseRequestAdTech({
      url: `api/senderNames/${senderId || NewOrder.smsCampaign.senderId}`,
    });
    this.nameSender = senderName;
    this.moderatedTill = moderatedTill;
    this.senderId = id;
    this.senderStatus = status;
    this.isMultiOperator = isMultiOperator;
    this.ban = ban;
  }

  @action getCommentsForSenderName = async () => {
    this.comments = await axiosBaseRequestAdTech({
      url: `api/senderNames/${NewOrder.smsCampaign.senderId}/comments?skip=0&take=777`,
    });
  }

  @action getAllFilesBySenderId = async () => {
    this.files = await axiosBaseRequestAdTech({
      url: `api/senderNames/${NewOrder.smsCampaign.senderId || this.senderId}/files`,
    });
  }

  @action getExistingFiles = async () => {
    if (this.editSenderNameId) {
      this.files = await axiosBaseRequestAdTech({
        url: `api/senderNames/${NewOrder.smsCampaign.senderId}/files`,
      });
    }
  }

  @action checkForDuplicates = async (ban?: string | null) => {
    try {
      this.duplicateId = await axiosBaseRequestAdTech({
        url: `/api/senderNames/check?senderName=${this.nameSender}&ban=${ban}`,
      });
      // this.duplicateId = 5412;
    } catch (error) {
      this.duplicateId = null;
      console.error(error);
    }
  }

  @action handleClientFileRemove = (file: File) => {
    this.clientFiles = this.clientFiles.filter(f => f !== file);
  }

  @action verification = async () => {
    await axiosBaseRequestAdTech({
      url: `api/senderNames/${NewOrder.smsCampaign.senderId}/verification`,
      method: 'POST',
    });
  }

  @action verificationSection = async () => {
    await axiosBaseRequestAdTech({
      url: `api/senderNames/${this.senderId}/verification`,
      method: 'POST',
    });
  }

  @action handleFileRemove = async (id: number, isEditMode: boolean = false) => {
    try {
      if (isEditMode) {
        await axiosBaseRequestAdTech({
          url: `api/senderNames/${NewOrder.smsCampaign.senderId}/files/${id}`,
          method: 'DELETE',
        });
        this.files = this.files?.filter((file: any) => file.id !== id);
      }
    } catch (e) {
      console.error(e);
    }
  };

  @action handleFileRemoveSection = async (id: number, isEditMode: boolean = false) => {
    try {
      if (isEditMode) {
        await axiosBaseRequestAdTech({
          url: `api/senderNames/${this.senderId}/files/${id}`,
          method: 'DELETE',
        });
        this.files = this.files?.filter((file: any) => file.id !== id);
      }
    } catch (e) {
      console.error(e);
    }
  };

  @action deleteByIdSection = async (id: number) => {
    try {
      await axiosBaseRequestAdTech({
        url: `api/senderNames/${id}`,
        method: 'DELETE',
      });

      await this.getAllSenderNames();
      this.senderNames.data = this.senderNames.data.filter((item) => item.id !== id);
    } catch (e) {
      console.error(e);
    }
  };

  @computed get isAcceptedSederName() {
    return this.senderStatus === 'Accepted';
  }

  @observable docsByBusinessType: DocsByBusinessList[] = [];

  @observable form: { senderName: string|null, documentType: {value: string}[] | [], comment: string }= { senderName: '', documentType: [], comment: '' };

  @observable loading = false;

  fetchDocsByBusinessType = async (type: number) => {
    const files = await axiosBaseRequestAdTech({ url: `/api/documentTypes?businessTypeId=${type}` });
    this.docsByBusinessType = files as DocsByBusinessList[];
  }

  @observable userFilesById: null | { [key: string]: FilesById[] } = {};

  @observable documentsListViewId: null | number = null;

  @observable showMoreInfo = false;

  @computed get getFilesByDocId() {
    return this.userFilesById;
  }

  createName = async (name: string, ban?: string, customerId?: string | number) => {
    const data: { id: string } = await axiosBaseRequestAdTech({
      url: '/api/senderNames',
      params: {
        senderName: name,
        ban: ban || NewOrder.order?.ban || UserInfo.data?.company?.ban || '',
        customerId: customerId || NewOrder.order?.customerId || '',
      },

      method: 'POST',
    });
    return data.id;
  }

  updateName = async ({ name, senderNameId }: { name: string, senderNameId: string | number }) => {
    await axiosBaseRequestAdTech({
      url: `/api/senderNames/${senderNameId}`,
      data: {
        senderName: name,
      },
      method: 'POST',
    });
  }

  sendAllFiles = async (id: string) => {
    if (this.userFilesById) {
      Object.entries(this.userFilesById).forEach(([key, values]) => {
        values.map(async (document) => {
          if (document.file) {
            const formData = new FormData();
            formData.append('file', document.file);
            formData.append('documentTypeId', `${document?.documentTypeId}`);
            formData.append('customFileName', `${document.customFileName}`);

            await axiosBaseRequestAdTech({
              url: `/api/senderNames/${id}/files`,
              method: 'POST',
              data: formData,
            });
          }
        });
      });
    }
  }

  sendComment = async (id: string, comment: string) => {
    await baseApi.comments(+id, comment);
  }
}

const senderNameStore = new SenderNameStore();
export { senderNameStore };
