import React from 'react';
import classNames from 'classnames/bind';
import { PureButton } from 'components/buttons';
import styles from './styles.pcss';

export type ButtonsListItem = { label: string, value: any };

export type ButtonsListProps = {
  items: Array<ButtonsListItem> | [],
  onItemClick: (param: ButtonsListItem) => void,
  activeItemValue?: number | string,
  labelViolet?: string,
}

const DropdownButtonsList = ({ items, onItemClick, activeItemValue, labelViolet }: ButtonsListProps) => {
  return (
    <ul className={styles.dropdownButtonsList}>
      {items.map(item => {
        const isViolet = labelViolet === item.label;
        return (
          <li
            key={item.value}
            className={styles.listButtonElement}
          >
            <PureButton
              className={classNames(
                styles.itemButton,
                activeItemValue === item.value && styles.activeButton,
                isViolet && styles.violet,
              )}
              type="button"
              onClick={(e: any) => {
                // e.preventDefault();
                // e.stopPropagation();
                onItemClick(item);
              }}
            >
              {item.label}
            </PureButton>
          </li>
        );
      })}
    </ul>
  );
};

export default DropdownButtonsList;
