import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ORDER_SENDER_NAME_FIELD } from '../../../../../../../store/NewCampaign/channels/constants';
import useValidation from '../../../../../../HOC/hooks/useValidation';
import NewOrder from '../../../../../../../store/mobx/NewOrder';
import { PopupStateless } from '../../../../../../../components/common';
import styles from '../../../../../../../components/modals/AddName/styles.pcss';
import { FormFieldLabel } from '../../../../../../UI/typography';
import CounterTextInput from '../../../../../../UI/form/CounterTextInput';
import { InfoNameSender, UploadSenderNameFiles } from '../components';
import { PrimaryButton } from '../../../../../../UI/buttons';
import { senderNameStore } from '../SenderNameStore';
import { TextArea } from '../../../../../../UI/form';
import { editSenderNameRules } from './consts';
import { EditSenderNameProps } from './EditSenderNameProps';

export const EditSenderName: FC<EditSenderNameProps> = observer(({ onClose, isEditMode }) => {
  const validation = useValidation(senderNameStore, editSenderNameRules);

  useEffect(() => {
    if (senderNameStore.errorOnFileUploading !== null) {
      validation.setErrorsOfKey('files', senderNameStore.errorOnFileUploading!);
    }
  }, [senderNameStore.errorOnFileUploading]);

  useEffect(() => {
    if (!NewOrder.smsCampaign.senderId) return;
    (async () => {
      await senderNameStore.getSenderNameById();
      await senderNameStore.getAllFilesBySenderId();
    })();
  }, []);

  const onSubmit = async () => {
    if (validation.getAllValid().firstErrorKey) {
      return;
    }
    NewOrder.smsCampaign.senderName = senderNameStore.nameSender;
    NewOrder.smsCampaign.senderId = senderNameStore?.senderId;
    await senderNameStore.updateSenderName();
    senderNameStore?.senderId && await senderNameStore.createComment(senderNameStore?.senderId);
    onClose();
    await NewOrder.saveOrder();
    await NewOrder.calculate();
  };

  const renderHeader = () => (
    <>
      <h2 className={styles.title}>Редактирование имени отправителя</h2>
      <p className={styles.explanation}>
        Имя отправителя - это название вашей компании, товарного знака или бренда, которое получатели увидят в поле «отправитель SMS»
      </p>
    </>
  );

  return (
    <PopupStateless opened className={styles.container} onClose={onClose}>
      {renderHeader()}
      <FormFieldLabel className={styles.input}>Имя отправителя</FormFieldLabel>

      <CounterTextInput
        max={11}
        placeholder="Например, beeline"
        keepErrorIndent
        validation={validation}
        name={ORDER_SENDER_NAME_FIELD}
        maxErrorWidth={514}
        onChange={(e) => {
            senderNameStore[ORDER_SENDER_NAME_FIELD] = e;
        }}
        value={senderNameStore[ORDER_SENDER_NAME_FIELD]}
        />

      <InfoNameSender />

      { senderNameStore.isShowUploadFiles() && <UploadSenderNameFiles validation={validation} isEditMode={isEditMode} store={senderNameStore} campaignId={NewOrder.smsCampaign.campaignId} /> }

      <div className={styles.inputHolder}>
        <FormFieldLabel>Комментарий</FormFieldLabel>
        <TextArea
          maxLength={4000}
          placeholder="Укажите любой комментарий, если нужно"
          value={senderNameStore.senderNameComment}
          onChange={(value) => {
            senderNameStore.senderNameComment = value;
          }}
          name="comment"
        />
      </div>

      <div className={styles.btnHolder}>
        <PrimaryButton onClick={onSubmit}>
          Обновить имя
        </PrimaryButton>
      </div>
    </PopupStateless>
  );
});
