import React from 'react';
import classNames from 'classnames';
import TooltipInfo from '../TooltipInfo';
import FileIcon from './assets/File.svg';
import Cross from './assets/Cross.svg';
import LoadIcon from './assets/downloadIcon.svg';
import styles from './styles.pcss';

type Props = {
  name: string,
  description?: string
  onDelete?: (id?: number) => void,
  onLoad?: (id?: number, name?: string) => void,
  id?: number,
  notTakeIt?: string,
  tooltipText?: React.ReactNode | string | null,
  className?: string
}

const UploadedFile: React.FC<Props> = ({
  name,
  onDelete,
  id,
  description,
  tooltipText,
  notTakeIt,
  onLoad,
  className,
}) => {
  return (
    <div className={classNames(styles.uploadedFile, className)}>
      <FileIcon/>
      <span className={styles.nameFile}>{name}</span>
      <span className={styles.descriptionFile}>
        {description}
        <div className={styles.notTakeIt}>
          <span>{notTakeIt}</span>
          {tooltipText && notTakeIt && <TooltipInfo className={styles.tooltipInfo}>{tooltipText}</TooltipInfo>}
        </div>

      </span>
      {onLoad && <LoadIcon className={styles.cross} onClick={() => onLoad(id, name)}/>}
      {onDelete && <Cross className={styles.cross} onClick={() => onDelete(id)}/>}

    </div>
  );
};

export default UploadedFile;
