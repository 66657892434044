import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Preloader from 'components/common/Preloader';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const OverlayLoader = ({ children, isLoading, className, isTopPosition, noBackground, classNamePreload }) => {
  return (
    <div className={cx('component', {
      active: isLoading && !noBackground,
      activeNoBackground: isLoading && noBackground,
    }, className)}>
      {isLoading && <Preloader className={cx('preloader', { top: isTopPosition }, classNamePreload)} />}
      {children}
    </div>
  );
};

OverlayLoader.propTypes = {
  classNamePreload: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isTopPosition: PropTypes.bool,
  className: PropTypes.string,
  noBackground: PropTypes.bool,
};

export default OverlayLoader;
