import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Icon, Tooltip } from '@beeline/design-system-react';
import moment from 'moment';
// @ts-ignore
import qs from 'query-string';
import { Link } from '@reach/router';
// @ts-ignore
import debounce from 'debounce';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';
import { MY_CAMPAIGNS_URL } from 'pages/constants';
import { scrollSmoothTo } from 'utils/scroll';
import { MainTitle } from 'redesignSrc/UI/typography';
import { PageLoader } from 'components/common/Loaders/components';
import TextInput, { IconStart } from 'redesignSrc/UI/form/TextInput';
import MyCampaigns from 'store/mobx/MyCampaigns';
import Templates from 'store/mobx/Templates';
import Common from 'store/mobx/Common';
import DatePicker from 'redesignSrc/UI/form/DatePicker';
import NewAudiences from 'store/mobx/NewAudiences';
import NewOrder from 'store/mobx/NewOrder';
import { Section, Row, Column } from 'redesignSrc/UI/layout';
import DropdownLinks, { CheckBoxList } from 'redesignSrc/components/DropdownLinks';
import { InViewPortDetector } from 'components/helpers';
import { formatPrice, formatPriceTotal } from 'utils/formatting';
import TooltipInfo from 'redesignSrc/UI/elements/TooltipInfo';
import { OrderType } from 'store/mobx/NewOrder/type';
import styles from './styles.pcss';
import Arrow from './assets/arrow.svg';

const MyCampaignsPage = observer(() => {
  const [loading, setLoading] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [isOpenChannels, setIsOpenChannels] = useState(false);
  useEffect(() => {
    const loadMyCampaignPageData = async () => {
      setLoading(true);
      try {
        NewAudiences.resetNewAudiences();
        NewOrder.resetOrder();
        await Templates.getTemplate('myCampaigns');
        await Common.getCampaignsChannelTypes();
        await Common.getStatuses();
        await Common.getOrders();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        scrollSmoothTo(0);
      }
    };

    loadMyCampaignPageData();
  }, []);

  useEffect(() => {
    MyCampaigns.resetPages();
    MyCampaigns.getCampaignByParams();
  }, [
    MyCampaigns.filter.endDate,
    MyCampaigns.filter.search.length,
    MyCampaigns.filter.startDate,
    MyCampaigns.filter.types.length,
    MyCampaigns.filter.statuses.length,
  ]);

  const draftNameReplacer = (name: string) => name === 'Создан' && 'Черновик' || name;

  const statusesForCheckbox = useMemo(() => {
    const statuses = Common.statusesOrder.map(
      (item: { label: string, id: number, description: string }) =>
        ({
          label: draftNameReplacer(item.description),
          value: item.id,
          isChecked: MyCampaigns.filter.statuses.includes(item.id),
        }
        ));
    return {
      statuses,
      filtersApplied: statuses.filter((status: { isChecked: boolean }) => !status.isChecked),
    };
  }, [Common.statusesOrder.length, MyCampaigns.filter.statuses.length]);

  // 'CustomSegment'
  const channelsForCheckbox = useMemo(() => {
    const renameType = Common.orderTypes.map((item) => {
      if (item.value === 'CustomSegment') {
        return {
          ...item,
          description: 'Сбор индивидуальной аудитории',
        };
      }
      if (item.value === 'Push') {
        return {
          ...item,
          description: 'PUSH Stories',
        };
      }
      return item;
    });
    const statuses = renameType.map(
      (item: { description: string, id: number }) =>
        ({ label: item.description, value: item.id, isChecked: MyCampaigns.filter.types.includes(item.id) }
        ));
    return {
      statuses,
      filtersApplied: statuses.filter((status: { isChecked: boolean }) => !status.isChecked),
    };
  }, [Common.orderTypes.length, MyCampaigns.filter.types.length]);
  const selectedStatusesCount = statusesForCheckbox.statuses.length - statusesForCheckbox.filtersApplied.length;
  const selectedChannelsStatuses = channelsForCheckbox.statuses.length - channelsForCheckbox.filtersApplied.length;

  return (
    <PageLoader isLoading={loading} className={styles.container}>
      <MainTitle className={styles.title}>Мои кампании</MainTitle>
      <Section className={styles.section}>
        {
          MyCampaigns.isLoading && <div className={styles.placeholder}/>
        }
        <InputsList/>
        <Row className={styles.bar}>
          <Column className={styles.column1}>
            <span className={`${styles.name} ${styles.menuList}`}>Название</span>
          </Column>

          <Column className={styles.column2}>
            <span className={styles.menuList}>Статус:
              <span className={styles.dropdown} onClick={() => {
                setIsOpenChannels(false);
                setIsOpenStatus(true);
              }}>
                {
                  selectedStatusesCount > 0 &&
                  ` Выбрано ${selectedStatusesCount}` ||
                  ' Все'
                }
                {
                  isOpenStatus && statusesForCheckbox.statuses.length &&
                  <DropdownLinks
                    isOpen
                    onClose={() => setIsOpenStatus(false)}
                    className={styles.linksContainer}
                    dropDownClass={styles.dropdownElement1}
                  >
                    <CheckBoxList
                      items={statusesForCheckbox.statuses}
                      onItemClick={async (param: string | number) => {
                        if (MyCampaigns.filter.statuses.includes(+param)) {
                          // тут игнор, потому что TS не знает, что это псевдо массив
                          // @ts-ignore
                          MyCampaigns.filter.statuses.remove(param);
                        } else {
                          MyCampaigns.filter.statuses.push(+param);
                        }
                      }}
                    />
                  </DropdownLinks> || null
                }
              </span>
            </span>
          </Column>

          <Column className={styles.column3}>
            <span className={styles.menuList}>Вид рекламы:
              <span className={styles.dropdown} onClick={() => {
                setIsOpenStatus(false);
                setIsOpenChannels(true);
              }}>
                {
                  selectedChannelsStatuses > 0 &&
                  ` Выбрано ${selectedChannelsStatuses}` ||
                  ' Все'
                }
                {
                  isOpenChannels && channelsForCheckbox.statuses.length &&
                  <DropdownLinks
                    isOpen
                    onClose={() => setIsOpenChannels(false)}
                    dropDownClass={styles.dropdownElement2}
                    className={styles.dropdownContainer}
                  >
                    <CheckBoxList
                      items={channelsForCheckbox.statuses}
                      onItemClick={async (param) => {
                        if (MyCampaigns.filter.types.includes(+param)) {
                          // @ts-ignore
                          MyCampaigns.filter.types.remove(param);
                        } else {
                          MyCampaigns.filter.types.push(+param);
                        }
                      }}
                    />
                  </DropdownLinks> || null
                }
              </span>
            </span>
          </Column>

          <Column className={styles.column4}>
            <span className={styles.menuList}>Бюджет</span>
          </Column>
        </Row>

        {
          !MyCampaigns.campaigns.length && !MyCampaigns.isLoading &&
          <p className={styles.emptyNote}>{MyCampaigns.defaultText}</p>
        }

        <CampaignsList/>
      </Section>

      {MyCampaigns.campaigns.length !== 0 && <InViewPortDetector onIntersectIn={MyCampaigns.getMoreCampaigns}/>}
    </PageLoader>
  );
});

const getCampaignsWithDebounce = debounce(MyCampaigns.getCampaignByParams, 500);

const InputsList = observer(() => {
  return (
    <div className={styles.wrapperSearch}>

      <TextInput
        startIcon={IconStart.search}
        name="search"
        className={styles.search}
        placeholder="Поиск по названию"
        onChange={async (value) => {
          MyCampaigns.filter.search = value;
          getCampaignsWithDebounce();
        }}
        value={MyCampaigns.filter.search}
      />
      <div className={styles.datesHolder}>
        <DatePicker
          onStartDateClick={async () => {
            MyCampaigns.filter.startDate = undefined;
          }}
          onEndDateClick={async () => {
            MyCampaigns.filter.endDate = undefined;
          }}
          name="dates_search"
          width={200}
          isInputsReadOnly
          secondMarginLeft={8}
          onChange={async value => {
            MyCampaigns.filter.startDate = value[0]?.format('YYYY-MM-DD').substring(0, 10);
            MyCampaigns.filter.endDate = value[1]?.format('YYYY-MM-DD').substring(0, 10);
          }}
          value={[MyCampaigns.filter.startDate, MyCampaigns.filter.endDate]}
        />
      </div>
    </div>
  );
});

const getStatusColor = (status: string) => {
  switch (status) {
    case 'Завершен':
    case 'Создан':
    case 'На согласовании':
    case 'Действующий':
    case 'Черновик':
      return styles.active;
    case 'Модерация не пройдена':
    case 'Отказ':
      return styles.inactive;
    default:
      return styles.inProgress;
  }
};

export const modifyStatus = (status: string) => {
  // if (status === 'На согласовании' && UserInfo.data?.company?.post_pay === false) {
  //   return 'Ожидает оплаты11';
  // }
  return status;
};

const noName = (name: string) => {
  return name || 'Без названия';
};

const CampaignsList = observer(() => {
  const statusDescription = Common.statusDescription as { [key: string]: string };
  const typeDescription = Common.typeDescription as { [key: string]: string };
  return (
    <>
      {
        MyCampaigns.campaigns.length && MyCampaigns.campaigns.map((campaign: OrderType) => {
          if (
            campaign.type === 'CustomSegment' ||
            campaign.type === 'Sms' ||
            campaign.type === 'Push' ||
            campaign.type === 'VoiceTarget' ||
            campaign.type === 'Otm' ||
            campaign.type === 'DigitalBillboard' ||
            campaign.type === 'SocialNetwork' ||
            campaign.type === 'AudioAds' ||
            campaign.type === 'ContextAds'
          ) {
            return (
              <Link to={`${MY_CAMPAIGNS_URL}${campaign.id}/statistics/`} className={styles.statsLink}>
                <div className={styles.wrapList}>
                  <Row className={styles.dataRow} key={campaign.id}>
                    <div className={styles.column1}>
                      <span
                        className={`${styles.columnText} ${styles.campaignName} ${styles.name}`}>{noName(campaign.name)}</span>
                    </div>

                    <div className={styles.column2}>
                      <span
                        className={`${styles.columnText} ${styles.status} ${getStatusColor(statusDescription[campaign.status])}`}>
                        {modifyStatus(statusDescription[campaign.status])}
                        {!!campaign.unseenStopDemandsId?.length &&
                          <TooltipInfo icon="red">Запрос на остановку отклонен модератором</TooltipInfo>
                        }
                      </span>
                    </div>

                    <div className={styles.column3}>
                      <p className={`
                    ${styles.columnText} ${styles.campaignType}
                  `}>
                        {typeDescription[campaign.type]}
                        {
                          campaign.type === 'CustomSegment' &&
                          <Tooltip
                            title="Аудитория доступна в течении 6 месяцев">
                            <Icon iconName={Icons.InfoCircled} className={styles.tooltip}/>
                          </Tooltip>
                        }
                      </p>
                      {campaign.startDate && campaign.endDate &&
                        <p className={styles.date}>
                          {moment(new Date(campaign.startDate)).format('DD.MM.YYYY')} - {moment(new Date(campaign.endDate)).format('DD.MM.YYYY')}
                        </p>
                      }
                    </div>

                    <div className={styles.column4}>
                      <span className={`${styles.columnText} ${styles.campaignName}`}>
                        {(campaign.status === 'Done' || campaign.type === 'CustomSegment') && formatPriceTotal(campaign.invoicePrice) || formatPriceTotal(campaign.discountPrice)}
                      </span>
                    </div>

                    <div className={styles.arrowWrap}><Arrow className={styles.arrow}/></div>
                  </Row>
                </div>

              </Link>
            );
          }

          return (
            <div className={styles.wrapList}>
              <Row className={styles.dataRow} key={campaign.id}>
                <div className={styles.column1}>
                  <span
                    className={`${styles.columnText} ${styles.campaignName} ${styles.name}`}>{noName(campaign.name)}</span>
                </div>

                <div className={styles.column2}>
                  <span
                    className={`${styles.columnText} ${styles.status} ${getStatusColor(statusDescription[campaign.status])}`}>
                    {modifyStatus(statusDescription[campaign.status])}
                  </span>
                </div>

                <div className={styles.column3}>
                  <p className={`
                    ${styles.columnText} ${styles.campaignType}
                  `}>
                    {typeDescription[campaign.type]}
                  </p>
                  {campaign.startDate && campaign.endDate &&
                    <p className={styles.date}>
                      {moment(new Date(campaign.startDate)).format('DD.MM.YYYY')} - {moment(new Date(campaign.endDate)).format('DD.MM.YYYY')}
                    </p>
                  }
                </div>

                <div className={styles.column4}>
                  <span className={`${styles.columnText} ${styles.campaignName}`}>
                    {campaign.status === 'Done' && `${formatPrice(campaign.totalPrice)} ₽` || `${formatPrice(campaign.discountPrice)} ₽`}
                  </span>
                </div>
              </Row>
            </div>
          );
        }) || null
      }
    </>
  );
});

export default MyCampaignsPage;
