import React from 'react';
import { navigate } from '@reach/router';

import Common from 'store/mobx/Common';
import { DesktopOnly, MobileOnly } from '../Responsive';
import LogoIcon from './NewLogo.svg';
import styles from './styles.pcss';
import Close from './assets/close.svg';

const LogoRouterLink = () => {
  return (
    <>
      <DesktopOnly>
        <LogoIcon className={styles.logoLink} onClick={() => navigate('/')}/>
      </DesktopOnly>
      <MobileOnly className={styles.mobileBtn}>
        <button
          className={styles.closebtn}
          onClick={() => {
            Common.isMobileSidebarVisible = false;
          }}>
          <Close/>
        </button>
      </MobileOnly>
    </>
  );
};

export default LogoRouterLink;
