import React, { useState } from 'react';
import ru from 'date-fns/locale/ru';
import classNames from 'classnames/bind';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import withError from 'redesignSrc/HOC/withError';
import ImgCalendar from './assets/calendar.svg';
import ImgCross from './assets/cross.svg';
import styles from './DateRangePicker.pcss';
import './DateRangePicker.css';


type Props = {
  isMobile?: boolean,
  maxDate?: Date;
  onChange: (dateStart: string | Date | null) => void;
  valueDateStart?: string | Date | null;
  startDatePlaceholder?: string;
  minDate?: Date;
  isErrorStart?: boolean,
  onBlur?: () => void,
  isNotCross?: boolean,
  className?: string,
  readOnly?: boolean
  dateFormat?: string
};

const DateSinglePicker: React.FC<Props> = (props) => {
  const {
    onChange,
    maxDate,
    minDate,
    valueDateStart,
    startDatePlaceholder = 'Дата старта',
    isErrorStart,
    isNotCross,
    className,
    onBlur,
    readOnly = true,
    dateFormat = 'dd.MM.yy',
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const onChangeMobile = (date: Date | null): void => {
    const currentDateFormat = date && format(date, 'yyyy-MM-dd');
    onChange(currentDateFormat);
    setIsOpen(false);
  };

  return (
    <div className={classNames(styles.container, className)}>
      <DatePicker
        onBlur={onBlur}
        open={isOpen}
        minDate={minDate}
        maxDate={maxDate}
        selected={valueDateStart ? new Date(valueDateStart) : null}
        onChange={onChangeMobile}
        className={classNames(isErrorStart ? `${styles.input} ${styles.error}` : styles.input)}
        dateFormat={dateFormat}
        calendarClassName={classNames('calendarMob', 'DateRangePicker_DatePicker_calendar')}
        locale={ru}
        placeholderText={startDatePlaceholder}
        readOnly={readOnly}
        onInputClick={() => setIsOpen(true)}
        onClickOutside={() => setIsOpen(false)}
      />
      <div className={styles.imgMob}>
        {valueDateStart && !isNotCross ? (
          <ImgCross
            onClick={() => {
              onChange(null);
            }}
          />
        ) : (
          <ImgCalendar onClick={() => setIsOpen(true)}/>
        )}
      </div>
    </div>
  );
};

export default withError(DateSinglePicker);
