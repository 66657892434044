import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './styles.pcss';

let timerId:number| null = null;

type Props = {
  maxSymbol: number;
  text: string;
  className?: string;
  delay?: number
  classNameTooltip?:string;
  notTooltip?:boolean
}

const ShortenerText:React.FC<Props> = ({
  text,
  maxSymbol,
  className,
  delay = 500,
  classNameTooltip,
  notTooltip,
}) => {
  const [isActive, setIsActive] = useState(false);
  const isShortener = text.length >= maxSymbol;

  const openPopup = () => {
    if (notTooltip) return;
    timerId = window.setTimeout(() => {
      setIsActive(true);
    }, delay);
  };

  const closePopup = () => {
    if (timerId) {
      clearTimeout(timerId);
    }
    setIsActive(false);
  };
  return (
    <div className={className}>
      <span
        className={styles.wrapper}
        onMouseEnter={openPopup}
        onMouseLeave={closePopup}
      >
        {isShortener ?
          `${text.slice(0, maxSymbol - 3)}...` :
          text}
        {isActive && isShortener && <span className={classnames(styles.tooltip, classNameTooltip)}>{text}</span>}
      </span>
    </div>
  );
};

export default ShortenerText;
