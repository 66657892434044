import { axiosBaseRequestAdTech } from 'requests/helpers';
import { getAddFileToAudienceOrderUrlById, GET_FOCUS_REPORTS_API_URL, getUpdateAudienceOrderUrlById, GET_FOCUS_ORDERS_API_URL, getAudienceReportUrlById, AUDIENCE_ORDER_API_URL } from './constants';

export const requestFocusReports = () => axiosBaseRequestAdTech({ url: GET_FOCUS_REPORTS_API_URL });

export const requestFocusOrders = (params) => {
  return axiosBaseRequestAdTech({
    url: GET_FOCUS_ORDERS_API_URL,
    params,
  });
};

export const requestCreateReport = ({ data }) => axiosBaseRequestAdTech({ url: AUDIENCE_ORDER_API_URL, method: 'post', data });
export const requestUpdateReport = ({ data, orderId }) => axiosBaseRequestAdTech({ url: getUpdateAudienceOrderUrlById({ orderId }), method: 'post', data });
export const requestAddFileToReport = ({ data, orderId }) => axiosBaseRequestAdTech({ url: getAddFileToAudienceOrderUrlById({ orderId }), method: 'post', headers: { 'Content-Type': 'multipart/form-data' }, data });

export const requestReport = ({ orderId }) => axiosBaseRequestAdTech({ url: getAudienceReportUrlById({ orderId }) });
