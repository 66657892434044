import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { Button, DropdownMenu, Icon, List, ListItem } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import styles from '../styles.pcss';
import { FilesById, senderNameStore } from '../../../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import EditableField from '../../../SmsPages/SmsPageSetup/components/SenderName/CreateSenderName/components/EditableFields';
import campaignStatistics from '../../../../../store/mobx/TSMS/CampaignStatistics';
import { BASE_URL } from '../../../../../requests/helpers';
import { TypographyWithDangling } from '../../../../UI/beeline/TypographyWithDangling/TypographyWithDangling';
import { DialogModal } from '../../../../UI/beeline/Dialogs/DialogModal';

const FileInList = observer(({
  document,
  idx,
  onReplaceFile,
  onDeleteFile,
  onFileEdit,
  cantEdit,
}: {
  document: FilesById,
  idx: number,
  cantEdit?: boolean,
  onReplaceFile: (idFile: string,
    idxFile: number,
    file: FileList | null,
    existFile?: Omit<FilesById, 'file'>) => void,
  onDeleteFile: (catIdx: FilesById, catId: number | string) => void,
  onFileEdit: (fileId: string, data: { customFileName: string, documentTypeName: string, documentTypeId: string }) => void,
}) => {
  const buttonRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const inputFileRef = useRef<HTMLInputElement>(null);

  return (
    <ListItem className={styles.listItem}>
      <input
        type="file"
        onChange={async (e) => {
          await onReplaceFile(
            `${document.documentTypeId}`,
            idx,
            e.target.files,
            {
              customFileName: document.customFileName,
              documentTypeId: document.documentTypeId,
              documentTypeName: document.documentTypeName,
              id: document.id,
            });
          setShowMenu(false);
        }}
        ref={inputFileRef}
        style={{ display: 'none' }}
      />

      <DialogModal
        onSuccess={() => {
          inputFileRef?.current?.click();
        }}
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        title="Замена файла"
        confirmLabel="Загрузить другой файл"
        cancelLabel="Отменить"
        footer
      >
        <TypographyWithDangling variant="body2">
          При замене файла, старый файл будет удален. Вы уверены, что хотите заменить файл?
        </TypographyWithDangling>
      </DialogModal>

      <DialogModal
        onSuccess={async () => {
          await onDeleteFile(document, document.documentTypeId);
        }}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Удаление файла"
        confirmLabel="Удалить"
        cancelLabel="Отменить"
        footer
      >
        <TypographyWithDangling variant="body2">
          Вы уверены, что хотите удалить файл?
        </TypographyWithDangling>
      </DialogModal>

      <span>{idx + 1}</span>
      <span className={styles.documentHolder}>
        <EditableField
          setIsEditable={setIsEditable}
          document={document}
          isEditable={isEditable}
          onFileEdit={onFileEdit}
        />
        <Button
          variant="plain"
          className={styles.moreBtn}
          ref={buttonRef}
          onClick={() => setShowMenu(e => !e)}
          type="button"
          >
          <Icon iconName={Icons.MoreHoriz} />
          {
              showMenu &&
              <DropdownMenu onOutsideClick={() => setShowMenu(false)} targetElement={buttonRef} align="left">
                <List className={styles.listFile}>
                  <ListItem className={styles.editFileList}>
                    {
                      senderNameStore.senderId && document.id &&
                      <Button
                        startIcon={<Icon iconName={Icons.Download} />}
                        className={styles.editFileBtn}
                        onClick={() => {
                          const docLink = window.document.createElement('a');
                          docLink.target = '_blank';
                          docLink.href = `${BASE_URL}api/senderNames/${senderNameStore.senderId}/files/${document.id}`;
                          docLink.click();
                        }}
                      >
                        Скачать
                      </Button>
                    }
                  </ListItem>
                  {
                    !cantEdit &&
                    <>
                      <ListItem className={styles.editFileList}>
                        <Button
                          startIcon={<Icon iconName={Icons.Edit} />}
                          className={styles.editFileBtn}
                          onClick={() => setIsEditable(true)}
                        >
                          Редактировать название
                        </Button>
                      </ListItem>
                      <ListItem className={styles.editFileList}>
                        <Button
                          startIcon={<Icon iconName={Icons.Change} />}
                          className={styles.editFileBtn}
                          onClick={() => setShowEditModal(true)}
                        >
                          Заменить
                        </Button>
                      </ListItem>
                      <ListItem className={styles.editFileList}>
                        <Button
                          startIcon={<Icon iconName={Icons.Delete} />}
                          className={styles.editFileBtn}
                          onClick={async () => {
                            setShowMenu(false);
                            setShowDeleteModal(true);
                          }}
                        >
                          Удалить
                        </Button>
                      </ListItem>
                    </>
                  }
                </List>
              </DropdownMenu>
            }
        </Button>
      </span>
    </ListItem>
  );
});

export default FileInList;
