import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import Icon from '../../../../assets/icon.svg';
import LoaderFile from '../../../../../../../../UI/loaders/LoaderFile';
import TooltipInfo from '../../../../../../../../UI/elements/TooltipInfo';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type Props = {
  onFileChanged: (e: CustomEvent & { dataTransfer?: DataTransfer }) => any,
  onClick?: () => any,
  maxSize?: number,
  error?: string | null,
  fileExtensions?:string,
  multiple?: boolean,
  name: string,
  isLoading?: boolean,
  fileUploadInfoTooltip?: string;
}

export const UploadFilesSenderName: React.FunctionComponent<Props> = observer(({
  onFileChanged,
  onClick,
  fileExtensions,
  multiple,
  name,
  isLoading,
  fileUploadInfoTooltip,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: any) => {
    onFileChanged(e);

    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <div>
      <label className="className" htmlFor={name} >
        {/* <span className={styles.labelInfo}>Загрузить с устройства</span> */}
        <div className={styles.content}>
          <LoaderFile isLoading={isLoading}>
            <div className={styles.icon}>
              <Icon onClick={() => {}} />
              <TooltipInfo className={styles.tooltip}>{fileUploadInfoTooltip || 'Формат: PDF, .JPG, .PNG, .JPEG, .RAR, .ZIP до 20 Мб.'}</TooltipInfo>
            </div>
          </LoaderFile>
        </div>


        <input
          id={name}
          ref={inputRef}
          onChange={handleFileChange}
          className={styles.input}
          onClick={onClick}
          type="file"
          accept={fileExtensions && fileExtensions || '.txt,.csv,.xlsx'}
          multiple={multiple}
        />
      </label>
    </div>
  );
});
