export default class ApplyFiltersBoolean {
  constructor(array) {
    this.sortBy.bind(this);
    this.hideBy.bind(this);
    this.array = array;
  }

  hideBy(param, value) {
    return {
      sortBy: this.sortBy,
      array: this.array.filter(item => item[param] !== value),
      hideBy: this.hideBy,
    };
  }

  sortBy(param, value) {
    const result = this.array;
    this.array.forEach((item, index) => {
      if (item[param] === value) {
        result.splice(index, 1);
        result.unshift(item);
      }
    });
    return {
      sortBy: this.sortBy,
      array: result,
      hideBy: this.hideBy,
    };
  }
}
