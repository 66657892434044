import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { SingleFileUploadInput } from 'components/fields';
// import { pushToGA } from 'consts/ga-analytics/data-layer';
import { FIELD_FILE } from 'pages/AudienceStatistic/constants';
import CreateFocusReport from 'store/mobx/CreateFocusReport';
import Templates from 'store/mobx/Templates';
import { STEP_ERROR_PREPARE_ORDER } from 'store/mobx/constants';
import confirmDialog from 'components/modals/confirmDialog';
import buttonStyles from 'redesignSrc/UI/buttons/PrimaryButtons/styles.pcss';

import EditCampaignStepper from '../../index';
import styles from './styles.pcss';

function CreateCampaignButton({ children, className, onClose }) {
  const handleFileUploadChange = async (fileBlob) => {
    try {
      const ext = fileBlob?.name?.split('.');
      if (ext && (ext[ext.length - 1] !== 'csv' && ext[ext.length - 1] !== 'xlsx' && ext[ext.length - 1] !== 'txt')) {
        CreateFocusReport.error = {
          title: 'Неверный формат файла',
          description: 'Для корректной загрузки используйте списки в форматах .csv, .txt или .xlsx размером до 20 Мб',
          isDraftError: true,
        };
        CreateFocusReport.step = STEP_ERROR_PREPARE_ORDER;
        onClose && onClose();
        confirmDialog(EditCampaignStepper);
        return;
      }
      await CreateFocusReport.processLoadFileStep(fileBlob);

      if (!CreateFocusReport.error.title) {
        await CreateFocusReport.processPrepareOrderStep();
      }
      if (!CreateFocusReport.error.title) {
        onClose && onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <SingleFileUploadInput
      accept=".txt,.csv,.xlsx"
      onChange={handleFileUploadChange}
      name={FIELD_FILE}
      className={className}
    >
      <span className={`${buttonStyles.primaryComponent} ${buttonStyles.primaryButton} ${styles.buttonText}`}>
        {children}
      </span>
    </SingleFileUploadInput>
  );
}

CreateCampaignButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
};
export default observer(CreateCampaignButton);
