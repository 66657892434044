import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import { PopupStateless } from '../../../../../../../UI/modals/Popup';
import styles from '../styles.pcss';
import { axiosBaseRequestAdTech } from '../../../../../../../../requests/helpers';
import FileIcon from '../../../../../../NewTemplate/components/DocumentsForModeration/components/UploadingFilesForModeration/assets/File.svg';
import messageTemplatesStore from '../../../../../../../../store/mobx/MessageTemplates';
import { ShowTemplateCompanyModalProps } from './ShowTemplateCompanyModalProps';

export const ShowTemplateCompanyModal: FC<ShowTemplateCompanyModalProps> = observer(() => {
  const [companies, setCompanies] = useState<any>([]);
  const onCancel = () => {
    messageTemplatesStore.showTemplateCompany = false;
    messageTemplatesStore.selectedTemplateId = 0;
    setCompanies([]);
  };
  useEffect(() => {
    if ((companies.length > 0 || !messageTemplatesStore.showTemplateCompany)) return;
    (async () => {
      const res = await axiosBaseRequestAdTech({
        url: `/api/messageTemplates/${messageTemplatesStore.selectedTemplateId}/usages`,
      });
      setCompanies(res);
    })();
  }, [messageTemplatesStore.showTemplateCompany, messageTemplatesStore.selectedTemplateId]);

  const goToCompanyHandler = (path: string) => async (e: any) => {
    e.preventDefault();
    onCancel();
    await navigate(path);
  };

  return (
    <PopupStateless
      opened={messageTemplatesStore.showTemplateCompany}
      className={styles.modal}
      onClose={onCancel}
    >
      <div className={styles.modalTitle}>Список рекламных кампаний</div>
      <div className={styles.modalScrollList}>
        {
          companies.length > 0 ? companies?.map((company: any) => {
            const path = `/my-campaigns/${company?.orderId}/statistics/`;
            return (
              <a className={styles.companyLink} onClick={goToCompanyHandler(path)}>{company.orderName}</a>
            );
          }) : <p>Компаний не найдено</p>
        }
      </div>
    </PopupStateless>
  );
});
