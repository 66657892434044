export const optionRub = [
  {
    label: 'р.',
    value: '0',
  },
  {
    label: 'руб.',
    value: '1',
  },
];

export const optionBonus = [
  {
    label: 'бонусов',
    value: '0',
  },
  {
    label: 'бонус',
    value: '1',
  },
  {
    label: 'бонуса',
    value: '2',
  },
];
