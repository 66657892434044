import { ReactNode } from 'react';
import { TypeBlockDrag } from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/types';

export type TypeIdBox = TypeBlockDrag

export type TypeBoxes = {
  top: number
  left: number
  id: TypeIdBox
  valid?: any
}

export type BoxProps = {
  id: any
  left: number
  top: number
  hideSourceOnDrag?: boolean
  children?: ReactNode
}

export const ItemTypes = {
  BOX: 'box',
};

export type PropsNotDrag = {
  children?: ReactNode
}

export type DragItem = {
  type: string
  id: TypeIdBox
  top: number
  left: number
}

