import { computed, observable, action, toJS, keys } from 'mobx';
import { convertListToObjectBy, isNullOrUndefined } from 'utils/fn';
import { requestAllTaxonsData } from 'requests/bigdata';
import { TAXONS_TYPES, TAXON_KEYS } from 'constants/index';

import ApplyFiltersBoolean from './utils/applyFilters';

import Constructor from './Constructor';
import NewCampaign from './NewCampaign';
import WebsAndPhonesTaxons from './WebsAndPhonesTaxons';

// Есть
/* Taxons.taxonsToShow - массив объектов для отобржания формы Параметры Аудитории: [
  {
    name: groupItem.name,
    type: TAXONS_TYPES[TYPE],
    taxons: массив объектов таксонов,
    taxon_uid: groupItem.taxon_uid,
  }
]
*/
// Taxons.geoPoints - массив гео-поинтов, берётся одновременно из weekend или job или тд
// Taxons.selectedData - выбранные данные по ключам в формате отправки на бэк, any_location - массив
// Taxons.selectedTaxonsGroups - объект с ключами-group_uid, значениями - массивами строковых лейблов
//  для отображения в Виджете аудиториии в блоке Итого

class Taxons {
  @observable selectedData = new Map();
  @observable selectedTaxonsGroups = null;

  @observable geoType = 'regions';

  @observable geoPoints = [];
  @observable anyLocation = [];

  @observable geoPointsRadius = 5000;
  @observable newGeoPoint = {
    address: null,
    radius: null,
  };
  @observable addressInputError = '';
  @observable geoFieldRequiredError = '';
  @observable rangeError = null;
  /**
   * Содержит неизменённый список таксонов из метода /taxon_group/
   * @type {[]}
   */
  @observable.ref taxonGroupList = [];
  /**
   * Содержит неизменённый список таксонов из метода /taxon-subgroup/
   * @type {[]}
   */
  @observable.ref taxonSubGroupList = [];
  @observable isShowBackModal = false;
  @observable currentNavigateProps = null;
  @observable accessPassFromAudience = false;

  @action set = (value, data) => {
    this[value] = data;
  };

  @action resetTaxonsData = () => {
    this.selectedData = new Map();
    this.selectedTaxonsGroups = null;
    this.geoPoints = [];
    this.anyLocation = [];
    this.geoType = 'regions';

    this.geoPointsRadius = 5000;
    this.newGeoPoint = {
      address: null,
      radius: null,
    };
    this.addressInputError = '';
    this.geoFieldRequiredError = '';
  }

  @action loadAllTaxonsData = async () => {
    try {
      const { taxonGroups, taxonSubgroups } = await requestAllTaxonsData();
      this.taxonSubGroupList = taxonSubgroups;
      this.taxonGroupList = taxonGroups;
    } catch (e) {
      console.error(e);
    }
  };

  @computed get taxonsToShow() {
    const taxonsToShowResult = [];

    const getTaxonsSubgroups = (taxonsArray) => {
      return this.taxonSubGroupList.map((subgroupItem) => ({
        ...subgroupItem,
        taxons: taxonsArray.filter(filteredItem => filteredItem.subgroup === subgroupItem.id),
      })).filter(item => item.taxons.length > 0);
    };

    this.taxonGroupList?.filter(filtereditem => filtereditem.group_uid !== 'geo').forEach((groupItem) => {
      const genderUindItem = groupItem.taxons.find(genderItem => genderItem.taxon_uid === 'gender_ind');
      const rangeItem = groupItem.taxons.find(
        taxonItem => !isNullOrUndefined(taxonItem.max_value) || !isNullOrUndefined(taxonItem.min_value),
      );

      // Группу Демография отдельно разбираем
      if (genderUindItem) {
        taxonsToShowResult.push({
          name: genderUindItem.name,
          type: TAXONS_TYPES.GENDER,
          taxons: genderUindItem.items,
          taxon_uid: genderUindItem.taxon_uid,
          id: genderUindItem.id,
        });

        if (rangeItem) taxonsToShowResult.push({ ...rangeItem, type: TAXONS_TYPES.RANGE });
        return;
      }

      // Группы мин-макс значения отдельно разбираем
      if (rangeItem) {
        taxonsToShowResult.push({ ...rangeItem, type: TAXONS_TYPES.RANGE });
      } else if (groupItem.taxons.find(taxonItem => taxonItem.subgroup)) { // Группы, которые необходимо делить на подгруппы
        taxonsToShowResult.push({
          name: groupItem.name,
          type: TAXONS_TYPES.SUBGROUPED,
          taxons: getTaxonsSubgroups(groupItem.taxons),
          taxon_uid: groupItem.group_uid,
          id: groupItem.id,
        });
      } else if (groupItem.taxons?.length === 1 &&
        groupItem.taxons?.[0].type === TAXONS_TYPES.CATEGORY &&
        groupItem.taxons?.[0]?.items.length > 0) {
        taxonsToShowResult.push({
          id: groupItem.taxons?.[0].id,
          name: groupItem.taxons?.[0].name,
          type: TAXONS_TYPES.CATEGORY,
          taxons: groupItem.taxons?.[0]?.items.map(
            itemsItem => ({ ...itemsItem, name: itemsItem.value, taxon_uid: itemsItem.key }),
          ),
          taxon_uid: groupItem.taxons?.[0]?.taxon_uid,
        });
      } else {
        taxonsToShowResult.push({ ...groupItem, taxon_uid: groupItem.group_uid, type: TAXONS_TYPES.SELECTION });
      }
    });

    return taxonsToShowResult;
  }

  @computed get taxonsGroupData() {
    return convertListToObjectBy('group_uid')(this.allTaxonGroups || []);
  }

  @computed get getCorrectGeo() {
    if (this.geoType === 'regions') {
      // const resArrWithoutRussia = [...toJS(this.anyLocation)];
      // if (resArrWithoutRussia.indexOf('allRussia') !== -1) {
      //   resArrWithoutRussia.splice(resArrWithoutRussia.indexOf('allRussia'), 1);
      // }
      return (this.anyLocation.length ? { any_location: toJS(this.anyLocation) } : {});
    } else if (this.geoType === 'points') {
      return (this.geoPoints.length ? {
        [TAXON_KEYS.JOB_GEO]: this.geoPoints,
        [TAXON_KEYS.HOME_GEO]: this.geoPoints,
        [TAXON_KEYS.WEEKEND_GEO]: this.geoPoints,
        [TAXON_KEYS.GEO_POINTS]: this.geoPoints,
      } : {});
    }

    return {};
  }

  @action getSelectedTaxonsGroups = () => {
    this.selectedTaxonsGroups = null;

    const rangeValuesObj = this.selectedData?.get('range_values') || [];
    const genderIndValue = this.selectedData?.get('gender_ind') || undefined;
    const keysOfSelectedTaxon = keys(this.selectedData);
    // if (this.anyLocation?.length) {
    this.selectedTaxonsGroups = {
      ...this.selectedTaxonsGroups,
      any_location: {
        groupLabel: 'Город или регион',
        id: this.geoAnyLocationTaxon?.id,
        elements: this.anyLocation.map(
          idItem => {
            if (idItem === 'allRussia') return 'Вся Россия';

            const geoItem = this.geoAnyLocationTaxon?.items?.find(suggestOption => suggestOption.key === idItem);
            const { value, parent, alias_value } = geoItem;
            return this.getLocationLabelByProps({ value, parent, alias_value });
          },
        ),
      },
    };
    // }

    // if (this.geoPoints?.length && this.geoType === 'points') {
    this.selectedTaxonsGroups = {
      ...this.selectedTaxonsGroups,
      geo_points: {
        groupLabel: 'Геолокация',
        id: this.geoHomeGeoTaxon?.id,
        elements: this.geoPoints?.map(item => item.address),
      },
    };
    // }

    this.selectedTaxonsGroups = {
      ...this.selectedTaxonsGroups,
      web_sites: {
        groupLabel: 'Веб-сайты',
        id: WebsAndPhonesTaxons?.webSitesId,
        type: TAXONS_TYPES.FILE_NAME,
        label: WebsAndPhonesTaxons.webSitesFile?.file_name,
        value: WebsAndPhonesTaxons.webSitesFile?.file_name,
      },
    };

    this.selectedTaxonsGroups = {
      ...this.selectedTaxonsGroups,
      phones: {
        groupLabel: 'Телефонные номера',
        id: WebsAndPhonesTaxons?.phonesId,
        type: TAXONS_TYPES.FILE_NAME,
        label: WebsAndPhonesTaxons.phonesFile?.file_name,
        value: WebsAndPhonesTaxons.phonesFile?.file_name,
      },
    };

    this.selectedTaxonsGroups = {
      ...this.selectedTaxonsGroups,
      removeClientBase: {
        groupLabel: 'Списки номеров для исключения',
        elements: (NewCampaign.currentCampaign?.my_audiences || []).filter(itemFiltered => itemFiltered?.type_my_audience !== 2)
          .map(audienceItem => audienceItem?.audience_name) || [],
      },
    };

    if (NewCampaign.currentCampaign?.my_audiences.find(itemFiltered => itemFiltered?.type_my_audience === 2)) {
      this.selectedTaxonsGroups = {
        ...this.selectedTaxonsGroups,
        removeClientBase: {
          isNotEditable: true,
          groupLabel: 'Аудитория WiFi',
          elements: (NewCampaign.currentCampaign?.my_audiences || []).filter(itemFiltered => itemFiltered?.type_my_audience === 2)
            .map(audienceItem => audienceItem?.audience_name) || [],
        },
      };
    }

    this.taxonsToShow.forEach((curObject) => {
      let taxonsArr = [];

      if (curObject.type === TAXONS_TYPES.RANGE) { // && rangeValuesObj[curObject.taxon_uid]
        this.selectedTaxonsGroups = {
          ...this.selectedTaxonsGroups,
          [curObject.taxon_uid]: {
            id: curObject?.id,
            groupLabel: curObject.name,
            type: TAXONS_TYPES.RANGE,
            rangeValues: (rangeValuesObj[curObject.taxon_uid]?.min || rangeValuesObj[curObject.taxon_uid]?.max)
              ? [rangeValuesObj[curObject.taxon_uid]?.min, rangeValuesObj[curObject.taxon_uid]?.max]
              : [],
          },
        };
      } else if (curObject.type === TAXONS_TYPES.GENDER) { //  && genderIndValue
        this.selectedTaxonsGroups = {
          ...this.selectedTaxonsGroups,
          [curObject.taxon_uid]: {
            id: curObject?.id,
            groupLabel: curObject.name,
            type: TAXONS_TYPES.GENDER,
            label: curObject.taxons?.find(item => item.key === genderIndValue)?.value || '',
            value: genderIndValue,
          },
        };
      } else {
        if (curObject.type === TAXONS_TYPES.SUBGROUPED) {
          taxonsArr = curObject?.taxons?.reduce((prevGroup, curGroup) => {
            return [...prevGroup, ...(curGroup?.taxons || [])];
          }, []);
        } else {
          taxonsArr = curObject?.taxons || [];
        }

        const resultElements = [];

        if (curObject.type === TAXONS_TYPES.CATEGORY) {
          const selectedCategoryElements = this.selectedData?.get(curObject.taxon_uid) || [];

          resultElements.push(
            ...(curObject.taxons.filter(
              item => selectedCategoryElements?.includes(item.taxon_uid),
            ).map(itemsItem => itemsItem.name)),
          );
        } else {
          taxonsArr.forEach(taxonItem => {
            if (keysOfSelectedTaxon?.includes(taxonItem.taxon_uid)) {
              // взять и составить группу из curObject.taxon_uid и массива [taxonItem.name]
              resultElements.push(taxonItem.name);
            }
          });
        }

        this.selectedTaxonsGroups = {
          ...this.selectedTaxonsGroups,
          [curObject.taxon_uid]: {
            groupLabel: curObject.name,
            elements: resultElements,
            id: curObject?.id,
          },
        };
      }
    });
  }

  @action getLocationLabelByProps({ value, parent, alias_value }) {
    const itemsDataById = convertListToObjectBy('key')(this.geoAnyLocationTaxon?.items || []);

    if (itemsDataById[parent] && !alias_value) {
      return `${itemsDataById[parent].value}, ${value}`;
    }
    if (alias_value) {
      return alias_value;
    }
    return value;
  }

  @computed get geoAnyLocationTaxon() {
    const geoTaxons = this.taxonGroupList?.find(findedElem => findedElem.group_uid === 'geo')?.taxons;

    return convertListToObjectBy('taxon_uid')(geoTaxons || []).any_location;
  }

  @computed get geoHomeGeoTaxon() {
    const geoTaxons = this.taxonGroupList?.find(findedElem => findedElem.group_uid === 'geo')?.taxons;

    return convertListToObjectBy('taxon_uid')(geoTaxons || []).home_geo;
  }

  @computed get itemsDataById() {
    return convertListToObjectBy('key')(this.geoAnyLocationTaxon?.items || []);
  }

  @computed get geoAnyLocationTaxonLocationsList() {
    const array = this.geoAnyLocationTaxon?.items || [];
    const applyFilterBoolean = new ApplyFiltersBoolean(array);
    return applyFilterBoolean.hideBy('not_show', true).sortBy('region', true).sortBy('main_city', true).array;
  }

  /**
   * Возвращает список таксонов группированы по ключу taxon_uid
   * */
  @computed get getTaxonsByKeys() {
    const result = {};
    try {
      this.taxonGroupList.forEach(group => {
        group.taxons.forEach((item) => {
          result[item.taxon_uid] = item;
        });
      });
    } catch (e) {
      console.error(e);
    }
    return result;
  }
  /**
   * Возвращает список всех taxon_uid группированные по ключу родительского group_id
   */
  @computed get getTaxonsIdsGroupByKey() {
    const result = {};
    this.taxonGroupList.forEach(group => {
      result[group.group_uid] = group.taxons.map((item) => item.taxon_uid);
    });
    return result;
  }
  /**
   * Возвращает список taxon_uid полученных с сервера группированные по ключу родительского group_id
   */
  @computed get getTaxonsIdsServerGroupByKey() {
    const result = {};
    Constructor?.recommendations?.taxons.forEach(item => {
      for (const taxonGroup in this.getTaxonsIdsGroupByKey) {
        if (this.getTaxonsIdsGroupByKey[taxonGroup].includes(item)) {
          if (result[taxonGroup]) {
            result[taxonGroup].push(item);
          } else {
            result[taxonGroup] = [item];
          }
        }
      }
    });

    return result;
  }

  @computed get getGroupInfoByUid() {
    const result = {};
    this.taxonGroupList.forEach(group => {
      result[group.group_uid] = group;
    });
    return result;
  }

  @action removeTaxonFromSelected = async (taxonObj) => {
    const { value, key } = taxonObj;
    const taxon = NewCampaign.currentCampaign.selection.data[key];

    if (taxonObj.key === 'geo_points') {
      const filterRemovedTaxon = (item) =>
        item.lat !== value[0] && item.lng !== value[1];

      NewCampaign.currentCampaign.selection.data[key] = taxon.filter(filterRemovedTaxon);

      if (NewCampaign.currentCampaign.selection.data.job_geo) {
        NewCampaign.currentCampaign.selection.data.job_geo = taxon.filter(filterRemovedTaxon);
      }
      if (NewCampaign.currentCampaign.selection.data.home_geo) {
        NewCampaign.currentCampaign.selection.data.home_geo = taxon.filter(filterRemovedTaxon);
      }
      if (NewCampaign.currentCampaign.selection.data.weekend_geo) {
        NewCampaign.currentCampaign.selection.data.weekend_geo = taxon.filter(filterRemovedTaxon);
      }
    } else if (typeof taxon === 'object') {
      NewCampaign.currentCampaign.selection.data[key] = taxon.filter((t) => t !== value);
    } else {
      NewCampaign.currentCampaign.selection.data[key] = null;
    }

    await NewCampaign.updateSelection();

    if (NewCampaign.currentCampaign.audience === 0 &&
      !WebsAndPhonesTaxons.isPhonesChanged &&
      !WebsAndPhonesTaxons.isWebSitesCalculating &&
      !WebsAndPhonesTaxons.phonesIsActive &&
      !WebsAndPhonesTaxons.webSitesIsActive) {
      NewCampaign.isShowZeroAudienceTaxonModal = true;
    }
  }
}

const taxonsStore = new Taxons();

export default taxonsStore;
