import { TextField } from '@beeline/design-system-react';
import React from 'react';
import styles from '../styles.pcss';

const MAX_LENGTH = 11;

const SenderNameText = (
  props:
    {
      currentLength: number,
      error: boolean,
      helperText: string,
      value?: string
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
      onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void,
      disabled?: boolean,
    }) => {
  return (
    <TextField
      size="medium"
      label="Имя отправителя"
      className={styles.fieldWithLabel}
      maxLength={MAX_LENGTH}
      disabled={props.disabled}
      endAdornment={<>
        <span className={styles.currentSymbols}>{props.currentLength}</span>/<span className={styles.beelineFont}>{MAX_LENGTH}</span></>
      }
      {...props}
    />
  );
};

export default SenderNameText;
