import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import LinkOpenInfo from 'redesignSrc/UI/elements/LinkOpenInfo';
import wikiStore from 'store/wiki';
import { FormFieldLabel } from 'redesignSrc/UI/typography';
import { PopupStateless } from 'redesignSrc/UI/modals/Popup';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import useValidation from 'redesignSrc/HOC/hooks/useValidation';
import UploadingScale from 'redesignSrc/components/UploadingScale';
import { composeArrayRequiredValidator } from 'utils/fieldValidators';
import { valueToText } from 'redesignSrc/pagesTsms/components/FilterPanel/utils';
import { ORDER_FILES_FIELD } from '../../../../../constants';
import DeleteFile from './assets/DeleteFile.svg';
import FileIcon from './assets/File.svg';
import { UploadingFilesForModerationProps } from './UploadingFilesForModerationProps';
import { Files } from './components/Files/Files';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const UploadingFilesForModeration: FC<UploadingFilesForModerationProps> = observer(({ store, campaignId, text, fileUploadInfoTooltip, fileSize, isTemplateDownloadUrl }) => {
  const [showSendDocs, setShowSendDocs] = useState<boolean>(false);
  const [selectedFileId, setSelectedFileId] = useState<null | number>(null);

  const rules = {
    [ORDER_FILES_FIELD]: [composeArrayRequiredValidator('Обязательное поле')],
  };

  useEffect(() => {
    store.getExistingFiles();
  }, []);

  const validation = useValidation(store, rules);

  const handleDeleteFile = (fileId: number) => () => {
    setShowSendDocs(true);
    setSelectedFileId(fileId);
  };

  const handleDownloadFile = (path: string, file: any) => async (e: any) => {
    e.preventDefault();

    const fileView = await axiosBaseRequestAdTech({
      url: path,
      responseType: 'blob',
    });
    const docLink = document.createElement('a');
    docLink.href = window.URL.createObjectURL(fileView);
    docLink.download = file.name || file.filename;
    docLink.click();
  };

  const popup = () => {
    const onCancel = () => {
      setShowSendDocs(false);
      setSelectedFileId(null);
    };

    const onOk = () => {
      selectedFileId && store.handleFileRemove(selectedFileId);
      setShowSendDocs(false);
      setSelectedFileId(null);
    };

    return (
      <PopupStateless
        opened
        className={styles.sendModal}
        onClose={onCancel}>
        <div className={styles.sendModalTitle}>Удаление файла</div>
        <div>Вы уверены, что хотите удалить файл?</div>
        <PrimaryButton
          className={styles.sendModalBtn}
          onClick={onOk}
        >
          Да
        </PrimaryButton>
        <PrimaryButton
          className={styles.sendModalBtnCancel}
          onClick={onCancel}
        >
          Отмена
        </PrimaryButton>
      </PopupStateless>
    );
  };

  const getPathDownloadFile = (file:any) => {
    if (isTemplateDownloadUrl) {
      return `api/messageTemplates/${messageTemplatesStore.id}/file/${file?.id}`;
    }
    return file?.name ? `api/campaign/${campaignId}/file/${file?.id}` : `api/v1/brif/file/${file?.id}`;
  };

  return (
    <div>
      {showSendDocs && popup()}
      <FormFieldLabel className={cx(styles.inputDesc, styles.inputDescWiki)}>
        {
          text || 'Вы можете загрузить документы, подтверждающие акцию, если это необходимо'
        }
      </FormFieldLabel>
      <LinkOpenInfo
        isArrow
        className={styles.mb0}
        onClick={() => {
          wikiStore.isAboutMoreConfirmationWiki = true;
        }}
      >
        Подробнее о подтверждении
      </LinkOpenInfo>
      <Files
        store={store}
        isLoading={store.isLoading}
        onFilesAdded={store.uploadFile}
        error={validation && validation.errors[ORDER_FILES_FIELD]}
        validate={validation}
        fileUploadInfoTooltip={fileUploadInfoTooltip}
        fileSize={fileSize}
      />
      {
        store.filesProgress && Object.entries(store.filesProgress).map((item: any) => {
          return <UploadingScale progress={item[1]} name={item[0]} key={item[0]}/>;
        })
      }

      {
       store?.files.customerFiles && store?.files?.customerFiles?.length && store?.files?.customerFiles.map((file: any) => {
          const name = file?.name || file?.filename;
          const path = getPathDownloadFile(file);
          // const path = `api/v1/brif/${campaignId}/file/${file?.id}`;
          return (
            <div className={styles.uploadedFile} key={file.id}>
              <div className={styles.icon}>
                <a onClick={handleDownloadFile(path, file)}><FileIcon /></a>
              </div>
              <div className={styles.column}>
                <div className={styles.docName} title={name}>{valueToText(name, 50)}</div>
              </div>
              <div className={styles.closeHolder}>
                <div className={styles.row}>
                  <button className={styles.button} onClick={handleDeleteFile(file.id)}>
                    <DeleteFile/>
                  </button>
                </div>
              </div>
            </div>
          );
        }) || null
      }
    </div>
  );
});
