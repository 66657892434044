const regPrepositions = /(\s(и|без|безо|близ|в|во|вместо|вне|для|до|за|из|изо|из-за|из-под|к|ко|кроме|между|меж|на|над|надо|о|об|обо|от|ото|перед|пред|передо|предо|по|под|подо|при|про|ради|с|со|сквозь|среди|у|через|чрез|И|Без|Безо|Близ|В|Во|Вместо|Вне|Для|До|За|Из|Изо|Из-за|Из-под|К|Ко|Кроме|Между|Меж|На|Над|Надо|О|Об|Обо|От|Ото|Перед|Пред|Передо|Предо|По|Под|Подо|При|Про|Ради|С|Со|Сквозь|Среди|У|Через|Чрез)\s)/gi;
/**
 * Функция парсит строку и добавляет к словам менее 3 букв неразрывный пробел
 * что должно предотвратить появление висячих предлогов
 * @param text{string} - Исходнай текст
 * @returns {string} - Отредактированный текст
 */
export default function danglingPrepositions(text: string | undefined) : string | undefined {
  return text ? text.replace(regPrepositions, ' $2\xA0') : text;
}

