import { action, computed, observable, reaction } from 'mobx';
import NewOrder from '../../../../../../store/mobx/NewOrder';


type PushText = {
  id: number,
  value:string,
  isFocused?:boolean,
}

class MessagesChainLocalStore {
  @observable isTextLengthMoreThanMaxLingthValid = false;

  @observable pushText: PushText[] = [{ id: Date.now(), value: '' }];
  @observable isChainSelected = false;
  @observable activeIndex = 0;
  @observable isPreviewStarted = false;
  @observable renderedMessagesKey = `${Math.random().toFixed(3)}`
  @observable renderedRipplesKey = `${Math.random().toFixed(3)}`
  @observable timeoutId1: null | ReturnType<typeof setTimeout> = null
  @observable timeoutId2: null | ReturnType<typeof setTimeout> = null
  @observable isOnePreview = true
  @observable timer = 5000
  @observable timerInterval = 0

  @observable isShowMessagesChainError = false;

  @action setPushText = (index:number, data:string) => {
    // this.pushText[index].value = data.replace(/\n/gi, ' ').replace(/ {1,}/g, ' ');
    this.pushText[index].value = data;
  };

  @computed get validPushText() {
    return this.pushText.map(item => item.value).filter(item => item?.length > 0) || [];
  }

  @action resetPreview = () => {
    this.isPreviewStarted = false;
    clearTimeout(Number(this.timeoutId1));
    clearTimeout(Number(this.timeoutId2));
  }

  private run = () => {
    if (this.isPreviewStarted) {
      this.isPreviewStarted = false;
      this.renderedMessagesKey = `${Math.random().toFixed(3)}`;
      this.renderedRipplesKey = `${Math.random().toFixed(3)}`;
    }
    this.isPreviewStarted = true;
  }


  @action goPreview = () => {
    const countPush = this.validPushText.length;
    if (countPush > 1) {
      this.timeoutId1 = setTimeout(() => {
        this.run();
        switch (countPush) {
          case 2: {
            this.timerInterval = 5000;
            break;
          }
          case 3: {
            this.timerInterval = 7000;
            break;
          }
          case 4: {
            this.timerInterval = 9000;
            break;
          }
          default: return;
        }

        this.timeoutId2 = setInterval(this.run, this.timerInterval + this.timer);
      }, this.timer);
    }
  }

  @action updateData = () => {
    // @ts-ignore
    if (NewOrder.pushCampaign?.pages?.length > 0) {
      // @ts-ignore
      messagesChainLocalStore.pushText = NewOrder.pushCampaign?.pages?.map(
        (item:string) => ({
          value: item,
          id: `${Date.now()}-${Math.random().toFixed(2)}`,
        }),
      );
    } else {
      messagesChainLocalStore.pushText = [{ id: Date.now(), value: '' }];
    }
  }

  @action resetData = () => {
    this.pushText = [{ id: Date.now(), value: '' }];
    this.isChainSelected = false;
    this.activeIndex = 0;
    this.isPreviewStarted = false;
    this.isTextLengthMoreThanMaxLingthValid = false;
  }
}

const messagesChainLocalStore = new MessagesChainLocalStore();

export default messagesChainLocalStore;
