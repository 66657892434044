import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Button, Dialog, DialogContent } from '@beeline/design-system-react';
import { observer } from 'mobx-react';

import { TypographyWithDangling } from '../TypographyWithDangling/TypographyWithDangling';
import styles from './styles.pcss';

export interface DialogModalProps {
  isLoading?: boolean;
  isSingleBtn?: boolean;
  invertedActionBtns?: boolean;
  footer?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  title?: string;
  description?: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  onCancel?: () => void;
  id?: number | string;
  selectedMenu?: number;
  children?: ReactNode;
  className?: string;
}

export const DialogModal: FC<DialogModalProps> = observer((props) => {
  const {
    open,
    onSuccess,
    onCancel,
    onClose,
    isSingleBtn = false,
    className,
    title = '',
    description = '',
    confirmLabel = 'Да',
    cancelLabel = 'Нет',
    children,
    isLoading,
    footer = false,
  } = props;

  const close = () => onClose && onClose();

  const onSuccessHandler = async () => {
    await onSuccess?.();
    close();
  };

  const onCancelHandler = async () => {
    await onCancel?.();
    close();
  };

  const renderFooter = () => {
    return (
      <footer className={styles.footer}>
        {!isSingleBtn && <Button onClick={onCancelHandler} variant="outlined" disabled={isLoading}
          size="medium">{cancelLabel}</Button>}
        <Button onClick={onSuccessHandler} variant="contained" disabled={isLoading}
          size="medium">{confirmLabel}</Button>
      </footer>
    );
  };

  return (
    <Dialog
      className={classNames(styles.dialog, className && className)}
      onClose={close}
      open={open}
    >
      <DialogContent
        className={styles.noContent}
        title={<TypographyWithDangling variant="h5">{title}</TypographyWithDangling>}
        footer={footer && renderFooter()}
      >
        {children || <TypographyWithDangling variant="body2">{description}</TypographyWithDangling> || null}
      </DialogContent>
    </Dialog>
  );
});
