import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PopupStateless } from '../../../../../../../UI/modals/Popup';
import styles from '../styles.pcss';
import { axiosBaseRequestAdTech } from '../../../../../../../../requests/helpers';
import FileIcon from '../../../../../../NewTemplate/components/DocumentsForModeration/components/UploadingFilesForModeration/assets/File.svg';
import messageTemplatesStore from '../../../../../../../../store/mobx/MessageTemplates';
import { ShowTemplateDocsModalProps } from './ShowTemplateDocsModalProps';

export const ShowTemplateDocsModal: FC<ShowTemplateDocsModalProps> = observer(() => {
  const [files, setFiles] = useState<any>([]);
  const onCancel = () => {
    messageTemplatesStore.showTemplateDocs = false;
    messageTemplatesStore.selectedTemplateId = 0;
    setFiles([]);
  };
  useEffect(() => {
    if ((files.length > 0 || !messageTemplatesStore.showTemplateDocs)) return;
    (async () => {
      const res = await axiosBaseRequestAdTech({
        url: `api/messageTemplates/${messageTemplatesStore.selectedTemplateId}/files`,
      });
      setFiles(res.customerFiles);
    })();
  }, [messageTemplatesStore.showTemplateDocs, messageTemplatesStore.selectedTemplateId]);

  const handleDownloadFile = (path: string, file: any) => async (e: any) => {
    e.preventDefault();

    const fileView = await axiosBaseRequestAdTech({
      url: path,
      responseType: 'blob',
    });
    const docLink = document.createElement('a');
    docLink.href = window.URL.createObjectURL(fileView);
    docLink.download = file.name || file.filename;
    docLink.click();
  };

  return (
    <PopupStateless
      opened={messageTemplatesStore.showTemplateDocs}
      className={styles.modal}
      onClose={onCancel}
    >
      <div className={styles.modalTitle}>Документы шаблона</div>
      <div className={styles.modalScrollList}>
        {
          files.length > 0 ? files?.map((file: any) => {
            const path = `api/messageTemplates/${messageTemplatesStore.selectedTemplateId}/file/${file?.id}`;
            return (
              <div className={styles.uploadedFile} key={file.id}>
                <div className={styles.icon}>
                  <a onClick={handleDownloadFile(path, file)}><FileIcon /></a>
                </div>
                <div className={styles.column}>
                  <div className={styles.docName}>{file.name}</div>
                </div>
              </div>
            );
          }) : <p>Документов не найдено</p>
        }
      </div>
    </PopupStateless>
  );
});
