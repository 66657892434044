import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { observable, action, reaction, toJS, autorun, computed } from 'mobx';
import { Button } from '@beeline/design-system-react';
import GreyContainerPopup from 'redesignSrc/UI/elements/GreyContainerPopup';
import { InputRadio, TextInput } from 'redesignSrc/UI/form';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import danglingPrepositions from 'utils/danglingPrepositions';
import StoreDnd from 'containers/ContainerDnD/StoreDnd';
import { FormFieldLabel } from 'redesignSrc/UI/typography';
import StoreT from '../../storeT';
import styles from './styles.pcss';

// Внешний стор для компонента для неизменчивости выбранного состояния при дропе
class Store {
  @observable promoSelect:'staticPromocode' | 'dynamicPromocode' = 'staticPromocode'
}
const store = new Store();

const PromoBlockVar = () => {
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [oddLoaded, setOddLoaded] = useState(false);
  const [value, setValue] = useState(StoreT.variables.get('staticPromocode')?.replaceAll('"', '') || '');
  const isEdit = StoreT.variables.has('staticPromocode');

  useEffect(() => {
    if (oddLoaded) {
      if ((value.length >= 3 && value.length <= 20) && /^[а-яА-ЯёЁa-zA-Z0-9]+$/.test(value)) {
        setIsValid(true);
        setError(null);
      } else {
        setIsValid(false);
      }
    }
    setOddLoaded(true);
  }, [value]);

  useEffect(() => {
    if (store.promoSelect === 'dynamicPromocode') {
      setIsValid(true);
      setError(null);
      setValue('');
    } else {
      setIsValid(false);
    }
  }, [store.promoSelect]);

  const handleOnBlur = () => {
    if (!/^[а-яА-ЯёЁa-zA-Z0-9]+$/.test(value) || !(value.length >= 3 && value.length <= 20)) {
      setError('Промокод должен быть от 3 до 20 символов, только буквы и цифры');
    }
  };

  const onChange = (e:string) => {
    setValue(e.toLocaleUpperCase());
  };

  const onChangeDynamicPromocode = () => {
    store.promoSelect = 'dynamicPromocode';
    setIsValid(true);
    setError(null);
    setValue('');
  };

  const onChangeStaticPromocode = () => {
    store.promoSelect = 'staticPromocode';
    setIsValid(false);
  };


  const handleSubmit = () => {
    if (store.promoSelect === 'staticPromocode') {
      StoreT.setPromoVariable(value, false);
    } else {
      StoreT.setPromoVariable('dynamicPromocode', true);
    }
    store.promoSelect = 'staticPromocode';
    StoreDnd.deleteBox('staticPromocode');
  };


  return (
    <GreyContainerPopup
      title="Выберите вид промокода и мы добавим его в SMS"
      onClose={() => {
        StoreT.currentValueVariable.staticPromocode = null;
        store.promoSelect = 'staticPromocode';
        StoreDnd.deleteBox('staticPromocode');
        StoreT.isOpenPopupPromo = false;
      }}
    >
      <span className={styles.infoCheckBox}>Единый промокод добавляет кол-во символов в текст. Персональные промокоды не влияют на длину SMS</span>

      <div className={styles.wrapSelect}>
        <InputRadio
          onChange={onChangeStaticPromocode}
          checked={store.promoSelect === 'staticPromocode'}
          textInfo={
            <>
              <span>Всем клиентам придет одинаковый промокод</span>
              <p style={{ margin: '10px 0 0 0' }} >Количество символов с учетом кавычек добавляются к основному тексту</p>
            </>
          }
        >
          Всем одинаковый
        </InputRadio>

        <InputRadio
          onChange={onChangeDynamicPromocode}
          checked={store.promoSelect === 'dynamicPromocode'}
          textInfo={
            <>
              <span>Каждому клиенту придет уникальный промокод из вашего файла</span>
              <p style={{ margin: '10px 0 0 0' }} >Не влияют на длину SMS</p>
            </>
          }
          disabled={isEdit}
        >
          Каждому свой
        </InputRadio>
      </div>
      <div className={styles.content}>
        {store.promoSelect === 'staticPromocode' &&
          <>
            <FormFieldLabel>Текст промокода</FormFieldLabel>
            <TextInput
              placeholder="Например, СКИДКА15"
              onBlur={handleOnBlur}
              onChange={onChange}
              value={value}
              error={error}
            />
            {!error && <span className={styles.info}>От 3 до 20 символов, только буквы и цифры. Прибавятся 2 символа за “ “</span>}
          </>
        }
        {store.promoSelect === 'dynamicPromocode' &&
          <span className={styles.infoCheckBox}>
            {danglingPrepositions('Вы сможете загрузить файл с уникальными промокодами позже' +
              ' при создании кампании. Количество промокодов должно совпадать с количеством сообщений')}
          </span>
        }

        <div className={styles.buttonContainer}>
          <Button
            variant="overlay"
            size="medium"
            disabled={ !isValid }
            onClick={handleSubmit}
            >
            {isEdit ? 'Изменить промокод' : 'Добавить в текст'}
          </Button>
          {/* <PrimaryButton
            isGray={!isValid}
            isDisabled={!isValid}
            onClick={handleSubmit}
          >
            {isEdit ? 'Изменить промокод' : 'Добавить в текст'}
          </PrimaryButton> */}
        </div>
      </div>


    </GreyContainerPopup>
  );
};

export default observer(PromoBlockVar);
