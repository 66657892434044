import { ContentBlock, ContentState } from 'draft-js';
import { OrderLink } from '../../../../types';

export const MaskVariable = 'SLZWELMM';
export const MaskLink = 'ALQBISLZ';
export const MaskPromo = 'IRJDLSJS';
export const promoForm = ['промокод', 'промокода', 'промокодов'];

export const PLACEHOLDER = 'Например, рейсы МСК - Екатеринбург по 1999 рублей весь май! Ваша "Название" https://www 8800XXXXXX';

export type TypeBlockDrag = 'promo' | 'link' | 'phone' | 'discount' | 'date' | 'amount' | 'staticPromocode' | 'bonus'
export type TypeBlockVariables = Exclude<TypeBlockDrag, 'promo' | 'link'>

export type TypeInitialEditor = {
  text: string | null,
  link: string | null,
  isPromo: boolean,
  promo: string,
  variables: Map<string, string>
}

export type TypePosition = {
  x: number,
  y: number,
}

export type TypeValue = {
  text: null | string,
  originText: string,
  links: OrderLink,
}

export type TypeLinkValue = OrderLink & { mask: typeof MaskLink }


export type TypeStrategy = (
  contentBlock: ContentBlock,
  callback: (a: number, b: number) => void,
  contentState: ContentState
) => void

export type TypeFilePromo = {
  name: string,
  declined: number,
  id: number,
  maxPromocode?: number,
  rowCount: number
}

type TypeVariableTemplate = {
  name: 'Телефон' | 'Скидка' | 'Дата' | 'Сумма' | 'Промокод' | 'Бонус',
  value: TypeBlockDrag,
  info: string,
}

export const VARIABLES_TEMPLATE: TypeVariableTemplate[] = [
  {
    name: 'Телефон',
    value: 'phone',
    info: '',
  },
  {
    name: 'Скидка',
    value: 'discount',
    info: '',
  },
  {
    name: 'Дата',
    value: 'date',
    info: '',
  },
  {
    name: 'Сумма',
    value: 'amount',
    info: '',
  },
  {
    name: 'Промокод',
    value: 'staticPromocode',
    info: '',
  },
  {
    name: 'Бонус',
    value: 'bonus',
    info: '',
  },
];

export type PropsLinkTemplate = {
  typeTemplate?: TypeBlockDrag,
  info?: string,
  decoratedText?: string
}

export type TypeVariables = {
  value: string
  id: string
  type: string
}

export type TypeFocusVariables = {
  phone: number | null,
  discount: number | null,
  amount: number | null,
  date: number | null,
  staticPromocode: number | null,
  bonus: number | null,
}

export type TypeCurrentValueVariable = {
  phone: { type: string, value: string } | null,
  discount: { type: string, value: string } | null,
  amount: { type: string, value: string } | null,
  date: { type: string, value: string } | null,
  staticPromocode: { type: string, value: string } | null,
  bonus: { type: string, value: string } | null,
}
