import { CompositeDecorator, ContentBlock, ContentState, EditorState, SelectionState } from 'draft-js';
// @ts-ignore
import linkifyIt from 'linkify-it';
import tlds from 'tlds';
import { phoneRegExp, validPhoneRegExp } from 'utils/fieldValidators';
import NewOrder from 'store/mobx/NewOrder';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import messagesChainLocalStore from 'redesignSrc/pages/PushPages/PushPageSetup/components/PushMessages/store';
import { OrderLink } from 'redesignSrc/types';
import {
  PhoneNotValid,
  cautionWordValid,
  Link,
  Promo,
  MoreThanMaxSizePushTextValid,
  LinkTemplate,
} from '../components/Link';
import {
  MaskLink,
  MaskPromo,
  MaskVariable,
  TypeBlockVariables,
  TypeStrategy,
} from '../types';


export const MAX_LENGTH_PUSH_SMS = 99;

export const linkify = linkifyIt();
linkify.set({ fuzzyEmail: false }).tlds(tlds);
const linkRegExp = new RegExp(MaskLink, 'ig');

export const linkStrategy: TypeStrategy = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  let matchArr;
  let start;
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = linkRegExp.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

export const linkError: TypeStrategy = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  const links = linkify.match(text);
  if (links) {
    // @ts-ignore
    links.forEach((link) => {
      if (link?.raw !== 'https://www') {
        callback(link.index, link.lastIndex);
      }
    });
  }
};

export const promoStrategy: TypeStrategy = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  const regExp = new RegExp(MaskPromo, 'ig');
  let matchArr = regExp.exec(text);
  let start;
  while (matchArr !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
    matchArr = regExp.exec(text);
  }
};

export const templateStrategy: TypeStrategy = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  const typeTemplate = '(phone|amount|discount|date|staticPromocode|bonus)';
  const strReg = `\\[${typeTemplate}[0-9]?${MaskVariable}\\]`;
  const regExp = new RegExp(strReg, 'ig');
  let matchArr = regExp.exec(text);
  let start;
  while (matchArr !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
    matchArr = regExp.exec(text);
  }
};

export const cautionWordValidStrategy: TypeStrategy = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  if (NewOrder.intolerantWords.cautionWords.length === 0 && messageTemplatesStore.intolerantWords.cautionWords.length === 0) return;
  let sample = '';
  if (NewOrder.intolerantWords.cautionWords.length) {
    sample = NewOrder.intolerantWords.cautionWords.toString().replaceAll(',', '|');
  }
  if (messageTemplatesStore.intolerantWords.cautionWords.length) {
    sample = messageTemplatesStore.intolerantWords.cautionWords.toString().replaceAll(',', '|');
  }
  const regExp = new RegExp(sample, 'ig');

  let matchArr = regExp.exec(text);
  let start;
  while (matchArr !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
    matchArr = regExp.exec(text);
  }
};

export const stopWordValidStrategy: TypeStrategy = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  if (NewOrder.intolerantWords.stopWords.length === 0 && messageTemplatesStore.intolerantWords.stopWords.length === 0) return;
  let sample = '';
  if (NewOrder.intolerantWords.stopWords.length) {
    sample = NewOrder.intolerantWords.stopWords.toString().replaceAll(',', '|').replaceAll('$', '\\$');
  }
  if (messageTemplatesStore.intolerantWords.stopWords.length) {
    sample = messageTemplatesStore.intolerantWords.stopWords.toString().replaceAll(',', '|').replaceAll('$', '\\$');
  }
  const regExp = new RegExp(sample, 'ig');

  let matchArr = regExp.exec(text);
  let start;
  while (matchArr !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
    matchArr = regExp.exec(text);
  }
};

export const phoneNotValidStrategy: TypeStrategy = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();

  let matchArr = phoneRegExp.exec(text);

  let start;
  while (matchArr !== null) {
    if (
      !matchArr[0].match(validPhoneRegExp) ||
      matchArr[0][matchArr[0].length - 1].includes(',') ||
      matchArr[0][matchArr[0].length - 1].includes('.') ||
      matchArr[0][matchArr[0].length - 1].includes(';') ||
      matchArr[0][matchArr[0].length - 1].includes('!') ||
      matchArr[0][matchArr[0].length - 1].includes('?')
    ) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
    matchArr = phoneRegExp.exec(text);
  }
};

const isValidLengthMsg = () => NewOrder.pushCampaign.pages?.every((text: string) => text.length <= MAX_LENGTH_PUSH_SMS);

export const moreThanMaxSizePushText: TypeStrategy = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();

  if (text.length > MAX_LENGTH_PUSH_SMS || !isValidLengthMsg()) {
    messagesChainLocalStore.isTextLengthMoreThanMaxLingthValid = true;
  } else {
    messagesChainLocalStore.isTextLengthMoreThanMaxLingthValid = false;
  }

  callback(MAX_LENGTH_PUSH_SMS, text.length);
};

export const decoratorPush = new CompositeDecorator([
  {
    strategy: moreThanMaxSizePushText,
    component: MoreThanMaxSizePushTextValid,
  },
  {
    strategy: phoneNotValidStrategy,
    component: PhoneNotValid,
  },
  {
    strategy: stopWordValidStrategy,
    component: PhoneNotValid,
  },
  {
    strategy: cautionWordValidStrategy,
    component: cautionWordValid,
  },
]);

export const decorator = new CompositeDecorator([
  {
    strategy: linkStrategy,
    component: Link,
  },
  {
    strategy: promoStrategy,
    component: Promo,
  },
  {
    strategy: phoneNotValidStrategy,
    component: PhoneNotValid,
  },
  {
    strategy: stopWordValidStrategy,
    component: PhoneNotValid,
  },
  {
    strategy: cautionWordValidStrategy,
    component: cautionWordValid,
  },
  {
    strategy: linkError,
    component: PhoneNotValid,
  },
  {
    strategy: templateStrategy,
    component: LinkTemplate,
  },
]);

export const createNewEditorState = (editorState: EditorState, params: any) => {
  const {
    newText = editorState.getCurrentContent().getFirstBlock().getText(),
    focus = editorState.getSelection().getFocusOffset(),
    blockKey = editorState.getCurrentContent().getFirstBlock().getKey(),
    isClearSpace = false,
  } = params;

  const newBlockMap = isClearSpace ?
    (editorState.getCurrentContent().getBlockMap().slice(0, 1).map((block) => {
      return new ContentBlock({
        text: newText,
        key: blockKey,
        type: 'unstyled',
      });
    })) :
    (editorState.getCurrentContent().getBlockMap().map((block) => {
      return new ContentBlock({
        text: newText,
        key: block?.getKey(),
        type: block?.getType(),
        characterList: block?.getCharacterList(),
      });
    }));


  const newSelectionState = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: focus,
    focusKey: blockKey,
    focusOffset: focus,
  });

  const newContentState = new ContentState({
    blockMap: newBlockMap,
  });

  const newEditorState = EditorState.createWithContent(newContentState, decorator);
  return EditorState.forceSelection(newEditorState, newSelectionState);
};

/**
 * Функция Вставляет текст в EditorState
 * @param editorState{EditorState} - Обьект EditorState
 * @param text{string} - Вставляемый текст
 * @param maxLength{number} - Длинна обрезанного текста
 * @returns {EditorState} - Обьект EditorState с удаленными лишними пробелами
 * и установленным курсором в месте предыдущего состояния и вставленным текстом
 */
export const pasteText = (text = '', editorState: EditorState, maxLength?: number) => {
  const start = editorState.getSelection().getStartOffset();
  const end = editorState.getSelection().getEndOffset();
  const isBackWard = editorState.getSelection().getIsBackward();
  let currentText = editorState.getCurrentContent().getFirstBlock().getText();
  let newFocus = editorState.getSelection().getFocusOffset() + (text?.length || 0) + 1;// 2 -это добавленные пробелы
  if (currentText.length === 0) {
    newFocus -= 1;
  }
  if (start !== end) {
    currentText = currentText.slice(0, start) + currentText.slice(end, currentText.length);
    newFocus -= (end - start);
  }
  const focusPaste = isBackWard ?
    editorState.getSelection().getFocusOffset() :
    editorState.getSelection().getFocusOffset() - (end - start);

  let newText = `${currentText.slice(0, focusPaste)} ${text} ${currentText.slice(focusPaste, currentText.length)}`;
  if (maxLength && newText.length > maxLength) {
    newText = newText.slice(0, maxLength);
    newFocus = maxLength;
  }
  // Убираем все символы \n и лишние пробелы
  newText = newText.replace(/\n/gi, ' ').replace(/ {1,}/g, ' ').trim();
  return createNewEditorState(editorState, { focus: newFocus, newText, isClearSpace: true });
};

/**
 * Функция Вставляет текст c маской для ссылки в EditorState
 * @param editorState{EditorState} - Обьект EditorState
 * @param focus{number | null} - Фокус куда вставлять
 * @param mask{string} - Маска
 * @returns {EditorState} - Обьект EditorState
 */
export const pastMaskLink = (editorState: EditorState, mask: string, focus?: number | null) => {
  const currentText = editorState.getCurrentContent().getFirstBlock().getText();
  let newText;
  if (focus) {
    newText = `${currentText.slice(0, focus)} ${mask} ${currentText.slice(focus, currentText.length)}`;
  } else {
    newText = `${currentText} ${mask} `;
  }
  return EditorState.createWithContent(ContentState.createFromText(newText), decorator);
};

/**
 * Функция Вставляет текст c маской для ссылки в EditorState
 * @param editorState{EditorState} - Обьект EditorState
 * @param focus{number | null} - Фокус куда вставлять
 * @param mask{string} - Маска
 * @returns {EditorState} - Обьект EditorState
 */
export const pastMaskVariable = (editorState: EditorState, mask: string, focus?: number | null) => {
  const currentText = editorState.getCurrentContent().getFirstBlock().getText();
  let newText;
  if (currentText.length === 0) {
    const text = `${mask} `;
    return EditorState.createWithContent(ContentState.createFromText(text), decorator);
  }
  if (focus) {
    newText = `${currentText.slice(0, focus)} ${mask} ${currentText.slice(focus, currentText.length)}`;
    if (isDestructionMask(currentText, newText)) {
      newText = `${currentText} ${mask} `;
    }
  } else {
    newText = `${currentText} ${mask} `;
  }
  return EditorState.createWithContent(ContentState.createFromText(newText), decorator);
};

export const replaceLink = (text: string, mask: string, linkReplace: string) => {
  const indexStart = text.indexOf(mask);
  const indexEnd = indexStart + (mask.length - 1);
  const isStartSpace = text[indexStart - 1] === ' ';
  const isEndSpace = text[indexEnd + 1] === ' ';
  if (isStartSpace && isEndSpace) {
    return text.replace(mask, `${linkReplace}`);
  }
  if (isStartSpace && !isEndSpace) {
    return text.replace(mask, `${linkReplace} `);
  }
  if (!isStartSpace && isEndSpace) {
    return text.replace(mask, ` ${linkReplace}`);
  }
  return text.replace(mask, ` ${linkReplace} `);
};

/**
 * Функция удаляет маску для ссылки в EditorState
 * @param editorState{EditorState} - Обьект EditorState
 * @param mask{string} - Маска
 * @returns {EditorState} - Обьект EditorState
 */
export const deleteMaskLink = (editorState: EditorState, mask: string) => {
  const currentText = editorState.getCurrentContent().getFirstBlock().getText();
  if (!currentText.includes(mask)) return EditorState.createWithContent(ContentState.createFromText(currentText), decorator);
  const newText = currentText.replace(mask, '');
  return EditorState.createWithContent(
    ContentState.createFromText(
      newText.replace(/\n/gi, ' ')
        .replace(/ {1,}/g, ' ')
        .trim()), decorator);
};

export const setFocusAndNewText = (focus: number, text: string) => {
  const newEditorState = EditorState.createWithContent(ContentState.createFromText(text), decorator);
  const blockKey = newEditorState.getCurrentContent().getFirstBlock().getKey();
  const newSelectionState = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: focus,
    focusKey: blockKey,
    focusOffset: focus,
  });
  return EditorState.forceSelection(newEditorState, newSelectionState);
};

export const dropMaskLink = (editorState: EditorState, focus: number, mask: string) => {
  const tempMask = 'DRTWBJKLKSG';
  const currentText = editorState.getCurrentContent().getFirstBlock().getText();
  let newText = `${currentText.slice(0, focus)} ${tempMask} ${currentText.slice(focus, currentText.length)}`;
  newText = newText.replace(mask, '');
  newText = newText.replace(tempMask, mask);
  const newEditorState = EditorState.createWithContent(ContentState.createFromText(newText), decorator);
  const blockKey = newEditorState.getCurrentContent().getFirstBlock().getKey();
  const newSelectionState = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: focus,
    focusKey: blockKey,
    focusOffset: focus,
  });
  return EditorState.forceSelection(newEditorState, newSelectionState);
};
/**
 * Функция проверяют пытаются ли удалить маску
 * @param esCurrent{EditorState} - Обьект EditorState измененный
 * @param esLast{EditorState} - Обьект EditorState существующий
 * @param mask{string} - Маска
 * @returns {boolean}
 */
export const isDeleteMask = (esCurrent: EditorState, esLast: EditorState, mask: string) => {
  const isIncludesMaskCurrent = esCurrent.getCurrentContent().getFirstBlock().getText().includes(mask);
  const isIncludesMaskLast = esLast.getCurrentContent().getFirstBlock().getText().includes(mask);
  return !isIncludesMaskCurrent && isIncludesMaskLast;
};

/**
 * Функция проверяют пытаются ли удалить маску шаблонов
 */
export const isDeleteMaskVariable = (esCurrent: EditorState, esLast: EditorState, variables: Map<string, string>) => {
  for (const type of variables.keys()) {
    const isIncludesMaskCurrent = esCurrent.getCurrentContent().getFirstBlock().getText().includes(`[${type}${MaskVariable}]`);
    const isIncludesMaskLast = esLast.getCurrentContent().getFirstBlock().getText().includes(`[${type}${MaskVariable}]`);
    if (!isIncludesMaskCurrent && isIncludesMaskLast) {
      return type;
    }
  }
  return null;
};

/**
 * Функция проверяет наличие маски ссылки или промокодов и возвращает корректный текст
 */
type TypeReplaceLinkPromoFromStore = Pick<OrderLink, 'link' | 'shortLink' | 'isShort' | 'dynamicPartLink'> & {
  textT: string,
  promo: string,
}

export const replaceLinkPromoFromStore = ({ textT, link, shortLink, promo, isShort }: TypeReplaceLinkPromoFromStore) => {
  const currentText = textT.replaceAll(MaskVariable, '');
  let originText = '';
  let text = '';

  if (currentText.includes(MaskLink) && currentText.includes(MaskPromo)) {
    originText = replaceLink(currentText, MaskLink, link || '').trim();
    text = isShort ?
      replaceLink(currentText, MaskLink, shortLink || '').trim() :
      replaceLink(currentText, MaskLink, link?.trim() || '');

    originText = replaceLink(originText, MaskPromo, promo).trim();
    text = replaceLink(text, MaskPromo, promo).trim();
  }
  if (currentText.includes(MaskLink) && !currentText.includes(MaskPromo)) {
    originText = replaceLink(currentText, MaskLink, link || '').trim();
    text = isShort ?
      replaceLink(currentText, MaskLink, shortLink || '').trim() :
      replaceLink(currentText, MaskLink, link || '').trim();
  }
  if (!currentText.includes(MaskLink) && currentText.includes(MaskPromo)) {
    originText = replaceLink(currentText, MaskPromo, promo).trim();
    text = replaceLink(currentText, MaskPromo, promo).trim();
  }
  if (!currentText.includes(MaskLink) && !currentText.includes(MaskPromo)) {
    originText = currentText;
    text = currentText;
  }
  return { originText, text };
};

/**
 * Функция находит числа и тип в маске шаблона которое является и его id и типом в собираемых переменных
 */

export const findTypeIdFromDecoratedText = (decoratedText: string) => {
  let type = null;
  let id = null;
  const regArrType = decoratedText.match(/(phone|amount|discount|date|staticPromocode|bonus)/);
  const regArrId = decoratedText.match(/\d+/);
  if (regArrType) {
    type = regArrType[0] as TypeBlockVariables;
  }
  if (regArrId) {
    id = regArrId[0];
  }
  return { id, type };
};

export const uniqueTypeVariable = (type: string, variables: Map<string, string>) => {
  let id = 0;
  let uniqueType;
  do {
    id += 1;
    uniqueType = `${type}${id}`;
  }
  while (variables.has(uniqueType));
  return uniqueType;
};

/**
 * Функция заменяет маски переменных на их значение
 */
export const replaceVariables = (text: string, variables?: Map<string, string>) => {
  let currentText = text;
  if (variables) {
    for (const [key, value] of variables) {
      currentText = currentText.replace(`[${key}]`, value);
    }
  }
  return currentText;
};

/**
 * Функция проверяет наличие масок до и после изменения текста и возвращает bool в зависимости раздробилась ли маска
 */
const isDestructionMask = (text: string, nextText: string) => {
  const typeTemplate = '(phone|amount|discount|date|staticPromocode)';
  const strReg1 = `\\[${typeTemplate}[0-9]?${MaskVariable}\\]`;
  const strReg2 = `${strReg1}|${MaskLink}|${MaskPromo}`;
  const regMasks = new RegExp(strReg2, 'ig');
  let maskCount = 0;
  let maskCountNext = 0;
  const matchVar = text.match(regMasks);
  const matchVarNext = nextText.match(regMasks);
  maskCount += (matchVar?.length || 0);
  maskCountNext += (matchVarNext?.length || 0);
  return maskCount >= maskCountNext;
};

export const dateFormatChips = (date: string) => {
  const arrDete = date.split('.');
  if (arrDete.length === 3) {
    const day = arrDete[0];
    const mounth = arrDete[1];
    const year = arrDete[2];
    const dateForm = `${mounth}.${day}.${year}`;
    return new Date(dateForm);
  }
  return null;
};
