import { debounce } from 'debounce';
import { TypeChildren } from '../../../components/SuggestListWithSearch';
import { TypeValueFilter, TypeValueSuggest } from './types';

export const changeDebounce = debounce((value:TypeValueFilter, fn:(a:TypeValueFilter)=>void) => {
  fn(value);
}, 500);

export const normalizeData = (data: TypeValueSuggest[]) => {
  return [
    {
      name: '',
      children: data,
    },
  ];
};

export const valueToText = (value:TypeChildren[] | string, maxLength: number = 30) => {
  if (value?.length === 0) return null;
  let text = '';
  if (Array.isArray(value)) {
    text = value.map(el => el.name).toString().slice(0, maxLength);
    return value?.length > 1 ? `${text}...` : text;
  }
  return value?.length > maxLength ? `${value.slice(0, maxLength)}...` : value;
};

export const valuesFromIds = (idsValue:string[], values:TypeValueSuggest[]) => {
  return values.filter(el => idsValue.includes(el.value));
};
