import React, { ChangeEvent, ClipboardEvent, RefObject, FocusEvent } from 'react';
import classNames from 'classnames';
import withError from 'redesignSrc/HOC/withError';
import styles from './styles.pcss';


interface IProps {
  value?: string,
  className?: string,
  onChange?: (t: string, e: ChangeEvent<HTMLTextAreaElement>) => void,
  onFocus?: (e?: FocusEvent<HTMLTextAreaElement>) => void,
  onBlur?: (e?: FocusEvent<HTMLTextAreaElement>) => void,
  debounceDelay?: number,
  name?: string,
  placeholder?: string,
  labelText?: string,
  maxLength?: number,
  isDisabled?: boolean
  onPaste?: (e?: ClipboardEvent<HTMLTextAreaElement>) => void,
  textareaRef?: RefObject<HTMLTextAreaElement>

}

const TextArea = ({
  value,
  className = '',
  onChange,
  onFocus,
  onBlur,
  name,
  placeholder,
  maxLength,
  isDisabled,
  onPaste,
  textareaRef,
}: IProps) => {
  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e.target.value, e);
  };
  const onFocusHandler = (e?: FocusEvent<HTMLTextAreaElement>) => {
    onFocus && onFocus(e);
  };
  const onBlurHandler = (e?: FocusEvent<HTMLTextAreaElement>) => {
    onBlur && onBlur(e);
  };

  const onPasteHandler = (e?: ClipboardEvent<HTMLTextAreaElement>) => {
    onPaste && onPaste(e);
  };

  return (
    <div className={classNames(styles.holder, className)}>
      <textarea
        maxLength={maxLength}
        id={name}
        placeholder={placeholder}
        name={name}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        className={classNames(styles.textArea)}
        value={value}
        disabled={isDisabled}
        onPaste={onPasteHandler}
        ref={textareaRef}
      />
    </div>
  );
};

export default withError(TextArea);
