export const INTERNET_STATISTICS = ['impressions', 'clicks', 'cost', 'cpc'];
export const TARGET_STATISTIC = ['shows', 'clicks', 'spent', 'cpc', 'ctr'];

export const TITLE_CHART_INTERNET = {
  clicks: 'Клики',
  cpc: 'Средняя цена за клик',
  impressions: 'Показы',
  cost: 'Расходы',
};

export const TITLE_CHART_TARGET = {
  clicks: 'Клики',
  cpc: 'Средняя цена за клик',
  shows: 'Показы',
  spent: 'Расходы',
  ctr: 'Соотношение кликов и показов(CTR)',
};

export const COLOR_LINE_INTERNET = {
  0: '#868686',
  1: '#F02313',
  2: '#282828',
  3: '#34A853',
  4: '#FDCC04',
  5: '#6297D2',
  6: '#006EF5',
  7: '#7E3AB0',
  8: '#FF5B67',
};
// эту константу пока оставил для оссациации с COLOR_LINE_INTERNET
export const TOOLS_TYPE_INTERNET = {
  0: 'Общее',
  1: 'Поиск Google Ad',
  2: 'Поиск Яндекс.Директ',
  3: 'Сеть Google Ads',
  4: 'Сеть Яндекс.Директ',
  5: 'Соцсеть Вконтакте',
  6: 'Соцсеть Facebook',
  7: 'Соцсеть Instagram',
  8: 'Соцсеть myTarget',
};

export const CHANNEL_TYPE_CHARTS = {
  1: {
    CHARTS: INTERNET_STATISTICS,
    TITLES: TITLE_CHART_INTERNET,
  },
  6: {
    CHARTS: TARGET_STATISTIC,
    TITLES: TITLE_CHART_TARGET,
  },
};
// TODO Написать потом функцию генерирующую даты от new Date, пока это заглушка чтобы сетка не исчизала с графиков
export const DEFAULT_DATE = ['2021-01-01', '2021-01-02', '2021-01-03', '2021-01-04', '2021-01-05', '2021-01-06'];

