import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams } from '@reach/router';
import { observer } from 'mobx-react';
import { UploadSenderNameFilesCreate } from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName/components/UploadSenderNameFilesCreate/UploadSenderNameFilesCreate';
import { WorkingHours } from 'redesignSrc/UI/elements/WorkingHours/WorkingHours';
import { WrapperPage } from 'components/common/WrapperPage/WrapperPage';
import { FormFieldLabel, MainTitle } from '../../../UI/typography';
import { PageLoader } from '../../../../components/common/Loaders';
import BlurLoader from '../../../UI/loaders/BlurLoader';

import useValidation from '../../../HOC/hooks/useValidation';
import { senderNameStore } from '../../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import { createSenderNameRules, editSenderNameRules } from '../../SmsPages/SmsPageSetup/components/SenderName/EditSenderName/consts';
import { ContentContainer } from '../../../UI/layout';
import CounterTextInput from '../../../UI/form/CounterTextInput';
import { BAN, ORDER_FILES_FIELD, ORDER_SENDER_NAME_FIELD } from '../../../../store/NewCampaign/channels/constants';
import { BtnsBlock, InfoNameSender, UploadSenderNameFilesEdit } from '../components';
import NewOrder from '../../../../store/mobx/NewOrder';
import RedInfoOcon from '../../NewTemplate/assets/redInfoIcon.svg';
import { NameInfo } from '../../NewTemplate/components';
import { TEMPLATE_MESSAGE_STATUS_MAP } from '../../MessageTemplates/consts';
import ContractSelectionStore from '../../../components/ContractSelection1/ContractSelectionStore';
import ContractSelection from '../../../components/ContractSelection1/ContractSelection';
import { TypeBanCustomer } from '../../../components/ContractSelectionSS/ContractSelectionSSStore';
import styles from './styles.pcss';
import { NewTemplateProps } from './NewTemplateProps';
import { TemplateComments } from './components/TemplateComments/TemplateComments';

const cx = classNames.bind(styles);

export const EditSenderName: FC<NewTemplateProps> = observer(() => {
  const [selectedBan, setSelectedBan] = useState<TypeBanCustomer | null>(null);
  const validation = useValidation({
    [ORDER_SENDER_NAME_FIELD]: senderNameStore[ORDER_SENDER_NAME_FIELD],
    [ORDER_FILES_FIELD]: senderNameStore[ORDER_FILES_FIELD],
    [BAN]: selectedBan?.ban,
  }, editSenderNameRules);
  const [senderNameValue, setSenderNameValue] = useState('');
  const [commentValue, setCommentValue] = useState('');
  const [isGoTo, setIsGoTo] = useState(false);
  const [wiki1, setWiki1] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    (async () => {
      await senderNameStore.getSenderNameByIdSection(id);
      await senderNameStore.getAllFilesBySenderIdSection();
      await senderNameStore.getAllComments(id);
    })();
  }, [id]);

  const renderHeader = () => (
    <>
      <h2 className={styles.titleInput}>Имя отправителя</h2>
      <p className={styles.explanation}>
        Имя отправителя - это название вашей компании, товарного знака или бренда, которое получатели увидят в поле «отправитель SMS»
      </p>
      <div><FormFieldLabel className={styles.input}>Имя отправителя</FormFieldLabel></div>

    </>
  );

  useEffect(() => {
    if (!isGoTo) return;
    let tId: any = '';
    (async () => {
      window.location.hash = 'goTo';
      tId = setTimeout(() => {
        setIsGoTo(false);
        history.pushState('', document.title, window.location.pathname);
      }, 300);
    })();
    return () => clearTimeout(tId);
  }, [isGoTo]);

  const goToCommentHandler = () => {
    setIsGoTo(true);
  };

  const isDisabledField = () => {
    return senderNameStore.senderStatus === 'Verification' || senderNameStore.senderStatus === 'Accepted';
  };
  const isDisabledFieldIsNotAccepted = () => {
    return senderNameStore.senderStatus === 'Verification';
  };

  return (
    <PageLoader isLoading={false}>
      <BlurLoader
        isLoading={false}
        className={styles.holder}
        classNameLoader={styles.loaderWrap}
      >
        <WrapperPage>
          {/* <Link to="/sender-names/" className={styles.backLink}> */}
          {/*   {'<'} Вернуться к списку шаблонов */}
          {/* </Link> */}
          <MainTitle className={styles.mainTitle}>{senderNameStore.senderStatus !== 'Verification' ? 'Создание имени отправителя' : 'Просмотр имени отправителя'}</MainTitle>

          <div className={styles.statusName}>
            <NameInfo
              status={TEMPLATE_MESSAGE_STATUS_MAP[senderNameStore?.senderStatus || 'Draft']}
            />
          </div>

          <ContractSelection
            description="Выбирайте номер договора, для которого хотите создать имя"
            validation={validation}
            isDisable={!!senderNameStore.ban}
            selectedValue={senderNameStore.ban}
            onChange={(e) => {
              ContractSelectionStore.currentBan = e;
              setSelectedBan(e);
            }}
          />

          {senderNameStore.senderStatus !== 'Rejected' || !senderNameStore?.actualRejectComment || senderNameStore.isLoading ? null : (
            <div onClick={goToCommentHandler} className={styles.topLvlCommentBlock}>
              <RedInfoOcon className={styles.topLvlCommentBlockIcon} />{senderNameStore.actualRejectComment.text}
            </div>
          )}

          <ContentContainer className={styles.whiteBlock}>
            {renderHeader()}
            <div className={cx({ disabledSmsText: isDisabledField() })}>
              <CounterTextInput
                placeholder="Например, beeline"
                max={11}
                keepErrorIndent
                validation={validation}
                name={ORDER_SENDER_NAME_FIELD}
                maxErrorWidth={514}
                onChange={(e) => {
                  senderNameStore[ORDER_SENDER_NAME_FIELD] = e;
                }}
                value={senderNameStore[ORDER_SENDER_NAME_FIELD]}
              />
            </div>
          </ContentContainer>

          <ContentContainer className={styles.whiteBlock}>
            <InfoNameSender />
            <div className={cx({ disabledSmsText: isDisabledFieldIsNotAccepted() })}>
              { senderNameStore.senderStatus === 'Accepted' ?
                <UploadSenderNameFilesCreate
                  validation={validation}
                  isEditMode={false}
                  store={senderNameStore}
                  campaignId={NewOrder.smsCampaign.campaignId}
                /> :
                <UploadSenderNameFilesEdit
                  validation={validation}
                  isEditMode
                  store={senderNameStore}
                  campaignId={NewOrder.smsCampaign.campaignId} /> }
            </div>
          </ContentContainer>

          <div className={cx({ disabledSmsText: isDisabledField() })}>
            <TemplateComments valid={validation} />
          </div>
          <WorkingHours />
          <BtnsBlock valid={validation} />
        </WrapperPage>
      </BlurLoader>
    </PageLoader>
  );
});
