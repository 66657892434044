import { STATIC_URL } from 'requests/constants';
import { convertMbToBytes } from 'utils/fn';

export const CHANNEL_TYPE_FOCUS_TYPE_ID = 3;
export const CHANNEL_TYPE_FOCUS = 'focus';

export const REPORTS_LIST_ID = 'reports-list';

export const STATIC_LINKS = {
  EXAMPLE_CSV: 'https://static.beeline.ru/upload/images/marketing/example.csv',
  EXAMPLE_TXT: `${STATIC_URL}templates/example.txt`,
  EXAMPLE_XLSX: 'https://static.beeline.ru/upload/images/marketing/example.xlsx',
};


export const focusFileSizeMegabytes = 50;
export const focusFileSizeBytes = convertMbToBytes(focusFileSizeMegabytes);

export const NOT_SIZE = 'not-size';

export const FIELD_BUDGET = 'budget';
export const FIELD_CAMPAIGN_ID = 'campaignId';
export const FIELD_FILE = 'file';
export const FIELD_FILES = 'files';
export const FIELD_FILE_NAME = 'fileName';
export const FIELD_FILE_SIZE = 'fileSize';
