import React from 'react';
import PropTypes from 'prop-types';
import Preloader from '../../../Preloader';

export default function PageLoader({ children, isLoading, className = '' }) {
  return <div style={{ minHeight: '500px' }} className={`${className}`}>{isLoading ? <Preloader /> : children}</div>;
}

PageLoader.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};
