import React, { Fragment, useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { TextInput } from 'redesignSrc/UI/form';
import { IconEnd } from 'redesignSrc/UI/form/TextInput';
import { PureButton } from 'redesignSrc/UI/buttons';
import { TypeIndustries } from '../../../index';
import styles from './styles.pcss';

export type TypeChildren = {
  name: string,
  id?: number
}

type Props = {
  data?: TypeIndustries
  onChange:(e:TypeChildren)=>void
  placeholder?: string
  value:string
  onChangeText:()=>void
}

const filterData = (arr:TypeIndustries, filter:string) => {
  if (arr) {
    return arr.map((item) => {
      const filterChildren = item.children.filter((el) => {
        return el.name.toLowerCase().includes(filter.toLowerCase());
      });
      if (filterChildren.length) {
        return { ...item, children: filterChildren };
      }
      return { ...item, children: filterChildren, groupName: '' };
    });
  }
  return arr;
};

const SuggestListWithSearchVoice:React.FC<Props> = ({
  data,
  onChange,
  placeholder,
  value,
  onChangeText,
}) => {
  const [currentData, setCurrentData] = useState(data || []);
  const [search, setSearch] = useState(value);

  useEffect(() => {
    if (search) {
      setCurrentData(filterData(data || [], search));
    } else {
      setCurrentData(data || []);
    }
  }, [search]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.containerInput}>
        <TextInput
          placeholder={placeholder || 'Отрасль, тип товара или услуги'}
          value={search}
          endIcon={IconEnd.search}
          onChange={(e) => {
            onChangeText();
            setSearch(e);
          }}
        />
      </div>

      <div className={styles.containerSelect}>
        {currentData.map((chapter) => {
          return (
            <Fragment key={chapter.id}>
              {chapter.groupName && <label className={styles.label}>{chapter.groupName}</label>}
              <div className={styles.wrapperButton}>
                {chapter.children.map((check) => {
                  return (
                    <PureButton
                      key={check.id}
                      className={styles.checkInput}
                      onClick={() => onChange({ id: check.id, name: check.name })}
                    >
                      {check.name}
                    </PureButton>
                  );
                })}
              </div>

            </Fragment>
          );
        })
        }
      </div>


    </div>
  );
};

export default SuggestListWithSearchVoice;
