import React, { useState, useEffect } from 'react';
import { PageLoader } from 'components/common/Loaders/components';
import styles from './styles.pcss';

const BaseTemplatesInitializer = ({ children }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async function initialLoading() {
      try {
        // await Promise.all([
        //   getErrorTemplates(dispatch),
        //   Templates.getTemplate('common'),
        //   Templates.getTemplate('popups'),
        // ]);
      } finally {
        setLoaded(true);
      }
    }());
  }, []);

  if (!loaded) {
    return <PageLoader isLoading/>;
  }

  return <div className={styles.redefinition}>{children}</div>;
};

export default BaseTemplatesInitializer;
