import React, { ReactNode } from 'react';
import Preloader from '../Preloader';

type Props = {
  children: ReactNode,
  isLoading?: boolean,
  className?: string
}

const PageLoader:React.FC<Props> = ({ children, isLoading, className = '' }) => {
  return <div className={className}>{isLoading ? <Preloader /> : children}</div>;
};

export default PageLoader;
