import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.pcss';

type Props = {
  children?:ReactNode,
  className?:string
}

const ContainerSticky:React.FC<Props> = ({ children, className }) => {
  return (
    <div>
      <div className={classNames(styles.wrapper, className)}>
        {children}
      </div>
    </div>
  );
};

export default ContainerSticky;
