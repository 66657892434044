import React from 'react';
import PropTypes from 'prop-types';
import Logo from './assets/Logo.svg';

const LogoB2B = ({ isMobile, className }) => (
  <Logo/>
);

LogoB2B.propTypes = {
  isMobile: PropTypes.bool,
  className: PropTypes.any,
};


export default LogoB2B;
