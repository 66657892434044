import { observer } from 'mobx-react';
import { Button, Icon, List } from '@beeline/design-system-react';
import React, { useState } from 'react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import classNames from 'classnames/bind';
import { FilesById, senderNameStore } from '../../../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import styles from '../styles.pcss';
import FileInList from './FileInList';

const generateKey = () => {
  return Math.floor(Math.random() * Date.now());
};

const cx = classNames.bind(styles);

const SenderNamesFileList = observer(({
  onReplaceFile,
  onDeleteFile,
  cantEdit,
  onFileEdit,
  docs,
}: {
  onReplaceFile: (id: string,
    idx: number,
    file: FileList | null,
    existFile?: Omit<FilesById, 'file'>) => void,
  onDeleteFile: (idx: FilesById, id: number | string) => void,
  docs: FilesById[],
  cantEdit?: boolean,
  onFileEdit: (fileId: string, data: { customFileName: string, documentTypeName: string, documentTypeId: string }) => void,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className={styles.listHolder}>
      <List className={cx(styles.list, isOpened ? styles.opened : styles.closed)}>
        <>
          {
            docs.map((document, idx) => {
              return (
                <FileInList
                  document={document}
                  idx={idx}
                  key={generateKey()}
                  onReplaceFile={onReplaceFile}
                  onDeleteFile={onDeleteFile}
                  onFileEdit={onFileEdit}
                  cantEdit={cantEdit}
                />
              );
            }) || null
          }
        </>
      </List>
      {
        (docs.length && docs.length > 5) &&
        <Button
          variant="plain"
          className={styles.hideBtn}
          onClick={() => setIsOpened(!isOpened)}
          endIcon={<Icon iconName={isOpened ? Icons.ArrowDropUp : Icons.ArrowDropDown} />}
          type="button"
        >
          { isOpened ? 'Скрыть' : 'Показать ещё' }
        </Button>
      }
    </div>
  );
});

export default SenderNamesFileList;
