import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
// @ts-ignore
import qs from 'query-string';
// @ts-ignore
import debounce from 'debounce';
import classNames from 'classnames/bind';
import { Link, navigate } from '@reach/router';
import { toJS } from 'mobx';
import { Button, Icon } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { scrollSmoothTo } from 'utils/scroll';
import { MainTitle } from 'redesignSrc/UI/typography';
import { PageLoader } from 'components/common/Loaders/components';
import TextInput, { IconStart } from 'redesignSrc/UI/form/TextInput';
import DatePicker from 'redesignSrc/UI/form/DatePicker';
import { Section, Row, Column } from 'redesignSrc/UI/layout';
import DropdownLinks, { CheckBoxList } from 'redesignSrc/components/DropdownLinks';
import { InViewPortDetector } from 'components/helpers';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { GlobalIcon } from 'components/common';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import danglingPrepositions from 'utils/danglingPrepositions';
import { Heading } from 'components/layouts';
import UserInfo from 'store/mobx/UserInfo';
import { TypeSenderNamesValues, senderNameStore } from '../SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';
import styles from './styles2.pcss';
import MenuClientBase from './components/MenuClientBase/MenuClientBase';
import { TEMPLATE_MESSAGE_STATUS_MAP } from './consts';
import {
  ShowTemplateDocsModal,
} from './components/ClientBase/components/modals/ShowTemplateDocsModal/ShowTemplateDocsModal';

export const SenderNamesPage = observer(() => {
  const [loading, setLoading] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [isOpenChannels, setIsOpenChannels] = useState(false);

  useEffect(() => {
    (async () => {
      await UserInfo.getUser();
      if (!UserInfo.data?.company?.ban) navigate('/user-info/');
    })();
  }, []);

  useEffect(() => {
    const loadMyCampaignPageData = async () => {
      setLoading(true);
      try {
        await senderNameStore.getStatuses();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        scrollSmoothTo(0);
      }
    };
    (async () => {
      await loadMyCampaignPageData();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      senderNameStore.resetPages();
      senderNameStore.resetFilters();
      senderNameStore.resetForm(); // d
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    senderNameStore.resetPages();
    (async () => {
      await senderNameStore.getAllSenderNames();
    })();
  }, [
    senderNameStore.filter.statuses.length,
  ]);

  const draftNameReplacer = (name: string) => name === 'Отклонен' && 'Не согласован' || name;

  const statusesForCheckbox = useMemo(() => {
    const statuses = senderNameStore.statuses.map(
      (item: { value: string, id: number, description: string }) =>
        ({
          label: draftNameReplacer(item.description),
          value: item.value,
          isChecked: senderNameStore.filter.statuses?.includes(item.value),
        }
        ));
    return {
      statuses,
      filtersApplied: statuses.filter((status: { isChecked: boolean }) => !status.isChecked),
    };
  }, [senderNameStore.statuses?.length, senderNameStore.filter.statuses?.length]);

  const selectedStatusesCount = statusesForCheckbox.statuses.length - statusesForCheckbox.filtersApplied.length;

  const createNewTemplateHandler = async () => {
    senderNameStore.resetForm();
    await navigate('/sender-name');
  };

  return (
    <PageLoader isLoading={loading} className={styles.container}>
      <Section className={styles.section}>
        <MainTitle className={styles.title}>Имена отправителей</MainTitle>
        <Heading level={5} className={styles.description}>
          {danglingPrepositions('Создавайте имена отправителей для быстрого запуска рекламных кампаний')}
        </Heading>
        <div className={styles.addNewTemplateBtnWrapper}>
          <Button
            startIcon={<Icon iconName={Icons.Add}/>}
            variant="contained"
            size="medium"
            onClick={createNewTemplateHandler}
          >
            Создать новое имя
          </Button>
        </div>
        {
          senderNameStore.isLoading && <div className={styles.placeholder}/>
        }
        <InputsList/>
        <Row className={styles.bar}>
          <Column className={styles.column1}>
            <span className={`${styles.name} ${styles.menuList}`}>Имя отправителя</span>
          </Column>

          <Column className={styles.column2}>
            <span className={styles.menuList}>Статус:
              <span className={styles.dropdown} onClick={() => {
                setIsOpenChannels(false);
                setIsOpenStatus(true);
              }}>
                {
                  selectedStatusesCount > 0 &&
                  ` Выбрано ${selectedStatusesCount}` ||
                  ' Все'
                }
                {
                  isOpenStatus && statusesForCheckbox.statuses.length &&
                  <DropdownLinks
                    isOpen
                    onClose={() => setIsOpenStatus(false)}
                    className={styles.linksContainer}
                    dropDownClass={styles.dropdownElement1}
                  >
                    <CheckBoxList
                      items={statusesForCheckbox.statuses}
                      onItemClick={async (param: string | number) => {
                        if (senderNameStore.filter.statuses?.includes(param)) {
                          // тут игнор, потому что TS не знает, что это псевдо массив
                          // @ts-ignore
                          senderNameStore.filter.statuses.remove(param);
                        } else {
                          senderNameStore.filter.statuses.push(param);
                        }
                      }}
                    />
                  </DropdownLinks> || null
                }
              </span>
            </span>
          </Column>

          <Column className={styles.column3}>
            <span className={styles.menuList}>Дата создания</span>
          </Column>

          <Column className={styles.column4}>
            <span className={styles.menuList}>Дата окончания</span>
          </Column>
        </Row>

        {
          (senderNameStore.senderNames?.data.length < 1) && !senderNameStore.isLoading &&
          <p className={styles.emptyNote}>Ничего не найдено</p>
        }

        <CampaignsList/>

        <div>
          <ShowTemplateDocsModal showModal={messageTemplatesStore.showTemplateDocs}/>
        </div>

      </Section>
      {(senderNameStore.senderNames?.data?.length !== 0) &&
        <InViewPortDetector onIntersectIn={senderNameStore.getMoreTemplates}/>}
    </PageLoader>
  );
});

const getAllMessageTemplatesDebounce = debounce(senderNameStore.getAllSenderNames, 500);

export const InputsList = observer(() => {
  return (
    <div className={styles.wrapperSearch}>
      <TextInput
        startIcon={IconStart.search}
        name="search"
        className={styles.search}
        placeholder="Поиск по названию"
        onChange={async (value) => {
          senderNameStore.filter.skip = 0;
          senderNameStore.filter.name = value;
          getAllMessageTemplatesDebounce();
        }}
        value={senderNameStore.filter.name}
      />
      <div className={styles.datesHolder}>
        <DatePicker
          onStartDateClick={async () => {
            senderNameStore.filter.createdFrom = undefined;
          }}
          onEndDateClick={async () => {
            senderNameStore.filter.createdTo = undefined;
          }}
          name="dates_search"
          width={250}
          isInputsReadOnly
          secondMarginLeft={8}
          onChange={async value => {
            senderNameStore.filter.skip = 0;
            senderNameStore.filter.createdFrom = value[0]?.format('YYYY-MM-DD').substring(0, 10);
            senderNameStore.filter.createdTo = value[1]?.format('YYYY-MM-DD').substring(0, 10);
            getAllMessageTemplatesDebounce();
          }}
          value={[senderNameStore.filter.createdFrom, senderNameStore.filter.createdTo]}
        />
      </div>
    </div>
  );
});

const getStatusColor = (status: string) => {
  switch (status) {
    case 'Завершен':
    case 'Создан':
    case 'На согласовании':
    case 'Действующий':
    case 'Черновик':
      return styles.active;
    case 'Модерация не пройдена':
    case 'Отказ':
      return styles.inactive;
    default:
      return styles.inProgress;
  }
};

export const modifyStatus = (status: string) => {
  return status;
};

const noName = (name: string) => {
  return name || 'Без названия';
};

const CampaignsList = observer(() => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<any>(0);
  const [selectedTemplateId, setSelectedTemplateId] = useState<any>(0);
  const [showTemplateDocs, setShowTemplateDocs] = useState(false);
  const [showTemplateCompany, setShowTemplateCompany] = useState(false);
  const [showEditDelGroupModal, setShowEditDelGroupModal] = useState(false);
  const [showTemplateAddGroup, setShowTemplateAddGroup] = useState(false);
  const [showTemplateEditGroup, setShowTemplateEditGroup] = useState(false);

  const editGroupByHoverHandler = (id: number) => {
    messageTemplatesStore.showTemplateEditGroup = true;
    messageTemplatesStore.selectedTemplateGroupId = id;
  };

  const renderStatusOperator = (sender: TypeSenderNamesValues) => {
    if (sender.status === 'Accepted' && sender.multiOperatorModeratedOn) {
      return (
        <>
          <span>{TEMPLATE_MESSAGE_STATUS_MAP[sender.status]} для билайнa</span><br/>
          <span>{TEMPLATE_MESSAGE_STATUS_MAP[sender.status]} для всех операторов</span>
        </>
      );
    } else if (sender.status === 'Accepted' && sender.moderatedOn) {
      return `${TEMPLATE_MESSAGE_STATUS_MAP[sender.status]} для билайнa`;
    }
    return TEMPLATE_MESSAGE_STATUS_MAP[sender.status];
  };

  const renderEndDate = (sender: TypeSenderNamesValues) => {
    if (sender.status === 'Accepted' && sender.multiOperatorModeratedOn) {
      return (
        <>
          <span>{sender.moderatedTill ? moment(sender.moderatedTill).format('DD.MM.YYYY') : '-'}</span><br/>
          <span>{sender.multiOperatorModeratedTill ? moment(sender.multiOperatorModeratedTill).format('DD.MM.YYYY') : '-'}</span>
        </>
      );
    } else if (sender.status === 'Accepted' && sender.moderatedOn) {
      return `${sender.moderatedTill ? moment(sender.moderatedTill).format('DD.MM.YYYY') : '-'}`;
    }
    return '-';
  };

  const renderCreatedOnDate = (sender: TypeSenderNamesValues) => {
    if (sender.status === 'Accepted' && sender.multiOperatorModeratedOn) {
      return (
        <>
          <span>{moment(sender.createdOn).format('DD.MM.YYYY')}</span><br/>
          <span>{moment(sender.createdOn).format('DD.MM.YYYY')}</span>
        </>
      );
    }
    return moment(sender.createdOn).format('DD.MM.YYYY');
  };

  return (
    <>
      {
        senderNameStore.senderNames?.data.length && senderNameStore.senderNames?.data.map((sender) => {
          return (
            <>
              <div className={sender.senderName === 'BrandInfo' ? styles.hidden : styles.wrapList}>
                <Row className={styles.dataRow} key={sender.id}>
                  <Link to={`/sender-name/${sender.id}`} style={{ textDecoration: 'none', padding: '20px 0' }}>
                    <div className={styles.column1}>
                      <span
                        className={`${styles.columnText} ${styles.campaignName} ${styles.name}`}>{sender.senderName}</span>
                    </div>
                  </Link>
                  <Link to={`/sender-name/${sender.id}`} style={{ textDecoration: 'none', padding: '20px 0' }}>
                    <div className={styles.column2}>
                      <span className={`${styles.columnText} ${styles.status} ${sender.status}`}>
                        {renderStatusOperator(sender)}
                      </span>
                    </div>
                  </Link>

                  <Link to={`/sender-name/${sender.id}`} style={{ textDecoration: 'none', padding: '20px 0' }}>
                    <div className={styles.column3}>
                      {renderCreatedOnDate(sender)}
                    </div>
                  </Link>

                  <Link to={`/sender-name/${sender.id}`} style={{ textDecoration: 'none', padding: '20px 0' }}>
                    <div className={styles.column4}>
                      <div className={`${styles.columnText} ${styles.campaignName}`}>
                        {renderEndDate(sender)}
                      </div>
                    </div>
                  </Link>
                  <div className={classNames(styles.arrowWrap)}>
                    {
                      sender.status !== 'Verification' ? (
                        <div
                          role="button"
                          tabIndex={0}
                          className={classNames(styles.moreActions)}
                          onClick={() => {
                            setMenuVisible(!isMenuVisible);
                            setSelectedMenu(sender.id || 0);
                            messageTemplatesStore.selectedTemplateId = sender.id || 0;
                            setSelectedTemplateId(sender.id);
                          }}
                        >
                          <GlobalIcon slug="dotsMenu" className={styles.moreDots}/>
                        </div>
                      ) : null
                    }


                    <div>
                      {isMenuVisible ?
                        <MenuClientBase
                          isMenuVisible={selectedMenu === sender.id}
                          selectedTemplateId={selectedTemplateId}
                          onClose={() => setMenuVisible(false)}
                          id={sender.id || 0}
                          groupId={0}
                          name={sender.senderName}
                          count={0}
                          status={sender.status}
                          setShowTemplateDocs={setShowTemplateDocs}
                          setShowTemplateCompany={setShowTemplateCompany}
                          setShowTemplateAddGroup={setShowTemplateAddGroup}
                          setShowTemplateEditGroup={setShowTemplateEditGroup}
                        /> : null}
                    </div>
                  </div>
                </Row>
              </div>
            </>
          );

          return (
            <div className={styles.wrapList}>
              <Row className={styles.dataRow} key={sender.id}>
                <div className={styles.column1}>
                  <span
                    className={`${styles.columnText} ${styles.campaignName} ${styles.name}`}>{noName(sender.senderName)}</span>
                </div>

                <div className={styles.column2}>
                  <span className={`${styles.columnText} ${styles.status} ${sender.status}`}>
                    {sender.status}
                  </span>
                </div>

                <div className={styles.column3}>
                  <p className={`
                    ${styles.columnText} ${styles.campaignType}
                  `}>
                    {sender.status}
                  </p>
                </div>

                <div className={styles.column4}>
                  <span className={`${styles.columnText} ${styles.campaignName}`}>
                    {sender.status}
                  </span>
                </div>
              </Row>
              <div/>
            </div>
          );
        }) || null
      }
    </>
  );
});
