import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useLocation } from '@reach/router';
import InputMask from 'react-input-mask';
import { Button } from '@beeline/design-system-react';
import Authorization from 'store/mobx/Authorization';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import { Close, NewLogo } from '../assets';
import { ym } from '../../../utils/ga-analytics/utils';
import styles from './styles.pcss';

const Phone = () => {
  const {
    setPhone,
    isErrorPhone,
    resetError,
    phone,
    setErrorPhone,
  } = Authorization;
  const [isLoading, setLoading] = useState(false);
  const { search } = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!Authorization.checkIsPhoneCorrect) {
        setErrorPhone(true);
        return;
      }
      if (!isErrorPhone) {
        const dataSource = search.includes('source_resource')
          ? search.slice(17, search.length)
          : null;
        await Authorization.phoneConfirmation({
          phone: phone.replace(/\D/g, ''),
          source: dataSource,
        });
        ym(87718585, 'reachGoal', 'auth_success_email');
        console.info('ID:231445304-YM');
        Authorization.setScreen('sms');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getInputStyles = (isPhoneInputed, isErrorOccured) => {
    if (isPhoneInputed && !isErrorOccured) {
      return `${styles.phoneInput} ${styles.pnoneInputed}`;
    } else if (isPhoneInputed && isErrorOccured) {
      return `${styles.phoneInput} ${styles.errorText} ${styles.errorInput}`;
    } else if (isErrorOccured) {
      return `${styles.phoneInput} ${styles.errorInput}`;
    }
    return styles.phoneInput;
  };

  const onChange = (e) => {
    resetError();
    Authorization.phone = e.currentTarget.value;
    if (
      Authorization.checkIsPhoneCorrect &&
      Authorization.phoneOnCheck === false
    ) {
      Authorization.phoneOnCheck = true;
    }
    if (Authorization.checkIsPhoneCorrect === false) {
      Authorization.phoneOnCheck = false;
    }
  };

  return (
    <>
      <div className={styles.logo}>
        <NewLogo/>
      </div>
      <div className={styles.description}>
        Введите номер телефона
        <br/> любого оператора
      </div>
      <form
        className={styles.formWrapper}
        onSubmit={handleSubmit}
      >
        <div className={styles.inputHolder}>
          <InputMask
            placeholder="+7 (_ _ _) _ _ _-_ _-_ _"
            mask="+7 (999) 999-99-99"
            onChange={onChange}
            onFocus={() => {
              ym(87718585, 'reachGoal', 'phone_click');
              console.info('ID:231445303-YM');
            }}
            className={getInputStyles(phone.length, isErrorPhone)}
            value={phone}
          />
          {!!phone && (
            <div
              className={styles.close}
              onClick={(e) => {
                e.preventDefault();
                setPhone('');
              }}
            >
              <Close/>
            </div>
          )}
        </div>
        {isErrorPhone && (
          <label className={styles.errorTextMsg}>
            {isErrorPhone && !phone
              ? 'Введите номер телефона'
              : 'Неправильный номер телефона'}
          </label>
        )}
        {/* <PrimaryButton
          type="submit"
          className={styles.button}
          isDisabled={isLoading}
        >
          Войти
        </PrimaryButton> */}
        <Button
          variant="contained"
          type="submit"
          size="medium"
          fullWidth
          className={styles.button}
          disabled={isLoading}
        >
          Войти
        </Button>
      </form>
    </>
  );
};

export default observer(Phone);
