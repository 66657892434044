import React from 'react';
import { observer } from 'mobx-react';
import { TSMSUserInfo } from 'store/mobx/TSMS/UserInfo';
import InfoIcon from './info-icon.svg';

import styles from './styles.pcss';

const TroubleShootMessage = () => {
  if (TSMSUserInfo.data?.troubleShootMessage) {
    return (
      <div className={styles.wrapper}>
        <div>
          <InfoIcon className={styles.icon}/>
        </div>
        <div className={styles.content}
          dangerouslySetInnerHTML={{ __html: TSMSUserInfo.data?.troubleShootMessage }}/>
      </div>
    );
  }
  return null;
};

export default observer(TroubleShootMessage);
