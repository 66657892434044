import React from 'react';
import classNames from 'classnames/bind';
import Unchecked from './assets/Unchecked.svg';
import NewChecked from './assets/newChecked.svg';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IProps {
  id?: string,
  name?: string,
  className?: string,
  // eslint-disable-next-line no-unused-vars
  onChange: (value: boolean, e?: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => void,
  // eslint-disable-next-line no-unused-vars
  onBlur?: (value: boolean, e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void,
  value?: boolean,
  disabled?: boolean
  children?: React.ReactNode,
  isAddLinkCheckbox?: boolean;
  isValidLink?: boolean;
}

function SuggestCheckbox({
  id,
  name,
  className,
  onChange,
  value,
  disabled,
  children,
  isAddLinkCheckbox,
  isValidLink,
}: IProps) {
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as unknown as KeyboardEvent;
    if (target.key === 'Enter') onChange && onChange(!value, e);
  };

  const handleChange = (e: any) => {
    if (e.target.tagName === 'A') {
      e.target.getAttribute('href').click();
    }
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;

    onChange(!value);

    // setIsChecked(!isChecked);
  };
  // TODO Если повесить на инпут onChange или onClick или onClick на label, то не работает состояние чекед в стилях
  // Надо либо делать локальный стейт isСhecked чтоб быстрее переключать визуально
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label
      className={cx('containerLabel', className, disabled && styles.disabled)}
      htmlFor={id}
      onClick={handleChange}>
      <input
        type="checkbox"
        className={cx('input')}
        id={id}
        name={name}
        onKeyUp={(e) => handleKeyUp(e)}
        disabled={disabled}
      />

      <span className={cx('check', { pulse: isAddLinkCheckbox && (!isValidLink && !value) })}>
        {value ? <NewChecked/> : <Unchecked/>}
      </span>

      {children}
    </label>
  );
}

export default SuggestCheckbox;
