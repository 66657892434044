import React from 'react';
import styles from './styles.pcss';

const UploadingScale = ({ progress, name }: {progress: number, name: string}) => {
  return (
    <div>
      <div className={styles.uploadingFileName}>{name}</div>
      <div className={styles.scaleHolder}><div className={styles.scale} style={{ width: `${progress}%` }}/></div>
    </div>
  );
};

export default UploadingScale;
