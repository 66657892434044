import { action, observable } from 'mobx';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import { Answer, Survey, SurveyProgress, SurveyStatus } from '../types';

class Questions {
  @observable forms: Answer[] | null = null;
  @observable currentScreen:number = 0;
  @observable surveys:Survey | null = null;
  @observable status: SurveyStatus | null = null;
  @observable isOpened = false;
  @observable loading = true;

  @action getActiveSurvey = async () => {
    this.surveys = await axiosBaseRequestAdTech({ url: '/api/surveys/active' });
  }

  @action updateSurvey = async (id: number, isFinished?: boolean) => {
    if (!this.forms) return;
    const data: SurveyProgress = {
      answers: this.forms as Answer[],
      pageNumber: this.currentScreen + 1,
      status: isFinished && 'Finished' || this.status,
      surveyId: id || this.surveys?.surveyId || 0,
    };
    await axiosBaseRequestAdTech({ url: `/api/surveys/${id || this.surveys?.surveyId}/status`, method: 'POST', data });
  }

  @action finishSurvey = async () => {
    if (!this.forms) return;
    const data: SurveyProgress = {
      answers: this.forms as Answer[],
      pageNumber: this.currentScreen,
      status: 'Finished',
      surveyId: this.surveys?.surveyId || 0,
    };
    const dataToShow:{
      resultHintText1?: string,
      resultHintText2?: string,
      resultMainText: string,
      resultRewardText: string
    } = await axiosBaseRequestAdTech({ url: `/api/surveys/${this.surveys?.surveyId}/status`, method: 'POST', data });

    this.status = 'Finished';

    (this.surveys as Survey).resultHintText1 = dataToShow.resultHintText1;
    (this.surveys as Survey).resultHintText2 = dataToShow.resultHintText2;
    (this.surveys as Survey).resultMainText = dataToShow.resultMainText;
    (this.surveys as Survey).resultRewardText = dataToShow.resultRewardText;
  }

  @action getSurveyData = async (id: number) => {
    const data:SurveyProgress = await axiosBaseRequestAdTech({ url: `/api/surveys/${id}/status` });
    if (data.answers) {
      this.forms = data.answers;
      this.currentScreen = data.pageNumber;
      this.status = data.status;
    } else {
      this.status = 'InProgress';
    }
  }
}

export const questionsState = new Questions();
