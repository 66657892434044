import { observable, action } from 'mobx';
import { TypeBoxes, TypeIdBox } from './types';


class StoreDnd {
  @observable boxes:TypeBoxes[] = []
  @observable isDrag = true

  @action moveBox = (id: TypeIdBox, left: number, top: number) => {
    this.boxes = this.boxes.map(el => {
      if (id === el.id) {
        return { top, left, id };
      }
      return el;
    });
  }

  @action setBox = (id:TypeIdBox) => {
    this.boxes.push({ top: 810, left: 426, id });
  }

  @action deleteBox = (id:TypeIdBox) => {
    this.boxes = this.boxes.filter(el => el.id !== id);
  }

  @action clearBox = () => {
    this.boxes = [];
  }

  @action includesId = (id:TypeIdBox) => {
    return !!this.boxes.find(el => el.id === id);
  }
}

const storeDnD = new StoreDnd();

export default storeDnD;
