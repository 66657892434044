import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { SMS_LEGAL_INFO_MAX_LENGTH } from 'store/NewCampaign/channels/constants';
import InfoDescription from 'redesignSrc/components/InfoDescription';
import CounterFieldText from 'redesignSrc/UI/form/CounterFieldText';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { HovesCardTitle } from 'redesignSrc/UI/typography';
import withErrorStopWords from 'redesignSrc/HOC/withErrorStopWords';
import { AboutWhoAndWhatToWrite } from 'redesignSrc/pages/NewAudience/components/FloatingContent/AboutWhoAndWhatToWrite';
import FloatingSidePanel from 'pages/MyFinances/components/FloatingPanel';
import { LegalInfoProps } from './LegalInfoProps';
import styles from './styles.pcss';


const CounterFieldTextStopWords = withErrorStopWords(CounterFieldText);

export const LegalInfo: React.FC<LegalInfoProps> = observer(({ labelText, handleSmsFormChange, valid }) => {
  const [value, setValue] = useState(messageTemplatesStore.formTemplate.values?.additionalTemplate || '');
  const [wikiWhoAndWhatToWrite, setWikiWhoAndWhatToWrite] = useState(false);
  useEffect(() => {
    messageTemplatesStore.formTemplate.values.additionalTemplate = value.replace(/ +/g, ' ').trim().replace(/\n+/g, '\n').trim();
    handleSmsFormChange && handleSmsFormChange();
  }, [value]);

  const isDisabledField = () => {
    return messageTemplatesStore.formTemplate.values.status === 'Verification' || messageTemplatesStore.formTemplate.values.status === 'Accepted';
  };

  return (
    <div className={styles.wrapperLegalArea}>
      <HovesCardTitle className={styles.title}>Дополнительная юридическая информация</HovesCardTitle>
      <CounterFieldTextStopWords
        label={labelText}
        onChange={(e: any) => {
          setValue(e);
        }}
        error={valid.errors.additionalTemplate}
        onErrorWords={(isErr, errorWords) => {
          if (isErr) {
            valid.setErrorsOfKey(
              'additionalTemplate',
              `Данное слово запрещено использовать в описании, пожалуйста, замените его. Использованные запрещенные слова: ${errorWords.toString()}`,
            );
          } else {
            valid.setErrorsOfKey('additionalTemplate', '');
          }
        }}
        name="additionalTemplate"
        onBlur={() => {
          setValue(value.replace(/ +/g, ' ').trim().replace(/\n+/g, '\n').trim());
        }}
        value={value}
        max={SMS_LEGAL_INFO_MAX_LENGTH}
        disabled={isDisabledField()}
        placeholder="Например, не является лекарством"
      />
      {/* <InfoDescription icon="yellow">
        <span className={styles.warning}>
          Обязательно заполните поле, если продаёте товары дистанционно, рекламируете финансовые или медицинские услуги.&nbsp;
          <a
            href="https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/%D0%9F%D0%B0%D0%BC%D1%8F%D1%82%D0%BA%D0%B0_%D0%BF%D0%BE_%D0%AE%D1%80_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8_%D0%B4%D0%BB%D1%8F_%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D1%8B%D0%B9_%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D0%BD%D0%B8%D0%B9.pdf"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Кому и что писать.
          </a>&nbsp;Данный текст появится после основного текста сообщения <span>(бесплатно)</span>
        </span>
      </InfoDescription> */}

      <InfoDescription icon="yellow">
        <span className={styles.warning}>
          Обязательно заполните поле, если продаёте товары дистанционно, рекламируете финансовые или медицинские услуги.&nbsp;
          <span
            onClick={() => setWikiWhoAndWhatToWrite(true)}
            className={styles.link}
          >
            Кому и что писать.
          </span>
          &nbsp;Данный текст появится после основного текста сообщения <span>(бесплатно)</span>
        </span>
      </InfoDescription>
      {
        wikiWhoAndWhatToWrite &&
        <FloatingSidePanel onClose={() => setWikiWhoAndWhatToWrite(false)}
          title="Памятка к обязательной юридической информации">
          <AboutWhoAndWhatToWrite/>
        </FloatingSidePanel>
      }
    </div>
  );
});

