import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.pcss';

const cx = classNames.bind(styles);
type Props = {
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  key?: string|number
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>
}


const PureButton: React.FC<Props> = ({ className, type = 'button', ...otherProps }) => {
  return <button type={type} className={cx('component', className)} {...otherProps} />;
};

export default PureButton;

