import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'redesignSrc/UI/form/DatePicker';
import { ORDER } from 'redesignSrc/pages/constants';
import { formatDate } from 'utils/date';
import NewCampaign from 'store/mobx/NewCampaign';
import LaunchDatesValidator from 'store/mobx/LaunchDatesValidator';
import { ERROR_KEY } from 'redesignSrc/HOC/hooks/useValidation';
import styles from './styles.pcss';


type Props = {
  onChange?: () => void
  name:string
  validation?:any,
  className?: string,
}

const DateSelectionVoice = ({ onChange, name, validation, className }: Props) => {
  const [isWeekendDisabled, setWeekendDisabled] = useState(false);
  const [error, setError] = useState<string|null>(null);

  const {
    countMinStartDate,
    countMaxStartDate,
    countMinEndDate,
    countMaxEndDate,
    isEndDisabled,
    isSecondDateDisabled,
  } = LaunchDatesValidator;


  useEffect(() => {
    setError(validation?.errors[name as ERROR_KEY]);
  }, [validation?.errors[name as ERROR_KEY]]);


  const handlerChange = (e:any, key:string) => {
    // @ts-ignore
    NewCampaign.currentCampaign.currentOrder[key] = e;
    onChange && onChange();
    // validation?.getvalidObj(name);
  };

  const handleCalendarClose = () => {
    validation?.getvalidObj(name);
  };

  const handleFocusChange = () => {
    validation?.setErrorsOfKey(name, '');
  };

  const { date } = NewCampaign.currentCampaign.currentOrder;

  return (
    <div className={className}>
      <DatePicker
        handleFocusChange={handleFocusChange}
        handleCalendarClose={handleCalendarClose}
        startDatePlaceholder="Дата старта"
        endDatePlaceholder="Дата завершения"
        width={248}
        // @ts-ignore
        value={date ?? [undefined, undefined]}
        name={name}
        // @ts-ignore
        onChangeProxy={formatDate}
        minStart={countMinStartDate()}
        maxStart={countMaxStartDate()}
        // @ts-ignore
        minEnd={countMinEndDate(NewCampaign.currentCampaign.currentOrder.data?.date?.length ? NewCampaign.currentCampaign.currentOrder.data?.date?.[0] : null)}
        maxEnd={countMaxEndDate()}
        onInputFocusChange={() => {
          setWeekendDisabled(true);
        }}
        openDirection="up"

        isWeekendDisabled={isWeekendDisabled}
        isEndDisabled={isEndDisabled}
        countMinEnd={countMinEndDate}
        countMaxStart={countMaxStartDate}
        countMaxEnd={countMaxEndDate}

        holidays={NewCampaign.getHolidaysByChannelType}
        isInputsReadOnly
        isSecondDateDisabled={isSecondDateDisabled}
        // @ts-ignore
        onChange={(e) => handlerChange([e[0]?.format('YYYY-MM-DD'), e[1]?.format('YYYY-MM-DD')], ORDER.DATE)}
        secondMarginLeft={8} label="Период обзвона"
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default observer(DateSelectionVoice);
