import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Select } from '@beeline/design-system-react';
import { DocsByBusinessList } from '../../SenderNameStore';
import styles from '../styles.pcss';

const SelectField = observer(({
  onChange,
  fetchDocsList,
  type,
  docsByBusinessType,
  currentValues,
  error,
  ...props
} : {
  onChange?: (values: { value: string; }[], changedItem?: { value: string; } | undefined) => void,
  type: 1 | 0,
  fetchDocsList: (val: number) => Promise<void>,
  docsByBusinessType: DocsByBusinessList[] | [],
  currentValues: DocsByBusinessList[] | [],
  error: string | undefined
}) => {
  useEffect(() => {
    fetchDocsList(type);
  }, [type]);

  return (
    <Select
      label="Тип документа"
      onChange={onChange && onChange || (() => {})}
      options={docsByBusinessType.map(item => {
        return { value: item.documentTypeName, id: item.id };
      })}
      values={currentValues as { value: string}[]}
      helperText={error && error}
      error={!!error}
      className={styles.selectField}
      filter
      showTooltip
    />
  );
});

export default SelectField;
