import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { ContentContainer } from 'redesignSrc/UI/layout';
import InfoDescription from 'redesignSrc/components/InfoDescription';

import YellowInfoIcon from './assets/yellow-icon-info.svg';

import styles from './styles.pcss';

interface WorkingHoursProps {
  text?: string;
  startHour?: number;
  endHour?: number;
  margins?: boolean;
  className?: string;
}

const DEFAULT_TEXT = 'Заявка будет проверена в рабочее время с 9 до 18 по МСК';
const START_HOUR = 18;
const END_HOUR = 3;

export const WorkingHours: FC<WorkingHoursProps> = ({ text = DEFAULT_TEXT, startHour = START_HOUR, endHour = END_HOUR, className = '', margins = true }) => {
  const [showBlock, setShowBlock] = useState(false);

  const checkTime = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour >= startHour || currentHour < endHour) {
      setShowBlock(true);
    } else {
      setShowBlock(false);
    }
  };

  useEffect(() => {
    checkTime();
    const interval = setInterval(checkTime, 60000);
    return () => clearInterval(interval);
  }, [startHour, endHour]);

  return (
    <>
      {showBlock && (
        <ContentContainer className={classNames(className || styles.bottomBlockWithoutTop, !margins && styles.margins0)}>
          <div className={styles.descBlock}>
            <YellowInfoIcon className={styles.descIcon} /> <span className={styles.warning}>{text}</span>
          </div>
        </ContentContainer>
      )}
    </>
  );
};

