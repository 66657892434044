import React, { useEffect, ReactNode } from 'react';
import OutsideClickTracker from 'redesignSrc/UI/elements/OutsideClickTracker/OutsideClickTracker';
import styles from './styles.pcss';


type Props = {
  onClose: () => void;
  children: ReactNode,
}

const FloatingPanel = ({ onClose, children }:Props) => {
  return (
    <div className={styles.sideHolder}>
      <button className={styles.close_float} onClick={onClose}/>
      <div className={styles.scrollContainer}>
        {children}
      </div>
    </div>
  );
};

export default FloatingPanel;
