import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { action, observable, runInAction } from 'mobx';
import axios from 'axios';
import TextInput from 'components/fields/TextInput/components/TextInput';
import styles from './styles.pcss';

class SearchInputLocal {
  @observable addressFieldLink = null;
  @observable searchIsFetching = false;
  @observable addressSearch = '';
  @observable.ref suggester = null;
  @observable.ref addressesFounded = null;
  @observable fullAddress = null;
  @observable showAddressError = false;
  @action setSuggester = value => {
    this.suggester = value;
  }

  @action getAddresses = async () => {
    try {
      const searchString = this.addressSearch.split(',').map(item => { return item.trim(); }).join('+');
      this.searchIsFetching = true;
      const { data } = await axios(
        `https://geocode-maps.yandex.ru/1.x/?apikey=47ccf150-20ef-479b-badf-227a4601403c&lang=ru_RU&format=json&geocode=${searchString}`,
        {
          crossDomain: true,
        },
      );
      runInAction(() => {
        this.addressesFounded = data?.response?.GeoObjectCollection?.featureMember.map(item => {
          if (item.GeoObject.metaDataProperty.GeocoderMetaData.precision !== 'exact') {
            this.showAddressError = 'Введите точный адрес';
            return null;
          }
          this.showAddressError = false;
          this.addressSearch = item.GeoObject.metaDataProperty.GeocoderMetaData.text;
          this.fullAddress = {
            postal_code: item.GeoObject.metaDataProperty.GeocoderMetaData.Address.postal_code,
            city: item.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(geo => geo.kind === 'locality').name,
            street: item.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(geo => geo.kind === 'street').name,
            house: item.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(geo => geo.kind === 'house').name,
          };
          return {
            label: item.GeoObject.metaDataProperty.GeocoderMetaData.text,
            value: item.GeoObject.Point.pos,
          };
        })[0];
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.searchIsFetching = false;
    }
  }
}
const localState = new SearchInputLocal();

const SearchField = observer((props) => {
  const {
    name: id,
    onChange = (e) => { console.info(e); },
    className,
    error,
    onGetFullAddress,
  } = props;

  const { suggester, setSuggester, getAddresses } = localState;
  const addressInput = useRef();

  const handleAddressSelect = (e) => {
    localState.addressSearch = e.get('item').value;
    getAddresses();
    suggester?.destroy();
  };

  useEffect(() => {
    onChange(localState.addressesFounded);
    onGetFullAddress(localState.fullAddress);
  }, [localState.addressesFounded]);

  useEffect(() => {
    if (suggester && localState.addressesFounded?.label) {
      suggester.destroy();
      return;
    }
    window.ymaps.ready(() => {
      const suggestView = new window.ymaps.SuggestView(id, { width: 400 });
      setSuggester(suggestView);
      suggestView.events.add('select', handleAddressSelect);
    });
    // eslint-disable-next-line consistent-return
    return () => {
      if (suggester && window.ymaps) {
        suggester.events.remove('select', handleAddressSelect);
      }
    };
  }, [localState.addressesFounded?.label]);

  useEffect(() => {
    if (!localState.addressSearch.length && localState.addressesFounded?.label) {
      localState.addressFieldLink = null;
    }
  }, [localState.addressSearch.length]);

  useEffect(() => {
    if (
      localState.addressSearch.length && localState.addressesFounded?.label.length &&
      localState.addressSearch.length !== localState.addressesFounded?.label.length &&
      !localState.searchIsFetching
    ) {
      localState.addressesFounded = null;
    }
  }, [localState.addressSearch.length]);

  const changeOffAttribute = () => {
    addressInput.current.setAttribute('autocomplete', 'nope');
  };
  return (
    <div className={styles.inputHolder}>
      <TextInput
        type="text"
        className={className}
        onChange={(e) => {
          localState.addressSearch = e;
        }}
        name={id}
        error={localState.showAddressError || error}
        value={localState.addressSearch}
        onFocus={() => {
          changeOffAttribute();
          localState.showAddressError = false;
        }}
        ref={addressInput}
        id={id}
      />
    </div>
  );
});

export default SearchField;
