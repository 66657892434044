import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import { TextInput } from '../../../../../../../../UI/form';
import { IconEnd } from '../../../../../../../../UI/form/TextInput';
import messageTemplatesStore from '../../../../../../../../../store/mobx/MessageTemplates';
import { PopupStateless } from '../../../../../../../../UI/modals/Popup';
import { ItemListProps } from './ItemListProps';
import EditIcon from './assets/edit.svg';
import DeleteIcon from './assets/delete.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ItemList: FC<ItemListProps> = observer((props) => {
  const { onChange, createNewText, onSelectedItem, setIsShowList, CreateNewItemModal, isActionBtn, onDeleteItem, onEditItem, selectedName } = props;
  const [namesList, setNamesList] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [showSendDocs, setShowSendDocs] = useState<boolean>(false);

  // const isCurrent = selectedName === item.name ? ' current' : '';

  const searchTextHandler = (val: string) => setSearchText(val);

  const renderSearch = (): JSX.Element => {
    return (
      <TextInput
        onChange={searchTextHandler}
        endIcon={IconEnd.search}
        placeholder="Поиск по группам" />
    );
  };

  const search = () => {
    if (searchText.length < 1) return namesList;

    return namesList.filter((item: any) => {
      return item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });
  };

  const renderAddName = useMemo((): JSX.Element | null => {
    return (
      <div
        className={`${styles.listElement} ${styles.addName}`}
        onClick={() => setShowSendDocs(true)}
      >
        {createNewText}
      </div>
    );
  }, []);

  const onSelectedItemHandler = (name: string, id: number) => () => {
    setIsShowList(false);
    onSelectedItem(name, id);
    onChange && onChange();
  };

  useEffect(() => {
    setNamesList(messageTemplatesStore.messageTemplatesGroup);
  }, []);

  const popup = () => {
    const onCancel = () => {
      setShowSendDocs(false);
    };

    return (
      <PopupStateless
        opened
        className={styles.modal}
        onClose={onCancel}>
        <CreateNewItemModal onCloseModal={setShowSendDocs} onCloseList={setIsShowList} />
      </PopupStateless>
    );
  };

  const onEditItemHandler = (name: string, id: number) => (e: any) => {
    e.stopPropagation();
    onEditItem && onEditItem(name, id);
  };

  const onDeleteItemHandler = (name: string, id: number) => (e: any) => {
    e.stopPropagation();
    onDeleteItem && onDeleteItem(name, id);
  };

  return (
    <>
      {showSendDocs && popup()}
      <div className={styles.container}>
        {renderSearch()}
        {renderAddName}
        <div className={styles.scroll}>
          {
          search()?.length && search().map((item: any) => {
            return (
              <div
                className={cx(styles.listElement, { selected: selectedName === item.name })}
                key={item.id}
                onClick={onSelectedItemHandler(item.name, item.id)}
              >
                <div className={styles.infoHolder}>
                  <p className={styles.name}>{item.name}</p>
                  {
                    isActionBtn && (
                      <div>
                        <EditIcon onClick={onEditItemHandler(item.name, item.id)} />
                        <DeleteIcon onClick={onDeleteItemHandler(item.name, item.id)} />
                      </div>
                    )
                  }
                </div>
              </div>
            );
          }) || null
        }
        </div>
      </div>
    </>
  );
});

