import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { withForwardedRef } from 'enhancers';
import CustomPropTypes from 'utils/prop-types';
import ToBottomArrowIcon from './assets/ToBottomArrow.svg';
import styles from './styles.pcss';

function TextArrowSwitch({
  value,
  options,
  label,
  name,
  onChange,
  forwardedRef,
  isActive,
  isDisabled,
  className,
}) {
  const handleChange = () => onChange(value);
  return (
    <label className={classNames(styles.component, isDisabled && styles.disabled, className)}>
      <span className={styles.label}>
        {label}

        {isActive && <span className={styles.isActiveLabel} />}
      </span>
      <input
        className={styles.input}
        ref={forwardedRef}
        onClick={handleChange}
        type="button"
        name={name}
        disabled={isDisabled}
      />
      <div className={styles.arrowsContainer}>
        <ToBottomArrowIcon className={classNames(styles.arrowUp, value === options[0] && styles.active)} />

        <ToBottomArrowIcon className={classNames(styles.arrowDown, value === options[1] && styles.active)} />
      </div>
    </label>
  );
}

TextArrowSwitch.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  forwardedRef: CustomPropTypes.ref,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default withForwardedRef(TextArrowSwitch);
