import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import InfoDescription from '../../../components/InfoDescription';
import TooltipInfo from '../../elements/TooltipInfo';
import styles from './styles.pcss';

type Props = {
  name?: string,
  children?: string,
  checked?: boolean,
  onChange?: (b?: boolean) => void,
  className?: string,
  classNameContainer?: string
  disabled?:boolean
  textInfo?:ReactNode
  isNotClose?:boolean
}

const InputRadio = ({ name, children, onChange, checked, className, classNameContainer = '', disabled, textInfo, isNotClose }:Props) => {
  return (
    <div className={classNames(styles.container, classNameContainer)}>
      <div className={disabled ? styles.disabled : ''}>
        <input
          disabled={disabled}
          type="radio"
          name={name}
          id={children}
          onChange={() => onChange && onChange(checked)}
          checked={checked}
          className={styles.input}
          />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <label
          htmlFor={children}
          onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            onChange && onChange();
          }}
          className={classNames(styles.label, className)}
          >
          {children}
        </label>
      </div>
      { textInfo &&
        <TooltipInfo
          className={styles.tooltip}
          isNotClose={isNotClose}
        >
          {textInfo}
        </TooltipInfo>
      }
    </div>
  );
};

export default InputRadio;
