import React, { MouseEvent, ReactNode, useState } from 'react';
import classNames from 'classnames/bind';
import { PureButton } from 'redesignSrc/UI/buttons';
import { TypographyWithDangling } from 'redesignSrc/UI/beeline/TypographyWithDangling/TypographyWithDangling';
import Arrow from './assets/arrow.svg';
import ArrowNote from './assets/arrowNote.svg';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const MainTitleWiki: React.FC = ({
  children,
}) => {
  return (
    <div className={styles.mainTitle}>
      {children}
    </div>
  );
};

export const MainDescriptionWiki: React.FC = ({
  children,
}) => {
  return (
    <div className={styles.mainDescription}>
      {children}
    </div>
  );
};

type TypeLink = {
  href?: string,
  rel?: string,
  target?: string,
  className?: string
  onClick?: (e: MouseEvent) => void
}

export const Link: React.FC<TypeLink> = ({
  children,
  href,
  rel,
  target,
  className,
  onClick,
}) => {
  return (
    <a
      onClick={onClick}
      className={classNames(styles.link, className)} rel={rel || 'noreferrer'}
      target={target || '_blank'}
      href={href || '#'}>
      {children}
    </a>
  );
};

export const CollapsingBlockWiki: React.FC<{
  title?: string
  description?: ReactNode | string
  isNotCollaps?: boolean
}> = ({
  children,
  title,
  description,
  isNotCollaps,
}) => {
  const [active, setActive] = useState(isNotCollaps || false);
  return (
    <div className={styles.containerCollapsing}>
      {title && <div className={styles.headerCollapsing}>
        <span>{title}</span>
        {!isNotCollaps &&
          <PureButton
            className={styles.arrowButton}
            onClick={() => setActive(!active)}
          >
            <Arrow className={classNames(styles.arrow, active && styles.rotated)}/>
          </PureButton>
        }
      </div>}
      {active &&
        <>
          {description && <div className={styles.descriptionCollapsing}>{description}</div>}
          {children}
        </>
      }
    </div>
  );
};

export const TextInCollapsing = ({ children, className }: { children: ReactNode, className?: string }) => {
  return <div className={cx(styles.text, className)}>{children}</div>;
};

type TProps = {
  access: 'allow' | 'deny' | 'none',
  children?: ReactNode
}

export const TextPermission = ({ access, children }: TProps) => {
  return (
    <div className={styles.textAccess}>
      <div className={`${styles.accessIcon} ${styles[access]}`}/>
      <div>{children}</div>
    </div>
  );
};

export const NoticeText = ({ children }: { children: ReactNode | string }) => {
  return (
    <div className={styles.noticeHolder}>
      <div className={styles.note}/>
      <div>{children}</div>
    </div>
  );
};

export const StrongText = ({ children }: { children: ReactNode | string }) => {
  return (
    <div className={styles.strongText}>{children}</div>
  );
};

export const WhiteBlockWiki: React.FC<{
  title?: string | ReactNode,
  content?: ReactNode,
  note?: ReactNode,
}> = ({
  title,
  content,
  note,
}) => {
  const [active, setActive] = useState(false);
  return (
    <div className={styles.whiteContainer}>
      {
        title && <TypographyWithDangling variant="h6">{title}</TypographyWithDangling>
      }
      <div className={styles.noteWhiteBlock}>{content}</div>
      {note &&
        <PureButton
          className={classNames(styles.buttonWhiteBlock, active && styles.marginBottom)}
          onClick={() => setActive(!active)}
        >
          <span>Примечание</span>
          <ArrowNote className={classNames(styles.arrow, active && styles.rotated)}/>
        </PureButton>
      }
      {active && <div className={styles.noteWhiteBlock}>{note}</div>}
    </div>
  );
};

