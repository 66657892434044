import React, { useRef, useState } from 'react';
import { toJS } from 'mobx';
import LinkOpenInfo from '../LinkOpenInfo';
import TooltipInfo from '../TooltipInfo';
import LoaderFile from '../../loaders/LoaderFile';
import Icon from './assets/loaderIcon.svg';
import styles from './styles.pcss';

type Props = {
  children?: React.ReactNode | string,
  toolTipText?: React.ReactNode | string
  allowedFormats?: string,
  onChange?: (e: File) => void
  onClick?: () => void,
  className?: string,
  labelInfo?: string
  onClickLoad?: () => void
  href?: string
  isLoading?: boolean
  validate?: (e: File) => { title: string, description: string } | undefined
  isError?: string | null
}

const UploadLink: React.FC<Props> = ({
  children,
  allowedFormats = '.txt,.csv,.xlsx',
  onChange,
  onClick,
  className,
  toolTipText,
  labelInfo,
  onClickLoad,
  href,
  isLoading,
  validate,
  isError,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState({ title: '', description: '' });

  // eslint-disable-next-line consistent-return
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (validate && file) {
      if (validate(file)?.title) {
        setError({ title: validate(file)?.title || '', description: validate(file)?.description || '' });
        return null;
      }
    }
    if (e.target?.files) {
      onChange && onChange(e.target?.files[0] as File);
      e.target.value = '';
    }
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick && onClick();
  };

  const handleLoadClick = () => {
    inputRef.current?.click();
    onClickLoad && onClickLoad();
  };

  return (
    <div className={className}>
      {labelInfo && <span className={styles.labelInfo}>{labelInfo}</span>}
      {children ||
        <div className={styles.content}>
          <LoaderFile isLoading={isLoading}>
            <div className={styles.icon}>
              <Icon onClick={handleLoadClick}/>
              {toolTipText && <TooltipInfo className={styles.tooltip}>{toolTipText}</TooltipInfo>}
            </div>
          </LoaderFile>
          {onClick && <LinkOpenInfo className={styles.linkClick} onClick={handleClick}>Скачать шаблон</LinkOpenInfo>}
          {href && <a className={styles.link} rel="noreferrer" target="_blank" href={href}>Скачать шаблон</a>}
        </div>
      }

      <input
        ref={inputRef}
        onChange={handleFileChange}
        className={styles.input}
        type="file"
        accept={allowedFormats}
      />
      {isError && !error.title && <div className={styles.error}>{isError}</div>}
      {error.title && <div className={styles.error}>{error.title} ({error.description})</div>}
    </div>
  );
};

export default UploadLink;
