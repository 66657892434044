import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { FormFieldLabel } from '../../typography';
import { CharsCounter, TextArea } from '../index';
import styles from './styles.pcss';

type Props = {
  className?: string,
  onChange?: (e: string) => void
  max: number
  label?: string,
  value?: string,
  name?: string,
  validation?: any,
  onBlur?: () => void
  placeholder?: string,
  disabled?: boolean,
  error?: string
}

const CounterFieldText: React.FC<Props> = ({
  className,
  onChange,
  max,
  label,
  value,
  name,
  validation,
  onBlur,
  placeholder,
  disabled,
  error,
}) => {
  const [isError, setIsError] = useState<string | undefined>();
  const [isPaste, setIsPaste] = useState(false);
  const [position, setPosition] = React.useState(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleAreaChange = (text: string, e: ChangeEvent<HTMLTextAreaElement>) => {
    if (text.length > max && !isPaste) {
      setIsPaste(false);
      return;
    }
    if (text.length > max && isPaste) {
      setPosition(e.target.selectionStart);
      onChange && onChange(text.slice(0, max));
      setIsPaste(false);
      return;
    }
    onChange && onChange(text);

    // @ts-ignore
    if (validation?.errors[name]) {
      validation.getvalidObj(name);
    }
    setIsPaste(false);
  };

  const handleOnBlur = () => {
    validation?.getvalidObj(name);
    onBlur && onBlur();
  };

  useEffect(() => {
    if (textareaRef !== null && textareaRef.current) {
      textareaRef.current.selectionStart = position;
      textareaRef.current.selectionEnd = position;
    }
  }, [position]);

  useEffect(() => {
    // @ts-ignore
    setIsError(validation?.errors[name]);
    // @ts-ignore
  }, [validation?.errors[name]]);
  return (
    <div id={name} className={classNames(className, styles.container)}>
      <div className={styles.wrapTitle}>
        {label && <FormFieldLabel>{label}</FormFieldLabel>}
        <CharsCounter className={classNames(isError && styles.isError)} current={value?.length} max={max}/>
      </div>

      <TextArea
        textareaRef={textareaRef}
        placeholder={placeholder}
        error={isError || error}
        value={value}
        // @ts-ignore
        onChange={handleAreaChange}
        onBlur={handleOnBlur}
        onPaste={() => setIsPaste(true)}
        isDisabled={disabled}
      />

    </div>
  );
};

export default CounterFieldText;
