import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { formatPriceTotal } from '../../../../utils/formatting';
import { PriceForWidgetsProps } from './PriceForWidgetsProps';

import styles from './styles.pcss';

export const PriceInfoForWidgets: FC<PriceForWidgetsProps> = ({ price, customStyle, width }) => {
  return (
    <div className={classNames(customStyle ? styles.iconCustom : styles.icon)} style={{ maxWidth: width }}>
      {width ? <span>от {formatPriceTotal(price)}</span> : <span>{customStyle ? '' : '+'} {formatPriceTotal(price)}</span>}
    </div>
  );
};
