import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { List } from '@beeline/design-system-react';
import classNames from 'classnames/bind';
import styles from '../styles.pcss';
import { FilesById, senderNameStore } from '../../SenderNameStore';
import FileInList from './FileInList';

const cx = classNames.bind(styles);

const generateKey = () => {
  return Math.floor(Math.random() * Date.now());
};

const SenderNamesFileList = observer(({
  onReplaceFile,
  onDeleteFile,
  title,
  onFileEdit,
}: {
  onReplaceFile: (id: string, idx: number, file: FileList | null, existFile?: Omit<FilesById, 'file'>) => Promise<void>,
  onDeleteFile: (idx: FilesById, id: number | string) => void,
  title: string,
  onFileEdit: (fileId: string, data: { customFileName: string, documentTypeName: string, documentTypeId: string }) => void,
}) => {
  useEffect(() => {
    if (!senderNameStore.userFilesById?.[`${senderNameStore.documentsListViewId}`]) {
      senderNameStore.documentsListViewId = null;
    }
  }, [senderNameStore.userFilesById?.[`${senderNameStore.documentsListViewId}`]]);
  return (
    <>
      <div className={cx(styles.mainTitle, styles.mb29)}>{title}: все файлы</div>
      <List className={styles.list}>
        {
          senderNameStore.userFilesById?.[`${senderNameStore.documentsListViewId}`] &&
          senderNameStore.userFilesById?.[`${senderNameStore.documentsListViewId}`].map((document, idx) => {
            return (
              <FileInList
                document={document}
                idx={idx}
                key={generateKey()}
                id={`${senderNameStore.documentsListViewId}`}
                onReplaceFile={onReplaceFile}
                onDeleteFile={onDeleteFile}
                onFileEdit={onFileEdit}
              />
            );
          }) || null
        }
      </List>
    </>
  );
});

export default SenderNamesFileList;
