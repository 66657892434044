import React from 'react';
import { observer } from 'mobx-react';
import { GreyBlock, GroupTitle } from 'redesignSrc/UI/typography';
import { ContentContainer } from 'redesignSrc/UI/layout';
import danglingPrepositions from 'utils/danglingPrepositions';
import InputRadio from 'redesignSrc/UI/form/InputRadio';
import { formatPriceWithLabel } from 'utils/formatting';
import NewOrder from 'store/mobx/NewOrder';
import { PriceInfoForWidgets } from 'redesignSrc/UI/elements/PriceInfoForWidgets/PriceForWidgets';
import { ORDER } from '../../../../constants';
import { newYearBudget } from '../../index';
import styles from './styles.pcss';

export const configConnectionTypes = [
  {
    id: 1,
    title: 'Самостоятельный обзвон',
    description: 'Вы получаете базу абонентов и сами производите обзвон по ней. 1 контакт — от 10 рублей',
    priceDescription: 'контакт',
    price: 60000,
    value: 'NotTarget',
  },
  // {
  //   id: 2,
  //   title: 'Соединение с заинтересованными абонентами',
  //   description: 'Контактный центр билайн сам обзвонит таргетированную базу и переведет соединение уже с заинтересованными абонентами.',
  //   priceDescription: 'контакт',
  //   price: 624.55,
  //   value: 'Target',
  // },
  {
    id: 3,
    title: 'Индивидуальные условия',
    description: 'Наш менеджер готов предоставить еще более гибкие инструменты для поиска целевой аудитории, а также организовать работу по SIP-транку',
    priceDescription: 'контакт',
    price: 120000,
    value: 'Custom',
  },
];

type Props = {
  onChange?:()=>void
}

const ConnectionTypeBlock:React.FC<Props> = ({ onChange }) => {
  const handlerChange = (value:any) => {
    NewOrder.voiceTargetDetails.audienceType = value;
    if (NewOrder.voiceTargetDetails.audienceType !== 'NotTarget') {
      NewOrder.voiceTargetDetails.numberType = null;
    } else if (!NewOrder.voiceTargetDetails.numberType) {
      NewOrder.voiceTargetDetails.numberType = 'Mobile';
    }
    if (NewOrder.voiceTargetDetails.audienceType === 'Custom') {
      NewOrder.voiceTargetDetails.industryId = null;
    }
    onChange && onChange();
  };

  return (
    <ContentContainer
      className={styles.content}
      id={ORDER.CONNECTION_TYPE}
    >
      <GroupTitle className={styles.mainTitle}>Тип подключения</GroupTitle>

      { configConnectionTypes.map((item, idx) => {
        return (
          <GreyBlock
            key={item.id}
            className={styles.itemContainer}
            onClick={() => handlerChange(item.value)}
          >
            <InputRadio
              name={item.title}
              // @ts-ignore
              checked={NewOrder.voiceTargetDetails.audienceType === item.value}
              onChange={() => handlerChange(item.value)}
            />
            <div>
              <p className={styles.label}>{item.title}</p>
              <p className={styles.description}>{danglingPrepositions(item.description)}</p>
              <PriceInfoForWidgets width={idx === 0 ? 90 : 99} price={item.id === 1 ? newYearBudget() : item.price} />
            </div>
          </GreyBlock>
          );
      })}
    </ContentContainer>
  );
};

export default observer(ConnectionTypeBlock);
