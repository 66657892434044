import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

type Props = {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  isDisabled?: boolean;
  hasRightArrow?: boolean;
  isWhite?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isGray?: boolean,
  id?: string,
}

const PrimaryButton: React.FC<Props> = ({
  children,
  type = 'button',
  className,
  onClick,
  isDisabled,
  isWhite,
  hasRightArrow,
  isGray,
  id,
}) => {
  return (
    <button
      type={type}
      className={classNames(
        styles.primaryComponent,
        styles.primaryButton,
        hasRightArrow ? styles.arrow : '',
        isWhite ? styles.white : '',
        isGray ? styles.gray : '',
        className,
      )}
      onClick={onClick}
      disabled={isDisabled}
      id={id}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
