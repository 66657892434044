import React from 'react';
import classNames from 'classnames/bind';
import Img from './image.svg';
import styles from './styles.pcss';

type Props = {
  className?: string,
  size?: string|number,
}

const Preloader:React.FC<Props> = ({ size, className }) => {
  return (
    <div className={classNames(styles.component, className)}>
      <Img width={size} height={size} className={styles.image} />
    </div>
  );
};

export default Preloader;
