import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';


const Dropdown: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => {
  return (
    <div className={classNames(styles.dropdown, className)}>
      {children}
    </div>
  );
};

export default Dropdown;
