import { observable, action } from 'mobx';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import { toastErrorNotification } from 'modules/toast-notifications';

export default class Store {
  @observable stopWords: string[] = []
  @observable text: string = ''

  @action checkWordsStop = async () => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: '/api/orders/textCheck',
        method: 'POST',
        data: { text: this.text },
      });

      this.stopWords = request.stopWords;
    } catch (e) {
      toastErrorNotification('При выполнении запроса orders/textCheck возникла ошибка');
    }
  };
}
