import React from 'react';
import { observer } from 'mobx-react';
import CampaignProgressBar from '../CampaignProgressBar';
import { LowTitle, MainTitle } from '../../UI/typography';
import styles from './styles.module.pcss';

const CampaignProgress = (
  { step, title, description, totalStep }:
  { step?: number, title?: string, description?: string, totalStep?: number },
) => {
  return (
    <div className={styles.container}>
      <MainTitle className={styles.mainTitle}>{title}</MainTitle>
      <a
        rel="noreferrer"
        href="#"
        className={styles.channelsTitle}
      >
        <LowTitle>{description}</LowTitle>
      </a>
      {
        step && totalStep &&
        <CampaignProgressBar total={totalStep} active={step} completed={step > 1 && step - 1 || 0} />
      }
    </div>
  );
};

export default observer(CampaignProgress);
