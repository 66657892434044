import React, { Fragment, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useDrop, XYCoord } from 'react-dnd';
import {
  LinkBlock,
  PromoBlockVar,
  PromoBlock,
  DateBlock,
  DiscountBlock,
  AmountBlock,
  PhoneBlock,
  BonusBlock,
} from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/components';
import styles from './styles.pcss';
import { Box } from './Box';
import StoreDnd from './StoreDnd';
import CustomDragLayer from './CustomDragLayer';
import { DragItem, TypeIdBox, ItemTypes } from './types';

const BLOCKS = {
  link: LinkBlock,
  promo: PromoBlock,
  phone: PhoneBlock,
  discount: DiscountBlock,
  date: DateBlock,
  amount: AmountBlock,
  staticPromocode: PromoBlockVar,
  bonus: BonusBlock,
};

const ContainerDnD = ({ children }: { children: ReactNode }) => {
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        StoreDnd.moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [],
  );

  return (
    <div ref={drop} className={styles.wrapper}>
      {StoreDnd.boxes.map((el) => {
        const CurrentBlock = BLOCKS[el.id as TypeIdBox];

        return (
          <Fragment key={el.id}>
            <Box
              key={el.id}
              id={el.id}
              left={el.left}
              top={el.top}
            >
              <CurrentBlock/>
            </Box>
            <CustomDragLayer/>
          </Fragment>
        );
      })}
      {children}
    </div>
  );
};

export default observer(ContainerDnD);
