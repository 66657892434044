import React, { FC, ReactNode } from 'react';
import { Button } from '@beeline/design-system-react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';


interface WhiteBtnProps {
  variant?: 'contained' |
  'outlined' |
  'plain' |
  'overlay';
  href?: string;
  size?: 'small' |
  'medium' |
  'large';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  isActive?: boolean;
  width?: number;
  id?: string;
}

export const WhiteBtn: FC<WhiteBtnProps> = ({ children, size = 'medium', variant = 'outlined', className, startIcon, isActive, width, ...props }) => {
  return (
    <Button
      {...props}
      startIcon={startIcon}
      style={{ width }}
      size={size}
      variant={variant}
      className={`${classNames(styles.whiteBtn, isActive && styles.isActive)}
      ${className}`}
  >{children}</Button>
  );
};

// interface WhiteBtnProps {
//   variant?: 'contained' |
//   'outlined' |
//   'plain' |
//   'overlay';
//   href?: string;
//   size?: 'small' |
//   'medium' |
//   'large';
//   startIcon?: ReactNode;
//   endIcon?: ReactNode;
//   className?: string;
//   fullWidth?: boolean;
//   disabled?: boolean;
// }

// export const WhiteBtn: FC<WhiteBtnProps> = ({ children, size = 'medium', variant = 'outlined', className, ...props }) => {
//   return <Button {...props} size={size} variant={variant} className={`${styles.whiteBtn} ${className}`}>{children}</Button>;
// };
