import { action, observable, toJS } from 'mobx';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import NewOrder from '../NewOrder';
import audienceStore from '../AudienceAnalysis';
import { costAdjustmentItemType } from '../NewOrder/type';
import newAudiences from '../NewAudiences';

class CustomerDiscountsStore {
  @observable isClickBtnBonuses: boolean = false;
  @observable isVisible: boolean = true;
  @observable free: number | string = 0;
  @observable planBonusValue: number | string | null = null;
  @observable isBonusesUsed: boolean = false;
  @observable isCustomType: boolean = false;
  @observable isPromoCodeUsed: boolean = false;
  @observable isDisabledFields: boolean = false;
  @observable costAdjustments: costAdjustmentItemType[] | [] = [];

  @action resetState = () => {
    this.isClickBtnBonuses = false;
    this.free = 0;
    this.planBonusValue = null;
    this.isBonusesUsed = false;
    this.isPromoCodeUsed = false;
    this.isDisabledFields = false;
    this.isCustomType = false;
    this.costAdjustments = [];
    audienceStore.isSale = null;
    audienceStore.isReport = false;
    audienceStore.orderId = null;
    audienceStore.discount = null;
    audienceStore.promocode = null;
    audienceStore.discountCnt = 0;
  }

  @action checkUsedType = () => {
    this.costAdjustments = NewOrder.calculateData?.costAdjustments && NewOrder.calculateData?.costAdjustments.sort((a, b) => a.type - b.type) || [];
    this.isCustomType = newAudiences.isCustom;
    const isCustumTypeUsedBonuses = this.isCustomType && NewOrder.calculateData?.costAdjustments?.length === 2;
    this.isBonusesUsed = !!this.planBonusValue || (!!NewOrder.calculateData?.totalCostAdjustment && !this.isCustomType) || isCustumTypeUsedBonuses;
    this.isPromoCodeUsed = !!NewOrder.calculateData?.discount;
  }

  @action isBlockedDiscountItem = () => {
    const isCustomUseBonuses = NewOrder.calculateData?.costAdjustments?.length === 2;
    if (this.isPromoCodeUsed || this.planBonusValue || this.isBonusesUsed || isCustomUseBonuses) {
      this.isDisabledFields = true;
    } else {
      this.isDisabledFields = false;
    }
  }

  @action getBonuses = async () => {
    try {
      const { free } = await axiosBaseRequestAdTech({
        url: 'api/bonuses/balance',
      });
      this.free = free;
    } catch (e) {
      console.error(e);
    }
  }
}

export const customerDiscounts = new CustomerDiscountsStore();
