import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.pcss';

type Props = {
  text: string;
}

export const Tooltip: FC<Props> = ({ text }) => {
  return (
    <div
      className={classNames(styles.containerText,
          styles.top,
          styles.tooltip,
      )}>
      <span
        className={styles.text}
          >
        {text}
      </span>
    </div>
  );
};
