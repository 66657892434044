import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { toJS } from 'mobx';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import ContentEditableLinks from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks';
import StoreT from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/storeT';
import { FIELDS_TEMPLATE_FORM } from '../../../../consts';

type Props = {
  error?: string,
  handleSmsFormChange: () => void,
  valid: any,
}

const TextMessage:React.FC<Props> = ({ error, handleSmsFormChange, valid }) => {
  const onChange = useCallback(handleSmsFormChange, []);
  const [currentMessage, setCurrentMessage] = useState('');
  const [currentShortMessage, setCurrentShortMessage] = useState('');
  const [currentLink, setCurrentLink] = useState<any>('');
  const [isBlur, setIsBlur] = useState(false);
  const onBlurHandler = async () => {
    if (!isBlur) return;
    messageTemplatesStore.formTemplate.values.template = currentMessage.replace(/\n/gi, ' ').replace(/ {1,}/g, ' ').trim();
    messageTemplatesStore.formTemplate.values.shortTemplate = currentShortMessage.replace(/\n/gi, ' ').replace(/ {1,}/g, ' ').trim();
    messageTemplatesStore.formTemplate.values.links = currentLink;
    messageTemplatesStore.formTemplate.values.variables = StoreT.variables;
    await messageTemplatesStore.checkWordsStop();
    valid.getvalidObj(FIELDS_TEMPLATE_FORM.TEMPLATE);
    setIsBlur(false);
  };
  return (
    <>
      <ContentEditableLinks
        valid={valid}
        error={error}
        onBlur={onBlurHandler}
        onChange={(e) => {
          setIsBlur(true);
          const { text, originText, links } = e;
          setCurrentMessage(originText);
          setCurrentShortMessage(text as string);
          setCurrentLink(links);
          messageTemplatesStore.formTemplate.values.template = originText;
          messageTemplatesStore.formTemplate.values.shortTemplate = text;
          messageTemplatesStore.formTemplate.values.links = links;
          messageTemplatesStore.formTemplate.values.variables = StoreT.variables;
          valid.getvalidObj(FIELDS_TEMPLATE_FORM.TEMPLATE);
          onChange();
        }}
      />
    </>

  );
};

export default observer(TextMessage);
