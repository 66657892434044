import {
  BAN,
  ORDER_CLIENT_FILES_FIELD,
  ORDER_FILES_FIELD,
  ORDER_SENDER_NAME_FIELD,
} from '../../../../../../../store/NewCampaign/channels/constants';
import {
  composeArrayRequiredValidator,
  composeLengthValidator,
  composeOrderSenderNameRegexpValidator2,
  composeOrderSenderNameRegexpValidator3,
  composeOrderSenderNameRegexpValidator4,
  composeRequiredValidator,
  composeSlugValidator,
} from '../../../../../../../utils/fieldValidators';

const TEXT_VALID = 'Имя должно быть без пробелов и может содержать только латинские \nсимволы и цифры,нижнее подчеркивание, точку и тире';

export const editSenderNameRules = {
  [ORDER_SENDER_NAME_FIELD]: [
    composeRequiredValidator('Обязательное поле'),
    composeSlugValidator('Должна быть хотя бы одна буква'),
    // @ts-ignore
    composeLengthValidator('Длина имени должна быть от 2 до 11 символов', { min: 2, max: 11 }),
    // composeOrderSenderNameRegexpValidator(TEXT_VALID),
    composeOrderSenderNameRegexpValidator2('Имя должно быть без пробелов'),
    composeOrderSenderNameRegexpValidator3('Только латинские буквы'),
    composeOrderSenderNameRegexpValidator4(' Имя может содержать только нижнее подчеркивание, точку и тире'),
  ],
  [ORDER_FILES_FIELD]: [composeArrayRequiredValidator('Обязательное поле')],
  [BAN]: [composeRequiredValidator('Обязательное поле')],
};

export const createSenderNameRules = {
  [ORDER_SENDER_NAME_FIELD]: [
    composeRequiredValidator('Обязательное поле'),
    composeSlugValidator('Должна быть хотя бы одна буква'),
    // @ts-ignore
    composeLengthValidator('Длина имени должна быть от 2 до 11 символов', { min: 2, max: 11 }),
    // composeOrderSenderNameRegexpValidator(TEXT_VALID),
    composeOrderSenderNameRegexpValidator2('Имя должно быть без пробелов'),
    composeOrderSenderNameRegexpValidator3('Только латинские буквы'),
    composeOrderSenderNameRegexpValidator4(' Имя может содержать только нижнее подчеркивание, точку и тире'),
  ],
  [ORDER_CLIENT_FILES_FIELD]: [composeArrayRequiredValidator('Обязательное поле')],
};
