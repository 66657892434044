import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { debounce } from 'debounce';
import { toJS } from 'mobx';
import Store from './store';

type Props = {
  onErrorWords?: (isErr: boolean, errorWords: string[]) => void,
  onChange?: (e: any) => void,
}

const withErrorStopWords = <P extends object>(Component: ComponentType<P>): React.FC<Props & P> => observer(({
  onErrorWords,
  onChange,
  ...props
}) => {
  const [store] = useState(new Store());

  const checkWordsStopDebouns = useCallback(debounce(() => {
    if (typeof store.checkWordsStop === 'function') {
      store.checkWordsStop();
    }
  }, 1000), [store]);

  useEffect(() => {
    onErrorWords && onErrorWords(!!store.stopWords.length, store.stopWords);
  }, [store.stopWords]);

  const onChangeHandler = (e: any) => {
    store.text = e;
    checkWordsStopDebouns();
    onChange && onChange(e);
  };

  return (
    <>
      <Component
        onChange={onChangeHandler}
        {...props as P}
      />
    </>
  );
});

export default withErrorStopWords;
