export const WEBINAR = '/dashboard/#webinar';
export const NEW_CAMPAIGN_URL = '/new-campaign/';
export const MY_CAMPAIGNS_URL = '/my-campaigns/';
export const MESSAGE_TEMPLATES_URL = '/message-templates/';
export const SENDER_NAMES_URL = '/sender-names/';
export const MY_CAMPAIGNS_STATISTIC_URL = '/my-campaigns/:campaignId/statistics/';
export const MY_CAMPAIGNS_INVOICE = '/my-campaigns/:invoiceId/invoice/';
export const MY_CAMPAIGNS_REPEAT_URL = '/my-campaigns/:campaignId/repeat';
export const POLLS_PAGE_URL = '/polls/';
export const USER_INFO_URL = '/user-info/';
export const AUDIENCE_STATISTIC_URL = '/audience-statistic/';
export const AUDIENCE_STATISTIC_REPORT_URL = '/audience-statistic/report/';
export const OUTER_LINK_QUICKREQUEST = 'https://moskva.beeline.ru/business/services/beeline-prodvizhenie/#quickrequest';
export const FAQ_URL_SUBPAGE = '/faq/:faqPage';
export const FAQ_URL = '/faq/';
export const TINDER_URL = '/beetogether/';
export const MY_FINANCES = '/my-finances/';
export const CHANNELS_URL = '/channels/';
export const TINDER_LANDING = '/business-vmeste';
export const MY_AUDIENCES = '/my-audiences/';
export const NEW_AUDIENCES = '/new-audience/';
export const SIMPLE_AUDIENCE = '/simple-audience';
export const AUDIENCE_TYPE = '/audience-type';
export const NEW_TEMPLATE = '/new-template/';
export const NEW_SENDER = '/sender-name/';
export const NEW_AUDIENCES_TSMS = '/new-audience/tsms/';
export const PAY_INFO_URL = '/user-info/';
export const VIEW_AUDIENCE = '/view-audience/';
export const COUNTER_URL = '/counter/';

export const FILE_TYPES = {
  BRAND_FILE: 0,
  ORDER_REPORT: 1,
  MEDIA_PLAN: 2,
  FOCUS: 3,
  FORM: 4,
  PHONE: 5,
};

export const FILE_FORMATS = {
  CSV: 'csv',
  TXT: 'txt',
};
