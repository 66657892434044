import React, { FC } from 'react';

import { Button, Icon } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';

import styles from '../styles.pcss';

interface GoBackBtnProps{
  disabled?: boolean;
  className?: string;
  text?: string;
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset'
}

export const GoBackBtn: FC<GoBackBtnProps> = ({ children, text = 'Назад', disabled = false, ...props }) => {
  return (
    <Button startIcon={<Icon iconName={Icons.ArrowLeft} />} {...props} disabled={disabled} size="medium" variant="plain" className={styles.goBackBtn}>{text || children}</Button>
  );
};
