import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ContentContainer } from 'redesignSrc/UI/layout';
import { GreyBlock, GroupTitle } from 'redesignSrc/UI/typography';
import NewCampaign from 'store/mobx/NewCampaign';
import Templates from 'store/mobx/Templates';
import InputRadio from 'redesignSrc/UI/form/InputRadio';
import { ORDER } from 'redesignSrc/pages/constants';
import danglingPrepositions from 'utils/danglingPrepositions';

import NewOrder from '../../../../../../store/mobx/NewOrder';
import styles from './styles.pcss';

export const configCallMethods = [
  {
    id: 1,
    title: 'Со своего номера',
    description: 'Вы сами производите обзвон по базе абонентов со своего номера. Набираете зашифрованные номера в тоновом режиме, и система соединяет вас с потенциальными клиентами.',
    priceDescription: 'Звонки оплачиваются согласно вашему тарифу. \n Не требуется забирать симкарту для обзвона в офисе',
    value: 'Mobile',
  },
  // {
  //   id: 2,
  //   title: 'C корпоративного номера билайн',
  //   description: 'Мы предоставляем мобильный номер со специальными настройками. Вы набираете зашифрованные номера, а система соединяет вас с потенциальными клиентами.',
  //   priceDescription: 'Все вызовы на зашифрованные номера — бесплатные.\n Нужно получить симкарту в офисе билайн или дождаться курьера',
  //   value: 'Corporate',
  // },
];

type Props = {
  onChange?:()=>void
}


const WaysToMakeCalls:React.FC<Props> = ({ onChange }) => {
  const handlerChange = (value:any) => {
    NewOrder.voiceTargetDetails.numberType = value;
    onChange && onChange();
  };

  useEffect(() => {
    if (!NewOrder.voiceTargetDetails.numberType && NewOrder.voiceTargetDetails.audienceType === 'NotTarget') {
      NewOrder.voiceTargetDetails.numberType = 'Mobile';
    }
  }, []);

  return (
    <ContentContainer >
      <GroupTitle className={styles.mainTitle}>Способ совершения звонков</GroupTitle>
      {configCallMethods.map(item => {
        return (
          <GreyBlock
            key={item.id}
            className={styles.itemContainer}
            onClick={() => handlerChange(item.value)}
          >
            <InputRadio
              name={item.title}
              // @ts-ignore
              checked={NewOrder.voiceTargetDetails.numberType === item.value}
              onChange={() => handlerChange(item.value)}
            />
            <div>
              <p className={styles.label}>{item.title}</p>
              <p className={styles.description}>{danglingPrepositions(item.description)}</p>
              <p className={styles.label}>{danglingPrepositions(item.priceDescription)}</p>
            </div>
          </GreyBlock>
        );
      })}

    </ContentContainer>
  );
};

export default observer(WaysToMakeCalls);
