import React from 'react';
import classNames from 'classnames/bind';
import { withForwardedRef } from 'enhancers';
import Checkbox from './assets/Checkbox.svg';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IProps {
  id?: string,
  name?: string,
  className?: string,
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: boolean, e?: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => void,
  // eslint-disable-next-line no-unused-vars
  onBlur?: (value: boolean, e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void,
  value?: boolean,
  disabled?: boolean
  children?: React.ReactNode,
}

function CheckMark({ id, name, className, onChange, value, disabled, children }:IProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(!value, e);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as unknown as KeyboardEvent;
    if (target.key === 'Enter') onChange && onChange(!value, e);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label className={cx('container', className)}/* onClick={() => onChange && onChange(!value)} */>
      <input
        type="checkbox"
        className={cx('input')}
        id={id}
        name={name}
        onKeyUp={(e) => handleKeyUp(e)}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
      />

      <span className={cx('check', { selected: value })} >
        {value && <Checkbox/>}
      </span>

      {children}
    </label>
  );
}

export default withForwardedRef(CheckMark);
