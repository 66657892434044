import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';
import { valueToText } from 'redesignSrc/pagesTsms/components/FilterPanel/utils';
import UploadingScale from '../../../../../../../components/UploadingScale';
import { axiosBaseRequestAdTech } from '../../../../../../../../requests/helpers';
import { PopupStateless } from '../../../../../../../UI/modals/Popup';
import { PrimaryButton } from '../../../../../../../UI/buttons';
import { ORDER_CLIENT_FILES_FIELD } from '../../../../../../../../store/NewCampaign/channels/constants';
import { senderNameStore } from '../../SenderNameStore';
import { SenderNameFiles } from './components/SenderNameFiles/SenderNameFiles';
import { UploadSenderNameFilesPropsCreate } from './UploadSenderNameFilesPropsCreate';
import DownloadFileIcon from './assets/download-icon.svg';
import FileIcon from './assets/File.svg';
import DeleteFile from './assets/DeleteFile.svg';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const UploadSenderNameFilesCreate: FC<UploadSenderNameFilesPropsCreate> = observer((props) => {
  const { store, fileUploadInfoTooltip, fileSize, validation, isEditMode } = props;

  const [showSendDocs, setShowSendDocs] = useState<boolean>(false);
  const [selectedFileId, setSelectedFileId] = useState<null | number>(null);
  const [selectedFile, setSelectedFile] = useState<null | number>(null);

  const MAX_LOADED_FILES = 10;

  const handleDeleteFile = (fileId: number, file: any) => () => {
    setShowSendDocs(true);
    setSelectedFile(file);
    if (senderNameStore.senderStatus === 'Accepted' && senderNameStore?.clientFiles?.length <= 1) {
      senderNameStore.isUploadfileWhenStatusSenderAccepted = false;
    }
  };

  const popup = () => {
    const onCancel = () => {
      setShowSendDocs(false);
      setSelectedFileId(null);
    };

    const onOk = () => {
      selectedFile && store.handleClientFileRemove(selectedFile);
      setShowSendDocs(false);
      setSelectedFileId(null);
    };

    return (
      <PopupStateless
        opened
        className={styles.sendModal}
        onClose={onCancel}>
        <div className={styles.sendModalTitle}>Удаление файла</div>
        <div>Вы уверены, что хотите удалить файл?</div>
        <PrimaryButton
          className={styles.sendModalBtn}
          onClick={onOk}
        >
          Да
        </PrimaryButton>
        <PrimaryButton
          className={styles.sendModalBtnCancel}
          onClick={onCancel}
        >
          Отмена
        </PrimaryButton>
      </PopupStateless>
    );
  };

  const handleDownloadFile = (path: string, file: any) => async (e: any) => {
    e.preventDefault();

    const fileView = await axiosBaseRequestAdTech({
      url: path,
      responseType: 'blob',
    });
    const docLink = document.createElement('a');
    docLink.href = window.URL.createObjectURL(fileView);
    docLink.download = file.name || file.filename;
    docLink.click();
  };

  const isDisabledFields = () => store?.files?.length >= MAX_LOADED_FILES || store?.clientFiles.length >= MAX_LOADED_FILES || ((store?.files?.length + store?.clientFiles.length) >= MAX_LOADED_FILES);
  return (
    <div>
      {showSendDocs && popup()}
      <div className={cx({ disabledSmsText: isDisabledFields() })}>
        <SenderNameFiles
          store={store}
          isLoading={store.isLoading}
          onFilesAdded={store.createSenderNameUploadFile}
          error={validation && validation.errors[ORDER_CLIENT_FILES_FIELD]}
          validate={validation}
          fileUploadInfoTooltip={fileUploadInfoTooltip}
          fileSize={fileSize}
        />
      </div>
      {
        store.filesProgress && Object.entries(store.filesProgress).map((item: any) => {
          return <UploadingScale progress={item[1]} name={item[0]} key={item[0]}/>;
        })
      }

      { senderNameStore.senderStatus === 'Accepted' && (
        store?.files && store?.files?.length && store?.files?.map((file: any) => {
          const name = file?.name || file?.filename;
          const path = `/api/senderNames/${store.senderId}/files/${file?.id}`;
          return (
            <div className={styles.uploadedFile} key={file.id}>
              <div className={styles.icon}>
                <FileIcon />
              </div>
              <div className={styles.column}>
                <div className={styles.docName} title={name}>{valueToText(name, 50)}</div>
              </div>
              <div className={styles.closeHolder}>
                <div className={styles.row}>
                  <DownloadFileIcon className={styles.iconDownload} onClick={handleDownloadFile(path, file)} />
                  {
                    store.isShowUploadFiles() && (
                      <button className={styles.button} onClick={handleDeleteFile(file.id, file)}>
                        <DeleteFile/>
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
          );
        }) || null)
      }

      {
        store?.clientFiles && store?.clientFiles?.length && store?.clientFiles?.map((file: any) => {
          const name = file?.name || file?.filename;
          return (
            <div className={styles.uploadedFile} key={file.id}>
              <div className={styles.icon}>
                <span>
                  <FileIcon />
                </span>
              </div>
              <div className={styles.column}>
                <div className={styles.docName} title={name}>{valueToText(name, 50)}</div>
              </div>
              <div className={styles.closeHolder}>
                <div className={styles.row}>
                  <button className={styles.button} onClick={handleDeleteFile(file.id, file)}>
                    <DeleteFile/>
                  </button>
                </div>
              </div>
            </div>
          );
        }) || null
      }
    </div>
  );
});
