import React from 'react';
import { observer } from 'mobx-react';
import { FormFieldLabel } from 'redesignSrc/UI/typography';
import { PriceInput } from 'redesignSrc/UI/form';
import { ORDER } from 'redesignSrc/pages/constants';
import { formatPriceWithLabel } from 'utils/formatting';
import NewOrder from 'store/mobx/NewOrder';
import styles from './styles.pcss';

type Props = {
  onChange:()=>void
  validation:any,
}

const BudgetVoice:React.FC<Props> = ({ onChange, validation }) => {
  const isCustom = NewOrder!.order!.voiceTargetDetails.audienceType === 'Custom';

  return (
    <>
      {
        !isCustom &&
        <>
          <p className={styles.notifications}>
            Количество контактов:&nbsp;
            <span>{NewOrder.calculateData?.count}</span>
          </p>
          {
            NewOrder.smsSegmentSizeValid ?
              <div className={styles.error} >
                <p className={styles.strError} style={{ color: 'rgba(220, 0, 0, .8)' }}>{NewOrder.smsSegmentSizeValid}</p>
              </div>
              : null
          }
          </>
      }
      <FormFieldLabel>Бюджет</FormFieldLabel>
      <PriceInput
        validation={validation}
        name={ORDER.BUDGET_FIELD}
        placeholder={isCustom ? 'от 120 000' : 'от 12 000'}
        value={`${NewOrder.currentOrder.planPrice || ''}`}
        max={8}
        onChange={ (e) => {
          NewOrder.currentOrder.planPrice = +e;
          onChange();
        }}
      />
    </>
  );
};

export default observer(BudgetVoice);
