export const orderStatusesTranslateMap = {
  0: 'Черновик',
  1: 'На согласовании',
  2: 'Действующая',
  3: 'Отказ',
  4: 'Остановлена',
  5: 'Завершена',
  6: 'Оплачена',
  7: 'Модерация не пройдена',
  8: 'На согласовании',
  9: 'Ожидает оплаты',
  10: 'Ожидает запуск',
};

export const pollsStatusesTranslateMap = {
  0: 'Создан',
  1: 'На согласовании',
  2: 'Действующий',
  3: 'Отказ',
  4: 'Остановлен',
  5: 'Завершен',
  6: 'Оплачен',
  7: 'Модерация не пройдена',
  8: 'Модерация',
  9: 'Ожидает оплаты',
  10: 'Ожидает запуск',
};
