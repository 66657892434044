import { action, observable, reaction, computed, toJS } from 'mobx';
// @ts-ignore
import { uniqueId } from 'lodash';
import debounce from 'debounce';
import moment from 'moment';
import { TypeBanCustomer } from 'redesignSrc/components/ContractSelectionSS/ContractSelectionSSStore';
import {
  toastErrorNotification,
  toastAccessNotification,
} from 'modules/toast-notifications';
import {
  TypeRegion,
} from 'redesignSrc/pages/NewAudience/components/SettingAudience/components/TaxonWidgets/GeoWidget/components/InputCities';
import {
  axiosBaseRequestAdTech,
  prepareMultipartFormData,
} from 'requests/helpers';

import NewOrder from '../NewOrder';
import { ym } from '../../../utils/ga-analytics/utils';
import UserInfo from '../UserInfo';
import CampaignStatistics from '../TSMS/CampaignStatistics';
import {
  TypeValueFile,
} from '../../../redesignSrc/pages/NewAudience/components/SettingAudience/components/TaxonWidgets/MyBaseWidget/utils';
import {
  TypeOperator,
  TypeSegments,
  TypeMyAudience,
  TypePriceGroups,
  TypeAudience,
  TypeSetEditTaxon,
  CustomAudienceBalanceHoldInvoiceItemType,
  CustomAudienceQueryType,
  CustomAudienceBalanceType, TypeOptCreateSegment, AuctionPriceType,
} from './type';
import { getArrBundleCategoryPriceGroupIds, getSegmentOrderPrice, optionsBottomGroupRealTime } from './utils';


const BLOCK_PRICE_DATE = new Date(2023, 0, 9);

class NewAudiences {
  @observable isEditCustomAudience: boolean = false;

  @observable customAudienceBalanceHoldList: CustomAudienceBalanceHoldInvoiceItemType[] = [];

  @observable showCheckoutModal: boolean = false;
  @observable showCheckoutSuccessModal: boolean = false;

  @observable historyCustomAudienceBalance: CustomAudienceBalanceHoldInvoiceItemType[] = [];

  @observable dataCustomer?: TypeBanCustomer
  @observable customerId: number | null = null

  @observable customAudienceBalance: CustomAudienceBalanceType = {
    orderId: null,
    price: 0,
    totalHoldCost: 0,
    totalInvoicedCost: 0,
    freeBalance: 0,
    holdDetails: [],
    invoicedDetails: [],
  };
  @observable customAudienceQuery: CustomAudienceQueryType = {
    url: '',
    count: 0,
    description: '',
    exceptions: '',
  };
  @observable isLoadingSegmentOrderWidget = false
  @observable isSame: boolean = false;
  @observable isMyAudience = false;
  // это флаг который отправляется на бэк. Не стал использовать isMyAudience
  // потому что на него завязано мого логики
  @observable myAudienceFlag: boolean | undefined = undefined;
  @observable canStartTriggerError = '';
  @observable isLoading = false;
  @observable nameAudience = '';
  @observable typeAudience: TypeAudience = 'Offline';
  @observable segments: TypeSegments | null = null;
  @observable createSegmentDate: string | null = null;
  @observable oldPrice: number | null = null
  @observable selectedSegments: any[] = [];
  @observable filterAudienceData: any[] = [];
  @observable initOperator: string | null = null;
  @observable flagForCheckEstimate = Date.now();
  @observable replacementFilterObj: {
    operator: string;
    type: string;
  } | null = null;
  @observable hasAnyTaxons: boolean | null = null;
  @observable prevAnyTaxons = false;
  @observable replacementId: string | null = null;
  @observable isLoadingAudience = false;
  @observable audience: number | null = null;
  @observable audienceVT = null;
  @observable errorEmptyAudience: string | null = null
  @observable error = {
    sitesError: null,
    callsError: null,
    senderError: null,
    keywordsError: null,
  };
  @observable isLoadTaxon = false;
  // Везде когда надо пересчитать аудиторию при изменении параметров в коде статусу присваивается null
  @observable status: string | null = null;
  @observable msisdnsCount: number | null = null;
  @observable voiceTargetMsisdnsCount: number | null = null;
  @observable timerId: number | null = null;
  @observable myAudienceCount: number | null = null;
  @observable selectionAudience: 'new' | 'save' = 'new';
  @observable saveAudience: null | TypeMyAudience = null;
  @observable saveAudienceOrderId: number | null = null;
  @observable lastUploading = false;
  @observable priceSegment: any[] = [];
  @observable segmentPriceGroups: TypePriceGroups | null = null;
  @observable orderPriceGroups: TypePriceGroups | null = null;
  @observable isErrCount = false;

  @observable isInfoVisible = false

  @observable optionsBottom = optionsBottomGroupRealTime[0]
  @observable auctionPrice: AuctionPriceType | null = null
  @observable isLoadAuctionPrice = true

  @observable.ref citiesGeo: TypeRegion[] | null = null


  @observable site: string = '';

  @action resetNewAudiences = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }

    this.showCheckoutModal = false;
    this.showCheckoutSuccessModal = false;
    this.isEditCustomAudience = false;
    this.historyCustomAudienceBalance = [];
    this.customAudienceBalanceHoldList = [];
    this.customAudienceBalance = {
      orderId: null,
      price: 0,
      totalHoldCost: 0,
      totalInvoicedCost: 0,
      freeBalance: 0,
      holdDetails: [],
      invoicedDetails: [],
    };
    this.customAudienceQuery = {
      url: '',
      count: 0,
      description: '',
      exceptions: '',
    };
    this.nameAudience = '';
    this.typeAudience = 'Offline';
    this.segments = null;
    this.selectedSegments = [];
    this.filterAudienceData = [];
    this.initOperator = null;
    this.replacementFilterObj = null;
    this.replacementId = null;
    this.audience = null;
    this.audienceVT = null;
    this.status = null;
    this.msisdnsCount = null;
    this.voiceTargetMsisdnsCount = null;
    this.isMyAudience = false;
    this.selectionAudience = 'new';
    this.isSame = false;
    this.createSegmentDate = null;
    this.priceSegment = [];
    this.segmentPriceGroups = null;
    this.orderPriceGroups = null;
    this.oldPrice = null;
    this.errorEmptyAudience = null;
    this.optionsBottom = optionsBottomGroupRealTime[0];
    this.auctionPrice = null;
    this.isLoadAuctionPrice = true;
  };

  @action renameTitleSenderNames(str: string) {
    return str === 'Имена отправителей' ? `${str} SMS` : str;
  }

  @computed get getSegmentBase() {
    return this.segments?.filter((segment) => segment.isBase);
  }

  @computed get getSegmentGroupRealTime() {
    if (this.getSegmentBase?.length && this.getSegmentBase[0].targetGroup) {
      return this.getSegmentBase.filter(el => el.targetGroup === this.optionsBottom.value);
    }

    if (this.getSegmentBase?.length) {
      switch (this.optionsBottom.value) {
        case 1: {
          return this.getSegmentBase.filter(el => el.bundleType !== 'Geo');
        }
        case 2: {
          return this.getSegmentBase.filter(el => el.bundleType === 'Geo');
        }
        default:
          return [];
      }
    }
    return [];
  }

  @computed get isValidOneBaseSegmentRealTime() {
    let valid = false;
    if (this.selectedSegments.length) {
      this.filterAudienceData.forEach(el => {
        if (
          (el.condition.term?.hosts?.length >= 5 && !this.error.sitesError) ||
          (el.condition.term?.numbers?.length >= 5 && !this.error.callsError) ||
          (el.condition.term?.senders?.length >= 5 && !this.error.senderError) ||
          el.condition.term?.ids?.length ||
          el.condition.term?.pixelIds?.length ||
          el.condition.term?.Zones?.length > 0 ||
          el.condition.term?.Polygons?.length > 0 ||
          el.condition.term?.RegionsIds?.length > 0 ||
          el.condition.term?.CitiesIds?.length > 0
        ) {
          valid = true;
        }
      });
    }
    return valid;
  }

  @computed get isAuction() {
    if (this.selectedSegments.length && NewOrder.order?.type === 'Sms' && !NewOrder.calculateData?.isFixedPrice) {
      return this.selectedSegments[0]?.isAuction;
    }
    return false;
  }

  @computed get isAuctionBadStatus() {
    return !!(NewOrder.smsCampaign.auctionPrice &&
      this.auctionPrice?.minRecommendedPrice &&
      (NewOrder.smsCampaign.auctionPrice < this.auctionPrice?.minRecommendedPrice));
  }

  @computed get isAuctionSave() {
    return !!(NewOrder.order?.type === 'Sms' && this.auctionPrice?.minPrice && !NewOrder.calculateData?.isFixedPrice);
  }


  @computed get getSegmentExtra() {
    return this.segments?.filter((segment) => !segment.isBase);
  }

  // массив id всех выбранных сегментов
  @computed get getSelectedSegmentsIds() {
    return this.selectedSegments.map(segment => segment.id);
  }

  @computed get getOfflineSegment() {
    return this.selectedSegments.filter(segment => !segment.isTrigger);
  }

  // Отсортированные аудитории офлайн в сборке онлайн
  @computed get getOfflineFilterAudienceData() {
    return this.filterAudienceData.filter(segment => !segment.isTrigger);
  }

  @computed get isRealTime() {
    return this.typeAudience === 'RealTime';
  }

  @computed get isSimple() {
    return this.typeAudience === 'Simple';
  }

  @computed get isCustom() {
    return this.typeAudience === 'Custom';
  }

  @computed get isOffline() {
    return this.typeAudience === 'Offline';
  }

  @computed get isMultiOperators() {
    return this.typeAudience === 'MultiOperators';
  }

  @computed get isLookAlike() {
    return this.typeAudience === 'LookAlike';
  }

  @action getTypeAudience = async () => {
    this.segments = null;
    try {
      this.segments = await axiosBaseRequestAdTech({
        url: `api/segment/bundles?segmentType=${this.typeAudience}`,
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action getGeoCities = async () => {
    if (this.citiesGeo) return;
    try {
      this.citiesGeo = await axiosBaseRequestAdTech({
        url: '/api/resources/segments/cities',
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action getEditTaxon = (
    id: string,
  ): {
    payload?: any;
    term: any;
    operator: TypeOperator | null;
    id: string | null;
  } => {
    const currentIndexTaxon = this.filterAudienceData.findIndex(
      (item: any) => item?.id === id,
    );
    const currentIndexTaxonOffline = this.getOfflineFilterAudienceData.findIndex(
      (item: any) => item?.id === id,
    );
    if (currentIndexTaxon !== -1) {
      return {
        term: toJS(this.filterAudienceData)[currentIndexTaxon].condition.term,
        payload: toJS(this.filterAudienceData)[currentIndexTaxon].condition
          ?.payload,
        operator: toJS(this.getOfflineFilterAudienceData)[currentIndexTaxonOffline + 1]
          ?.operator,
        id: toJS(this.getOfflineFilterAudienceData)[currentIndexTaxonOffline + 1]?.id,
      };
    }
    return {
      term: [],
      operator: null,
      id: null,
    };
  };

  @action setEditTaxon = ({
    segmentData,
    term,
    type,
    isTrigger,
    store,
  }: TypeSetEditTaxon) => {
    let categoryId = null;
    if (segmentData.bundleType === 'Categories') {
      categoryId = segmentData?.values[0]?.id;
    }
    const { values, ...other } = segmentData;
    const withoutValueSegmentData = { ...other, categoryId };
    const currentIndexTaxon = this.filterAudienceData.findIndex(
      (item: any) => item?.id === segmentData.uniqueId,
    );
    if (
      currentIndexTaxon !== -1 &&
      this.filterAudienceData.length > currentIndexTaxon
    ) {
      this.filterAudienceData[currentIndexTaxon].condition.term = term;
      this.filterAudienceData[currentIndexTaxon].condition.payload = store;
      this.filterAudienceData[currentIndexTaxon].condition.segmentBundleId = segmentData.id;
      this.filterAudienceData[currentIndexTaxon].segmentData = withoutValueSegmentData;
      if (this.replacementFilterObj) {
        this.filterAudienceData[currentIndexTaxon].operator = this.replacementFilterObj.operator;
        // (type === 'Campaigns') -Думаю временно пока не появится новый виджет для кампаний в БиПро таких нет
        this.filterAudienceData[currentIndexTaxon].condition.type = (type === 'Campaigns') ?
          'Campaigns' : this.replacementFilterObj.type;
        this.filterAudienceData[currentIndexTaxon].condition.segmentBundleId = segmentData.id;
        this.filterAudienceData[currentIndexTaxon].segmentData = withoutValueSegmentData;
      }
    } else {
      this.filterAudienceData.push({
        segmentData: withoutValueSegmentData,
        operator: this.initOperator || 'EQ',
        id: segmentData.uniqueId,
        isTrigger,
        condition: {
          segmentBundleId: segmentData.id,
          type,
          term,
          payload: store,
        },
      });
    }
    this.replacementFilterObj = null;
    this.flagForCheckEstimate = Date.now();
    this.calculatePriceSegment();
  };

  @action setOperatorTaxon = (id: string, operator: string) => {
    const currentIndexTaxon = this.filterAudienceData.findIndex(
      (item: any) => item?.id === id,
    );
    if (
      currentIndexTaxon !== -1 &&
      this.filterAudienceData.length > currentIndexTaxon
    ) {
      this.filterAudienceData[currentIndexTaxon].operator = operator;
    }
  };

  getTriggersRealTime() {
    const trigger: { triggers: {}[], operator: string } = {
      triggers: [],
      operator: 'EQ',
    };
    this.filterAudienceData.forEach((el, i) => {
      if ((el.operator === 'AND' || el.operator === 'EXCLUDE') && trigger.operator === 'EQ') {
        trigger.operator = el.operator;
      }
      if (el.isTrigger) {
        trigger.triggers.push({
          type: el.condition?.type,
          term: el.condition?.term,
        });
      }
    });
    return trigger;
  }

  taxonModelAssembly(isRealTime?: boolean, isPrice: boolean = false) {
    let taxonModel: any = {};
    const currentfilterAudienceData = isRealTime
      ? this.filterAudienceData.filter((el) => !el.isTrigger)
      : this.filterAudienceData;

    if (this.typeAudience === 'Simple') {
      return {
        operator: 'AND',
        conditions: this.filterAudienceData.filter(item => {
          if (item?.condition?.term?.hasOwnProperty('hosts')) {
            return !!item?.condition?.term.hosts.length;
          }
          if (item?.condition?.term?.hasOwnProperty('keywords')) {
            return !!item?.condition?.term.keywords.length;
          }
          return true;
        }).map(item => {
          return {
            operator: item.operator,
            condition: {
              segmentBundleId: item.condition.segmentBundleId,
              term: item.condition.term,
              type: item.condition.type,
            },
          };
        }),
      };
    }

    currentfilterAudienceData.forEach((item, i) => {
      if (i === 0) {
        taxonModel.operator = 'EQ';
        taxonModel.condition = {
          segmentBundleId: item.condition.segmentBundleId,
          term: item.condition.term,
          type: item.condition.type,
        };
      } else if (
        taxonModel.operator === item.operator &&
        item.operator !== 'EXCLUDE'
      ) {
        if (!taxonModel.hasOwnProperty('conditions')) {
          taxonModel.conditions = [];
        }
        taxonModel.conditions.push({
          operator: 'EQ',
          condition: {
            segmentBundleId: item.condition.segmentBundleId,
            term: item.condition.term,
            type: item.condition.type,
          },
        });
      } else if (
        taxonModel.operator !== item.operator &&
        item.operator !== 'EXCLUDE'
      ) {
        taxonModel = {
          operator: item.operator,
          conditions: [
            taxonModel,
            {
              operator: 'EQ',
              condition: {
                segmentBundleId: item.condition.segmentBundleId,
                term: item.condition.term,
                type: item.condition.type,
              },
            },
          ],
        };
      } else {
        taxonModel = {
          operator: item.operator,
          conditions: [
            taxonModel,
            {
              operator: 'EQ',
              condition: {
                segmentBundleId: item.condition.segmentBundleId,
                term: item.condition.term,
                type: item.condition.type,
              },
            },
          ],
        };
      }
    });
    if (!isPrice && taxonModel.condition) {
      if (
        taxonModel?.condition?.type === 'Categories' &&
        taxonModel.condition?.term[0]?.values?.length < 1
      ) {
        taxonModel = {
          operator: 'EQ',
          condition: { term: [], type: 'Categories' },
        };
      }
    }
    if (!isPrice && taxonModel.conditions) {
      taxonModel.conditions = taxonModel.conditions?.map((el: any) => {
        if (
          el?.condition?.type === 'Categories' &&
          el?.condition?.term[0]?.values?.length < 1
        ) {
          return {
            operator: 'EQ',
            condition: { term: [], type: 'Categories' },
          };
        }
        return el;
      });
      return Object.keys(taxonModel).length === 0 ? null : taxonModel;
    }
    return Object.keys(taxonModel).length === 0 ? null : taxonModel;
  }

  @action checkSites = async (sites: string[]) => {
    if (!sites || !sites.length) return;
    try {
      const request = await axiosBaseRequestAdTech({
        url: '/api/sites/check',
        method: 'POST',
        data: sites,
      });
      return request.failedSites;
    } catch (error) {
      console.error(error);
    }
  }

  @action counterAudience = debounce(async () => {
    // https://btask.beeline.ru/browse/TSMS-3938
    // if (this.isLookAlike && this.selectionAudience === 'new') return;
    const cond = this.taxonModelAssembly(false, false);
    if (!this.isRealTime) {
      try {
        // Тут возникает ошибка TypeError: Cannot read properties of undefined (reading 'type')
        if (
          (cond?.condition?.type === 'Calls' && cond?.condition?.term?.numbers) ||
          (cond?.condition?.type === 'Sites' &&
            cond?.condition?.term?.hosts?.length) ||
          (cond?.condition?.type === 'Senders' &&
            cond?.condition?.term?.senders?.length) ||
          (cond?.condition?.type === 'KeyWords' &&
            cond?.condition?.term?.keywords?.length)
        ) {
          if (this.lastUploading) {
            this.lastUploading = false;
          } else {
            ym(87718585, 'reachGoal', 'file_upload_audience', {
              my_audience: { file_upload_audience: 'ручной ввод' },
            });
          }
        }
        this.isLoadingAudience = true;
        this.flagForCheckEstimate = Date.now();

        const request = await axiosBaseRequestAdTech({
          url: '/api/segment/counter/v2',
          method: 'POST',
          data: cond || {
            operator: 'EQ',
            condition: { term: [], type: 'Categories' },
          },
        });
        this.audience = request.count;
        this.audienceVT = request.voiceTargetCount;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoadingAudience = false;
      }
    }
  }, 500);

  // Здесь мы перебираем выбранные виджеты и в зависимости от priceGroupIds, categoryIds, segmentBundleIds
  // расставляем прайсы.
  // Если priceGroupIds уже есть ставим discountPrice иначе price.
  // Если categoryIds, или segmentBundleIds уже есть не price ставим в 0.
  // В виджетах Segments и Orders дергаем метод segmentOrderHandle
  // и из него последовательно считаем прайс по выше описанной логике
  @action calculatePriceSegment = () => {
    if (this.isPriceOld) {
      return;
    }

    if (NewOrder?.order?.type === 'VoiceTarget') return;
    const priceGroupIds: (number | null)[] = [];
    const categoryIds: number[] = [];
    const segmentBundleIds: number[] = [];
    const segmentOrderData = [];
    let currentPriceSegment = [];

    for (let i = 0; i < this.filterAudienceData.length; i++) {
      const data = this.filterAudienceData[i];

      if (!data.segmentData) return;

      // Eсли добавлен виджет сохраненных сегментов или ордеров но не выбраны сегменты или ордера
      // считаем прайс стандартно иначе пушим в массив segmentOrderData и считаем чуть ниже по другой логике
      if (data.segmentData.bundleType === 'Segments' && this.segmentPriceGroups) {
        segmentOrderData.push(data);
      }
      if (data.segmentData.bundleType === 'Orders' && this.orderPriceGroups) {
        segmentOrderData.push(data);
      }
      //
      // Тут считается отдельно сегмент 'Пол, возраст, доход' по причине трех категирий в одном бандле
      if (data.segmentData?.title === 'Пол, возраст, доход') {
        let currentPrice = 0;
        if (priceGroupIds.includes(data.segmentData?.priceGroupId)) {
          if (data.condition.term.length === 0) {
            currentPrice = data.segmentData.discountPrice;
          } else {
            currentPrice = data.condition.term.map((el: any) => {
              if (categoryIds.includes(el.id)) {
                return 0;
              }
              categoryIds.push(el.id);
              return data.segmentData.discountPrice;
            }).reduce((a: number, n: number) => a + n, 0);
          }
        } else {
          if (data.condition.term.length === 0) {
            currentPrice = data.segmentData.price;
          } else {
            currentPrice = data.condition.term.map((el: any, index: number) => {
              if (!categoryIds.includes(el.id)) {
                categoryIds.push(el.id);
              }
              if (index === 0) {
                return data.segmentData.price;
              }
              return data.segmentData.discountPrice;
            }).reduce((a: number, n: number) => a + n, 0);
          }
          priceGroupIds.push(data.segmentData.priceGroupId);
        }
        //
        currentPriceSegment.push({
          title: data.segmentData.title,
          price: +currentPrice.toFixed(1),
          priceGroupId: data.segmentData.priceGroupId,
          id: data.id,
          categoryId: data.segmentData.categoryId,
        });
        // eslint-disable-next-line no-continue
        continue;
      }
      if (priceGroupIds.includes(data.segmentData?.priceGroupId)) {
        if (categoryIds.includes(data.segmentData.categoryId) || segmentBundleIds.includes(data.segmentData.id)) {
          currentPriceSegment.push({
            title: data.segmentData.title,
            price: 0,
            priceGroupId: data.segmentData.priceGroupId,
            id: data.id,
            categoryId: data.segmentData.categoryId,
          });
        } else {
          if (data.segmentData?.categoryId) {
            categoryIds.push(data.segmentData.categoryId);
          } else segmentBundleIds.push(data.segmentData.id);

          currentPriceSegment.push({
            title: data.segmentData.title,
            price: isNaN(+data.segmentData.discountPrice?.toFixed(1)) ? 0 : +data.segmentData.discountPrice?.toFixed(1),
            priceGroupId: data.segmentData.priceGroupId,
            id: data.id,
            categoryId: data.segmentData.categoryId,
          });
        }
      } else {
        priceGroupIds.push(data.segmentData.priceGroupId);
        if (data.segmentData?.categoryId) {
          categoryIds.push(data.segmentData.categoryId);
        } else segmentBundleIds.push(data.segmentData.id);

        currentPriceSegment.push({
          title: data.segmentData.title,
          price: +data.segmentData.price?.toFixed(1),
          priceGroupId: data.segmentData.priceGroupId,
          id: data.id,
          categoryId: data.segmentData.categoryId,
        });
      }
    }
    if (segmentOrderData.length) {
      for (let i = 0; i < segmentOrderData.length; i++) {
        const data = segmentOrderData[i];
        // Здесь в начале пересчитываем первый добавленный а во втором учитываем
        // уже мсползованные segmentBundleIds categoryIds priceGroupIds
        if (i === 0) {
          let price = 0;
          if (data.segmentData.bundleType === 'Segments') {
            price = getSegmentOrderPrice({
              segmentBundleIds,
              categoryIds,
              priceGroupIds,
              priceGroups: this.segmentPriceGroups,
            });
          }
          if (data.segmentData.bundleType === 'Orders') {
            price = getSegmentOrderPrice({
              segmentBundleIds,
              categoryIds,
              priceGroupIds,
              priceGroups: this.orderPriceGroups,
            });
          }
          currentPriceSegment = currentPriceSegment.map((el) => {
            if (el.id === data.id) {
              return {
                title: data.segmentData.title,
                price: isNaN(+(price).toFixed(1)) ? 0 : +(price).toFixed(1),
                priceGroupId: data.segmentData.priceGroupId,
                id: data.id,
                categoryId: data.segmentData.categoryId,
              };
            }
            return el;
          });
        }
        if (i === 1) {
          let price = 0;
          if (data.segmentData.bundleType === 'Segments') {
            const {
              currentSegmentBundleIds,
              currentPriceGroupIds,
              currentCategoryIds,
            } = getArrBundleCategoryPriceGroupIds(this.orderPriceGroups);
            price = getSegmentOrderPrice({
              segmentBundleIds: Array.from(new Set(segmentBundleIds.concat(currentSegmentBundleIds))),
              categoryIds: Array.from(new Set(categoryIds.concat(currentCategoryIds))),
              priceGroupIds: Array.from(new Set(priceGroupIds.concat(currentPriceGroupIds))),
              priceGroups: this.segmentPriceGroups,
            });
          }
          if (data.segmentData.bundleType === 'Orders') {
            const {
              currentSegmentBundleIds,
              currentPriceGroupIds,
              currentCategoryIds,
            } = getArrBundleCategoryPriceGroupIds(this.segmentPriceGroups);
            price = getSegmentOrderPrice({
              segmentBundleIds: Array.from(new Set(segmentBundleIds.concat(currentSegmentBundleIds))),
              categoryIds: Array.from(new Set(categoryIds.concat(currentCategoryIds))),
              priceGroupIds: Array.from(new Set(priceGroupIds.concat(currentPriceGroupIds))),
              priceGroups: this.orderPriceGroups,
            });
          }
          currentPriceSegment = currentPriceSegment.map((el) => {
            if (el.id === data.id) {
              return {
                title: data.segmentData.title,
                price: +(price).toFixed(1),
                priceGroupId: data.segmentData.priceGroupId,
                id: data.id,
                categoryId: data.segmentData.categoryId,
              };
            }
            return el;
          });
        }
      }
    }
    // При фиксированной цене обнуляем прайсы
    this.priceSegment = NewOrder.calculateData?.isFixedPrice ? currentPriceSegment.map(price => ({
      ...price,
      price: 0,
    })) : currentPriceSegment;
  };


  @action segmentOrderHandle = async (ids: number[], isOrder?: boolean) => {
    if (!ids?.length) {
      // Сбрасываем при удалении всех выбранных сегментов и запускаем пересчет
      if (isOrder) {
        this.orderPriceGroups = null;
      } else {
        this.segmentPriceGroups = null;
      }
      this.calculatePriceSegment();
      return;
    }
    try {
      this.isLoadingSegmentOrderWidget = true;
      if (isOrder) {
        if (UserInfo.data?.appType !== 2) {
          await this.getPriceGroupOrders(ids);
        }
      } else {
        await this.getPriceGroupSegments(ids);
      }
      this.calculatePriceSegment();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingSegmentOrderWidget = false;
    }
  };

  @action getPriceGroupSegments = async (ids: number[]) => {
    this.segmentPriceGroups = await axiosBaseRequestAdTech({
      url: '/api/segment/prices/bySegments',
      method: 'POST',
      data: { ids },
    });
  };

  @action getPriceGroupOrders = async (ids: number[]) => {
    const url = UserInfo.data?.appType !== 2 ?
      '/api/segment/prices/byCampaigns' : '/api/segment/prices/byOrders';
    this.orderPriceGroups = await axiosBaseRequestAdTech({
      url,
      method: 'POST',
      data: { ids },
    });
  };

  @computed get renderPriceSegment() {
    const segmentPrice: { title: string; price: number }[] = [];
    for (let i = 0; i < this.priceSegment.length; i++) {
      const currentPrice = this.priceSegment[i];
      if (currentPrice.price !== 0 && this.typeAudience !== 'Simple') {
        if (currentPrice.priceGroupId === 4) {
          segmentPrice.push({
            title:
              'Звонки абонентам, сайты, имена отравителей и/или ключевые слова за прошедший период',
            price: currentPrice.price,
          });
        } else if (currentPrice.priceGroupId === 7) {
          const priceTrigger = this.isAuction ?
            NewOrder.smsCampaign.auctionPrice || this.auctionPrice?.recommendedPrice :
            currentPrice.price;
          segmentPrice.push({
            title: 'Таргеты реал. времени',
            price: priceTrigger,
          });
        } else {
          segmentPrice.push({
            title: currentPrice.title,
            price: currentPrice.price,
          });
        }
      }
    }
    return segmentPrice;
  }

  @computed get isNotRenderPrice() {
    return this.renderPriceSegment.length === 0;
  }

  @computed get isPriceOld() {
    if (this.createSegmentDate) {
      return new Date(this.createSegmentDate) < BLOCK_PRICE_DATE;
    }
    return false;
  }

  @computed get sumPriceSegment() {
    const sum = +this.renderPriceSegment.reduce((a, n) => a + n.price, 0).toFixed(1);
    if (isNaN(sum)) return 0;
    return sum;
  }

  @action getBalanceByIdForCustomSegment = async (id: number) => {
    try {
      const balance = await axiosBaseRequestAdTech({
        method: 'GET',
        url: `/api/orders/cs/${id}/balance`,
      });

      this.customAudienceBalance = balance;

      this.customAudienceBalanceHoldList = [...this.customAudienceBalance.holdDetails];

      const holdArray: CustomAudienceBalanceHoldInvoiceItemType[] = this.customAudienceBalance.holdDetails.map((item) => {
        return {
          ...item,
          timestamp: moment(item.timestamp).format('DD.MM.YYYY'),
          orderName: `Резерв средств для ${item.orderType === 5 ? 'PUSH-рассылки ' : 'SMS-рассылка '} ${item.orderName || ''}(id ${item.orderId})`,
          type: 'hold',
        };
      });

      const invoicedArray: CustomAudienceBalanceHoldInvoiceItemType[] = this.customAudienceBalance.invoicedDetails.map((item) => {
        return {
          ...item,
          timestamp: moment(item.timestamp).format('DD.MM.YYYY'),
          orderName: (() => {
            if (item?.orderName.toLowerCase().trim() === 'возврат на баланс клиента') return item?.orderName;
            return `Оплата ${item.orderType === 4 ? 'SMS-рассылка ' : 'PUSH-рассылки '} ${item.orderName || ''}(id ${item.orderId})`;
          })(),
          type: 'invoice',
        };
      });

      const defaultValue: CustomAudienceBalanceHoldInvoiceItemType = {
        orderId: CampaignStatistics.data?.id,
        orderName: 'Оплата аудитории',
        timestamp: moment(CampaignStatistics.data?.createDate).format('DD.MM.YYYY'),
        cost: 30000,
        heldCost: 0,
        orderType: 0,
        type: 'base',
      };

      this.historyCustomAudienceBalance = [...holdArray, ...invoicedArray].sort((a, b) => a.timestamp < b.timestamp ? 1 : -1);
      this.historyCustomAudienceBalance = [...this.historyCustomAudienceBalance, defaultValue];
    } catch (e) {
      console.error('createSegment', e);
      throw new Error();
    }
  };

  @action getSegment = async (id?: number | null) => {
    if (this.status === 'Succeeded' && this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
    if (id) {
      try {
        const request = await axiosBaseRequestAdTech({
          url: `/api/segment/${id}`,
        });

        if (request.type === 'Custom') {
          this.customAudienceQuery.url = request.query?.url;
          this.customAudienceQuery.count = request.query?.count;
          this.customAudienceQuery.description = request.query?.description;
          this.customAudienceQuery.exceptions = request.query?.exceptions;
        }

        this.createSegmentDate = request.createDate;
        this.msisdnsCount = request.msisdnsCount;
        this.voiceTargetMsisdnsCount = request.voiceTargetMsisdnsCount;
        this.status = request.status;
        this.oldPrice = request.price;
        this.selectedSegments = request.raw?.select || [];
        this.filterAudienceData = request.raw?.filter || [];
        this.nameAudience = request.name;
        this.isMyAudience = request.isMyAudience;
        this.typeAudience = request.type || 'Offline';
        if (request.type === 'RealTime' && this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = null;
        }
        this.customerId = request.customerId;
        this.calculatePriceSegment();
      } catch (e) {
        console.error(e);
      }
    }
  };

  @action createSegment = async ({
    id,
    name,
    IsMyAudience,
    customerId,
  }: TypeOptCreateSegment) => {
    try {
      return await axiosBaseRequestAdTech({
        url: '/api/segment',
        method: 'POST',
        data: {
          id,
          trigger:
            this.isRealTime ? this.getTriggersRealTime() : null,
          query: this.isCustom ? {
            url: this.customAudienceQuery.url,
            count: this.customAudienceQuery.count,
            description: this.customAudienceQuery.description,
            exceptions: this.customAudienceQuery.exceptions,
          } : this.taxonModelAssembly(this.isRealTime),
          type: this.typeAudience,
          name: name || this.nameAudience,
          raw: {
            filter: this.filterAudienceData,
            select: this.selectedSegments,
          },
          IsMyAudience:
            this.myAudienceFlag !== undefined
              ? this.myAudienceFlag
              : !!IsMyAudience,
          customerId,
        },
      });
    } catch (e) {
      console.error('createSegment', e);
      throw new Error();
    }
  };

  @action deleteSelectedSegments = (id: number, orderId?: number) => {
    this.status = null;
    this.selectedSegments = this.selectedSegments.filter(
      (item) => item.uniqueId !== id,
    );
    this.filterAudienceData = this.filterAudienceData.filter(
      (el) => el.id !== id,
    );
    this.counterAudience();
  };

  @action getAddressesTemplate = async () => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: '/api/addressesTemplate',
        responseType: 'blob',
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(request);
      link.download = 'Шаблон адресов.csv';
      link.click();
    } catch (e) {
      console.error(e);
    }
  };

  @action getCoordinatesTemplate = async () => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: '/api/coordinatesTemplate',
        responseType: 'blob',
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(request);
      link.download = 'Шаблон координат.csv';
      link.click();
    } catch (e) {
      console.error(e);
    }
  };

  @action getFileInfoTemplate = async (type: TypeValueFile = 'Ctn') => {
    try {
      let request;
      if (type === 'Ctn') {
        request = await axiosBaseRequestAdTech({
          url: '/api/msisdnsjob/fileInfo',
          responseType: 'blob',
        });
      }
      if (type === 'IdfaGa') {
        request = 'https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%20%D1%81%D0%BF%D0%B8%D1%81%D0%BA%D0%B0%20IDFA.csv';
      }
      if (type === 'Mac') {
        request = 'https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%20%D1%81%D0%BF%D0%B8%D1%81%D0%BA%D0%B0%20MAC-%D0%B0%D0%B4%D1%80%D0%B5%D1%81%D0%BE%D0%B2.csv';
      }
      // if (type === 'Email') {
      //   request = 'https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%20%D1%81%D0%BF%D0%B8%D1%81%D0%BA%D0%B0%20%D0%BF%D0%BE%D1%87%D1%82.csv';
      // }
      if (type === 'MD5') {
        request = 'https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD_%D1%81%D0%BF%D0%B8%D1%81%D0%BA%D0%B0_%D0%B7%D0%B0%D1%85%D0%B5%D1%88%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D0%BE%D0%B2.csv';
      }
      const link = document.createElement('a');
      if (type === 'Ctn') {
        link.href = URL.createObjectURL(request);
        link.download = 'Шаблон списка номеров.csv';
      } else {
        link.href = request;
      }
      link.click();
    } catch (e) {
      console.error(e);
    }
  };

  @action getUrlInfoTemplate = async () => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: '/api/hosts',
        responseType: 'blob',
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(request);
      link.download = 'Шаблон списка сайтов.csv';
      link.click();
    } catch (e) {
      console.error(e);
    }
  };

  @action setSelectedSegments = async (id: number) => {
    this.isLoadTaxon = true;
    try {
      const segment = await axiosBaseRequestAdTech({
        url: `/api/segment/bundles/${id}`,
      });
      this.status = null;
      const unId = finduniqueId(this.selectedSegments, segment);
      this.selectedSegments.push({
        ...segment,
        uniqueId: unId,
        isError: false,
        finalPrice: 0,
      });
      return unId;
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      this.isLoadTaxon = false;
    }
  };

  @action editSelectedSegments = async (idSelect: number, id: string) => {
    try {
      const segment = await axiosBaseRequestAdTech({
        url: `/api/segment/bundles/${idSelect}`,
      });
      const unId = finduniqueId(this.filterAudienceData, segment);
      this.filterAudienceData = this.filterAudienceData.map((item) => {
        if (item.id === id) {
          this.replacementFilterObj = {
            operator: item.operator,
            type: segment.bundleType,
          };
          this.replacementId = unId;
          return {
            ...item,
            condition: { ...item.condition, term: [] },
            id: unId,
          };
        }
        return item;
      });

      this.selectedSegments = this.selectedSegments.map((item) => {
        if (item.uniqueId === id) {
          return { ...item, ...segment, uniqueId: unId, isError: false };
        }
        return item;
      });
      this.status = null;
      return unId;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  @action requestFile = async (file: File, type: 0 | 1) => {
    try {
      const response = await axiosBaseRequestAdTech({
        url: '/api/segment/geo/file',
        method: 'POST',
        data: prepareMultipartFormData({
          FileType: type,
          File: file,
        }),
      });
      if (type === 1) {
        toastAccessNotification('Адреса из файла добавлены в общий список');
      }
      if (type === 0) {
        toastAccessNotification('Координаты из файла добавлены в общий список');
      }
      return response;
    } catch (e) {
      console.error(e);
      toastErrorNotification('Загружен некорректный файл');
      return null;
    }
  };

  @action requestMyFile = async (file: File, type?: TypeValueFile) => {
    const query = type ? `?type=${type}` : '';
    try {
      const res = await axiosBaseRequestAdTech({
        url: `/api/segment/file${query}`,
        method: 'POST',
        data: prepareMultipartFormData({
          File: file,
        }),
      });
      ym(87718585, 'reachGoal', 'file_upload_audience', {
        my_audience: { file_upload_audience: 'загрузка' },
      });
      this.lastUploading = true;
      return res;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  @action requestCtnsFile = async (file: File) => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: '/api/segment/files/ctns',
        method: 'POST',
        data: prepareMultipartFormData({
          File: file,
        }),
      });
      toastAccessNotification(
        'Номера телефонов из файла добавлены в общий список',
      );
      ym(87718585, 'reachGoal', 'file_upload_audience', {
        my_audience: { file_upload_audience: 'загрузка' },
      });
      this.lastUploading = true;
      return request;
    } catch (e) {
      toastErrorNotification('Не получилось загрузить номера');
      console.error(e);
      return null;
    }
  };

  @action startSegment = async (id: number) => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: `/api/segment/${id}/start`,
      });
      this.status = request.status;
      if (this.status !== 'Succeeded' && this.isAudienceCountCheck) {
        this.timerId = setInterval(this.getSegment, 5000, id);
      }
    } catch (e) {
      console.error('startSegment', e, e?.response?.status);
      throw new Error();
    }
  };

  @action requestUrlFile = async (file: File) => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: '/api/segment/files/sites',
        method: 'POST',
        data: prepareMultipartFormData({
          File: file,
        }),
      });
      toastAccessNotification('Сайты из файла добавлены в общий список');
      ym(87718585, 'reachGoal', 'file_upload_audience', {
        my_audience: { file_upload_audience: 'загрузка' },
      });
      this.lastUploading = true;
      return request;
    } catch (e) {
      console.error(e);
      toastAccessNotification('Не получилось загрузить сайты');
      return null;
    }
  };

  @action requestSendersFile = async (file: File) => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: '/api/segment/files/senderNames',
        method: 'POST',
        data: prepareMultipartFormData({
          File: file,
        }),
      });
      toastAccessNotification(
        'Имена отправителей из файла добавлены в общий список',
      );
      ym(87718585, 'reachGoal', 'file_upload_audience', {
        my_audience: { file_upload_audience: 'загрузка' },
      });
      this.lastUploading = true;
      return request;
    } catch (e) {
      console.error(e);
      toastErrorNotification('Не получилось загрузить имена отправителей');
      return null;
    }
  };

  @action requestKeywordFile = async (file: File) => {
    try {
      const request = await axiosBaseRequestAdTech({
        url: '/api/segment/files/keywords',
        method: 'POST',
        data: prepareMultipartFormData({
          File: file,
        }),
      });
      toastAccessNotification(
        'Ключевые слова из файла добавлены в общий список',
      );
      ym(87718585, 'reachGoal', 'file_upload_audience', {
        my_audience: { file_upload_audience: 'загрузка' },
      });
      this.lastUploading = true;
      return request;
    } catch (e) {
      console.error(e);
      toastErrorNotification('Не получилось загрузить ключевые слова');
      return null;
    }
  };

  @action checkIfCanStartTrigger = async () => {
    const id = NewOrder?.order?.id || null;
    if (!this.isRealTime) return;
    try {
      await axiosBaseRequestAdTech({ url: `/api/orders/${id}/estimation`, method: 'POST' });
      this.canStartTriggerError = '';
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.text) {
        this.canStartTriggerError = e?.response?.data?.text || 'Неизвестная ошибка';
      }
    }
  }

  @action getStatisticsAuctionPrice = async () => {
    const isSaveSegment = this.selectionAudience === 'save' || NewOrder.order?.isSavedAudience;
    // if (!this.isAuction && (!isSaveSegment || !NewOrder.order?.isSavedAudience)) return;
    this.isLoadAuctionPrice = true;

    try {
      this.auctionPrice = await axiosBaseRequestAdTech({
        url: 'api/Auction/price',
        method: 'POST',
        params: isSaveSegment ? { segmentId: this.saveAudience?.id || NewOrder.order?.segmentId } : {},
        data: !isSaveSegment ? toJS(this.getTriggersRealTime()) : {},
      });
      this.isLoadAuctionPrice = false;
    } catch (e) {
      console.error(e);
      toastErrorNotification('Не удалось получить аукционный прайс');
    }
  }


  @computed get isAudienceCountCheck() {
    return !!newAudiences.selectedSegments.find(
      (el) =>
        el.bundleType === 'File' ||
        el.bundleType === 'Sites' ||
        el.bundleType === 'Calls' ||
        el.bundleType === 'Industries' ||
        el.bundleType === 'Senders' ||
        el.bundleType === 'KeyWords' ||
        el.bundleType === 'Orders' ||
        el.bundleType === 'Segments',
    );
  }

  @computed get OTHER_PRICE() {
    return this.isCustom ? 5.5 : 5;
  }
}

const newAudiences = new NewAudiences();

export default newAudiences;

reaction(
  () => newAudiences.selectionAudience,
  () => {
    newAudiences.saveAudience = null;
    newAudiences.nameAudience = '';
    newAudiences.selectedSegments = [];
    newAudiences.filterAudienceData = [];
    newAudiences.initOperator = null;
    newAudiences.replacementFilterObj = null;
    newAudiences.replacementId = null;
    newAudiences.audience = null;
    newAudiences.audienceVT = null;
    newAudiences.status = null;
    newAudiences.msisdnsCount = null;
    newAudiences.voiceTargetMsisdnsCount = null;
    newAudiences.isMyAudience = false;
    newAudiences.counterAudience();
  },
);

reaction(
  () => newAudiences.saveAudience,
  async () => {
    if (newAudiences.saveAudience !== null) {
      await newAudiences.getSegment(newAudiences.saveAudience.id);
      await newAudiences.counterAudience();
    }
  },
);

reaction(
  () => newAudiences.filterAudienceData.length,
  () => {
    if (newAudiences.filterAudienceData.length === 0) {
      newAudiences.initOperator = null;
    }
    newAudiences.errorEmptyAudience = null;
    newAudiences.calculatePriceSegment();
  },
);

reaction(
  () => newAudiences.typeAudience,
  () => {
    newAudiences.checkIfCanStartTrigger();
  },
);

reaction(() => newAudiences.flagForCheckEstimate, () => newAudiences.checkIfCanStartTrigger());

const finduniqueId = (
  elements: { uniqueId?: string; id?: string }[],
  element: { bundleType: string; id: string },
): string => {
  const unId = uniqueId(`${element?.bundleType}_${element?.id}_`);
  if (
    elements.find((item) => item.uniqueId === unId) ||
    elements.find((item) => item.id === unId)
  ) {
    return finduniqueId(elements, element);
  }
  return unId;
};
