import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { SMS_MESSAGE_MAX_LENGTH } from 'store/NewCampaign/channels/constants';
import { wordFormByCount } from 'utils/strings';
import messageTemplatesStore from 'store/mobx/MessageTemplates';
import { SMSCounter } from './utils';

import styles from './styles.pcss';


type Props = {
  currentLength: number,
  StoreT?: any;
}
const MessageRemainingLength: React.FC<Props> = ({ currentLength, StoreT }) => {
  const [countSMS, setCountSMS] = useState(0);
  const partForms = ['часть', 'части', 'частей'];

  useEffect(() => {
    if (countSMS !== SMSCounter(currentLength)) {
      setCountSMS(SMSCounter(currentLength));
    }
  }, [currentLength]);
  return (

    <div className={styles.block}>
      <span className={styles.text}>

        ({currentLength}/{SMS_MESSAGE_MAX_LENGTH}&nbsp;|&nbsp;
        <span className={classNames(countSMS > 0 && styles.sizeMoreThan70)}>
          {countSMS} {wordFormByCount(countSMS, partForms)}
        </span> )
      </span>
    </div>
  );
};


export default observer(MessageRemainingLength);
