import {
  composeLinkValidator,
  composePhoneNotValid,
  composeRequiredValidator,
  composeStopBadWordsNotValid,
  composeStopBadWordsNotValidForTemplate,
} from '../../../utils/fieldValidators';

export const FIELDS_TEMPLATE_FORM = {
  NAME: 'name',
  TEMPLATE: 'template',
};

export const FIELDS_TEMPLATE_GROUP_FORM = {
  NAME: 'name',
};

export const RULES_TYPE_TEMPLATE = {
  [FIELDS_TEMPLATE_FORM.NAME]: [
    composeRequiredValidator('Обязательное поле'),
  ],
  [FIELDS_TEMPLATE_FORM.TEMPLATE]: [
    composeRequiredValidator('Обязательное поле'),
    composeStopBadWordsNotValidForTemplate('Не показывать'),
    composePhoneNotValid('Номер телефона необходимо указывать с ' +
      'кодом страны в формате «+7» или «8» и кодом города, без «()», «-», ' +
      'пробелов, и без знаков препинания в конце, иначе модерация будет не пройдена.' +
      ' Например, +74951231212 или 88007000628'),
    composeLinkValidator('Сообщение может содержать только одну ссылку'),
  ],
};

export const RULES_TYPE_TEMPLATE_GROUP = {
  [FIELDS_TEMPLATE_GROUP_FORM.NAME]: [
    composeRequiredValidator('Обязательное поле'),
  ],
};
