import React, { FC, useState } from 'react';
import { SelectionInputStateless } from 'redesignSrc/UI/form/SelectionInput';
import { OutsideClickTracker } from 'components/common';
import { ItemList } from './components';
import { SelectItemProps } from './SelectItemProps';
import { CreateTemplateGroupModal } from './components/ItemList/components';


export const SelectItem: FC<SelectItemProps> = (props) => {
  const { error, onChange, labelText, onSelectedItem, createNewText, id, value, isActionBtn } = props;

  const [isShowList, setIsShowList] = useState(false);
  return (
    <OutsideClickTracker onOutsideClick={setIsShowList}>
      <SelectionInputStateless
        labelText={labelText || ''}
        active={isShowList}
        setActive={setIsShowList}
        error={error}
        value={value}
        id={id}
    >
        <ItemList
          onChange={onChange}
          createNewText={createNewText || ''}
          onSelectedItem={onSelectedItem}
          setIsShowList={setIsShowList}
          CreateNewItemModal={CreateTemplateGroupModal}
          isActionBtn={isActionBtn}
          selectedName={value}
        />
      </SelectionInputStateless>
    </OutsideClickTracker>
  );
};
