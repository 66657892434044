import { action, observable, computed, runInAction, reaction } from 'mobx';
import {
  axiosAuthorizedRequest,
  composeAxiosDeleteRequest,
  composeAxiosPostRequest,
  composeAxiosPatchRequest,
  composeAxiosMutationFileDataRequest } from 'requests/helpers';
import { formatPureFileName, formatPureFileExtension } from 'utils/formatting';
import { extractError } from 'utils/errors';

import NewCampaign from './NewCampaign';


const ACTION_TYPE = {
  add: 0,
  remove: 1,
};


class MyAudience {
  @observable popupStateAddBase = {
    isOpen: false,
  };
  @observable popupStateEditBase = {
    isOpen: false,
    id: null,
  };
  @observable audience = null
  @observable count = null
  @observable nextCount = null
  @observable currentAudience = null
  @observable nextPageLink = null;
  @observable tempAddFileBase = []
  @observable tempFileBaseId = []
  @observable optInput = []

  @observable createBaseError = '';


  @observable isLoadingClientBase = false
  @observable isLoadingFileBase = false
  @observable isAttachUnpin = false
  @observable isBeelineAudience = false
  @observable isLoading = false


  @action requestBasesAudience = async (idCampaign) => {
    let baseAudiences = null;
    try {
      if (idCampaign) {
        baseAudiences = await axiosAuthorizedRequest({ url: `/api/v1/client/audiences/?campaign=${idCampaign}` });
      } else {
        baseAudiences = await axiosAuthorizedRequest({ url: '/api/v1/client/audiences/' });
      }
      runInAction(() => {
        if (idCampaign) {
          this.currentAudience = baseAudiences;
        } else {
          this.audience = baseAudiences?.results;
          this.count = baseAudiences?.count;
          if (baseAudiences?.next) {
            const index = baseAudiences?.next?.indexOf('/api');
            this.nextPageLink = baseAudiences?.next?.slice(index, baseAudiences?.length);
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action requestBasesAudienceNext = async () => {
    this.isLoadingClientBase = true;
    try {
      const baseAudiences = await axiosAuthorizedRequest({ url: this.nextPageLink });
      runInAction(() => {
        this.audience = this.audience?.concat(baseAudiences?.results);
        this.count = baseAudiences?.count;
        if (baseAudiences?.next) {
          const index = baseAudiences?.next?.indexOf('/api');
          this.nextPageLink = baseAudiences?.next?.slice(index, baseAudiences?.next?.length);
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingClientBase = false;
    }
  }

  @action setTempFileBase = async (file) => {
    this.isLoadingFileBase = true;
    try {
      const requestFile = await composeAxiosMutationFileDataRequest({ url: '/api/v1/client/audience_file/', method: 'POST' })({
        file,
      });
      runInAction(() => {
        this.tempAddFileBase.push(requestFile);
        this.tempFileBaseId.push(requestFile.id);
      });
      return null;
    } catch (error) {
      if (error?.response?.status === 400) {
        if (error?.response?.data?.[0] === 'Beeline audience is 0' || error?.response?.data?.file?.[0] === 'Загруженный файл пуст.') {
          this.isBeelineAudience = true;
          this.createBaseError = error?.response?.data?.[0] || error?.response?.data?.file?.[0];
          return error;
        }
        if (error?.response?.data?.file?.[0] === 'Неверный тип файла. Файл должен быть *.csv, *.txt, *.xlsx.') {
          NewCampaign.isNotValidFormatFileOfMyAudience = true;
          this.createBaseError = error?.response?.data?.file?.[0];
          return error;
        }
        NewCampaign.bigDataError = true;
        console.error(error);
        return error;
      } else if (error?.response?.status === 406) {
        NewCampaign.isShowMaxAudienceOfMyAudience = true;
        this.resetTempFile();
        return error;
      }
      console.error(error);

      this.createBaseError = extractError(error)?.fullText;
      return error;
    } finally {
      this.isLoadingFileBase = false;
    }
  }

  @action deleteTempFileBase = (id) => {
    this.tempAddFileBase = this.tempAddFileBase.filter(file => file.id !== id);
    this.tempFileBaseId = this.tempFileBaseId.filter(idFile => idFile !== id);
    try {
      composeAxiosDeleteRequest({ url: `/api/v1/client/audience_file/${id}/` })();
    } catch (e) {
      console.error(e);
    }
  }

  @action deleteTempFileBaseAll = () => {
    try {
      this.tempAddFileBase.forEach((item) => {
        composeAxiosDeleteRequest({ url: `/api/v1/client/audience_file/${item.id}/` })();
      });
    } catch (e) {
      console.error(e);
    }
    this.resetTempFile();
  }

  @action deleteClientBase = async (id) => {
    this.isLoadingClientBase = true;
    try {
      await composeAxiosDeleteRequest({ url: `/api/v1/client/audiences/${id}/` })();
      await this.requestBasesAudience();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingClientBase = false;
    }
  }

  @action editClientBase = async (name, id) => {
    this.popupStateEditBase.isOpen = false;
    this.isLoadingClientBase = true;
    try {
      await composeAxiosPatchRequest({
        url: `/api/v1/client/audiences/${id}/`,
        data: {
          name,
        },
      });
      await this.requestBasesAudience();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingClientBase = false;
    }
  }

  @action resetTempFile = () => {
    this.tempFileBaseId = [];
    this.tempAddFileBase = [];
  }

  @action createClientBase = async (nameBase) => {
    this.popupStateAddBase.isOpen = false;
    this.isLoadingClientBase = true;
    try {
      const base = await composeAxiosPostRequest({
        url: ' /api/v1/client/audiences/',
        data: {
          name: nameBase,
          files: this.tempFileBaseId,
          type_audience: 0,
        },
      });
      window.ym(87718585, 'reachGoal', 'new_audiences_success');
      // const dataLayer = window.dataLayer || [];
      // const files = this.tempAddFileBase;
      // dataLayer.push({
      //   event: 'event_b2b_beePro',
      //   eventCategory: 'Interaction',
      //   eventAction: 'click',
      //   eventLabel: 'buttonPopUp',
      //   eventContent: 'Сохранить',
      //   eventOther: files.map(({ file }) => formatPureFileExtension(file)),
      //   blockName: null,
      // });
      await this.requestBasesAudience();
      this.resetTempFile();
      return base;
    } catch (error) {
      if (error?.response?.status === 406) {
        NewCampaign.isShowMaxAudienceOfMyAudience = true;
        this.resetTempFile();
        return error;
      }
      console.error(error);
      return error;
    } finally {
      this.isLoadingClientBase = false;
    }
  }

  @action attachMyAudience = async (idCampaign, idBase, type) => {
    this.isAttachUnpin = true;
    try {
      await composeAxiosPostRequest({
        url: `/api/v1/client/campaigns/${idCampaign}/add_my_audience/`,
        data: {
          audience: idBase,
          type_audience: ACTION_TYPE[type],
        },
      });
      await this.requestBasesAudience(idCampaign);
    } catch (error) {
      if (error?.response?.status === 400 && error?.response?.data?.non_field_errors[0]) {
        if (error?.response?.data?.non_field_errors[0] === 'Incorrect audience count') {
          NewCampaign.isShowMaxAudienceOfMyAudience = true;
        }
      } else console.error(error);
    } finally {
      this.isAttachUnpin = false;
    }
  }

  @action unpinMyAudience = async (idCampaign, idBase) => {
    this.isAttachUnpin = true;
    try {
      await composeAxiosDeleteRequest({ url: `/api/v1/client/campaigns/${idCampaign}/remove_my_audience/${idBase}/` })();
    } catch (e) {
      console.error(e);
    } finally {
      this.isAttachUnpin = false;
    }
  }

  @action setMyAudienceCounter = async (idCampaign, type) => {
    try {
      await composeAxiosPostRequest({
        url: `/api/v1/client/campaigns/${idCampaign}/my_audience_counter/`,
        data: {
          type_audience: ACTION_TYPE[type],
        },

      });
    } catch (error) {
      console.error(error);
    }
  }


  @action setPopupStateAddBase = (isOpen) => {
    window.ym(87718585, 'reachGoal', 'click_new_audiences');
    this.popupStateAddBase.isOpen = isOpen;
  };

  @action setPopupStateEditBase = (isOpen, id) => {
    this.popupStateEditBase.isOpen = isOpen;
    this.popupStateEditBase.id = id || null;
  };

  @computed get getClientBase() {
    const currentId = this.popupStateEditBase.id;
    return this.audience?.results.find(base => base.id === currentId);
  }

  @computed get getClientFilesTemp() {
    return this.tempAddFileBase
      .map(({ id, count, file }) => {
        return {
          id,
          count,
          type: formatPureFileExtension(file),
          name: formatPureFileName(file),
        };
      });
  }

  @computed get getCurrentClientBases() {
    return this.currentAudience ? this.currentAudience?.map(({ id, name, create_date, beeline_ctn_count }) => {
      // const count = files?.reduce((sum, file) => sum + file.count, 0);
      return {
        id,
        name,
        createDate: create_date,
        count: beeline_ctn_count,
      };
    }) : [];
  }

  @computed get getClientBases() {
    return this.audience ? this.audience?.map(({ id, name, create_date, type_audience, beeline_ctn_count }) => {
      // const count = files?.reduce((sum, file) => sum + file.count, 0);
      return {
        id,
        name,
        createDate: create_date,
        count: beeline_ctn_count,
        type_audience,
      };
    }) : [];
  }
}

const myAudience = new MyAudience();

reaction(
  () => myAudience.currentAudience,
  () => {
    myAudience.optInput = myAudience.currentAudience?.map(({ id, name }) => ({ value: id, label: name }));
  },
);

reaction(
  () => myAudience.audience,
  () => {
    if (myAudience.nextPageLink) {
      const nextCount = myAudience.count - myAudience.audience?.length;
      myAudience.nextCount = nextCount > 10 ? 10 : nextCount;
    }
  },
);

reaction(
  () => myAudience.isBeelineAudience,
  () => {
    // const dataLayer = window.dataLayer || [];
    // if (myAudience.isBeelineAudience) {
    //   dataLayer.push({
    //     event: 'event_b2b_beePro',
    //     eventCategory: 'Interaction',
    //     eventAction: 'view',
    //     eventLabel: 'popUp',
    //     eventContent: 'Ваша аудитория равна нулю',
    //     eventOther: 'myAudience',
    //     blockName: 'myAudience',
    //   });
    // } else {
    //   dataLayer.push({
    //     event: 'event_b2b_beePro',
    //     eventCategory: 'Interaction',
    //     eventAction: 'click',
    //     eventLabel: 'buttonPopUp',
    //     eventContent: 'Изменить настройки',
    //     eventOther: 'myAudience',
    //     blockName: 'myAudience',
    //   });
    // }
  },
);

export default myAudience;
