import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';
import UserInfo from 'store/mobx/UserInfo';
import MyFinances from 'store/mobx/MyFinances';
import Templates from 'store/mobx/Templates';
import styles from 'pages/MyFinances/MyBills/styles.pcss';
import { PrimaryButton } from 'redesignSrc/UI/buttons';

import { PopupStateless } from '../../common';
import { FinalForm } from '../../forms';
import { FFTextInput } from '../../fields';
import { composeEmailValidator, composeRequiredValidator } from '../../../utils/fieldValidators';
import confirmDialog from '../confirmDialog';

const EMAIL = 'email';
const getValidators = () => ({
  [EMAIL]: [
    composeRequiredValidator('Обязательное поле'),
    composeEmailValidator('Email должен быть заполнен в формате XXXX@XXX.XX'),
  ],
});


const BillDebt = observer(({ giveAnswer }) => {
  return (
    <PopupStateless
      opened
      onClose={() => giveAnswer('close')}
      className={styles.content}
    >
      <div>
        <div className={styles.titleModal}>
          {MyFinances.sendingEmail &&
            <>
              <Skeleton width={300} /><br/>
              <Skeleton width={250}/>
            </> ||
            <>
              {UserInfo.data.first_name}, ваш счет №{UserInfo.data?.company?.payments_warnings[0]?.invoice_number}
              <br /> необходимо оплатить до {UserInfo.data?.company?.payments_warnings[0]?.due_date}
            </>
          }

        </div>
        <div className={styles.descriptionModal}>
          {
            MyFinances.sendingEmail &&
              <>
                <Skeleton width={150}/><br/>
                <Skeleton width={100}/>
              </> ||
              <>
                Для оплаты отправьте счет себе
                <br /> на почту {UserInfo.data.email}
              </>
          }
        </div>
        <div className={styles.btnsHolder}>
          {
            MyFinances.sendingEmail &&
              <>
                <Skeleton width={150} height={39} />
                <Skeleton width={150} className={styles.editBtn} />
              </> ||
              <>
                <PrimaryButton
                  onClick={() => MyFinances.sendInvoicesToEmail({ invoices: [UserInfo.data?.company?.payments_warnings[0]?.id] })}
                >
                  Отправить
                </PrimaryButton>
                <PrimaryButton
                  className={styles.editBtn}
                  hasRightArrow
                  isGray
                  onClick={() => giveAnswer('changeEmail')}
                >
                  Изменить почту
                </PrimaryButton>
              </>
          }
        </div>
      </div>
    </PopupStateless>
  );
});

const PrepareToSend = observer(({ giveAnswer }) => {
  return (
    <PopupStateless
      opened
      className={styles.content}
      onClose={() => giveAnswer('close')}
    >
      <div>
        <div className={styles.titleModal}>
          {MyFinances.sendingEmail &&
            <>
              <Skeleton width={300} /><br/>
              <Skeleton width={200} />
            </> ||
            <>
              {UserInfo.data.first_name}, ваши документы будут направлены
              <br /> на почту {UserInfo.data.email}
            </>
          }
        </div>
        <div className={styles.btnsHolder}>

          {
            MyFinances.sendingEmail &&
            <Skeleton height={39} width={100}/> ||
            <PrimaryButton
              onClick={() => MyFinances.sendInvoicesToEmail({})}
            >
              Отправить
            </PrimaryButton>
          }

          {
            MyFinances.sendingEmail &&
            <Skeleton className={styles.editBtn} width={150}/> ||
            <PrimaryButton
              className={styles.editBtn}
              isGray
              onClick={() => {
                giveAnswer('changeEmail');
              }}
            >
              Изменить почту
            </PrimaryButton>
          }

        </div>
      </div>
    </PopupStateless>
  );
});

const LockedService = observer(({ giveAnswer, title, description }) => {
  const { getPopupsTemplate } = Templates;
  return (
    <PopupStateless
      opened
      className={styles.contentDebt}
      onClose={() => giveAnswer('close')}
    >
      <div className={styles.popupHolder}>
        {description && <div className={styles[description]}/> || null}

        <div className={styles.titleModalDebt}>
          {MyFinances.sendingEmail &&
            <>
              <Skeleton width={300} />
            </> ||
            <>
              {title && getPopupsTemplate(description).title.replace('{name}', UserInfo.data.first_name) ||
                `${UserInfo.data.first_name}, у вас есть задолженность`
              }
            </>
          }
        </div>
        <p className={styles.descriptionModal}>
          {MyFinances.sendingEmail &&
            <>
              <Skeleton width={250} /><br/>
              <Skeleton width={240} /><br/>
              <Skeleton width={230} />
            </> ||
              <>
                {description && getPopupsTemplate(description).message.replace('{email}', UserInfo.data.email) ||
                  <>
                    Ваша заявка сохранена в разделе Мои кампании<br />
                    Вы не сможете запустить рекламную кампанию, пока не погасите задолженность.<br />

                    Отправьте неоплаченный счет на почту и после оплаты повторите отправку заявки. Отправить счет на почту {UserInfo.data.email}?
                  </>
                }
              </>
          }
        </p>
        <div className={styles.btnsHolder}>
          {MyFinances.sendingEmail &&
            <Skeleton width={100} height={39}/> ||
            <PrimaryButton
              onClick={() => MyFinances.sendInvoicesToEmail({}) }
            >
              Отправить
            </PrimaryButton>
          }

          {MyFinances.sendingEmail &&
            <Skeleton className={styles.editBtn} width={150}/> ||
            <PrimaryButton
              className={styles.editBtn}
              hasRightArrow
              isGray
              onClick={() => {
                giveAnswer('changeEmail');
              }}
            >
              Изменить почту
            </PrimaryButton>
          }

        </div>
      </div>
    </PopupStateless>
  );
});

const ChangeEmail = observer(({ giveAnswer }) => {
  return (
    <PopupStateless
      opened
      className={styles.content}
      onClose={() => {
        MyFinances.email = '';
        giveAnswer('close');
      }}
    >
      <div>
        <div className={styles.titleModal}>
          {MyFinances.sendingEmail &&
            <>
              <Skeleton width={300} /><br/>
              <Skeleton width={200} />
            </> ||
            <>
              Введите, пожалуйста, новый
              <br /> электронный адрес
            </>
          }
        </div>

        <FinalForm
          onSubmit={async () => {
            const userInvoices = UserInfo.data?.company?.payments_warnings[0]?.invoice_number;
            try {
              await MyFinances.sendInvoicesToEmail({
                  invoices: (MyFinances.selectedInvoicesIds.length && MyFinances.selectedInvoicesIds) || (userInvoices && userInvoices) || (undefined),
                });
            } catch (e) {
              console.error(e);
            } finally {
              giveAnswer('close');
              await confirmDialog(SendingStatus);
            }
          }}
          values={MyFinances}
          onChange={(data) => {
            MyFinances.email = data.email;
          }}
          getValidators={getValidators}
        >{
          MyFinances.sendingEmail &&
          <>
            <Skeleton width={243} height={50} /><br/>
            <Skeleton height={50} width={100} />
          </> ||
          <>
            <FFTextInput
              name={EMAIL}
              className={styles.email}
            />
            <div className={styles.btnHolder}>
              <PrimaryButton type="submit">
                Отправить
              </PrimaryButton>
            </div>
          </>
        }
        </FinalForm>
      </div>
    </PopupStateless>
  );
});

const SendingStatus = observer(({ giveAnswer }) => {
  return (
    <PopupStateless
      opened
      className={styles.content}
      onClose={() => {
        MyFinances.errorOnSendingInvoices = false;
        giveAnswer('close');
      }}
    >
      <div>
        <div className={styles.titleModal}>
          {MyFinances.sendingEmail &&
            <>
              <Skeleton width={500} height={50} /><br/>
              <Skeleton width={450} height={50} />
            </> ||
            <>
              {
                MyFinances.errorOnSendingInvoices && ('Что-то пошло не так...') ||
                  <>
                    Готово! Ваши документы были отправлены<br/>
                    на почту {MyFinances.email ? MyFinances.email : UserInfo.data.email}
                  </>
              }
            </>
          }
        </div>

        <div className={styles.btnHolder}>
          {MyFinances.sendingEmail &&
            <Skeleton width={200} height={40}/> ||
            <PrimaryButton
              className={styles.sendBtn}
              onClick={() => {
                giveAnswer('close');
                MyFinances.errorOnSendingInvoices = false;
                navigate('/my-campaigns/');
              }}
            >
              Перейти в Мои кампании
            </PrimaryButton>
          }
        </div>
      </div>
    </PopupStateless>
  );
});


BillDebt.propTypes = {
  giveAnswer: PropTypes.func,
};

PrepareToSend.propTypes = {
  giveAnswer: PropTypes.func,
};

ChangeEmail.propTypes = {
  giveAnswer: PropTypes.func,
};

SendingStatus.propTypes = {
  giveAnswer: PropTypes.func,
};

export {
  BillDebt,
  PrepareToSend,
  ChangeEmail,
  SendingStatus,
  LockedService,
};
