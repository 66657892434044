import { useEffect } from 'react';
import { toJS } from 'mobx';
import { globalHistory, useNavigate } from '@reach/router';
import Taxons from 'store/mobx/Taxons';
import NewCampaign from 'store/mobx/NewCampaign';

const useBlockingNavigateFromAudience = (handlerAfterClose) => {
  const navigate = useNavigate();

  const originalNavigate = globalHistory.navigate;

  // @ts-ignore
  globalHistory.navigate = (...props) => {
    Taxons.currentNavigateProps = props;

    // @ts-ignore
    if (location.pathname.indexOf('/audience') !== -1 &&
    location.pathname.indexOf('/new-campaign') !== -1 &&
    !Taxons.accessPassFromAudience &&
    toJS(Taxons.currentNavigateProps)['0'].indexOf(`/channels/${NewCampaign.currentCampaign.channel_uid}`) === -1 &&
    NewCampaign.currentCampaign?.orders[0].is_empty) {
      Taxons.isShowBackModal = true;
      return;
    }
    Taxons.isShowBackModal = false;
    // @ts-ignore
    originalNavigate(...props);
  };

  useEffect(() => {
    const loadNavigate = async () => {
      if (Taxons.accessPassFromAudience) {
        Taxons.isShowBackModal = false;

        if (handlerAfterClose && Taxons.currentNavigateProps['0'] === '/') handlerAfterClose();
        else {
          await navigate(toJS(Taxons.currentNavigateProps)['0']);

          window.scrollTo(0, 0);
        }

        Taxons.accessPassFromAudience = false;
      }
    };

    loadNavigate();
  }, [Taxons.accessPassFromAudience]);

  return globalHistory;
};

export default useBlockingNavigateFromAudience;
