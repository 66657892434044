import React, { useState } from 'react';
import { PopupStateless } from 'components/common';
import LinkOpenInfo from '../../../../../../../UI/elements/LinkOpenInfo';
import FloatingPanel from '../../../../../../../components/FloatingPanel';
import {
  CollapsingBlockWiki,
  MainTitleWiki,
  NoticeText,
  StrongText,
  TextInCollapsing,
  TextPermission,
  WhiteBlockWiki,
} from '../../../../FloatingContent/componentWiki';
import danglingPrepositions from '../../../../../../../../utils/danglingPrepositions';
import popup from '../assets/popup.png';
import styles from './styles.pcss';

const WidgetsWikiLink = ({ title, className }: { title: string, className?: string }) => {
  const [visible, setVisible] = useState(false);
  const [opened, setOpen] = useState(false);
  const getContent = () => {
    if (title === 'Как работать с аудиториями из завершенных кампаний?') {
      return (
        <>
          <MainTitleWiki>
            Как работать с аудиторией из завершенных рекламных кампаний?
          </MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <ul>
              <li>
                Выбирайте данный параметр, если, например, хотите исключить из аудитории для рекламной кампании тех
                абонентов билайн, которым вы уже показывали/отправляли рекламное сообщение в предыдущей рассылке
              </li>
              <li>
                Аудитории из завершенных рекламных кампаний можно использовать для фильтрации, расширения или исключения
                из другой аудитории
              </li>
              <li>
                Аудитории из завершенных рекламных кампаний можно использовать для расчета доходимости от рекламной
                рассылки
              </li>
            </ul>
            <WhiteBlockWiki
              title="Как рассчитать доходимость?"
              content={danglingPrepositions('Для этого в конструкторе аудитории выберите аудиторию из завершенной рекламной кампании, нажмите фильтровать, далее загрузите клиентскую базу, которая совершала покупки за период проведения рекламной кампании или после нее. В результате пересечения вы узнаете сколько покупателей принесла рекламная кампания')}
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечания"
            description="Отрасли определяются, исходя из интересов пользователей. Интернет ресурсы сегментируются и группируются по категориям в зависимости от тематики. На сервере BigData обрабатываются лишь категории сайтов, что с определенной долей вероятности может свидетельствовать об интересе пользователя к определенной отрасли"
          />
        </>

      );
    }
    if (title === 'Как работать с клиентской базой?') {
      return (
        <>
          <MainTitleWiki>
            Как работать с клиентской базой?
          </MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <p>Порядок действий:</p>
            <ul>
              <li>Загрузите файл с базой в формате CSV, excel, txt, вес файла не более 5 мб</li>
              <li>Мы отправим рассылку только тем абонентам билайн, которые дали согласие на получение рекламной
                рассылки на стороне билайн (актуально только для SMS/Push рассылок)
              </li>
            </ul>
            <p>Вы можете загрузить собственную базу клиентов в следующих вариантах:</p>
            <ol>
              <li>Телефонные номера. Мы найдем абонентов билайн в вашей базе и покажем их количество. В загруженной базе
                должно быть не менее 100 абонентов билайн
              </li>
              {/* <li>Почты. Мы проведем сопоставление электронных почт с абонентами билайн, используя сервисы бигдаты, */}
              {/*   найдем абонентов и покажем их количество. В загруженной базе должно быть не менее 100 абонентов билайн. */}
              {/*   Пример почты для загрузки: name@domain.ru */}
              {/* </li> */}
              <li>IDFA/GAID (мобильные рекламные идентификаторы). IDFA/GAID — это средство, с помощью которого компании
                определяют
                атрибуцию загрузок приложений по мобильной рекламе. Мы проведем сопоставление идентификаторов с
                абонентами билайн, используя сервисы бигдаты, найдем абонентов и покажем их количество. В загруженной
                базе должно быть не менее 100 абонентов билайн. Пример идентификатора для загрузки:
                AB1234CD-E123-12FG-J123
              </li>
              <li>Мас-адреса. MAC-адрес (аппаратный или физический адрес) - уникальный идентификатор
                устройства, которое имеет выход в сеть. Мы проведем сопоставление адресов с абонентами билайн, используя
                сервисы бигдаты, найдем абонентов и покажем их количество. В загруженной базе должно быть не менее 100
                абонентов билайн. Пример идентификатора для загрузки: 1ab234c5678
              </li>
            </ol>
            <WhiteBlockWiki
              title="Примеры использования базы клиентов:"
              note={
                <>
                  <p>билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов и
                    SMS-сообщений пользователей.</p>
                  <p>Предоставленная заказчиками услуги информация используется исключительно для составления портрета
                    целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных клиентов
                    для заказчиков среди пользователей</p>
                </>
              }
              content={
                <ul>
                  <li>Вы можете применить дополнительные фильтры к вашей базе по соцдему, интересам, геолокации.
                    Например, из вашей базы вам нужны только мужчины от 18 до 25 лет с интересом к ипотеке из Краснодара
                  </li>
                  <li>Вы можете уточнить какими сайтами могли интересоваться ваши клиенты. Например, клиенты из вашей
                    базы могли интересоваться определенными интернет-магазинами за прошлую неделю
                  </li>
                  <li>Вы можете исключать вашу базу из собранной аудитории для рекламной кампании. Например, вам нужны
                    владельцы собак из Самары с доходом от 50 000 рублей, но при этом вы хотите исключить из данной
                    аудитории ваших текущих клиентов
                  </li>
                  <li>Вы можете получить бесплатный портрет аудитории, загрузив вашу базу клиентов</li>
                </ul>
              }
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечания"
            description="Оказание услуг осуществляется при наличии согласия пользователя на обработку данных об оказанных услугах связи и получение рекламы. Обработка сведений осуществляется предиктивной системой билайн с использованием математических моделей. Значения всех параметров являются вероятностными. Обработка персональных данных в рамках услуг не предусмотрена"
          />
        </>
      );
    }
    if (title === 'Как работать с интересами?') {
      return (
        <>
          <MainTitleWiki>
            Как работать с интересами?
          </MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <ul>
              <li>При выборе данного параметра, вы можете выбрать из предложенного списка интересов те, которые могут
                быть у ваших потенциальных клиентов. Например, интерес к аренде авто
              </li>
              <li>Интересы можно комбинировать с другими параметрами: соцдемом, геолокацией и прочими</li>
              <li>Для интересов нужно обязательно выбрать период для сбора аудитории (3 месяца, 2 месяца, 1 месяц, 14
                дней, 7 дней)
              </li>
            </ul>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны все, кто интересовался арендой автомобиля в Екатеринбурге в прошлом месяце"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Интернет ресурсы сегментируются и группируются по категориям в зависимости от тематики. На сервере BigData обрабатываются лишь категории сайтов, что с определенной долей вероятности может свидетельствовать об интересе пользователя к чему-либо"
          />
        </>
      );
    }
    if (title === 'Как работать с сайтами?') {
      return (
        <>
          <MainTitleWiki>
            Как работать с интересующими сайтами?
          </MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас пользователей, которые могли
              интересоваться определенными сайтами за конкретный период</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны пользователи, которые интересовались туристическими сайтами за последние 2 недели"
            />
            <TextInCollapsing>Сайты можно комбинировать с другими параметрами: соцдемом, геолокацией и
              прочими</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны не просто те, кто интересовался туристическими сайтами за последние 2 недели, но и те, у кого есть интерес к туризму"
            />
            <TextInCollapsing>Для сайтов нужно обязательно выбрать период для сбора аудитории (3 месяца, 2 месяца, 1
              месяц, 14 дней, 7 дней)</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content={
                <ul>
                  <li>Формат файлов: CSV, excel, txt, вес файлов — не более 5 мб</li>
                  <li>Используйте пример для загрузки</li>
                  <li>Сайты можно ввести/загрузить в любом формате (от 5 сайтов)</li>
                </ul>
              }
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов и SMS-сообщений пользователей. Предоставленная заказчиками услуги информация используется исключительно для составления портрета целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных клиентов для заказчиков среди пользователей"
          />
        </>
      );
    }

    if (title === 'Как работать со звонками?') {
      return (
        <>
          <MainTitleWiki>
            Как работать со звонками?
          </MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>
              При выборе данного параметра, мы найдем для вас тех пользователей, которые могли звонить на определенные
              номера, или им могли звонить с определенных номеров за конкретный период
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны пользователи, которые вероятно звонили местным застройщикам за последние три дня"
            />
            <TextInCollapsing>
              Можно выбрать исходящие, входящие звонки или любые звонки
            </TextInCollapsing>
            <TextInCollapsing>
              Звонки можно комбинировать с другими параметрами: соцдемом, интересами, геолокацией и прочими
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны не просто те, которые могли звонить на определенные номера, или им могли звонить с определенных номеров, а только пользователи из Санкт-Петербурга"
            />
            <TextInCollapsing>
              Для звонков нужно обязательно выбрать период для сбора аудитории (3 месяца, 2 месяца, 1 месяц, 14 дней, 7
              дней)
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Правила оформления"
              content={
                <ul>
                  <li>Номера указываются без префикса для российских и иностранных номеров (т.е. без 8, +7, 7, +38 и
                    тп.) и только цифры
                  </li>
                  <li>Формат файлов: CSV, excel, txt, вес файлов - не более 5 мб</li>
                  <li>Используйте пример для загрузки</li>
                  <li>Можно загрузить от 5 номеров</li>
                </ul>
              }
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов и SMS-сообщений пользователей. Предоставленная заказчиками услуги информация используется исключительно для составления портрета целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных клиентов для заказчиков среди пользователей"
          />
        </>
      );
    }

    if (title === 'Как работать с именами отправителей?') {
      return (
        <>
          <MainTitleWiki>
            Как работать с именами отправителей?
          </MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>
              При выборе данного параметра, мы найдем для вас пользователей, которые могли получать SMS от интересующих
              вас отправителей SMS-сообщений за конкретный период
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вас интересуют пользователи, которые могли получать SMS от местного сетевого гипермаркета на прошлой неделе"
            />
            <TextInCollapsing>
              Имена отправителей можно комбинировать с другими параметрами: соцдемом, интересами, геолокацией и прочими
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны не просто те, кто мог получать SMS от местного сетевого гипермаркета, а те, у кого доход от 100 000 ₽"
            />
            <TextInCollapsing>
              Для имен отправителей нужно обязательно выбрать период для сбора аудитории (3 месяца, 2 месяца, 1 месяц,
              14 дней, 7 дней)
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Требования к названиям имен отправителей"
              content={
                <ul>
                  <li>Только латиница и цифры</li>
                  <li>Номера могут быть длиной 3, 4 и 6 символов</li>
                  <li>Минимальное количество символов — 3</li>
                  <li>Нужно ввести не менее 5 имен отправителей</li>
                </ul>
              }
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов и SMS-сообщений пользователей. Предоставленная заказчиками услуги информация используется исключительно для составления портрета целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных клиентов для заказчиков среди пользователей"
          />
        </>
      );
    }

    if (title === 'Как работать с геолокацией?') {
      return (
        <>
          <MainTitleWiki>
            Как работать с геолокацией?
          </MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>
              При выборе данного параметра, мы найдем для вас пользователей, которые находятся в нужной вам геолокации
            </TextInCollapsing>
            <TextInCollapsing>
              Геолокация доступна по городам/регионам, адресам и координатам
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Примеры"
              content={
                <ul>
                  <li>Кофейня в Новосибирске хочет отправить SMS сообщение об акции «3-ий кофе в подарок»</li>
                  <li>Спа-салону нужны пользователи, которые находились в пределах определенных координат</li>
                </ul>
              }
            />
            <TextInCollapsing>
              Минимальный радиус: 500 — 50 000 м
            </TextInCollapsing>
            <TextInCollapsing>
              Геолокацию можно комбинировать с другими параметрами: соцдемом, интересами и прочими
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Крупный ритейлер хочет отправить сообщения не просто жителям улицы в определенном радиусе, а только «женщинам с детьми»"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Для анализа используется предсказательная математическая модель, основанная на информации по динамике перемещения групп пользователей и совокупности заданных параметров таргетинга. Анализ нагрузки на базовые станции. Сведения о местонахождении пользователей не передаются и анализ осуществляется при условии наличия согласия на обработку данных и получение рекламы"
          />
        </>
      );
    }

    if (title === 'Как работать с месяцем рождения?') {
      return (
        <>
          <MainTitleWiki>
            Как работать с месяцем рождения?
          </MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>
              При выборе данного параметра, мы найдем для вас пользователей, у которых день рождения в определенном
              месяце
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Примеры"
              content={
                <ul>
                  <li>Предложите клиенту праздничную скидку или подарок</li>
                  <li>Используйте период до или после дня рождения</li>
                </ul>
              }
            />
            <TextInCollapsing>
              День рождения можно комбинировать с другими параметрами: соцдемом, интересами, геолокаций и прочими
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Именинникам из Архангельска скидка 20% на все меню ресторана в марте"/>
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Оказание услуг осуществляется при наличии согласия пользователя на обработку данных и получение рекламы. Обработка персональных данных в рамках услуг не осуществляется. Обработка сведений об оказанных услугах связи осуществляется предиктивной системой билайн с использованием математических моделей. Значения всех параметров являются вероятностными"
          />
        </>
      );
    }

    if (title === 'Как работать с типом занятости?') {
      return (
        <>
          <MainTitleWiki>Как работать с типом занятости?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>
              При выборе данного параметра, мы найдем для вас пользователей с определенным типом занятости: домохозяйки,
              студенты, пенсионеры или работающие
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Магазин бытовой химии хочет сделать рассылку на местных домохозяек"/>
            <TextInCollapsing>
              Тип занятости можно комбинировать с другими параметрами: соцдемом, интересами, геолокаций и прочими
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Магазин бытовой химии хочет сделать рассылку не только на местных домохозяек, но и на всех тех, кто делает покупки в интернете"/>
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Оказание услуг осуществляется при наличии согласия пользователя на обработку данных и получение рекламы. Для анализа используется предсказательная математическая модель, основанная на совокупности заданных параметров таргетинга и предполагаемых интересах пользователей"
          />
        </>
      );
    }

    if (title === 'Как работать с возрастом детей?') {
      return (
        <>
          <MainTitleWiki>Как работать с возрастом детей?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас пользователей c детьми определенных
              возрастных групп: подростки, школьники, дошкольники, малыши</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Развивающий центр из Сочи хочет сделать рассылку на мам малышей"/>
            <TextInCollapsing>Возраст детей можно комбинировать с другими параметрами: соцдемом, интересами, геолокаций
              и прочими</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Развивающий центр из Сочи хочет сделать рассылку только на мам малышей из Геленджика"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Оказание услуг осуществляется при наличии согласия пользователя на обработку данных и получение рекламы. Для анализа используется предсказательная математическая модель, основанная на совокупности заданных параметров таргетинга и предполагаемых интересах пользователей"
          />
        </>
      );
    }

    if (title === 'Как работать с банковскими продуктами?') {
      return (
        <>
          <MainTitleWiki>Как работать с банковскими продуктами?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>Выбирайте данный параметр, если для вас важно наличие или отсутствие у пользователя
              кредита, кредитной или дебетовой карты</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Банк может предложить выгодный вклад или кредит для держателей дебетовой карты"/>
            <TextInCollapsing>Банковские продукты можно комбинировать с другими параметрами: соцдемом, интересами,
              геолокаций и прочими</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Банк может предложить вклад или кредит не всем, а только тем, у кого определенный доход"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Интернет ресурсы сегментируются и группируются по категориям в зависимости от тематики. На сервере BigData обрабатываются лишь категории сайтов, что с определенной долей вероятности может свидетельствовать об владении пользователя чем-то"
          />
        </>
      );
    }

    if (title === 'Как работать с соцсетями?') {
      return (
        <>
          <MainTitleWiki>Как работать с соцсетями?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас пользователей, которые могут пользоваться
              определенными социальными сетями: Vkontakte, OK</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Организатору мероприятий нужны пользователи, которые могли пользоваться VK"/>
            <TextInCollapsing>Пользователей соцсетей можно комбинировать с другими параметрами: соцдемом, интересами,
              геолокаций и прочими</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Организатору мероприятий нужны не просто пользователи VK, а только мамы с детьми"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Интернет ресурсы сегментируются и группируются по категориям в зависимости от тематики. На сервере BigData обрабатываются лишь категории сайтов, что с определенной долей вероятности может свидетельствовать о склонности к использованию определенной социальной сети"
          />
        </>
      );
    }

    if (title === 'Как работать с пользователями мессенджеров?') {
      return (
        <>
          <MainTitleWiki>Как работать с пользователями мессенджеров?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас пользователей, которые могли пользоваться
              мессенджерами: viber, telegram, skype</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Организатору бизнес мероприятий нужны пользователи skype"/>
            <TextInCollapsing>Пользователей мессенджеров можно комбинировать с другими параметрами: соцдемом,
              интересами, геолокаций и прочими</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Организатору бизнес мероприятий нужны не просто пользователи skype, а мужчины 30-60 лет, владельцы бизнеса"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Интернет ресурсы сегментируются и группируются по категориям в зависимости от тематики. На сервере BigData обрабатываются лишь категории сайтов, что с определенной долей вероятности может свидетельствовать о склонности к использованию определенным приложением"
          />
        </>
      );
    }

    if (title === 'Как работать с пользователями сервисов?') {
      return (
        <>
          <MainTitleWiki>Как работать с пользователями сервисов?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас пользователей, которые могли пользоваться
              определенными сервисами: навигаторами, онлайн кинотеатрами, музыкальными сервисами, Apple store/Google
              play, Youtube/Rutube</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Продавцу IT-сервиса нужны пользователи Rutube "/>
            <TextInCollapsing>Пользователей сервисов можно комбинировать с другими параметрами: соцдемом, интересами,
              геолокаций и прочими</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Продавцу IT - сервиса нужны не просто пользователи Rutube, а мужчины 35-45 лет из Москвы"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Интернет ресурсы сегментируются и группируются по категориям в зависимости от тематики. На сервере BigData обрабатываются лишь категории сайтов, что с определенной долей вероятности может свидетельствовать о склонности к использованию определенным сервисом"
          />
        </>
      );
    }

    if (title === 'Как работать с параметром "Владельцы"?') {
      return (
        <>
          <MainTitleWiki>Как работать с параметром "Владельцы"?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас пользователей, которые владеют бизнесом,
              дачей, автомобилем или недвижимостью</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Продавец бизнес-услуг может выбрать владельцев собственного бизнеса для рассылки"/>
            <TextInCollapsing>Владельцев можно комбинировать с другими параметрами: соцдемом, интересами, геолокаций и
              прочими</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Продавец бизнес-услуг может выбрать не просто владельцев собственного бизнеса для рассылки, а только тех, у кого интерес к инвестициям"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Интернет ресурсы сегментируются и группируются по категориям в зависимости от тематики. На сервере BigData обрабатываются лишь категории сайтов, что с определенной долей вероятности может свидетельствовать об владении пользователя чем-то"
          />
        </>
      );
    }

    if (title === 'Как работать с типом устройств?') {
      return (
        <>
          <MainTitleWiki>Как работать с типом устройств?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас владельцев определенных типов устройств:
              планшет, смартфон, кнопочный телефон</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Продавец товаров через каталог, считает, что его целевая аудитория может пользоваться кнопочными телефонами"/>
            <TextInCollapsing>Тип устройств можно комбинировать с другими параметрами: соцдемом, интересами, геолокаций
              и прочими</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Продавцу товаров через каталог для SMS- рассылки нужны не просто те, кто мог пользоваться кнопочными телефонами, а только женщины 55-65 лет из Саранска"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Интернет ресурсы сегментируются и группируются по категориям в зависимости от тематики. На сервере BigData обрабатываются лишь категории сайтов, что с определенной долей вероятности может свидетельствовать об владении пользователя чем-то"
          />
        </>
      );
    }

    if (title === 'Как работать с операционными системами?') {
      return (
        <>
          <MainTitleWiki>Как работать с операционными системами?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас пользователей, которые пользуются
              определенными операционными системами:
              Android, IOS, Windows OS, другие ОS
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Производителю телефонов для рассылки интересны только владельцы смартфонов apple для рекламы новой модели смартфона"/>
            <TextInCollapsing>Операционные системы можно комбинировать с другими параметрами: соцдемом, интересами,
              геолокаций и прочими</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Производителю телефонов для рассылки интересны не просто владельцы смартфонов apple для рекламы новой модели смартфона, а только пользователи с определенным доходом"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Для анализа используется агрегированная информация об оказании услуг связи"
          />
        </>
      );
    }

    if (title === 'Как работать с ключевыми словами?') {
      return (
        <>
          <MainTitleWiki>Как работать с ключевыми словами?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>Выбирайте данный параметр, если вам нужны пользователи, которые могли интересоваться
              сайтами с определенным контентом, то есть на сайтах употребляются интересующие вас слова (ключевые
              слова)</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны пользователи, которые могли интересоваться сайтами, где употребляются такие слова как «Ипотека», «Купить квартиру», «Ипотечный кредит»"/>
            <TextInCollapsing>Ключевые слова можно комбинировать с другими параметрами: соцдемом, геолокацией и
              прочими</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны не просто те, кто мог интересоваться сайтами, где употребляются такие слова как «Ипотека», «Купить квартиру», «Ипотечный кредит», а только мужчины 35-55 лет из Москвы"
            />
            <TextInCollapsing>
              Требования к оформлению ключевых слов:
              <ul>
                <li>Ключевые слова можно ввести вручную или загрузить файлом, до 500 слов, вес файла не более 5 мб</li>
                <li>Для ключевых слов нужно обязательно выбрать период для сбора аудитории (3 месяца, 2 месяца, 1 месяц,
                  14 дней, 7 дней)
                </li>
              </ul>
            </TextInCollapsing>
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов и SMS-сообщений пользователей. Предоставленная заказчиками услуги информация используется исключительно для составления портрета целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных клиентов для заказчиков среди пользователей"
          />
        </>
      );
    }

    if (title === 'Как работать с сохраненными аудиториями?') {
      return (
        <>
          <MainTitleWiki>Как работать с сохраненными аудиториями?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>
              <ul>
                <li>Выбирайте данный параметр, если, например, хотите запустить рекламную кампанию на ранее сохраненную
                  аудиторию из раздела «Мои аудитории»
                </li>
                <li>Аудитории из сохранных можно использовать для фильтрации, расширения или исключения из аудитории
                </li>
              </ul>
            </TextInCollapsing>
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="Сферы бизнеса определяются, исходя из поведения пользователей. Интернет ресурсы, в которых проявляют активность пользователи, сегментируются и группируются по категориям в зависимости от тематики. На сервере BigData обрабатываются лишь категории сайтов, что с определенной долей вероятности может свидетельствовать об активности пользователя в определенной сфере бизнеса"
          />
        </>
      );
    }

    if (title === 'Как работать с сайтами в реальном времени?') {
      return (
        <>
          <MainTitleWiki>Как работать с сайтами в реальном времени?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас пользователей, которые могут
              интересоваться определенными сайтами прямо сейчас</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Для интернет-рекламы вам нужны пользователи, которые могут интересоваться туристическими сайтами прямо сейчас. Мы покажем баннер или отправим SMS-сообщение таким пользователям в течение 15 минут после проявления интереса"
            />
            <TextInCollapsing>
              Сайты в реальном времени можно пересечь (расширить) со следующими параметрами:
              <ul>
                <li>{danglingPrepositions('сайты')} <span className={styles.inRealTimeColor}>(в реальном времени)</span>
                </li>
                <li>{danglingPrepositions('звонки')} <span
                  className={styles.inRealTimeColor}>(в реальном времени)</span></li>
                <li>{danglingPrepositions('интересами')} <span
                  className={styles.inRealTimeColor}>(в реальном времени)</span></li>
                <li>{danglingPrepositions('имена отправителей')} <span className={styles.inRealTimeColor}>(в реальном времени)</span>
                </li>
                <li>{danglingPrepositions('ключевые слова')} <span className={styles.inRealTimeColor}>(в реальном времени)</span>
                </li>
                <li>{danglingPrepositions('события')} <span
                  className={styles.inRealTimeColor}>(в реальном времени)</span></li>
              </ul>
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Для интернет-рекламы вам нужны не только пользователи, которые могут интересоваться туристическими сайтами прямо сейчас, а также те, кто попал в событие «Переезд». Мы покажем баннер или отправим SMS-сообщение таким пользователям в течение 15 минут после проявления интереса или попадания в событие"
            />
            <TextInCollapsing>
              Вы можете применить дополнительные параметры фильтрации: соцдем, интересы и тп. или исключить вашу базу
              клиентов
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны не просто те, кто может интересоваться туристическими сайтами прямо сейчас, но только мужчины 45-55 лет"
            />
            <WhiteBlockWiki
              title="Правила оформления"
              content={
                <ul>
                  <li>Формат файлов: CSV, excel, txt, вес файлов — не более 5 мб</li>
                  <li>Используйте пример для загрузки</li>
                  <li>Сайты можно ввести/загрузить в любом формате (от 5 сайтов)</li>
                </ul>
              }
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов и SMS-сообщений пользователей. Предоставленная заказчиками услуги информация используется исключительно для составления портрета целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных клиентов для заказчиков среди пользователей"
          />
        </>
      );
    }

    if (title === 'Как работать со звонками в реальном времени?') {
      return (
        <>
          <MainTitleWiki>Как работать со звонками в реальном времени?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас тех пользователей, которые могут звонить
              на определенные номера, или им могут звонить с определенных номеров в данный момент
              времени</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам для SMS-рассылки нужны пользователи, которые могут звонить местным застройщикам прямо сейчас. Мы отправим SMS-сообщение таким пользователям в течение 15 минут после входящего/исходящего звонка"
            />
            <TextInCollapsing>
              Можно выбрать исходящие от абонента звонки, входящие абоненту звонки или любые звонки
              <ul>
                <li>{danglingPrepositions('сайты')} <span className={styles.inRealTimeColor}>(в реальном времени)</span>
                </li>
                <li>{danglingPrepositions('звонки')} <span
                  className={styles.inRealTimeColor}>(в реальном времени)</span></li>
                <li>{danglingPrepositions('интересами')} <span
                  className={styles.inRealTimeColor}>(в реальном времени)</span></li>
                <li>{danglingPrepositions('имена отправителей')} <span className={styles.inRealTimeColor}>(в реальном времени)</span>
                </li>
                <li>{danglingPrepositions('ключевые слова')} <span className={styles.inRealTimeColor}>(в реальном времени)</span>
                </li>
                <li>{danglingPrepositions('события')} <span
                  className={styles.inRealTimeColor}>(в реальном времени)</span></li>
              </ul>
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам для SMS-рассылки нужны не только пользователи, которые могут звонить местным застройщикам прямо сейчас, а еще и те, кто в данный момент интересуются покупкой недвижимости. Мы отправим SMS-сообщение таким пользователям в течение 15 минут после входящего/исходящего звонка или проявления интереса"
            />
            <TextInCollapsing>
              Вы можете применить дополнительные параметры фильтрации: соцдем, интересы и тп. или исключить вашу базу
              клиентов
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны не просто те, кто может звонить на определенные номера, или им могут звонить с определенных номеров, а только пользователи из Санкт- Петербурга"
            />
            <WhiteBlockWiki
              title="Правила оформления"
              content={
                <ul>
                  <li>Номера указываются без префикса для российских и иностранных номеров (т.е. без 8, +7, 7, +38 и
                    тп.) и только цифры
                  </li>
                  <li>Формат файлов: CSV, excel, txt, вес файлов - не более 5 мб</li>
                  <li>Используйте пример для загрузки</li>
                  <li>Можно загрузить от 5 номеров</li>
                </ul>
              }
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов и SMS-сообщений пользователей. Предоставленная заказчиками услуги информация используется исключительно для составления портрета целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных клиентов для заказчиков среди пользователей"
          />
        </>
      );
    }

    if (title === 'Как работать именами отправителей в реальном времени?') {
      return (
        <>
          <MainTitleWiki>Как работать именами отправителей в реальном времени?</MainTitleWiki>
          <CollapsingBlockWiki isNotCollaps>
            <TextInCollapsing>При выборе данного параметра, мы найдем для вас пользователей, которые могут получать SMS
              от интересующих вас отправителей SMS-сообщений в данный момент</TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вас интересуют пользователи, которые могут получать SMS от известного ритейлера прямо сейчас"
            />
            <TextInCollapsing>
              Имена отправителей в реальном времени можно пересечь (расширить) со следующими параметрами
              <ul>
                <li>{danglingPrepositions('сайты')} <span className={styles.inRealTimeColor}>(в реальном времени)</span>
                </li>
                <li>{danglingPrepositions('звонки')} <span
                  className={styles.inRealTimeColor}>(в реальном времени)</span></li>
                <li>{danglingPrepositions('интересами')} <span
                  className={styles.inRealTimeColor}>(в реальном времени)</span></li>
                <li>{danglingPrepositions('имена отправителей')} <span className={styles.inRealTimeColor}>(в реальном времени)</span>
                </li>
                <li>{danglingPrepositions('ключевые слова')} <span className={styles.inRealTimeColor}>(в реальном времени)</span>
                </li>
                <li>{danglingPrepositions('события')} <span
                  className={styles.inRealTimeColor}>(в реальном времени)</span></li>
              </ul>
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вас интересуют не только пользователи, которые могут получать SMS от известного ритейлера прямо сейчас, а еще и те, кто может интересоваться сайтом ритейлера прямо сейчас"
            />
            <TextInCollapsing>
              Вы можете применить дополнительные параметры фильтрации: соцдем, интересы и тп. или исключить вашу базу
              клиентов
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Пример"
              content="Вам нужны не просто те, кто может получать SMS от местного сетевого гипермаркета, а те, кто у кого доход от 100 000 ₽"
            />
            <WhiteBlockWiki
              title="Требования к названиям имен отправителей"
              content={
                <ul>
                  <li>Только латиница и цифры</li>
                  <li>Номера могут быть длиной 3, 4 и 6 символов</li>
                  <li>Минимальное количество символов — 3</li>
                  <li>Нужно ввести не менее 5 имен отправителей</li>
                </ul>
              }
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Примечание"
            description="билайн не осуществляет анализ посещенных сайтов, поисковых запросов, телефонных вызовов и SMS-сообщений пользователей. Предоставленная заказчиками услуги информация используется исключительно для составления портрета целевой аудитории, на основании которого впоследствии осуществляется поиск потенциальных клиентов для заказчиков среди пользователей"
          />
        </>
      );
    }

    if (title === 'Обязательные требования к тексту для прохождения модерации') {
      return (
        <>
          <PopupStateless opened={opened} onClose={() => setOpen(false)}><img src={popup} alt="example"
            className={styles.modalImg}/></PopupStateless>
          <MainTitleWiki>Обязательные требования к тексту для прохождения модерации</MainTitleWiki>
          <CollapsingBlockWiki title="Текст сообщения">
            <TextInCollapsing className={styles.t1}>
              При составлении текста сообщения вы можете воспользоваться готовыми шаблонами текстов, в зависимости от
              вашей отрасли.
            </TextInCollapsing>
            <TextInCollapsing>Чтобы текст вашей таргетированной SMS был максимально эффективным, следуйте простым
              правилам:</TextInCollapsing>
            <WhiteBlockWiki
              title="Чек-лист идеального SMS-сообщения"
              content={
                <>
                  <ul>
                    <li>Ваше предложение должно быть понятным и привлекательным. Первые 2–3 слова должны заинтересовать
                      получателя, чтобы они захотели дочитать сообщение
                    </li>
                    <li>Получатели должны найти в тексте ответы на три вопроса: 1) Что вы предлагаете? 2) Почему это
                      выгодно? 3) Где купить?
                    </li>
                    <li>Получатели должны понимать, какие действия вы от них ожидаете, то есть в SMS должен быть
                      очевидный призыв к действию. «Закажите сейчас», «Посмотрите» и тп.
                    </li>
                    <li>Обязательно укажите ваши контактные данные: полный адрес, телефон или адрес сайта</li>
                    <li>Используйте промокоды</li>
                    <li>Используйте ссылку для отслеживания кликов и UTM-метки для оценки эффективности</li>
                  </ul>
                  <span className={styles.link} onClick={() => setOpen(true)}>Посмотреть пример текста</span>
                </>
              }
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki
            title="Требования к тексту"
          >
            <TextInCollapsing>
              Все данные требования являются обязательными.
              При нарушении любого из пунктов ваша таргетированная SMS-рассылка не пройдет модерацию и будет отклонена.
              Текст вашей таргетированной SMS-рассылки должен соответствовать
              <a className={styles.link} rel="noreferrer" target="_blank"
                href="https://static.beeline.ru/upload/images/business/doc/redpolitika_beeline.pdf"> редакционной
                политике билайн</a>
            </TextInCollapsing>
            <WhiteBlockWiki
              title="Указывайте сроки проведения акции в формате ДД.ММ.ГГГГ"
              content={
                <div>
                  <div><TextPermission access="allow">«С 12.01.2022 по 30.04.2022» или «до 15.04.2022» </TextPermission>
                  </div>
                  <div><TextPermission access="deny">«В феврале» или «до 1 марта» </TextPermission></div>
                </div>
              }
            />
            <WhiteBlockWiki title="Проверяйте текст на наличие лишних пробелов"/>
            <WhiteBlockWiki
              title="Указывайте стоимость товаров и услуг в рублях"
              content={
                <div>
                  <TextPermission access="allow">«2 500 руб.», «4 800р.»</TextPermission>
                  <TextPermission access="deny">«$10» или «50 долл.» </TextPermission>
                </div>
              }
            />
            {/* <WhiteBlockWiki */}
            {/*  content={<StrongText>Проверяйте текст на наличие <a rel="noreferrer" target="_blank" href="https://static.beeline.ru/upload/images/marketing/Стоп-слова.pdf">запрещенных слов</a>. Это слова, которые запрещено использовать в таргетированных SMS-сообщениях, согласно редакционной политики билайн</StrongText> } */}
            {/* /> */}
            {/* <WhiteBlockWiki */}
            {/*  content={<StrongText>Проверяйте текст на наличие <a rel="noreferrer" target="_blank" href="https://static.beeline.ru/upload/images/marketing/Спорные_слова.pdf">спорных слов</a>. Это слова, которые не рекомендуется использовать в таргетированных SMS-сообщениях, согласно редакционной политики билайн</StrongText> } */}
            {/* /> */}
            <WhiteBlockWiki
              title="Обращайтесь к получателям сообщений на Вы"
              content={
                <div>
                  <TextPermission access="allow">«Вы», «для Вас»</TextPermission>
                  <TextPermission access="deny">«ты», «для тебя»</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              title="Не набирайте текст заглавными буквами. Такой формат считается оскорбительным у получателей. Исключение промокоды и аббревиатуры."
              content={
                <div>
                  <TextPermission access="allow">«Только сегодня для посетителей...»</TextPermission>
                  <TextPermission access="deny">«ТОЛЬКО СЕГОДНЯ ДЛЯ ПОСЕТИТЕЛЕЙ...»</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              title="Используйте только общепринятые аббревиатуры"
              content={
                <div>
                  <TextPermission access="allow">«РФ», «ТО», «СПБ»</TextPermission>
                  <TextPermission access="deny">«ЦПК», «ДС-1»</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              title="Текст сообщения всегда пишите на русском языке. Допускается повторение текста на другом языке в «()»."
              content={
                <div>
                  <TextPermission access="allow">«В наличии более 200 авто марки "Лексус" в автосалоне "Авто
                    Мир"»</TextPermission>
                  <TextPermission access="deny">«More than 200 Lexus cars are available at Auto World»</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              title="Не размещайте ссылку на мессенджеры (WhatsApp, Telegram, Viber и др.)"
              content={
                <div>
                  <TextPermission access="deny">«t.me/username»</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              title="Не намекайте получателю о его возможных проблемах или неполноценности"
              content={
                <div>
                  <TextPermission access="deny">«Банкротство», «заболевание», «семейные трудности» и
                    т.п.</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              content={
                <NoticeText>
                  Условия скидок, акций, спец. условий в таргетированной SMS должны быть продублированы на официальном
                  сайте вашей компании, социальных сетях.
                  Если такой возможности нет, то загрузите в поле «Документы для модератора» письмо на бланке компании,
                  в котором опишите все условия проводимой акции, не забудьте поставить печать и подпись.
                </NoticeText>
              }
            />
            <WhiteBlockWiki
              title="Не используйте прилагательные в превосходной степени"
              content={
                <div>
                  <TextPermission access="deny">«Лучший», «первый», «номер один», «самый», «только»,
                    «единственный»</TextPermission>
                  Чтобы их использовать в тексте сообщения, нужно предоставить документ составленный и подтвержденный
                  авторитетной сторонней организацией, подтверждающей заявленные превосходные степени, например,
                  открытым исследованием.
                  Этот документ должен быть опубликован на официальном сайте вашей компании
                </div>
              }
            />
            <WhiteBlockWiki
              title="Нельзя использовать слова усилители, не несущие смысловой нагрузки"
              content={
                <div>
                  <TextPermission access="deny">«Сенсация», «шок» и прочие</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              title="Рекламируя приложение, шоу программу, развлекательный контент и подобное, не забывайте указывать возрастную категорию"
              content={
                <div>
                  <TextPermission access="allow">«6+», «12+», «18+» и т.д.</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              title="Реклама медицинских товаров и услуг нужно сопровождать приписками:"
              content={
                <div>
                  <TextPermission access="allow">«Имеются противопоказания, требуется консультация специалиста», «БАД не
                    является лекарственным средством»</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              title="Если у вас есть доставка, нужно указывать в тексте сообщения: наименование вашей компании и ИНН или ОГРН для ИП и Самозанятых"
              content={
                <div>
                  <TextPermission access="allow">«ПАО “ВымпелКом” ИНН»</TextPermission>
                </div>
              }
            />
            <TextInCollapsing>билайн оставляет за собой право отказать в рассылке без объяснения
              причин</TextInCollapsing>
          </CollapsingBlockWiki>
          <CollapsingBlockWiki title="Как правильно указать ссылку на ваш сайт?">
            <WhiteBlockWiki
              title={<>Ссылка на сайт должна быть отделена от текста сообщения пробелами.
                Знаки препинания, не являющиеся частью ссылки, их не нужно ставить после ссылки, чтобы она была
                кликабельной.<br/><br/>
                Обязательно проверьте ссылку в тестовом SMS-сообщении!</>}
            />
            <WhiteBlockWiki
              title="Не используйте верхний регистр для ссылок"
              content={
                <div>
                  <TextPermission access="allow">«https://beeline.ru»</TextPermission>
                  <TextPermission access="deny">«https://Beeline.RU»</TextPermission>
                </div>}
            />
            <WhiteBlockWiki
              title="Используйте https/http в названии сайта"
              content={
                <div>
                  <TextPermission access="allow">«https://beeline.ru»</TextPermission>
                </div>}
            />
            <WhiteBlockWiki
              title="Для отслеживания кликов в Google Analytics или Яндекс Метрике, добавьте к ссылке UTM-метку"
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki title="Как правильно указать ваш номер телефона?">
            <WhiteBlockWiki
              title="Номер телефона указывайте с кодом страны в формате «+7» или «8» и кодом города, без «()», «-», пробелов, и без знаков препинания в конце"
              content={
                <div>
                  <TextPermission access="allow">«+74951231212», «88007000628»</TextPermission>
                  <TextPermission access="deny">«8 (800) 7000-628»</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              title="Номер телефона должен быть отделен от текста сообщения пробелами, чтобы быть «кликабельным»"
              content={
                <div>
                  <TextPermission access="allow">«Подробности по телефону: 88007000628»</TextPermission>
                  <TextPermission access="deny">«Звоните по номеру-88007000628»</TextPermission>
                </div>
              }
            />
          </CollapsingBlockWiki>
          <CollapsingBlockWiki title="Как использовать промокод в таргетированном SMS-сообщении?">
            <WhiteBlockWiki title="Загрузите файл с уникальными промокодами в специальное поле в формате CSV"/>
            <WhiteBlockWiki title="Каждый абонент получит таргетированное SMS-сообщение c вашим уникальным промокодом"/>
            <WhiteBlockWiki
              title="Количество символов — от 3-х до 20-и, нужно использовать исключительно латинские буквы, цифры"
              content={
                <div>
                  <TextPermission access="allow">«Privetleto2022»</TextPermission>
                </div>
              }
            />
            <WhiteBlockWiki
              title="Наличие промокода влияет на длину и стоимость таргетированной SMS, сколько в промокоде символов, столько же и добавится к основному тексту"
            />
          </CollapsingBlockWiki>
        </>
      );
    }

    return '';
  };

  return (
    <div>
      {
        visible &&
        <FloatingPanel onClose={() => setVisible(false)}>
          {getContent()}
        </FloatingPanel>
      }
      <LinkOpenInfo className={className} isArrow onClick={() => setVisible(true)}>{title}</LinkOpenInfo>
    </div>
  );
};

export default WidgetsWikiLink;
