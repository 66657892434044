import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { TextInput } from 'redesignSrc/UI/form';
import { IconEnd } from 'redesignSrc/UI/form/TextInput';
import { PureButton } from 'redesignSrc/UI/buttons';
import { MessageTemplatesGroup } from 'store/mobx/MessageTemplates/types';
import CrossIcon from './redCross.svg';
import styles from './styles.pcss';

const filterData = (arr: MessageTemplatesGroup[], filter: string) => {
  if (arr) {
    return arr.filter((el) => {
      return el.name.toLowerCase().includes(filter.toLowerCase());
    });
  }
  return arr;
};

type Props = {
  isOpen: boolean,
  data: MessageTemplatesGroup[],
  onDelete: (e: MessageTemplatesGroup) => void,
  onChange: (e: MessageTemplatesGroup | null) => void,
  className?: string,
}

const TemplateGroupSearchFilter: React.FC<Props> = ({ isOpen, onDelete, onChange, data, className }) => {
  const [currentData, setCurrentData] = useState(data || []);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (search) {
      setCurrentData(filterData(data, search));
    } else {
      setCurrentData(data);
    }
  }, [search]);
  return (
    <>
      {isOpen &&
        <div className={classNames(styles.wrapper, className)}>
          <div className={styles.containerInput}>
            <TextInput
              placeholder="Поиск по группам"
              value={search}
              endIcon={IconEnd.search}
              onChange={(e) => {
                setSearch(e);
              }}
            />
          </div>
          <div className={styles.containerSelect}>
            <PureButton
              className={classNames(styles.checkInput, styles.all)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChange(null);
              }}>
              Выбрать все
            </PureButton>
            {currentData.map((item) => {
              return (
                <div key={item.id} className={styles.wrapperButton}>
                  <PureButton
                    className={styles.checkInput}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onChange(item);
                    }}

                  >
                    {item.name}
                  </PureButton>
                  {(item.templateCount === 0 && item.name !== 'Без группы') &&
                    <CrossIcon onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onDelete(item);
                    }} /> || null}
                </div>
              );
            })}
          </div>
        </div> || null
      }
    </>
  );
};

export default TemplateGroupSearchFilter;
