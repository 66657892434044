import React, { ReactNode } from 'react';
import ErrorPage from 'pages/ErrorPage';
import { ToastNotifications } from '../common';

class ErrorBoundary extends React.Component {
  constructor(props: { children: ReactNode }) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return (
      <>
        <ToastNotifications/>
        {this.props.children}
      </>
    );
  }
}

export default ErrorBoundary;
