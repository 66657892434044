import axios from 'axios';
import Cookies from 'js-cookie';
import Authorization from 'store/mobx/Authorization';
import { map } from 'utils/fn';

export const BASE_URL = '/partners/';
export const BASE_URL_API_CLIENT = '/partners';


export const axiosBaseRequestAdTech = (config) =>
  axios({
    method: config.method || 'GET',
    baseURL: BASE_URL,
    headers: {
      ...config.headers,
    },
    ...config,
    // eslint-disable-next-line consistent-return
  }).then((res) => {
    return res.data;
  });
/**
 * A base request with common params predefined
 * fully customizable, baseRequest is used to prevent boilerplate code generation
 * @returns {AxiosPromise}
 */
export const axiosBaseRequest = ({
  method = 'GET',
  url,
  responseType = 'json',
  withCredentials = true,
  headers,
  ...options
}) => axios({
  method,
  url,
  responseType,
  withCredentials,
  onUploadProgress: (e) => options?.config?.onUploadProgress && options.config.onUploadProgress(e),
  config: options.config,
  headers: {
    'X-CSRFToken': Cookies.get('marketeco_csrftoken'),
    ...headers,
  },
  ...options,
}).then(res => res.data);

const clearedData = (data) => {
  if (!data || typeof data !== 'object') return data;
  for (const key of Object.keys(data)) {
    if (typeof data[key] === 'object') {
      clearedData(data[key]);
    }
    if (typeof data[key] === 'string' && data[key] === '') {
      delete data[key];
    }
  }
  return data;
};

const composeAuthorizedRequest = (request) => (data, { isIgnoreErrors } = {}) => {
  return request(clearedData(data))
    .catch((err) => {
      const statusCode = err?.response?.status;

      if (data?.url === '/api/user/info' && process.env.NODE_ENV === 'development') {
        return Authorization.setUnauthorized();
      }

      if (statusCode === 401) {
        return Authorization.setUnauthorized();
      } else if (!isIgnoreErrors && statusCode === 403) {
        // eslint-disable-next-line no-console
        console.log('403');
      }
      throw err;
    });
};

export const composeErrorCatchingRequest = request => data =>
  request(data).catch((err) => {
    const { status: statusCode } = err.response;
    if (statusCode === 401) {
      return Authorization.setUnauthorized();
    }
    throw err;
  });


/**
 * Global store bound function that works with a store
 */
export const axiosAuthorizedRequest = composeAuthorizedRequest(axiosBaseRequest);
export const axiosAuthorizedRequestAdTech = composeAuthorizedRequest(axiosBaseRequestAdTech);

/**
 * Advanced request composer used to create POST/PUT requests, based on data provided
 * (checks if data contains `id` field to create a PUT request)
 * Takes same arguments as axiosBaseRequest (and axios store)
 */
export const composeAxiosMutationRequest = ({ url, ...requestOptions }) =>
  data => axiosAuthorizedRequest({
    ...(data && data.id ? {
      method: 'PUT',
      url: `${url}${data.id}/`,
    } : {
      method: 'POST',
      url,
    }),
    ...requestOptions,
    data,
  });


/**
 * Advanced request composer used to create POST/PUT requests, based on data provided
 * (checks if data contains `id` field to create a PUT request)
 * Takes same arguments as axiosBaseRequest (and axios store)
 */
export const composeAxiosUpdateRequest = requestOptions =>
  data => axiosAuthorizedRequest({
    method: 'PUT',
    ...requestOptions,
    data,
  });

export const composeAxiosMutationFileDataRequest = requestOptions =>
  data => axiosAuthorizedRequest({
    ...requestOptions,
    data: prepareMultipartFormData(data),
    config: requestOptions.config,
  });


export const composeAxiosDeleteRequest = requestOptions =>
  data => axiosAuthorizedRequest({
    method: 'DELETE',
    ...requestOptions,
    data,
  });

export const composeAxiosPostRequest = requestOptions =>
  axiosAuthorizedRequest({
    method: 'POST',
    ...requestOptions,
  });

export const composeAxiosPatchRequest = requestOptions =>
  axiosAuthorizedRequest({
    method: 'PATCH',
    ...requestOptions,
  });


/**
 * Allows uploading of files, as well as normal json data objects
 */
export const prepareMultipartFormData = (data) => {
  const formData = new FormData();
  map(data, (value, key) => {
    if (value instanceof Blob) {
      formData.append(key, value, value.name);
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};

const getJSON = (res) => {
  try {
    return res.json();
  } catch (e) {
    return res;
  }
};

export const adTechRequest = ({ url, method = 'GET', data = null, isSimple = false, withBlock = false, params = {} }) => {
  let resStatus = 0;
  let query = '';
  if (Object.keys(params).length) {
    query = '?';
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        if (query !== '?') query += '&';
        query += `${key}=${value}`;
      }
    }
    if (query === '?') query = '';
  }
  return fetch(`/partners${url}${query}`, {
    method,
    mode: 'cors',
    body: data && JSON.stringify(data),
    headers: {
      // 'x-api-key': 'eec458cd-b8ca-44b9-9432-c133f8596433',
      'Content-Type': 'application/json-patch+json',
    },
  })
    .then(res => {
      resStatus = res.status;
      if (resStatus === 401) {
        return Authorization.setUnauthorized();
      }
      return getJSON(res);
    })
    .then(res => {
      if (resStatus >= 400 && resStatus < 600) {
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            status: resStatus,
            data: getJSON(res),
          },
        };
      }
      return getJSON(res);
    })
    .then(res => {
      return res;
    })
    .catch((err) => {
      const statusCode = err?.response?.status;
      if (statusCode === 401) {
        return Authorization.setUnauthorized();
      } else if (withBlock) {
        // eslint-disable-next-line no-console
        console.log('401');
      }
      throw err;
    });
};
