import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import CreateFocusReport from 'store/mobx/CreateFocusReport';
import { PopupStateless } from 'components/common';
import { ReplaceLoader } from 'components/common/Loaders/components';
import {
  STEP_ERROR_CALCULATE_COST,
  STEP_ERROR_FILE_LOADING,
  STEP_ERROR_PREPARE_ORDER,
  TOO_MANY_REQUESTS,
} from 'store/mobx/constants';
import {
  PopupContentWrapper,
  ErrorLoadFile,
  ErrorCreateOrder,
  ErrorCalculateCost,
  ErrorTooManyRequests,
} from './components';

function EditCampaignStepper({ giveAnswer }) {
  const getCurrentStepView = (handleCloseModal) => {
    switch (CreateFocusReport.step) {
      // #1
      case STEP_ERROR_FILE_LOADING: {
        return <ErrorLoadFile onClose={handleCloseModal} />;
      }
      case STEP_ERROR_PREPARE_ORDER: {
        return <ErrorCreateOrder onClose={handleCloseModal} />;
      }
      case TOO_MANY_REQUESTS: {
        return <ErrorTooManyRequests />;
      }
      case STEP_ERROR_CALCULATE_COST: {
        return <ErrorCalculateCost onClose={handleCloseModal} />;
      }
      default: {
        return null;
      }
    }
  };

  const handleCloseModal = () => {
    giveAnswer();
  };

  return (
    <PopupStateless opened onClose={handleCloseModal}>
      <PopupContentWrapper>
        <ReplaceLoader isLoading={CreateFocusReport.loading}>
          {getCurrentStepView(handleCloseModal)}
        </ReplaceLoader>
      </PopupContentWrapper>
    </PopupStateless>
  );
}

EditCampaignStepper.propTypes = {
  giveAnswer: PropTypes.func,
};

export default observer(EditCampaignStepper);
