export const PUSH_TARGET_ACTIONS = {
  FOLLOW_LINK: 'followLink',
  APP_DOWNLOAD: 'appDownload',
  CALL_ON_NUMBER: 'phoneCall',
};

export const PUSH_MESSAGE_FIELD = 'pushText';
export const PUSH_TEXT_FIELD = 'text';
export const NOTIFICATION_TYPE = 'notificationType';
export const SHORT_NUMBER = 'phone';

export const NOTIFICATION_TYPE_VALUES = {
  SHOW_SOME_TIME: 'showSomeTime',
  ALWAYS_SHOW: 'alwaysShow',
  ALWAYS_SHOW_AND_RING: 'alwaysShowAndRing',
};
