import { Button, FileUploader, Icon, InlineAlert, TextArea } from '@beeline/design-system-react';
import React, { useContext, useEffect, useState } from 'react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { navigate, useParams } from '@reach/router';
import moment from 'moment';
import { toJS } from 'mobx';
import ContractSelection from 'redesignSrc/components/ContractSelection1/ContractSelection';
import { TypographyWithDangling } from 'redesignSrc/UI/beeline/TypographyWithDangling/TypographyWithDangling';
import { DocsByBusinessList, groupFilesById, senderNameStore } from 'redesignSrc/pages/SmsPages/SmsPageSetup/components/SenderName/SenderNameStore';

import { toastErrorNotification } from 'modules/toast-notifications';
import SelectField from '../../SmsPages/SmsPageSetup/components/SenderName/CreateSenderName/components/SelectField';
import { CreateSenderName, SenderNameContext, SenderNameContextType } from '../../SmsPages/SmsPageSetup/components/SenderName';
import { scrollSmoothToNodeById } from '../../../../utils/scroll';
import PageLoader from '../../../UI/loaders/PageLoader';
import { NameInfo } from '../../NewTemplate/components';
import { TEMPLATE_MESSAGE_STATUS_MAP } from '../../MessageTemplates/consts';
import ContractSelectionStore from '../../../components/ContractSelection1/ContractSelectionStore';
import styles from './styles.pcss';
import UploadedFiles from './components/UploadedFiles';
import SenderNameText from './components/SenderNameText';
import SwitchField from './components/SwitchField';
import SenderNamesFileList from './components/SenderNameList';
import ConfirmationDialog from './components/ConfirmationDialog';
import InfoDialog from './components/InfoDialog';

const cx = classNames.bind(styles);

type SendFormValues = { senderName: string, comment?: string, ban: string, customerId: string | number }

const CreateSenderNameBody = observer(() => {
  const senderContext = useContext<SenderNameContextType | null>(SenderNameContext);

  if (!senderContext) {
    throw new Error('senderContext не найден');
  }
  const {
    validation,
    senderNameId,
    isIp = false,
    setIsIp,
    onAddFiles,
    chooseDocsIsVisible,
    isInfoVisited,
    setIsInfoVisited,
    onReplaceFile,
    onDeleteFile,
    onFileEdit,
    checkIfSenderNameExist,
    selectedBan,
    setSelectedBan,
    setChooseDocsVisible,
  } = senderContext;
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const sendFormData = async (values: SendFormValues) => {
    if (!values.senderName) return null;
    senderNameStore.loading = true;
    let nameId = '';
    if (!senderNameId) {
      nameId = await senderNameStore.createName(values.senderName, values.ban, values.customerId);
      senderNameStore.senderId = +nameId;
    } else {
      nameId = senderNameId;
    }
    if (nameId) {
      await senderNameStore.sendAllFiles(nameId);
      await senderNameStore.updateName({ name: values.senderName, senderNameId: nameId });
    }
    if (values.comment) {
      await senderNameStore.sendComment(nameId, values.comment);
    }
    await senderNameStore.getSenderNameById(+nameId);
    await senderNameStore.getAllSenderNames();
  };

  const isEditable = senderNameStore.senderStatus !== 'Verification' && senderNameStore.senderStatus !== 'Accepted';

  return (
    <form
      className={styles.form}
      onSubmit={async (e) => {
        e.preventDefault();
        try {
          await senderNameStore.createSenderNameSection((selectedBan?.ban || senderNameStore.ban) as string, selectedBan?.customerId);
          const dataToSave = {
            ...senderNameStore.form,
            ban: selectedBan?.ban || senderNameStore.ban as string,
            customerId: selectedBan?.customerId,
          } as SendFormValues;
          await sendFormData(dataToSave);
          await navigate('/sender-names/');
        } catch (error) {
          console.error(error);
          toastErrorNotification('Во время выполнения запроса произошла ошибка');
        } finally {
          senderNameStore.loading = false;
        }
      }}
    >
      <TypographyWithDangling
        variant="h1"
        className={cx(styles.title, styles.titleEdit)}
      >
        {!senderNameId ? 'Создание имени отправителя' : 'Редактирование имени отправителя'}
      </TypographyWithDangling>

      <div className={styles.statusName}>
        <NameInfo
          status={TEMPLATE_MESSAGE_STATUS_MAP[senderNameStore?.senderStatus || 'Draft']}
        />
      </div>

      {
        senderNameStore.senderStatus === 'Rejected' && senderNameStore.actualRejectComment &&
        <InlineAlert type="error" className={styles.topComment}>
          {senderNameStore.actualRejectComment.text}
        </InlineAlert>
      }

      <ContractSelection
        description="Выбирайте номер договора, для которого хотите создать имя"
        validation={validation}
        isDisable={!!senderNameStore.ban}
        selectedValue={senderNameStore.ban}
        onChange={(e) => {
          ContractSelectionStore.currentBan = e;
          setSelectedBan(e);
        }}
      />

      <div className={styles.whiteBlock}>
        <div className={cx(styles.mainTitle, styles.mb29)}>
          Придумайте имя
          {
            !validation.errors?.senderName &&
            (senderNameStore.form?.senderName as string)?.length &&
            <span>
              <Icon iconName={Icons.CheckCircled} color="green" />
              </span> ||
            null
          }
        </div>
        {
          isEditable &&
          <SwitchField
            value={isIp}
            onChange={setIsIp}
          />
        }
        <div id="senderNameField" />
        <SenderNameText
          error={!!validation.errors?.senderName}
          currentLength={(senderNameStore?.form?.senderName as string)?.length || 0}
          onChange={(e) => {
            senderNameStore.form.senderName = e.currentTarget.value;
          }}
          disabled={!isEditable}
          value={senderNameStore?.form?.senderName as string || ''}
          helperText={validation.errors?.senderName || 'Только латинские буквы, цифры и символы «-», «_», «.».'}
        />
        {
          isIp &&
          <div className={styles.attention}>
            <Icon type="warning" iconName={Icons.WarningCircled} contained/>
            <TypographyWithDangling variant="body3">
              Для ИП имя отправителя должно отличаться от наименования организации
            </TypographyWithDangling>
          </div>
        }
      </div>

      <div className={styles.whiteBlock}>
        <div className={cx(styles.mainTitle, styles.mb16)}>
          Загрузите любой документ из списка
          {
            Object.entries(senderNameStore.userFilesById || {}).length &&
            <span>
              <Icon iconName={Icons.CheckCircled} color="green" />
              </span> || null
          }
        </div>
        <div className={styles.fileAttention}>
          <Icon iconName={Icons.WarningCircled} contained/>
          <TypographyWithDangling variant="body3" className={styles.naming}>
            Выберите из списка и загрузите тип документа, который у вас есть. Если документ состоит из нескольких файлов, назовите каждый файл понятно: например, стр. 1, стр 2 и т.д
          </TypographyWithDangling>
        </div>

        {
          Object.entries(senderNameStore.userFilesById || {}).length &&
          Object.entries(senderNameStore.userFilesById || {}).map(([key, value]) => {
            return (
              <UploadedFiles
                isEditable={isEditable}
                key={key}
                title={value[0]?.documentTypeName}
                onAddFiles={(e, docTypeId) => {
                  if (e.target.files) {
                    onAddFiles(e.target.files, docTypeId);
                  }
                }}
                documentTypeId={+key}
              >
                <SenderNamesFileList
                  onReplaceFile={onReplaceFile}
                  cantEdit={!isEditable}
                  onDeleteFile={onDeleteFile}
                  onFileEdit={onFileEdit}
                  docs={value}
                  />
              </UploadedFiles>
            );
          }) || null
        }
        {
          isEditable &&
          chooseDocsIsVisible &&
          <SelectField
            type={isIp ? 1 : 0}
            fetchDocsList={senderNameStore.fetchDocsByBusinessType}
            docsByBusinessType={senderNameStore.docsByBusinessType}
            currentValues={senderNameStore?.form?.documentType as DocsByBusinessList[] || []}
            onChange={(e) => {
              senderNameStore.form.documentType = e;
            }}
            error={!senderNameStore?.form?.documentType.length ? validation?.errors?.files : ''}
          />
        }

        {
          (senderNameStore?.form?.documentType as { value: string }[])?.[0]?.value === 'Другой документ, разрешающий использовать имя' &&
          chooseDocsIsVisible &&
          <div
            className={cx(styles.pseudoLink, isInfoVisited && styles.visited)}
            onClick={() => {
              setIsInfoDialogOpen(true);
              setIsInfoVisited(true);
            }}
          >
            <Icon contained={false} iconName={Icons.InfoCircled} color={isInfoVisited ? 'purple' : 'blue'} />
            Как быстро согласовать имя и какие документы не подойдут?
          </div> || null
        }

        {
          (senderNameStore?.form?.documentType.length) &&
          <FileUploader
            title="Перетащите сюда файлы или"
            linkName="загрузите документы"
            subTitle="pdf, jpg, png, gif, rar, zip до 20 Мб"
            multiple={false}
            error={!!validation?.errors?.files}
            helperText={validation?.errors?.files || ''}
            onChange={(e) => {
              if (e.target.files) {
                onAddFiles(e.target.files);
                validation.resetError();
              }
            }}
            className={styles.filesDrop}
            onDrop={(e) => e?.dataTransfer?.files && onAddFiles(e.dataTransfer.files as FileList)}
          /> || null
        }
        {
          Object.entries(senderNameStore.userFilesById || {}).length &&
          !senderNameStore?.form?.documentType.length &&
          !chooseDocsIsVisible &&
          isEditable &&
          <Button
            startIcon={<Icon iconName={Icons.Add} />}
            onClick={() => setChooseDocsVisible(true)}
            variant="overlay"
            className={styles.addMore}
            type="button"
          >
            Добавить документ
          </Button> || null
        }
      </div>
      {
        Object?.entries(senderNameStore.userFilesById || {})?.length &&
        <div className={styles.whiteBlock}>
          <div className={cx(styles.mainTitle, styles.mb29)}>
            Комментарий
          </div>
          {
            !!senderNameStore.actualRejectComment &&
            <div>
              <div className={styles.commentHolder}>
                {senderNameStore.actualRejectComment.text}
              </div>
              <div className={styles.commentInfo}>{moment(senderNameStore.actualRejectComment.createdOn).format('DD.MM.YY, HH:mm, ')}{senderNameStore.actualRejectComment.createdBy}</div>
            </div>
          }
          <TextArea
            onChange={(e) => {
              (senderNameStore.form as { comment: string }).comment = e.currentTarget.value;
            }}
            value={senderNameStore.form.comment}
            className={styles.comment}
            placeholder="Укажите любой комментарий, если нужно"
            disabled={!isEditable}
          />
        </div> || null
      }
      {
        isEditable &&
        <div className={styles.btnsHolder}>
          <Button
            disabled={senderNameStore.loading}
            variant="outlined"
            type="submit"
            className={styles.sendBtn}
          >
            Сохранить в черновик
          </Button>
          <Button
            variant="contained"
            disabled={senderNameStore.loading}
            type="button"
            className={styles.sendBtn}
            onClick={async () => {
              try {
                if (validation.getAllValid().firstErrorKey) {
                  console.error(validation.errors);
                  return;
                }
                let numberSender;
                if (!senderNameId) {
                  numberSender = await checkIfSenderNameExist(senderNameStore.form.senderName as string, selectedBan?.ban ? +selectedBan.ban : undefined);
                } else {
                  await sendFormData(
                    {
                      ...senderNameStore.form,
                      ban: selectedBan?.ban || senderNameStore.ban as string,
                      customerId: selectedBan?.customerId,
                    } as SendFormValues);
                  senderNameStore.agreementModal = true;
                }
                if (numberSender) {
                  validation.setErrorsOfKey('senderName', 'Такое имя уже существует');
                  scrollSmoothToNodeById('senderNameField', 200);
                }
              } catch (error) {
                if (error?.response?.status === 404) {
                  await sendFormData(
                    {
                      ...senderNameStore.form,
                      ban: selectedBan?.ban || senderNameStore.ban as string,
                      customerId: selectedBan?.customerId,
                    } as SendFormValues);
                  senderNameStore.agreementModal = true;
                }
              }
            }}
          >
            Отправить на согласование
          </Button>
        </div>
      }
      <ConfirmationDialog
        sendFormData={async () => {
          await sendFormData({
            ...senderNameStore.form,
            ban: selectedBan?.ban || senderNameStore.ban as string,
            customerId: selectedBan?.customerId,
          } as SendFormValues);
        }} />
      {
        isInfoDialogOpen &&
        <InfoDialog onClose={() => setIsInfoDialogOpen(false)} />
      }
    </form>
  );
});

const CreateSenderNameCard = () => {
  return (
    <CreateSenderName>
      <CreateSenderNameBody />
    </CreateSenderName>
  );
};

export default CreateSenderNameCard;

export const EditSenderName = observer(() => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        if (!id) {
          setIsLoading(false);
          return;
        }
        senderNameStore.senderId = id;
        await senderNameStore.getSenderNameByIdSection(id);
        await senderNameStore.getAllFilesBySenderIdSection();
        await senderNameStore.getAllComments(id);
        senderNameStore.userFilesById = groupFilesById(senderNameStore.files);
        senderNameStore.form.senderName = senderNameStore.nameSender;
      } catch (e) {
        toastErrorNotification('Ошибка при загрузке имени отправителя');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);
  return (
    <PageLoader isLoading={isLoading}>
      <CreateSenderName senderNameId={id}>
        <CreateSenderNameBody />
      </CreateSenderName>
    </PageLoader>
  );
});
