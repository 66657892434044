import { action, computed, observable, toJS } from 'mobx';
import { navigate } from '@reach/router';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import { replaceVariables } from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/utils/utils';
import StoreT from 'redesignSrc/pages/SmsPages/components/ContentEditableLinks/storeT';
import { toastAccessNotification, toastErrorNotification } from '../../../modules/toast-notifications';
import {
  StatusType,
  CampaignType,
  BannerFilesType,
  GeoTypeBillboard,
  GeoTypeCity,
  SocialNetwork,
  AudioAdsDetailsType,
} from '../NewOrder/type';
import UserInfo from '../UserInfo';
import { TypeContextual, TypeContextualValues } from '../InternetAd/type';
import { STATUS_BAN_MAP, TypeStatusBan } from './UserInfo';

type Link = {
  isShort: boolean
  link: string | null
  dynamicPartLink?: string | null
  shortLink: string | null
}

export type CampaignData = {
  name: string,
  id: number,
  eventCount: number,
  invoicePrice: number,
  invoiceId: number | null,
  otherOperatorsEventCount: number,
  status: StatusType,
  type: CampaignType,
  startDate: string,
  endDate: string,
  totalPrice: number,
  discountPrice: number,
  comment: string,
  segmentId: string,
  invoiceStatus: 1 | 2 | 3 | null,
  createDate: string,
  company: string | null,
  inn: string | null,
  industryId: number | null,
  industryName: string | null,
  isAutoInvoice: boolean,
  ban: string | null,
  customerId: number | null,
  unseenStopDemandsId: number[] | null,
  isSavedAudience: boolean,
  pushCampaign: {
    timeFrom: number,
    timeTo: number,
    pages: string[] | null,
    notificationType: number,
    targetAction: number,
    campaignId: number,
    targetResource: string,
    moderationComment: string,
    isShort: boolean,
  },
  smsCampaign: {
    shortMessage: string,
    message: string,
    links: Link,
    messageDescription: string,
    senderName: string | null,
    campaignId: number,
    timeFrom: number,
    timeTo: number,
    moderationComment: string,
    additionalInfo: string,
    isParameterized: boolean,
    variables: object,
    senderId: number,
  },
  voiceTargetDetails: {
    industryId: number | null,
    audienceType: 'NotTarget' | 'Target' | 'Custom',
    numberType: 'Mobile' | 'Corporate'
    campaignId: number
  },
  otmDetails: {
    mediaTypeId: number | null,
    mediaType: string | null,
    deviceType: number[],
    targetingType: number[],
    url: string,
    promotionalMaterials: string,
    geoProfiles: string,
    descAudience: string,
    moderationComment: string,
    campaignId: number,
  },
  digitalBillboardDetails: {
    purpose: string,
    hasBanner: boolean,
    bannerText: string,
    viewsCount: number,
    geoType: 'City' | 'Billboard',
    billboards: GeoTypeBillboard[],
    cities: GeoTypeCity[]
    bannerFiles: BannerFilesType[],
    moderationComment: string,
    campaignId: number,
  },
  contextAdsDetails: TypeContextualValues,
  audioAdsDetails?: AudioAdsDetailsType | null,
  socialNetworkDetails: SocialNetwork
}

class CampaignStatistics {
  @observable data: CampaignData | null = null;
  @observable typeOptions: [] | { label: string, value: number }[] = []
  @observable isCampaignLoading = true;
  @observable banStatus: string = 'Не определено'
  @observable namePromocode: string | null = null
  @observable currentStatusStopDemands: 'Created' | 'Rejected' | null = null

  removeLastWord = (url: string | null) => {
    if (!url) return '';
    return url.replace(/\/[^/]+$/, '/');
  }

  findText = (url: string | null, template: string | null) => {
    if (!url || !template) return '';
    return template.replace(url, this.removeLastWord(url));
  }

  findText2 = (url: string | null, template: string | null) => {
    if (!url || !template) return '';
    return template.replace(url, url + this.data?.smsCampaign?.links?.dynamicPartLink);
  }

  getOrderById = async (orderId: string) => {
    this.isCampaignLoading = true;
    try {
      this.data = await axiosBaseRequestAdTech({ url: `/api/orders/${orderId}` });
      if (this.data?.smsCampaign?.links?.dynamicPartLink) {
        // @ts-ignore
        this.data?.smsCampaign?.message = this.findText2(this.data?.smsCampaign?.links.link, this.data?.smsCampaign?.message);
        // @ts-ignore
        this.data?.smsCampaign?.shortMessage = this.findText2(this.data?.smsCampaign?.links.link, this.data?.smsCampaign?.shortMessage);
        // @ts-ignore
        this.data?.smsCampaign?.links.link = this.data?.smsCampaign?.links.link + this.data?.smsCampaign?.links.dynamicPartLink;
      }
      if (this.data?.type === 'Otm') {
        this.typeOptions = await axiosBaseRequestAdTech({
          url: '/api/v1/brif/typeCampaign/0',
        });
      }
      if (this.data?.ban && UserInfo.isRoleSS) {
        const response = await axiosBaseRequestAdTech({
          url: `/api/company/Ban?ban=${this.data?.ban}`,
        });
        const status = response.banStatus as TypeStatusBan;
        this.banStatus = STATUS_BAN_MAP[status || null];
      }
    } catch (e) {
      if (e?.request?.status === 500) {
        navigate('/my-campaigns/');
      }
      console.error(e);
    } finally {
      this.isCampaignLoading = false;
    }
  }

  resetCampaignData = () => {
    this.data = null;
  }

  @computed get lengthSms() {
    const MapVariables = new Map(Object.entries(this.data?.smsCampaign?.variables || {}));
    if (!this.data) return 0;
    if (this.data.smsCampaign.links.isShort && this.data.smsCampaign.shortMessage && this.data.smsCampaign.isParameterized) {
      return replaceVariables(this.data.smsCampaign.shortMessage || '', MapVariables).length - ((this.data.smsCampaign.links.shortLink?.length || 22) + StoreT.promo.length);
    }
    if (this.data.smsCampaign.links.isShort && this.data.smsCampaign.shortMessage && !this.data.smsCampaign.isParameterized) {
      return replaceVariables(this.data.smsCampaign.shortMessage || '', MapVariables).length - (this.data.smsCampaign.links.shortLink?.length || 22);
    }
    if (!this.data.smsCampaign.links.isShort && this.data.smsCampaign.shortMessage && this.data.smsCampaign.isParameterized) {
      return replaceVariables(this.data.smsCampaign.shortMessage || '', MapVariables).length - StoreT.promo.length;
    }
    if (this.data.smsCampaign.shortMessage) {
      return replaceVariables(this.data.smsCampaign.shortMessage || '', MapVariables).length;
    }
    return 0;
  }

  deleteOrderById = async (id: number) => {
    try {
      this.data = await axiosBaseRequestAdTech({
        url: `/api/orders/${id}/remove`,
        method: 'DELETE',
      });
      toastAccessNotification(`Кампания ${id} была успешно удалена`);
    } catch (e) {
      toastErrorNotification('При удалении кампании произошла ошибка. Попробуйте позднее');
    }
  }

  @action setIsSeenStopDemand = (campaignId?: number) => {
    if (this.data?.unseenStopDemandsId && this.data?.unseenStopDemandsId.length && campaignId) {
      this.data?.unseenStopDemandsId.forEach((demandId) => {
        axiosBaseRequestAdTech({
          url: `/api/campaign/${campaignId}/stopDemand/${demandId}/isSeen`,
          method: 'POST',
        });
      });
    }
  }

  @action getNamePromocod = async (orderId: number) => {
    try {
      this.namePromocode = await axiosBaseRequestAdTech({ url: `/api/orders/${orderId}/getPromocode` });
    } catch (e) {
      console.error(e);
    }
  }
}

const campaignStatistics = new CampaignStatistics();
export default campaignStatistics;
