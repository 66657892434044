import { Icon } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import React from 'react';
import classNames from 'classnames/bind';
import styles from '../styles.pcss';

const cx = classNames.bind(styles);

const MoreInfoScreen = () => {
  return (
    <>
      <div className={cx(styles.mainTitle, styles.mb16)}>
        Как ускорить согласование
      </div>
      <div className={styles.infoList}>
        <div className={styles.infoItemList} style={{ marginBottom: 48 }}>
          <Icon iconName={Icons.Check} contained type="info" />
          <div className={styles.infoText}>
            Чтобы быстрее обработать вашу заявку, указывайте понятные названия файлов: например, вывеска, домен, ИНН/ОГРН, договор, товарный знак, страницы 1,2,3,4 и т.д.)
          </div>
        </div>
      </div>
      <div className={cx(styles.mainTitle, styles.mb16)}>
        Какие документы точно не подойдут
      </div>
      <div className={styles.infoList}>
        <div className={styles.infoItemList}>
          <Icon iconName={Icons.Block} contained type="error" />
          <div className={styles.infoText} style={{ alignItems: 'center' }}>
            Выписка из ЕГРЮЛ, ФИПС
          </div>
        </div>

        <div className={styles.infoItemList}>
          <Icon iconName={Icons.Block} contained type="error" />
          <div className={styles.infoText}>
            Справки, выписки от регистраторов доменных имен
          </div>
        </div>

        <div className={styles.infoItemList}>
          <Icon iconName={Icons.Block} contained type="error" />
          <div className={styles.infoText}>
            Иностранные товарные знаки, составленные на иностранном языке (за исключением переведенных на русский язык и нотариально заверенных)
          </div>
        </div>

        <div className={styles.infoItemList}>
          <Icon iconName={Icons.Block} contained type="error" />
          <div className={styles.infoText}>
            Скриншоты о владении или оплате из личных кабинетов регистраторов доменных имен
          </div>
        </div>

        <div className={styles.infoItemList}>
          <Icon iconName={Icons.Block} contained type="error" />
          <div className={styles.infoText}>
            Патенты, УСТАВ, разрешения, лицензии, сертификаты, реквизиты юрлица
          </div>
        </div>

        <div className={styles.infoItemList}>
          <Icon iconName={Icons.Block} contained type="error" />
          <div className={styles.infoText}>
            Заявления и уведомления о подаче документов на регистрацию товарных знаков
          </div>
        </div>

        <div className={styles.infoItemList}>
          <Icon iconName={Icons.Block} contained type="error" />
          <div className={styles.infoText}>
            Просроченные и неподписанные документы, а также не полные документы, где не отображены все страницы
          </div>
        </div>

        <div className={styles.infoItemList}>
          <Icon iconName={Icons.Block} contained type="error" />
          <div className={styles.infoText}>
            Скан-копии документов в плохом качестве и в низком разрешении
          </div>
        </div>

      </div>
    </>
  );
};

export default MoreInfoScreen;
