import React from 'react';
import { Button, Icon } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import styles from './styles.pcss';

export type HeaderProps = {
  onBack?: () => void,
  onClose?: () => void,
  title: string | React.ReactNode,
  showBack?: boolean
}

export const BeelineSideSheetHeader = ({
  onBack,
  onClose,
  title,
  showBack,
}: HeaderProps) => {
  return (
    <div className={styles.header}>
      {
        onBack && showBack &&
        <Button onClick={onBack} size="large" className={styles.plainIcon} startIcon={<Icon iconName={Icons.ArrowLeft} />} />
      }
      <h5 className={styles.title}>{title}</h5>
      {
        onClose &&
        <Button onClick={onClose} size="large" className={styles.closeIcon} startIcon={<Icon iconName={Icons.Close} />} />
      }
    </div>
  );
};

export default BeelineSideSheetHeader;
