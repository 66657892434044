import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import Img from '../Preloader/image.svg';
import styles from './styles.pcss';

type Props = {
  isLoading?: boolean
  children?:ReactNode,
  className?: string
}

const LoaderFile:React.FC<Props> = ({
  children,
  isLoading,
  className,
}) => {
  return (
    <>
      {isLoading ?
        <div className={classNames(styles.wrapper, className)}>
          <Img width={24} height={24} className={styles.image} />
          <span>Идет загрузка файла</span>
        </div> :
        children
      }
    </>

  );
};

export default LoaderFile;
