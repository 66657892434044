import React from 'react';
// @ts-ignore
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { YMaps } from 'react-yandex-maps';
import { ThemeProvider } from '@beeline/design-system-react';
import { withHMR } from 'utils/hmr-provider';
import { MobxStoreProvider } from 'store/mobx';
import 'mobx-react-lite/batchingForReactDom';
import 'react-dates/initialize';
import 'styles/datepicker.css';
import 'styles/suggester.css';
import 'styles/cropper.css';
import 'styles/antd.css';
import '@beeline/design-tokens/css/iconfont/iconfont.css';
import '@beeline/design-tokens/css/font-face.css';
import '@beeline/design-tokens/css/scrollbar.css';
import Routes from 'pages/routes';
import RoutesWithTsmsAuth from 'pages/RoutesWithTsmsAuth';
import RoutesWithAggregatorAuth from 'pages/RoutesWithAggregatorAuth';
import ErrorBoundary from 'components/ErrorBoundary';


import LocationProvider from '../../utils/location/location-provider';
import ContainerDnD from '../ContainerDnD/ContainerDnD';
import UtmExtractor from '../../redesignSrc/components/UtmExtractor';
import AppUserAccessInitializer from './components/AppUserAccessInitializer';
import BaseTemplatesInitializer from './components/BaseTemplatesInitializer';


declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ym: any;
  }
  interface Array<T> {
    remove(o: T): Array<T>;
  }
}

export function App() {
  return (
    <LocationProvider>
      <MobxStoreProvider>
        <BaseTemplatesInitializer>
          <ThemeProvider isRoot theme="light">
            <DndProvider backend={HTML5Backend}>
              <ContainerDnD>
                <YMaps query={{
                      apikey: '47ccf150-20ef-479b-badf-227a4601403c',
                      lang: 'ru_RU',
                }}>
                  <ErrorBoundary>
                    <UtmExtractor>
                      <AppUserAccessInitializer
                        PageTsms={<RoutesWithTsmsAuth/>}
                        PageBeePro={<Routes/>}
                        PageAggregator={<RoutesWithAggregatorAuth/>}
                      />
                    </UtmExtractor>
                  </ErrorBoundary>
                </YMaps>
              </ContainerDnD>
            </DndProvider>
          </ThemeProvider>
        </BaseTemplatesInitializer>
      </MobxStoreProvider>
    </LocationProvider>
  );
}

export default withHMR(App);
