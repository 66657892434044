import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { observer } from 'mobx-react';
import userInfo from 'store/mobx/UserInfo';
import { axiosBaseRequestAdTech } from 'requests/helpers';

export const useUtmData = () => {
  const result = new URLSearchParams();
  const search = new URLSearchParams(useLocation().search);
  for (const [key, value] of search.entries()) {
    if (key.includes('utm_')) {
      result.append(key, value);
    }
  }
  return result.toString();
};

const UtmExtractor = ({ children }: { children: React.ReactNode }) => {
  const utmData = {
    pageUrl: window.location.origin + window.location.pathname,
    utmData: useUtmData(),
  };
  useEffect(() => {
    if (utmData.utmData && userInfo.data?.id) {
      axiosBaseRequestAdTech({
        method: 'POST',
        url: '/api/customerUser/utmData',
        data: utmData,
      });
    }
  }, [userInfo.data?.id]);
  return <>{children}</>;
};

export default observer(UtmExtractor);
