import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { FilesById } from '../../SenderNameStore';
import styles from '../styles.pcss';

const EditableField = observer((
  { setIsEditable, document, isEditable, onFileEdit }:
    {
      setIsEditable: (val: boolean) => void,
      document: FilesById,
      isEditable: boolean,
      onFileEdit?: (fileId: string, data: { customFileName: string, documentTypeName: string, documentTypeId: string }) => void
    },
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditable]);
  return (
    <>
      {
        isEditable &&
        <input
          ref={inputRef}
          onChange={(e) => {
            document.customFileName = e.target.value;
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setIsEditable(false);
              if (document.id && onFileEdit) {
                onFileEdit(
                  document.id,
                  {
                    customFileName: document.customFileName,
                    documentTypeId: `${document.documentTypeId}`,
                    documentTypeName: document.documentTypeName,
                  });
              }
            }
          }}
          value={document.customFileName}
          onBlur={() => {
            setIsEditable(false);
            if (document.id && onFileEdit) {
              onFileEdit(document.id, {
                customFileName: document.customFileName,
                documentTypeId: `${document.documentTypeId}`,
                documentTypeName: document.documentTypeName,
              });
            }
          }}
          className={styles.renameInput}
        /> || <span className={styles.fileName}>{document.customFileName}</span>
      }
    </>
  );
});

export default EditableField;
