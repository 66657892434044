import { observable, reaction } from 'mobx';

export type ComponentsNames = null | 'senderName' | 'polls' | '';

const showChat = (value: boolean) => {
  const el = document.getElementById('carrotquest-messenger-collapsed-container');
  if (el) {
    (el as HTMLDivElement).style.display = value ? 'block' : 'none';
  } else {
    setTimeout(() => {
      showChat(value);
    }, 1000);
  }
};

class SideSheetStore {
  @observable componentToShow:ComponentsNames = null;
  @observable title = '';
  @observable sideSheetComponentProps:null | unknown = null
}
const sideSheetStore = new SideSheetStore();

reaction(
  () => sideSheetStore.componentToShow,
  () => {
    if (sideSheetStore.componentToShow) {
      showChat(false);
    } else {
      showChat(true);
    }
  },
);

export default sideSheetStore;
