import { action, observable, reaction, runInAction, computed } from 'mobx';
import { navigate } from '@reach/router';
import { axiosAuthorizedRequest, composeAxiosDeleteRequest } from 'requests/helpers';
import { CAMPAIGN_API_URL, CAMPAIGN_SORT_PARAMS } from 'requests/campaigns/constants';
import { FIELD_STATUS } from 'store/shared/campaign-and-orders/constants';
import { POLLS_TARIFFS, POLLS_CREATE } from 'requests/constants';
import { debounce } from 'utils/debounce';
import { calcPriceByDiscount } from 'utils/business';
import Common from './Common';
import UserInfo from './UserInfo';
import NewCampaign from './NewCampaign';

const CHANNEL_TYPE = 5;
const ITEMS_IN_REQUEST = 5;

export const FORM_FIELDS = {
  QUESTION: 'comment',
  GEOGRAPHY: 'geography',
  PROFILE: 'profile',
  BUDGET: 'budget',
  PROMOCODE: 'promo_code',
  NAME: 'name',
};

interface IModal {
  isLoading: boolean,
  isModalOpened: boolean,
  isSuccessModalOpened: boolean,
  isFailModalOpened: boolean,
  tariff_uid?: string,
  promoCodeValue: number,
  promoCodeModalFailed: boolean,
  shouldValidatePromo: boolean,
  promoTypeId: number
  fields: {
    comment: string,
    geography: string,
    profile: string,
    budget: number | null,
    promo_code: string
  }
}

interface IValues {
  status: number,
  campaign_name: string,
}

interface IList {
  count: number,
  results: Array<{ id: string }>,
  next: string,
}

class Polls {
  @observable modal: IModal = {
    isLoading: false,
    isModalOpened: false,
    isSuccessModalOpened: false,
    isFailModalOpened: false,
    tariff_uid: undefined,
    promoCodeValue: 0,
    promoCodeModalFailed: false,
    shouldValidatePromo: false,
    promoTypeId: 0,
    fields: {
      comment: '',
      geography: '',
      profile: '',
      budget: null,
      promo_code: '',
    },
  };

  @observable tariffs = [];
  @observable isSend = false
  @observable pollsListLoading: boolean = false;
  @observable.shallow pollsList: Array<{ id: string }> = [];
  @observable totalCount: number = 0;
  @observable totalCountByChannelType: number = 0;
  @observable count: number = 0;
  @observable nextPageLink: string | null = null;

  @action setValues = (value: IValues): void => {
    this.values = value;
  };

  @action setModalFields = (value: IModal['fields']): void => {
    this.modal.fields = value;
  };

  @action erasePromoCode = (): void => {
    this.modal.fields.promo_code = '';
    this.modal.promoTypeId = 0;
    this.modal.promoCodeValue = 0;
  }

  @computed get nextCount(): number {
    const listSize = this.pollsList.length;
    const remainingItems = this.count - listSize;

    if (remainingItems < ITEMS_IN_REQUEST && remainingItems > 0) return remainingItems;
    if (remainingItems <= 0) return 0;

    return ITEMS_IN_REQUEST;
  }

  @action handleSendPoll = async (): Promise<void> => {
    // const isFilledInn = await checkForInn(
    //   () => { this.modal.isModalOpened = false; },
    //   () => { this.modal.isModalOpened = true; },
    //   { blockNameGA: 'polls' },
    // );
    // if (!isFilledInn) return;
    // this.sendPoll();
    if (!UserInfo.data?.company?.inn) {
      NewCampaign.currentCampaign.channel_uid = 'polls';
      await navigate('/polls/confirm-inn/');
      return;
    }
    this.sendPoll();
    this.isSend = false;
  }

  @action resetModalData = (): void => {
    this.modal.isLoading = false;
    this.modal.isModalOpened = false;
    this.modal.isSuccessModalOpened = false;
    this.modal.isFailModalOpened = false;
    this.modal.tariff_uid = undefined;
    this.modal.promoCodeValue = 0;
    this.modal.promoCodeModalFailed = false;
    this.modal.fields = {
      comment: '',
      geography: '',
      profile: '',
      budget: null,
      promo_code: '',
    };
  }

  @action setPromocode = (value: string) => {
    this.modal.promoCodeModalFailed = false;
    this.modal.shouldValidatePromo = true;
    this.modal.fields.promo_code = value.toUpperCase();
    this.modal.promoCodeValue = 0;
  }

  @computed get promocodeError(): string | boolean {
    if (!this.modal?.fields.promo_code) return false;
    if (!this.modal.fields.promo_code.length) {
      return 'Поле не может быть пустым';
    }
    if (this.modal.promoCodeModalFailed) return 'Данный промокод не существует или срок действия истёк';
    return false;
  }

  @computed get promocodeSuggest(): string | boolean {
    return this.modal.fields.promo_code && !this.modal.promoCodeValue && 'Нажмите "Применить" для проверки промокода' || false;
  }

  @action checkPollsPromocode = async (): Promise<void> => {
    if (this.promocodeError) return;
    if (!this.modal.fields.budget || this.modal.fields.budget < (Common?.constants?.MIN_POLL_BUDGET || 0)) {
      // спасибо "отличной" final form за то, что заставляет писать меня такой треш
      document.getElementsByName(FORM_FIELDS.BUDGET)[0].focus();
      document.getElementsByName(FORM_FIELDS.BUDGET)[0].blur();
      return;
    }
    try {
      this.modal.promoCodeModalFailed = false;
      const response = await axiosAuthorizedRequest({
        url: '/api/v1/client/campaigns/check_common_promo_code/',
        method: 'POST',
        data: {
          promo_code: this.modal.fields.promo_code,
        },
      });
      runInAction(() => {
        this.modal.promoCodeValue = response.promo_code_value;
        this.modal.promoTypeId = response.promo_code_value_type_id;
      });
    } catch (e) {
      runInAction(() => {
        this.modal.promoCodeModalFailed = true;
      });
    }
  }

  @computed get getPromoPrice(): number | null {
    if (this.modal.fields.budget) {
      return calcPriceByDiscount(
        this.modal.fields.budget,
        this.modal.promoTypeId,
        this.modal.promoCodeValue,
      );
    }
    return null;
  }

  @action getNextPollsList = async () => {
    this.pollsListLoading = true;

    if (this.nextPageLink) {
      const list: IList = await axiosAuthorizedRequest({
        url: this.nextPageLink,
      });

      runInAction(() => {
        this.pollsList.push(...list.results);
        this.pollsListLoading = false;
        this.count = list.count;
        this.nextPageLink = list.next;
      });
    }
  };

  getTotalCountByChannelType = async () => {
    const pollsResult = await axiosAuthorizedRequest({
      url: CAMPAIGN_API_URL,
      params: {
        limit: 1,
        channel_type: CHANNEL_TYPE,
      },
    });
    runInAction(() => {
      this.totalCountByChannelType = pollsResult.count;
    });
  };

  @action getPollsList = async () => {
    this.pollsListLoading = true;
    const params = new URLSearchParams();
    params.append('limit', `${ITEMS_IN_REQUEST}`);
    params.append('channel_type', `${CHANNEL_TYPE}`);
    params.append('sort', CAMPAIGN_SORT_PARAMS.CREATE_DATE_DESC);
    if (this.values.campaign_name) {
      params.append('campaign_name', this.values.campaign_name);
    }
    if (this.values[FIELD_STATUS] === 1) {
      params.append('order_status', '1');
      params.append('order_status', '0');
    } else if (this.values[FIELD_STATUS] !== 1 && this.values.status) {
      params.append('order_status', `${this.values.status}`);
    }
    params.append('sort', CAMPAIGN_SORT_PARAMS.CREATE_DATE_DESC);

    const pollsList = await axiosAuthorizedRequest({
      url: CAMPAIGN_API_URL,
      params,
    });

    runInAction(() => {
      this.pollsList = pollsList.results;
      this.pollsListLoading = false;
      this.count = pollsList.count;
      this.nextPageLink = pollsList.next;
      this.totalCount = pollsList.count;
    });
  };

  @observable.ref values: IValues = {
    status: 0,
    campaign_name: '',
  };

  @action removePollById = async (pollId: string) => {
    this.pollsListLoading = true;
    await composeAxiosDeleteRequest({ url: `${CAMPAIGN_API_URL}${pollId}` })();
    runInAction(() => {
      this.pollsList = this.pollsList.filter((item) => item.id !== pollId);
      this.pollsListLoading = false;
    });
    return Promise.resolve();
  };

  @action getTariffs = async () => {
    if (this.tariffs.length > 0) {
      return;
    }

    this.tariffs = await axiosAuthorizedRequest({ url: POLLS_TARIFFS });
  };

  @action showIsSuccessModal = (isSuccess: boolean) => {
    this.modal.isModalOpened = false;

    if (isSuccess) {
      this.modal.isSuccessModalOpened = true;
    } else {
      this.modal.isFailModalOpened = true;
    }
  };

  @action sendPoll = async () => {
    this.modal.isLoading = true;
    try {
      await axiosAuthorizedRequest({
        method: 'POST',
        url: POLLS_CREATE,
        data: {
          ...this.modal.fields,
          tariff_uid: this.modal.tariff_uid,
          budget: this.modal.fields.budget ? this.modal.fields.budget : undefined,
        },
      });
      this.showIsSuccessModal(true);

      // const dataLayer = window.dataLayer || [];
      // dataLayer.push({
      //   event: 'event_b2b_beePro',
      //   eventCategory: 'Interaction',
      //   eventAction: 'click',
      //   eventLabel: 'buttonPopUp',
      //   eventContent: 'Отправить заявку',
      //   eventOther: 'polls',
      //   blockName: null,
      // });
    } catch (e) {
      this.showIsSuccessModal(false);
    } finally {
      runInAction(() => {
        this.modal.isLoading = false;
      });
    }
  };

  @action setIsOpened = async (val: boolean) => {
    this.modal.isModalOpened = val;
  }
}

const polls = new Polls();
const updatePolls = debounce(() => polls.getPollsList(), 500);
const updateTotalCountByChannelType = debounce(() => polls.getTotalCountByChannelType(), 500);

reaction(
  () => polls.values,
  () => {
    updatePolls();
    updateTotalCountByChannelType();
  },
);

// reaction(
//   () => polls.modal.isModalOpened,
//   () => {
//     if (polls.modal.isModalOpened) {
//       const dataLayer = window.dataLayer || [];
//       dataLayer.push({
//         event: 'event_b2b_beePro',
//         eventCategory: 'Interaction',
//         eventAction: 'click',
//         eventLabel: 'button',
//         eventContent: 'Обратиться к специалисту',
//         eventOther: 'polls',
//         blockName: null,
//       });
//     }
//   },
// );

reaction(
  () => polls.modal?.fields?.budget === null || polls.modal?.fields?.budget < (Common?.constants?.MIN_POLL_BUDGET || 0),
  () => {
    polls.modal.promoCodeValue = 0;
  },
);


export default polls;
