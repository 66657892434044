import { action, observable, toJS } from 'mobx';
import UserInfo from 'store/mobx/UserInfo';
import { axiosBaseRequestAdTech } from 'requests/helpers';
import NewOrder from 'store/mobx/NewOrder';
import NewAudiences from 'store/mobx/NewAudiences';
import { TypeBanCustomer } from 'redesignSrc/components/ContractSelectionSS/ContractSelectionSSStore';


const normalizeBan = (data: TypeBanCustomer[], isCustomer?: boolean): TypeBanCustomer[] => {
  if (isCustomer) {
    const uniqueIds = new Set();
    const filteredCustomer = data.filter(el => {
      const id = el.customerId;
      if (!uniqueIds.has(id)) {
        uniqueIds.add(id);
        return true;
      }
      return false;
    });
    return filteredCustomer.map(el => ({ ...el, customerInn: el.inn || el.customerInn }));
  }
  return data.map(el => ({ ...el, customerInn: el.inn || el.customerInn }));
};

class ContractSelectionStore {
  @observable bans: TypeBanCustomer[] = []
  @observable currentBan: TypeBanCustomer | null = null
  @observable isNotEffect = false;
  @observable isDisableBan = false;
  @observable isClicked = false;


  @action getBans = async (isCustomer?: boolean) => {
    const urlBan = UserInfo.isRoleSS ? 'api/manager/bans?Take=99999' : 'api/user/bans';
    this.isDisableBan = false;
    try {
      this.bans = await axiosBaseRequestAdTech({ url: urlBan });
      this.bans = normalizeBan(this.bans, isCustomer);
      const draftBan = isCustomer ?
        this.bans.find(el => el.customerId === NewAudiences.customerId) :
        this.bans.find(el => el.ban === NewOrder.order?.ban);
      this.currentBan = draftBan || this.bans[0];
      if (this.bans.length === 1) {
        this.isDisableBan = true;
      }
    } catch (e) {
      console.error(e);
    }
  }
}

const contractSelectionStore = new ContractSelectionStore();

export default contractSelectionStore;
