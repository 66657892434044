import React, { useState } from 'react';
import ru from 'date-fns/locale/ru';
import classNames from 'classnames/bind';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import ImgCalendar from './assets/calendar.svg';
import ImgCross from './assets/cross.svg';
import styles from './DateRangePicker.pcss';
import './DateRangePicker.css';


type Props = {
  isMobile?: boolean,
  maxDate1?: Date;
  maxDate2?: Date;
  onChange: (dateStart: string | null, dateEnd: string | null) => void;
  valueDateStart: string | null;
  valueDateEnd: string | null;
  startDatePlaceholder?: string;
  endDatePlaceholder?: string;
  minDate1?: Date;
  minDate2?: Date;
  isErrorStart?: boolean,
  isErrorEnd?: boolean,
  onBlur?: () => void,
  isNotCross?: boolean
  isNotMaxDate1?: boolean
  isTimeOne?: boolean
  img2ClassName?: string
  img1ClassName?: string,
  ignoreSecondStartDate?: boolean
};

const DateRangePicker: React.FC<Props> = (props) => {
  const {
    onChange,
    maxDate1,
    maxDate2,
    minDate1,
    minDate2,
    valueDateEnd,
    valueDateStart,
    isMobile,
    startDatePlaceholder = 'Дата старта',
    endDatePlaceholder = 'Дата завершения',
    isErrorStart,
    isErrorEnd,
    onBlur,
    isNotCross,
    isNotMaxDate1,
    isTimeOne,
    img2ClassName,
    img1ClassName,
    ignoreSecondStartDate,
  } = props;
  const [isOpenStart, setIsOpenStart] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onChangeMobile = ([start, end]: [Date | null, Date | null]): void => {
    const startFormat = start && format(start, 'yyyy-MM-dd');
    const endFormat = end && format(end, 'yyyy-MM-dd');
    onChange(startFormat, endFormat);
    if (start && end) {
      setIsOpen(false);
    }
  };

  const onChangeStart = (date: Date | null) => {
    const dateFormat = date && format(date, 'yyyy-MM-dd');
    onChange(dateFormat, valueDateEnd);
    openEnd();
  };

  const onChangeEnd = (date: Date | null) => {
    const dateFormat = date && format(date, 'yyyy-MM-dd');
    setIsOpenEnd(false);
    onChange(valueDateStart, dateFormat);
  };

  const openStart = () => {
    setIsOpenStart(true);
    setIsOpenEnd(false);
  };

  const openEnd = () => {
    setIsOpenEnd(true);
    setIsOpenStart(false);
  };

  const secondStartDate = () => {
    if (minDate2) return minDate2;
    if (valueDateStart) return new Date(valueDateStart);
    return new Date();
  };

  return (
    <div className={classNames(styles.container, !isTimeOne && styles.cont2)}>
      {isMobile ?
        /* Одинарный календарик для мобилки */
        <>
          <DatePicker
            onBlur={onBlur}
            minDate={minDate1}
            maxDate={maxDate2}
            locale={ru}
            onClickOutside={() => setIsOpen(false)}
            onInputClick={() => setIsOpen(true)}
            selectsRange
            open={isOpen}
            startDate={valueDateStart ? new Date(valueDateStart) : null}
            endDate={valueDateEnd ? new Date(valueDateEnd) : null}
            onChange={onChangeMobile}
            className={classNames(styles.input, styles.inputMobile)}
            placeholderText={`${startDatePlaceholder} - ${endDatePlaceholder}`}
            dateFormat="dd.MM.yyyy"
            calendarClassName={classNames('calendarMob', 'DateRangePicker_DatePicker_calendar')}
            readOnly
          />
          <div className={styles.imgMob}>
            {valueDateStart || valueDateEnd ? (
              <ImgCross
                onClick={() => {
                  onChange(null, null);
                }}
              />
            ) : (
              <ImgCalendar onClick={() => setIsOpen(true)}/>
            )}
          </div>
        </> :
        /* Двойной календарик */
        <>
          <DatePicker
            onBlur={onBlur}
            minDate={minDate1}
            onClickOutside={() => setIsOpenStart(false)}
            locale={ru}
            placeholderText={startDatePlaceholder}
            dateFormat="dd.MM.yyyy"
            onInputClick={openStart}
            open={isOpenStart}
            selected={valueDateStart ? new Date(valueDateStart) : null}
            onChange={onChangeStart}
            maxDate={(valueDateEnd && !isNotMaxDate1 ? new Date(valueDateEnd) : maxDate1)}
            selectsStart
            startDate={valueDateStart ? new Date(valueDateStart) : null}
            endDate={valueDateEnd ? new Date(valueDateEnd) : null}
            monthsShown={2}
            className={isErrorStart ? `${styles.input} ${styles.error}` : styles.input}
            useShortMonthInDropdown
            calendarClassName="DateRangePicker_DatePicker_calendar"
            readOnly
          />
          <div className={img1ClassName || styles.img1}>
            {valueDateStart && !isNotCross ? (
              <ImgCross
                onClick={() => {
                  onChange(null, valueDateEnd);
                }}
              />
            ) : (
              <ImgCalendar onClick={openStart}/>
            )}
          </div>
          {!isTimeOne &&
            <>
              <DatePicker
                onBlur={onBlur}
                onClickOutside={() => setIsOpenEnd(false)}
                locale={ru}
                placeholderText={endDatePlaceholder}
                dateFormat="dd.MM.yyyy"
                onInputClick={openEnd}
                open={isOpenEnd}
                selected={valueDateEnd ? new Date(valueDateEnd) : null}
                onChange={onChangeEnd}
                maxDate={maxDate2}
                minDate={secondStartDate()}
                selectsEnd
                startDate={valueDateStart ? new Date(valueDateStart) : null}
                endDate={valueDateEnd ? new Date(valueDateEnd) : null}
                monthsShown={2}
                className={isErrorEnd ? `${styles.input} ${styles.error}` : styles.input}
                calendarClassName="DateRangePicker_DatePicker_calendar"
                readOnly
              />
              <div className={img2ClassName || styles.img2}>
                {valueDateEnd && !isNotCross ? (
                  <ImgCross
                    onClick={() => {
                      onChange(valueDateStart, null);
                    }}
                  />
                ) : (
                  <ImgCalendar onClick={openEnd}/>
                )}
              </div>
            </>
          }

        </>
      }
    </div>

  );
};

export default DateRangePicker;
