import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Button } from '@beeline/design-system-react';
import { DescriptionParagraph } from 'redesignSrc/UI/typography';
import UploadLink from 'redesignSrc/UI/elements/UploadLink';
import { PrimaryButton } from 'redesignSrc/UI/buttons';
import NewOrder from 'store/mobx/NewOrder';
import { axiosBaseRequestAdTech, prepareMultipartFormData } from 'requests/helpers';
import { toastErrorNotification } from 'modules/toast-notifications';
import UploadedFile from 'redesignSrc/UI/elements/UploadLink/UploadedFile';
import GreyContainerPopup from 'redesignSrc/UI/elements/GreyContainerPopup';
import StoreT from '../../storeT';
import styles from './styles.pcss';

const PromoBlock = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstEdit, setIsFirstEdit] = useState(false);
  const isEdit = StoreT.isOpenPopupPromo && NewOrder.smsCampaign.isParameterized;

  const loadingFile = async (e:File) => {
    setIsFirstEdit(true);
    try {
      setIsLoading(true);
      const request = await axiosBaseRequestAdTech({
        url: `api/campaign/${NewOrder.smsCampaign.campaignId}/file?fileType=2`,
        method: 'POST',
        data: prepareMultipartFormData({
          File: e,
        }),
      });
      StoreT.filePromo = { name: e.name, ...request };
      if (StoreT.variables.has('dynamicPromocode')) {
        StoreT.variables.set('dynamicPromocode', 'dynamicPromocode');
      }
    } catch (err) {
      console.error(err);
      toastErrorNotification('Не получилось загрузить промокоды');
    } finally {
      setIsLoading(false);
    }
  };

  const getFile = async (id?:number) => {
    const fileView = await axiosBaseRequestAdTech({
      url: `api/campaign/${NewOrder.smsCampaign.campaignId}/file/${id}`,
      responseType: 'blob',
    });
    const docLink = document.createElement('a');
    docLink.href = window.URL.createObjectURL(fileView);
    docLink.download = StoreT.filePromo?.name || 'Без названия';
    docLink.click();
  };

  const deleteFile = () => {
    StoreT.filePromo = null;
  };

  const addPromo = () => {
    NewOrder.smsCampaign.isParameterized = true;
    StoreT.isOpenPopupPromo = false;
  };

  const editPromo = () => {
    StoreT.isOpenPopupPromo = false;
  };

  useEffect(() => {
    (async () => {
      try {
        await StoreT.uploadFilePromo();
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);


  return (
    <GreyContainerPopup
      title="Добавить индивидуальные промокоды"
      onClose={() => {
        StoreT.isOpenPopupPromo = false;
      }}
    >
      <span className={styles.infoCheckBox}>Загрузите список с промокодами — мы добавим их в SMS</span>

      <div className={styles.wrapperFile}>
        <DescriptionParagraph>
          Загрузите файл в CSV формате со списком уникальных промокодов.  Количество знаков в промокоде от 3-х до 20-и, только буквы и цифры
        </DescriptionParagraph>

        <div className={styles.wrapLoad}>
          {StoreT.filePromo ?
            <>
              <UploadedFile
                name={StoreT.filePromo.name}
                id = {StoreT.filePromo.id}
                description={`Всего промокодов: ${StoreT.filePromo.rowCount}`}
                notTakeIt={StoreT.filePromo.declined !== 0 ? `Не учтено: ${StoreT.filePromo.declined}` : undefined}
                onDelete={isEdit ? deleteFile : StoreT.deleteFilePromo}
                onLoad={getFile}
                tooltipText={StoreT.infoPromo}
                />
              {StoreT.errorPromo && <p className={classNames(styles.info, styles.error)}>{StoreT.errorPromo}</p>}
            </> :
            <>
              <UploadLink
                isLoading={isLoading}
                labelInfo="Файл с промокодами"
                onChange={loadingFile}
                href="https://static.beeline.ru/upload/images/business/beeline-prodvizhenie/Пример промо-кодов.csv"
                toolTipText={
                  <>
                    <span>Каждый пользователь получит таргетированную SMS со своим персональным промокодом</span>
                    <p style={{ margin: '10px 0 0 0' }} >Промокоды не влияют на длину и стоимость SMS</p>
                  </>
                }
              />
              {StoreT.errorPromo && <p className={classNames(styles.info, styles.error)}>{StoreT.errorPromo}</p>}
            </>
            }

        </div>
        <div className={styles.wrapperButton}>

          {isEdit ?
            <Button
              variant="overlay"
              size="medium"
              disabled={ !StoreT.filePromo || !!StoreT.errorPromo || !isFirstEdit }
              onClick={editPromo}
              >
              Изменить промокод
            </Button> :
            <Button
              variant="overlay"
              size="medium"
              disabled={ !StoreT.filePromo || !!StoreT.errorPromo || NewOrder.smsCampaign.isParameterized }
              onClick={addPromo}
            >
              {NewOrder.smsCampaign.isParameterized ? 'Промокоды добавлены в текст' : 'Добавить в текст'}
            </Button>
          }

          {/* {isEdit ?
            <PrimaryButton
              className={styles.btn}
              isGray={!StoreT.filePromo || !!StoreT.errorPromo || !isFirstEdit}
              isDisabled={!StoreT.filePromo || !!StoreT.errorPromo || !isFirstEdit}
              onClick={editPromo}
            >
              Изменить промокод
            </PrimaryButton> :
            <PrimaryButton
              className={styles.btn}
              isGray={!StoreT.filePromo || !!StoreT.errorPromo || NewOrder.smsCampaign.isParameterized}
              isDisabled={!StoreT.filePromo || !!StoreT.errorPromo || NewOrder.smsCampaign.isParameterized}
              onClick={addPromo}
            >
              {NewOrder.smsCampaign.isParameterized ? 'Промокоды добавлены в текст' : 'Добавить в текст'}
            </PrimaryButton>
          } */}

        </div>

      </div>

    </GreyContainerPopup>
  );
};

export default observer(PromoBlock);
